import React from "react";

import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";

export const AjustsIcon = () => {
  const {
    state: { theme },
  } = useGlobalContext();
  const isDarkMode = theme.value === "dark";

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4999_37232)">
        <path
          d="M3.33398 17.4993V11.666"
          stroke={isDarkMode ? "#F2F2F2" : "#050505bf"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.33398 8.33333V2.5"
          stroke={isDarkMode ? "#F2F2F2" : "#050505bf"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 17.5V10"
          stroke={isDarkMode ? "#F2F2F2" : "#050505bf"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 6.66667V2.5"
          stroke={isDarkMode ? "#F2F2F2" : "#050505bf"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.666 17.5007V13.334"
          stroke={isDarkMode ? "#F2F2F2" : "#050505bf"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.666 10V2.5"
          stroke={isDarkMode ? "#F2F2F2" : "#050505bf"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.833984 11.666H5.83398"
          stroke={isDarkMode ? "#F2F2F2" : "#050505bf"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 6.66602H12.5"
          stroke={isDarkMode ? "#F2F2F2" : "#050505bf"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.166 13.334H19.166"
          stroke={isDarkMode ? "#F2F2F2" : "#050505bf"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4999_37232">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
