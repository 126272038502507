import React from "react";

export const NewsIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.69366 15.6574V18.8329L5.9711 18.0665L9.98642 15.6574H13.9688C14.8989 15.6574 15.6552 14.9011 15.6552 13.971V7.22546C15.6552 6.29541 14.8989 5.53906 13.9688 5.53906H3.85046C2.92041 5.53906 2.16406 6.29541 2.16406 7.22546V13.971C2.16406 14.9011 2.92041 15.6574 3.85046 15.6574H4.69366ZM3.85046 7.22546H13.9688V13.971H9.51928L6.38006 15.8548V13.971H3.85046V7.22546Z"
        fill="currentColor"
      />
      <path
        d="M17.3438 2.16406H7.22546C6.29541 2.16406 5.53906 2.92041 5.53906 3.85046H15.6574C16.5875 3.85046 17.3438 4.60681 17.3438 5.53686V12.2824C18.2739 12.2824 19.0302 11.5261 19.0302 10.596V3.85046C19.0302 2.92041 18.2739 2.16406 17.3438 2.16406Z"
        fill="currentColor"
      />
    </svg>
  );
};
