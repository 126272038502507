import React from "react";

export const AllAppsIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.14397 1.42969H1.7154C1.55826 1.42969 1.42969 1.55826 1.42969 1.7154V7.14397C1.42969 7.30112 1.55826 7.42969 1.7154 7.42969H7.14397C7.30112 7.42969 7.42969 7.30112 7.42969 7.14397V1.7154C7.42969 1.55826 7.30112 1.42969 7.14397 1.42969ZM6.2154 6.2154H2.64397V2.64397H6.2154V6.2154ZM14.2868 1.42969H8.85826C8.70112 1.42969 8.57255 1.55826 8.57255 1.7154V7.14397C8.57255 7.30112 8.70112 7.42969 8.85826 7.42969H14.2868C14.444 7.42969 14.5725 7.30112 14.5725 7.14397V1.7154C14.5725 1.55826 14.444 1.42969 14.2868 1.42969ZM13.3583 6.2154H9.78683V2.64397H13.3583V6.2154ZM7.14397 8.57255H1.7154C1.55826 8.57255 1.42969 8.70112 1.42969 8.85826V14.2868C1.42969 14.444 1.55826 14.5725 1.7154 14.5725H7.14397C7.30112 14.5725 7.42969 14.444 7.42969 14.2868V8.85826C7.42969 8.70112 7.30112 8.57255 7.14397 8.57255ZM6.2154 13.3583H2.64397V9.78683H6.2154V13.3583ZM14.2868 8.57255H8.85826C8.70112 8.57255 8.57255 8.70112 8.57255 8.85826V14.2868C8.57255 14.444 8.70112 14.5725 8.85826 14.5725H14.2868C14.444 14.5725 14.5725 14.444 14.5725 14.2868V8.85826C14.5725 8.70112 14.444 8.57255 14.2868 8.57255ZM13.3583 13.3583H9.78683V9.78683H13.3583V13.3583Z"
        fill="currentColor"
      />
    </svg>
  );
};
