import React from "react";

export const EnsinioLogoIcon = () => {
  return (
    <svg
      width="17"
      height="26"
      viewBox="0 0 17 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.92813 7.75398L14.0298 12.3569C15.7565 13.2474 16.25 14.4757 16.25 14.4757L16.233 13.3595L16.2107 11.6921L16.2049 11.4722C16.1709 9.36513 14.9213 7.4667 12.9986 6.62133L3.72091 2.52601C1.24887 1.33075 0.75 0.570312 0.75 0.570312L0.761162 1.21817L0.778147 2.38431L0.783485 2.93122C0.79769 3.95025 1.10071 4.94434 1.65735 5.79803C2.21399 6.65171 3.0014 7.32996 3.92813 7.75398Z"
        fill="#050505"
      />
      <path
        d="M5.65923 15.2333L13.6863 18.8895C15.0597 19.5995 15.4528 20.5744 15.4528 20.5744L15.4411 19.6897L15.4188 18.3654V18.1907C15.408 17.3722 15.1625 16.574 14.7114 15.8909C14.2603 15.2079 13.6225 14.6687 12.874 14.3375L5.50248 11.0861C3.53465 10.1398 3.13672 9.53125 3.13672 9.53125L3.14206 10.0495L3.1537 10.9735L3.15904 11.4074C3.17025 12.2164 3.41124 13.0057 3.85391 13.683C4.29658 14.3603 4.92269 14.8983 5.65923 15.2333Z"
        fill="#050505"
      />
      <path
        d="M7.32026 21.2335L13.3747 24.1122C14.4117 24.6703 14.7029 25.4366 14.7029 25.4366L14.6917 24.7377L14.6752 23.6953V23.5546C14.6524 22.2361 13.907 21.053 12.7525 20.5235L7.19651 17.9651C5.71106 17.2212 5.41406 16.7422 5.41406 16.7422L5.4194 17.1479L5.43056 17.8748L5.43639 18.2131C5.45289 19.52 6.18761 20.6977 7.31978 21.233L7.32026 21.2335Z"
        fill="#050505"
      />
    </svg>
  );
};
