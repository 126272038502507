import styled, { css } from "styled-components";

import { consolePageMobileBreakpoint } from "pages/[tenant]/console/hooks/useConsolePageConfig";

interface ISectionsDropdownButtonProps {
  shouldRotateIcon: boolean;
}

export const SectionsDropdownButton = styled.button<ISectionsDropdownButtonProps>`
  @media (min-width: ${consolePageMobileBreakpoint}px) {
    display: none;
  }

  background-color: ${({ theme }) => theme.console.backgroundColor};

  align-items: center;
  display: flex;
  gap: 0.75rem;
  justify-content: start;

  padding: 0.75rem;

  width: 100%;

  position: sticky;
  z-index: 11;

  color: ${({ theme }) => theme.console.sidebar.optionTextColor};
  font: 600 0.8125rem/1.21875rem "Inter";

  border: none;

  :hover {
    cursor: pointer;
  }

  &,
  svg {
    transition: 0.3s all;
  }

  ${({ shouldRotateIcon }) =>
    shouldRotateIcon &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;

export const InternalSectionSidebarContentContainer = styled.div`
  position: sticky;
  top: 0;
  width: 100%;

  @media (max-width: ${consolePageMobileBreakpoint}px) {
    background-color: ${({ theme }) => theme.console.backgroundColor};

    border-bottom: 1px solid ${({ theme }) => theme.console.sidebar.borderColor};

    left: 0;
    position: fixed;
    overflow: scroll;
    height: 100vh;

    padding: 140px 0.75rem 4rem 0.75rem;
    width: 100%;

    transition: 0.3s all;
    transform: translateY(-100%);
  }
`;

interface IInternalSectionOptionPageSidebarContainerProps {
  showMobileDropdown: boolean;
}

export const InternalSectionOptionPageSidebarContainer = styled.div<IInternalSectionOptionPageSidebarContainerProps>`
  background: ${({ theme }) => theme.console.sidebar.backgroundColor};
  height: 100%;

  z-index: 10;
  position: relative;

  border-right: 1px solid ${({ theme }) => theme.console.sidebar.borderColor};
  padding: 0 0.75rem;

  @media (max-width: ${consolePageMobileBreakpoint}px) {
    position: fixed;
    top: 104px;
    left: 0;
    padding: 0;

    width: 100%;
    height: fit-content;

    border-bottom: 1px solid ${({ theme }) => theme.console.sidebar.borderColor};

    ${({ showMobileDropdown }) =>
      showMobileDropdown &&
      css`
        ${InternalSectionSidebarContentContainer} {
          transform: translateY(0%);
        }
      `}
  }
`;
