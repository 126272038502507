import { detect } from 'detect-browser';

const browser = detect();

const browserList = [
  { browser: 'chrome', version: '60' },
  { browser: 'firefox', version: '52' },
  { browser: 'opera', version: '45' },
  { browser: 'edge-chromium', version: '18' },
  { browser: 'safari', version: '7' },
  { browser: 'ios', version: '7' },
  { browser: 'crios', version: '10' },
  { browser: 'samsung', version: '7' },
];

export function checkIfBrowserIsOutdated () {
  const userBrowser = browserList?.filter(
    (eachBrowser) => eachBrowser.browser === browser?.name
  );

  if (!userBrowser?.length) return false;

  const browserIsOutdated =
    Number(browser?.version.split('.')[0]) < Number(userBrowser[0]?.version);

  return browserIsOutdated;
}