/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from "react";

import useTranslation from "next-translate/useTranslation";
import PropTypes from "prop-types";

import PasswordInvalid from "shared/components/organisms/AuthModal/components/PasswordInvalid";
import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";

import { LockOutlined } from "@ant-design/icons";

interface IPasswordInputProps {
  FormItemComponent: (...args: any) => JSX.Element;
  InputComponent: (...args: any) => JSX.Element;
  getValue: (...args: any) => void;
  passwordInvalid: boolean;
}

const PasswordInput = ({
  FormItemComponent,
  InputComponent,
  getValue,
  passwordInvalid,
}: IPasswordInputProps) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const {
    state: { theme },
  } = useGlobalContext();

  const definePasswordForce = (e) => {
    const { value } = e.target;
    setPassword(value);

    if (getValue) getValue(value);
  };

  return (
    <>
      <FormItemComponent>
        <InputComponent
          style={{ height: "3.125rem" }}
          value={password.replace(" ", "")}
          onChange={definePasswordForce}
          prefix={
            <LockOutlined
              style={{
                color:
                  theme.value === "dark"
                    ? "rgba(255, 255, 255, 0.25)"
                    : "rgba(0, 0, 0, 0.25)",
              }}
            />
          }
          type="password"
          size="large"
          placeholder={t("common:password")}
          id="password"
        />

        {passwordInvalid && <PasswordInvalid />}
      </FormItemComponent>
    </>
  );
};

PasswordInput.defaultProps = {
  getValue: () => {},
};

PasswordInput.propTypes = {
  FormItemComponent: PropTypes.func.isRequired,
  InputComponent: PropTypes.func.isRequired,
  getValue: PropTypes.func,
};

export default PasswordInput;
