import { ReactNode } from "react";

import Link from "next/link";
import { useRouter } from "next/router";

import { IMenu } from "pages/[tenant]/cp/@types";

import { checkLinkPath } from "shared/providers/Layout/utils/checkLinkPath";
import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";

export const MenuItem = ({
  item,
  children,
}: {
  item: IMenu;
  children: ReactNode;
}) => {
  const { asPath } = useRouter();
  const { state } = useGlobalContext();
  const isMobileAppClient = state.isMobileAppClient;
  const isInternalPage = item.item_type === "internal";
  const currentPageIsSameAsItemHref = asPath === item?.static_url;

  const itemHref = item?.static_url || item?.internal_page;

  const itemHasHref = !!itemHref;
  const shouldShowItem = itemHasHref && item.active;

  if (!shouldShowItem) return null;
  return (
    <Link
      href={checkLinkPath(itemHref)}
      className={currentPageIsSameAsItemHref ? "active" : ""}
      target={isMobileAppClient && isInternalPage ? "_self" : item.target}
    >
      {item.name}
      {children}
    </Link>
  );
};
