import { HTMLAttributes } from "react";

import { AiOutlineLoading3Quarters } from "react-icons/ai";

import { CircularLoaderContainer } from "./styles";

interface ICircularLoaderProps extends HTMLAttributes<HTMLDivElement> {}

const CircularLoader = ({ ...props }: ICircularLoaderProps) => {
  return (
    <CircularLoaderContainer {...props}>
      <AiOutlineLoading3Quarters className="loader" />
    </CircularLoaderContainer>
  );
};

export default CircularLoader;
