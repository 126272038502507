import { IUseSideBarSections } from "pages/[tenant]/console/types";
import { useAnalyticsOptions } from "pages/[tenant]/console/utils/sectionOptions/analytics";
import { useAppStoreOptions } from "pages/[tenant]/console/utils/sectionOptions/appstore";
import { useConfigsOptions } from "pages/[tenant]/console/utils/sectionOptions/configs";
import { useGamificationOptions } from "pages/[tenant]/console/utils/sectionOptions/gamification";
import { useGroupAndContentOptions } from "pages/[tenant]/console/utils/sectionOptions/groupsAndContent";
import { useIndividualGroupOptions } from "pages/[tenant]/console/utils/sectionOptions/individualGroup";
import { usePeopleOptions } from "pages/[tenant]/console/utils/sectionOptions/people";
import { useSalesOptions } from "pages/[tenant]/console/utils/sectionOptions/sales";

export const useSideBarSections = (): {
  sectionInfo: IUseSideBarSections;
} => {
  const analyticsOptions = useAnalyticsOptions();
  const appStoreOptions = useAppStoreOptions();
  const groupsAndContentOptions = useGroupAndContentOptions();
  const salesOptions = useSalesOptions();
  const configOptions = useConfigsOptions();
  const gamificationOptions = useGamificationOptions();
  const peopleOptions = usePeopleOptions();
  const groupOptions = useIndividualGroupOptions();

  return {
    sectionInfo: {
      analytics: analyticsOptions,
      appStore: appStoreOptions,
      groupsAndContent: groupsAndContentOptions,
      sales: salesOptions,
      configs: configOptions,
      gamification: gamificationOptions,
      people: peopleOptions,
      group: groupOptions,
    },
  };
};
