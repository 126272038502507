import styled from "styled-components";

import { Form } from "@ant-design/compatible";

export const LoginStyle = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px;
  justify-content: space-between;

  input {
    width: 300px;
    margin-bottom: 9px;
  }

  button.primary.ant-btn-primary {
    padding: 0px 31px;
    font-size: 14px;
    cursor: pointer;
    width: 214px;
    height: 44px;

    margin: 10px 0 0 0;

    :hover {
      background-color: ${({ theme }) => theme.colors.secondary};
      opacity: 0.8;
    }
  }
  .ant-form-item-children {
    display: flex;
    flex-direction: column;

    .validEmail {
      min-height: 19px;
      margin: 5px 0 -2px 5px;
      color: red;
      font-size: 14px;
      line-height: 1.3;
    }
  }
`;
