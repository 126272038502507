import styled from "styled-components";

export const ModulesBaseButtonContainer = styled.button`
  display: flex;
  height: 2rem;
  padding: 0.5rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex: 1 0 0;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  opacity: 0.9;
  border: none;

  svg,
  .loader {
    width: 1rem;
    height: 1rem;
  }

  :hover:not(:disabled) {
    opacity: 1;
  }

  :disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;
