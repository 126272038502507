import React from "react";

import { TitleContainer } from "./styles";

interface ITitleProps {
  children: string;
}

export const Title = ({ children }: ITitleProps) => {
  return <TitleContainer>{children}</TitleContainer>;
};
