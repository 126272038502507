import React, { useState } from "react";

import Link from "next/link";

import DropDownGroups from "pages/[tenant]/g/components/ContextTopbar/components/DropDownGroups";
import { ActiveItemIndicator } from "pages/[tenant]/g/components/GroupNavigation/components/ActiveItemIndicator";
import { useOptionsList } from "pages/[tenant]/g/hooks/useOptionsList";

import useWindowSize from "shared/utils/hooks/useWindowSize";

import { GroupNavigationBackground, GroupNavigationContainer } from "./styles";
import useGroupContext from "../../hooks/useGroupContext";

export const GroupNavigation = () => {
  const {
    optionsList,
    setInfoToMoveActiveItemBottomIndicator,
    activeItemPosition,
    activeItemWidth,
    changeGroupInfoInRealTime,
  } = useOptionsList("#navigation");
  const [showMenuInMobile, setShowMenuInMobile] = useState(false);

  const { allowSectionChange } = useGroupContext();

  const handleClick = async ({ target }) => {
    if (!allowSectionChange) {
      return;
    }

    try {
      await changeGroupInfoInRealTime({
        route: target.getAttribute("href"),
        slug: target.getAttribute("data-groupslug"),
      });
      setInfoToMoveActiveItemBottomIndicator(target);

      if (showMenuInMobile) setShowMenuInMobile(!showMenuInMobile);
    } catch (error) {
      console.error("Erro ao mudar de seção:", error);
    }
  };

  const { width } = useWindowSize();
  const isMobile = width <= 850;

  return (
    <GroupNavigationBackground>
      <GroupNavigationContainer isMobile={isMobile} show={showMenuInMobile}>
        <ul className="navigation" id="navigation">
          {optionsList.map(
            ({ hasAccess, id, label, path, isActive, groupSlug }) =>
              hasAccess && (
                <li key={id}>
                  <Link
                    href={path}
                    className={isActive ? "active" : ""}
                    onClick={handleClick}
                    data-groupslug={groupSlug}
                  >
                    {label}
                  </Link>
                </li>
              ),
          )}
          <ActiveItemIndicator
            activeItemPosition={activeItemPosition}
            activeItemWidth={activeItemWidth}
          />
        </ul>

        {!isMobile && <DropDownGroups />}
      </GroupNavigationContainer>
    </GroupNavigationBackground>
  );
};
