import { ReactNode, createContext, useState } from "react";

import api from "shared/infra/services/api";

interface CardDetailsProps {
  id: number;
  brand: string;
  last_digits: string;
  address_id: number;
}

interface CardDataProps {
  last_digit: string;
  card_brand: string;
}

async function getOrderRequest(options: { uuid: string; status: string }) {
  try {
    const queryParams: any = {};
    const { status, uuid } = options;

    if (status) {
      queryParams["where[0][index]"] = "status";
      queryParams["where[0][condition]"] = "like";
      queryParams["where[0][value]"] = status;
    }

    if (uuid) {
      queryParams["where[1][index]"] = "uuid";
      queryParams["where[1][condition]"] = "like";
      queryParams["where[1][value]"] = uuid;
    }

    const response = await api.get("pay/order", { params: queryParams });
    return response.data.attrs;
  } catch (error) {
    console.log("error -->", error);
  }
  return null;
}

interface OrdersContextProps {
  onSearch: any;
  filterOn: boolean;
  loaderFilter: boolean;
  dataFiltered: any;
  onClearFilter: any;

  addNewCardOpen: boolean;
  setAddNewCardOpen: React.Dispatch<React.SetStateAction<boolean>>;

  selectedMethod: string;
  setSelectedMethod: React.Dispatch<React.SetStateAction<string>>;

  paymentMethod: string;
  setPaymentMethod: React.Dispatch<React.SetStateAction<string>>;

  cards: CardDetailsProps[];
  setCards: React.Dispatch<React.SetStateAction<CardDetailsProps[]>>;

  selectedCard: string;
  setSelectedCard: React.Dispatch<React.SetStateAction<string>>;

  openChangePaymentMethodModal: boolean;
  setOpenChangePaymentMethodModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;

  openChangePaymentMethodSuccess: boolean;
  setOpenChangePaymentMethodSuccess: React.Dispatch<
    React.SetStateAction<boolean>
  >;

  openSuccessRecurrenceCancelationModal: boolean;
  setOpenSuccessRecurrenceCancelationModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;

  cardData: CardDataProps;
  setCardData: React.Dispatch<React.SetStateAction<CardDataProps>>;
}

interface OrdersProviderProps {
  children: ReactNode;
  order?: any;
}

const OrdersContext = createContext({} as OrdersContextProps);

export function OrdersProvider({ children, order }: OrdersProviderProps) {

  const paymentType = order?.transactions[0]?.items?.payment_type;
  const accessPaymentMethod = order?.transactions[0]?.items?.access_payment_method;
  const orderPaymentMethod = order?.transactions[0]?.payment_method;
  const cardInformation = order?.transactions[0]?.items?.card_data;
  const accessCardInformation = order?.transactions[0]?.items?.access_card;

  const paymentMethodInitialState = paymentType === 'recurrent' && !accessCardInformation ?  accessPaymentMethod : orderPaymentMethod;
  const cardDataInitialState = paymentType === 'recurrent' && !accessCardInformation ?  accessCardInformation : cardInformation;

  const [filterOn, setFilterOn] = useState(false);
  const [loaderFilter, setLoaderFilter] = useState(false);
  const [dataFiltered, setDataFiltered] = useState(null);
  const [cardData, setCardData] = useState(cardDataInitialState);

  const [addNewCardOpen, setAddNewCardOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(paymentMethodInitialState);

  const [selectedMethod, setSelectedMethod] = useState();
  const [cards, setCards] = useState();
  const [selectedCard, setSelectedCard] = useState(null);
  const [openChangePaymentMethodModal, setOpenChangePaymentMethodModal] =
    useState(false);
  const [openChangePaymentMethodSuccess, setOpenChangePaymentMethodSuccess] =
    useState(false);
  const [
    openSuccessRecurrenceCancelationModal,
    setOpenSuccessRecurrenceCancelationModal,
  ] = useState(false);

  const onSearch = async (options: { uuid: string; status: string }) => {
    setFilterOn(true);
    setLoaderFilter(true);
    const data = await getOrderRequest(options);
    setDataFiltered(data);
    setLoaderFilter(false);
  };

  const onClearFilter = ({ setFilterValue, setStatus }: any) => {
    setFilterOn(false);
    if (setFilterValue) setFilterValue("");
    if (setStatus) setStatus("all");
  };

  return (
    <OrdersContext.Provider
      value={{
        onSearch,
        filterOn,
        loaderFilter,
        dataFiltered,
        onClearFilter,

        addNewCardOpen,
        setAddNewCardOpen,

        cards,
        setCards,

        selectedMethod,
        setSelectedMethod,

        openChangePaymentMethodModal,
        setOpenChangePaymentMethodModal,

        selectedCard,
        setSelectedCard,

        openChangePaymentMethodSuccess,
        setOpenChangePaymentMethodSuccess,

        openSuccessRecurrenceCancelationModal,
        setOpenSuccessRecurrenceCancelationModal,

        paymentMethod,
        setPaymentMethod,

        cardData,
        setCardData,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
}

export default OrdersContext;
