import { useRouter } from "next/router";

import {
  pagesToHideConsolePageSideBar,
  pagesToShowInInternalModulePage,
} from "pages/[tenant]/console/utils/layoutUtils";

export const consolePageMobileBreakpoint = 850;

export const useConsolePageConfig = () => {
  const { pathname } = useRouter();

  const sectionFromPathname = pathname.split("/").reverse()[0];
  const isInConsolePage = pathname?.includes("/console");
  const isInConsoleInternalPage =
    sectionFromPathname !== "console" && isInConsolePage;

  const shouldShowInternalSectionOptionsPageSideBar =
    pagesToShowInInternalModulePage.find((page) => pathname.includes(page));

  const shouldHideSideBar = pagesToHideConsolePageSideBar.find((page) =>
    pathname.includes(page),
  );

  const isInNotHavePermissionPage = pathname?.includes("/401");
  const isInInternalSectionOptionPage =
    !!shouldShowInternalSectionOptionsPageSideBar && !isInNotHavePermissionPage;

  const shouldShowConsolePageSideBar =
    isInConsolePage && !shouldHideSideBar && !isInNotHavePermissionPage;

  return {
    shouldShowConsolePageSideBar,
    isInConsolePage,
    isInConsoleInternalPage,
    isInInternalSectionOptionPage,
  };
};
