import { useEffect, useState } from "react";

import Image from "next/image";

import { Button, Spin } from "antd";
import useTranslation from "next-translate/useTranslation";

import Alert from "shared/components/molecules/Alert";
import auth from "shared/infra/services/auth";
import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";
import { validateEmail } from "shared/utils/regex";

import { UserOutlined } from "@ant-design/icons";

import EmailInvalid from "../../components/EmailInvalid";
import Input from "../../components/Input";
import SuccessMessage from "./components/SuccessMessage";
import { ForgotPasswordContainer, Form } from "./styles";

const FormItem = Form.Item;

const ForgotPassword = () => {
  const { t } = useTranslation();
  const { state } = useGlobalContext();

  const [message, setMessage] = useState(null);

  const [userEmail, setUserEmail] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [emailInvalid, setEmailInvalid] = useState(false);

  const [loadSpin, setLoadSpin] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const { settings } = state.tenancy;
  const isDarkMode = state.theme.value === "dark";

  const { users, site } = settings;
  const theme = settings["general-theme"];

  const resetPasswordImageStatus = users?.userResetPasswordImage;
  const resetPasswordImage = users?.userResetPasswordCustomImage;
  const defaultImage = theme?.themeLogoHorizontal;
  const fallbackImage = theme?.themeLogoTopBar;

  const platformHasNoImage = !defaultImage && !fallbackImage;

  const recoveryEmail = sessionStorage.getItem("recoveryEmail");

  useEffect(() => {
    if (recoveryEmail) {
      setEmailValue(recoveryEmail);
    }
    return () => {
      if (sessionStorage.getItem("recoveryEmail")) {
        sessionStorage.removeItem("recoveryEmail");
      }
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadSpin(true);
    setUserEmail(emailValue);
    const response = await auth.forgotPassword(emailValue);

    if (response) {
      setLoadSpin(false);
      if (response.type === "success") {
        setSuccessMessage(response.message);
      } else {
        setMessage(response.message);
      }
    }
  };

  return (
    <>
      <ForgotPasswordContainer>
        <div className="col">
          <div
            className="gx-login-header gx-text-center"
            style={{ marginBottom: 0 }}
          >
            <div
              style={{
                position: "relative",
                width: "224px",
                paddingBottom: "24%",
              }}
            >
              {resetPasswordImageStatus === "none" || platformHasNoImage ? (
                <h1 className="siteName">{site?.siteName}</h1>
              ) : isDarkMode ? (
                <Image
                  src={theme?.themeDarkModeLogoHorizontal || defaultImage}
                  fill
                  alt="logo"
                />
              ) : resetPasswordImageStatus === "default" ? (
                <Image src={defaultImage || fallbackImage} fill alt="logo" />
              ) : (
                <Image
                  src={resetPasswordImage || fallbackImage || defaultImage}
                  fill
                  alt="logo"
                />
              )}
            </div>
          </div>
          <div className="titulos">
            <h1>{users?.userResetPasswordTitle}</h1>
            <h2>{users?.userResetPasswordDescriptionTop}</h2>
          </div>

          {loadSpin && <Spin style={{ marginBottom: "0.5rem" }} />}

          {message && !successMessage && (
            <Alert message={message} error onClose={() => setMessage(null)} />
          )}
          {successMessage ? (
            <>
              <SuccessMessage
                title={t("common:aMensageWasSent")}
                msg1={t("common:openTheMessage")}
                email={userEmail}
                msg2={t("common:andClickTheLink")}
              />
            </>
          ) : (
            <Form onSubmit={handleSubmit}>
              <FormItem>
                <Input
                  type="email"
                  prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  value={emailValue.replace(" ", "")}
                  onChange={(e) => setEmailValue(e.target.value)}
                  onBlur={(e) => {
                    if (emailValue.length) {
                      if (validateEmail(emailValue)) {
                        setEmailInvalid(false);
                      } else {
                        setEmailInvalid(true);
                      }
                    }
                  }}
                  onFocus={(e) => {
                    setEmailInvalid(false);
                  }}
                  placeholder="Email"
                />
                {emailInvalid && <EmailInvalid />}
              </FormItem>

              <FormItem>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  disabled={!emailValue || emailInvalid}
                  size="large"
                >
                  {t("common:reset")}
                </Button>

                {users?.userResetPasswordDescriptionBottom ? (
                  <div
                    className="bottomDescription"
                    dangerouslySetInnerHTML={{
                      __html: users?.userResetPasswordDescriptionBottom,
                    }}
                  />
                ) : null}
              </FormItem>
            </Form>
          )}
        </div>
      </ForgotPasswordContainer>
    </>
  );
};

ForgotPassword.defaultProps = {
  authUser: null,
  alertMessage: null,
  messageSuccess: null,
};

const WrappedForgotPasswordForm = Form.create()(ForgotPassword);

export default WrappedForgotPasswordForm;
