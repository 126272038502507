import React from "react";

import Link from "next/link";

import { HelpIcon } from "pages/[tenant]/console/components/templates/ConsoleLayout/assets/icons/HelpIcon";

import { HelpActionButton } from "./styles";

interface IActionButtonProps {
  children: string;
  href: string;
}

export const ActionButton = ({ children, href }: IActionButtonProps) => {
  return (
    <Link href={href}>
      <HelpActionButton>
        <HelpIcon /> {children}
      </HelpActionButton>
    </Link>
  );
};
