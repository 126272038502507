import { IMenu, IMenus } from "pages/[tenant]/cp/@types";

export const orderMenusByPosition = (menus: IMenu[]): IMenus => {
  const menusNames: string[] = menus?.reduce(
    (acc, menu) =>
      acc?.includes(menu?.menu_type) ? acc : [...acc, menu?.menu_type],
    [],
  );

  const ordenedMenus = menusNames?.reduce(
    (acc, menuName) =>
      acc[menuName]
        ? { ...acc }
        : {
            ...acc,
            [menuName]: menus
              ?.filter((menu) => menu?.menu_type === menuName)
              ?.sort((a, b) => a?.position - b?.position),
          },
    {},
  ) as IMenus;

  return ordenedMenus;
};
