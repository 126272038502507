import api from "shared/infra/services/api";

type LinkUtmWithOrderProps = {
  utm_id: Number;
  order_id: Number;
}

type LinkUtmWithUserProps = {
  utm_id: Number;
  user_id: Number;
}

type CreateUtmProps = {
  order_id?: Number;
  user_id?: Number;
}

type FetchCreateUtm = {
  id: Number;
}

interface IUseUtm {
  createUtm: (props: CreateUtmProps) => void;
  linkUtmWithOrder: (props: LinkUtmWithOrderProps) => void;
  linkUtmWithUser: (props: LinkUtmWithUserProps) => void;
}

function useUtm(): IUseUtm {
  async function createUtm({ user_id, order_id }) {
    const utms = JSON.parse(sessionStorage.getItem("utms"));
    if (!utms?.utm_source) return

    const utm = await fetchCreateUtm(utms);

    if (!utm) return;

    if (user_id) {
      await linkUtmWithUser({ utm_id: utm.id, user_id });
    }

    if (order_id) {
      await linkUtmWithOrder({ utm_id: utm.id, order_id });
    }
  }

  async function fetchCreateUtm(utms): Promise<FetchCreateUtm> {
    try {
      const response = await api.post('/utms', { ...utms });
      console.log(response.data)
      return response.data;
    } catch (err) {
      console.log(err.response);
      return null;
    }
  }

  async function linkUtmWithOrder({ utm_id, order_id }) {
    try {
      await api.post('/utms/order', { utm_id, order_id });
    } catch (err) {
      console.log(err.response);
    }
  }

  async function linkUtmWithUser({ utm_id, user_id }) {
    try {
      await api.post('/utms/user', { utm_id, user_id });
    } catch (err) {
      console.log(err.response);
    }
  }


  return {
    createUtm,
    linkUtmWithOrder,
    linkUtmWithUser
  }
}

export default useUtm