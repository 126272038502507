import styled, { css } from "styled-components";

import { CircularLoaderContainer } from "shared/components/atoms/CircularLoader/styles";

interface ILessonListIconContainerProps {
  isHovering: boolean;
}
export const LessonListIconContainer = styled.div<ILessonListIconContainerProps>`
  .closed-book-icon,
  .drag-icon {
    transition: opacity 0.3s ease-in-out;
    min-height: auto;
    min-width: auto;
  }

  .closed-book-icon {
    opacity: ${({ isHovering }) => (isHovering ? "0" : "1")};
    height: ${({ isHovering }) => (isHovering ? "0" : "100%")};
  }

  .drag-icon {
    opacity: ${({ isHovering }) => (isHovering ? "1" : "0")};
    height: ${({ isHovering }) => (isHovering ? "100%" : "0")};
  }

  ${CircularLoaderContainer} {
    margin: 0 !important;
    svg {
      height: 1.25rem;
      width: 1.25rem;
      min-height: auto;
      min-width: auto;
    }
  }
`;

interface ILessonListItemContainerProps {
  isLessonlistVisible: boolean;
  isLessonSelected: boolean;
}

export const LessonListItemContainer = styled.div<ILessonListItemContainerProps>`
  align-items: center;
  display: flex;

  opacity: 0;
  pointer-events: none;
  height: 0;
  padding: 0;

  transition: all 0.2s ease-in-out;

  transform: translateY(-10px);

  gap: 0.75rem;

  ${LessonListIconContainer} {
    display: grid;
    place-items: center;
  }

  :hover {
    background-color: ${({ theme }) =>
      theme.console.sidebar.optionHoverBackgroundColor};
  }

  ${({ isLessonlistVisible }) =>
    isLessonlistVisible &&
    css`
      transition: all 0.1s ease-in-out;
      height: 100%;
      opacity: 1;
      pointer-events: all;
      transform: translateY(0);
      padding: 0.75rem;
    `}

  span {
    font: 600 1rem/1.125rem "Inter";
    color: ${({ isLessonSelected, theme }) =>
      isLessonSelected ? "#605cff" : theme.console.sidebar.titleColor};

    margin: 0;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    line-height: 170%;

    width: 100%;
  }

  svg {
    min-height: 1.5rem;
    min-width: 1.5rem;

    * {
      stroke: ${({ isLessonSelected, theme }) =>
        isLessonSelected ? "#605cff" : theme.console.sidebar.titleColor};
    }
  }
`;
