import { VscClose } from "react-icons/vsc";
import styled, { css } from "styled-components";

export const Close = styled(VscClose)`
  cursor: pointer;
`;

interface IErrorType {
  error: boolean;
  warning: boolean;
  success: boolean;
}

interface IContainerProps {
  errorType: IErrorType;
  margin: string;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${(props) => props.margin};
  padding: 0.5rem;
  border-radius: 0.25rem;
  max-width: 100%;

  ${(props) =>
    props.errorType.error
      ? css`
          background: #ffe7e7;
          border: 2px solid red;

          section.content svg path {
            color: red !important;
          }
        `
      : props.errorType.warning
      ? css`
          background: #fffdec;
          border: 2px solid #ffc107;

          section.content svg path {
            color: #ffc107 !important;
          }
        `
      : css`
          background: #f6ffed;
          border: 2px solid #b7eb8f;

          section.content {
            svg path {
              color: #4caf50 !important;
            }
          }
        `}

  .content {
    display: flex;

    svg {
      min-width: 1.125rem;
      width: 1.125rem;
      height: 1.125rem;
    }

    section.close {
      display: flex;
      justify-content: flex-end;

      svg {
        path {
          color: #717171 !important;
        }
      }
    }

    .message {
      padding: 0 0.5rem;

      h1.title {
        margin: -2px 0 2px 0;
        font-size: 1.125rem !important;
        color: #535353 !important;
        font-style: normal;
        font-weight: normal;
        font-family: Roboto;
      }

      h2.description {
        color: #535353 !important;
        font-style: normal;
        font-weight: normal;
        font-family: Roboto;
        max-width: 100%;
        line-height: 1.3;
        margin: 0;
        font-size: 0.875rem !important;
        text-align: center;

        flex-wrap: wrap;
      }
    }

    p {
      margin: 0 3px 0 0;
      font-weight: 600;
      cursor: pointer;
    }
  }
`;
