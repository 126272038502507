import React from "react";

export const AccountIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 2.16406C9.67591 2.16406 8.87033 2.40843 8.18512 2.86627C7.49992 3.32411 6.96587 3.97486 6.6505 4.73621C6.33514 5.49757 6.25262 6.33535 6.41339 7.14361C6.57417 7.95186 6.971 8.69429 7.55372 9.27701C8.13644 9.85973 8.87887 10.2566 9.68712 10.4173C10.4954 10.5781 11.3332 10.4956 12.0945 10.1802C12.8559 9.86486 13.5066 9.33081 13.9645 8.64561C14.4223 7.9604 14.6667 7.15482 14.6667 6.33073C14.6667 5.22566 14.2277 4.16585 13.4463 3.38445C12.6649 2.60305 11.6051 2.16406 10.5 2.16406ZM10.5 8.83073C10.0055 8.83073 9.5222 8.68411 9.11107 8.4094C8.69995 8.1347 8.37952 7.74425 8.1903 7.28744C8.00108 6.83062 7.95157 6.32796 8.04804 5.843C8.1445 5.35805 8.3826 4.91259 8.73223 4.56296C9.08186 4.21333 9.52732 3.97523 10.0123 3.87877C10.4972 3.7823 10.9999 3.83181 11.4567 4.02103C11.9135 4.21025 12.304 4.53068 12.5787 4.9418C12.8534 5.35293 13 5.83628 13 6.33073C13 6.99377 12.7366 7.62966 12.2678 8.0985C11.7989 8.56734 11.163 8.83073 10.5 8.83073ZM18 17.9974V17.1641C18 15.617 17.3854 14.1332 16.2915 13.0393C15.1975 11.9453 13.7138 11.3307 12.1667 11.3307H8.83333C7.28624 11.3307 5.80251 11.9453 4.70854 13.0393C3.61458 14.1332 3 15.617 3 17.1641V17.9974H4.66667V17.1641C4.66667 16.059 5.10565 14.9992 5.88705 14.2178C6.66846 13.4364 7.72826 12.9974 8.83333 12.9974H12.1667C13.2717 12.9974 14.3315 13.4364 15.1129 14.2178C15.8943 14.9992 16.3333 16.059 16.3333 17.1641V17.9974H18Z"
        fill="currentColor"
      />
    </svg>
  );
};
