import { Dispatch, SetStateAction, useCallback } from "react";

import {
  DragDropContext,
  DropResult,
  Droppable,
  DroppableProps,
} from "react-beautiful-dnd";

interface IEntity {
  id: number;
}

interface IUseDroppableProps extends DroppableProps {
  reorderEntity: (entityIds: number[]) => Promise<void>;
  children: any;
  entity: IEntity[];
  setEntity: Dispatch<SetStateAction<unknown>>;
}

export default ({
  reorderEntity,
  children,
  entity,
  setEntity,
  ...props
}: IUseDroppableProps) => {
  const droppableId = (Math.random() + 1).toString(36).substring(2);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) return;

    const newEntity = Array.from(entity);

    const [newOrder] = newEntity?.splice(source?.index, 1);
    newEntity?.splice(destination.index, 0, newOrder);

    setEntity(() =>
      newEntity?.map((entity, index) => ({ ...entity, position: index })),
    );

    onDragSuccess({ newEntity });
  };

  const onDragSuccess = useCallback(async ({ newEntity }) => {
    const entityIds: number[] = newEntity?.map((entity: IEntity) => entity?.id);

    await reorderEntity(entityIds);
  }, []);

  return (
    <DragDropContext onDragEnd={onDragEnd} {...props}>
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <ul
            style={{ listStyle: "none" }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {children}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};
