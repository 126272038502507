import React from "react";

export const BillboardIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6654 5.29859C14.6718 5.25215 14.6718 5.20503 14.6654 5.15859V5.10525C14.6539 5.06804 14.6382 5.03226 14.6187 4.99859C14.61 4.97939 14.5988 4.96145 14.5854 4.94525L14.5187 4.85859L14.4654 4.81859L14.3854 4.75859L8.38536 1.42525C8.28402 1.36674 8.16906 1.33594 8.05203 1.33594C7.93501 1.33594 7.82004 1.36674 7.7187 1.42525L1.7187 4.75859L1.6587 4.80525L1.58536 4.85859C1.566 4.88039 1.55024 4.90514 1.5387 4.93192C1.51737 4.95105 1.49935 4.97358 1.48536 4.99859C1.46805 5.02784 1.45461 5.05921 1.44536 5.09192C1.44163 5.11399 1.44163 5.13652 1.44536 5.15859C1.39953 5.19811 1.36114 5.24553 1.33203 5.29859V10.6319C1.3329 10.7507 1.36549 10.8671 1.42644 10.9691C1.48739 11.0711 1.57448 11.1549 1.6787 11.2119L7.6787 14.5453C7.70625 14.5613 7.73527 14.5747 7.76536 14.5853H7.83203C7.94155 14.6118 8.05584 14.6118 8.16536 14.5853H8.23203L8.32536 14.5453L14.3254 11.2119C14.4283 11.154 14.5141 11.0698 14.5738 10.9679C14.6336 10.866 14.6652 10.7501 14.6654 10.6319V5.29859ZM7.9987 7.87859L3.37203 5.29859L5.21203 4.28525L9.76536 6.88525L7.9987 7.87859ZM7.9987 2.73192L12.6254 5.29859L11.132 6.13192L6.5787 3.52525L7.9987 2.73192ZM2.66536 6.43192L7.33203 9.04525V12.8319L2.66536 10.2386V6.43192ZM8.66536 12.8319V9.04525L10.6654 7.92525V9.96525L11.9987 9.29859V7.17859L13.332 6.43859V10.2386L8.66536 12.8319Z"
        fill="currentColor"
      />
    </svg>
  );
};
