import React from "react";

import { Draggable, DraggableProps } from "react-beautiful-dnd";

interface IUseDraggableProps extends DraggableProps {
  index: number;
  children: any;
  listProps?: React.HTMLAttributes<HTMLLIElement>;
}

export default ({
  children,
  index,
  listProps,
  ...props
}: IUseDraggableProps) => {
  return (
    <Draggable
      key={index}
      index={index}
      draggableId={index?.toString()}
      {...props}
    >
      {(provided) => (
        <li
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          {...listProps}
        >
          {children}
        </li>
      )}
    </Draggable>
  );
};
