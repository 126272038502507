import React from "react";

export const DefinitionIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.14397 1.42725H1.7154C1.55826 1.42725 1.42969 1.55582 1.42969 1.71296V7.14153C1.42969 7.29868 1.55826 7.42725 1.7154 7.42725H7.14397C7.30112 7.42725 7.42969 7.29868 7.42969 7.14153V1.71296C7.42969 1.55582 7.30112 1.42725 7.14397 1.42725ZM6.2154 6.21296H2.64397V2.64153H6.2154V6.21296ZM14.2868 1.42725H8.85826C8.70112 1.42725 8.57255 1.55582 8.57255 1.71296V7.14153C8.57255 7.29868 8.70112 7.42725 8.85826 7.42725H14.2868C14.444 7.42725 14.5725 7.29868 14.5725 7.14153V1.71296C14.5725 1.55582 14.444 1.42725 14.2868 1.42725ZM13.3583 6.21296H9.78683V2.64153H13.3583V6.21296ZM7.14397 8.5701H1.7154C1.55826 8.5701 1.42969 8.69868 1.42969 8.85582V14.2844C1.42969 14.4415 1.55826 14.5701 1.7154 14.5701H7.14397C7.30112 14.5701 7.42969 14.4415 7.42969 14.2844V8.85582C7.42969 8.69868 7.30112 8.5701 7.14397 8.5701ZM6.2154 13.3558H2.64397V9.78439H6.2154V13.3558ZM14.2868 8.5701H8.85826C8.70112 8.5701 8.57255 8.69868 8.57255 8.85582V14.2844C8.57255 14.4415 8.70112 14.5701 8.85826 14.5701H14.2868C14.444 14.5701 14.5725 14.4415 14.5725 14.2844V8.85582C14.5725 8.69868 14.444 8.5701 14.2868 8.5701ZM13.3583 13.3558H9.78683V9.78439H13.3583V13.3558Z"
        fill="currentColor"
      />
    </svg>
  );
};
