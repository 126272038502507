import React from "react";

import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";

export const CheckIcon = () => {
  const {
    state: { theme },
  } = useGlobalContext();
  const isDarkMode = theme.value === "dark";

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#29D3A1" />
      <path
        d="M16.5 9L10.3125 15L7.5 12.2727"
        stroke={isDarkMode ? "black" : "white"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
