import parse from "html-react-parser";

export default function insertCustomScript(script, isBodyScript) {
  const tagsAllowedToBeInserted = ["<script", "<style", "<link", "<meta"];

  if (!tagsAllowedToBeInserted?.filter((tag) => script?.includes(tag))) {
    // eslint-disable-next-line no-eval
    eval(script);
    return;
  }

  if (isBodyScript && script.includes('<script')) {
    const parsed_script = parse(script);
    const script_func = parsed_script?.props?.dangerouslySetInnerHTML?.__html;

    if (script_func) eval(script_func);
    return
  }

  return parse(script);
}
