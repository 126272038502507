const ChevronRight = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.74609 3.74902L10.2471 8.25L5.74609 12.751"
      stroke="#AAAAAA"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChevronRight;
