import React, { useEffect, useState } from "react";

import ReactDOM from "react-dom";
import { FiArrowLeft, FiX } from "react-icons/fi";

import { Body, Container, Header, LayoutModal } from "./styles";

interface IModalProps {
  children?: React.ReactNode;
  onClose?: () => void;
  onGoBack?: () => void;
}

const Modal = ({ children, onClose, onGoBack }: IModalProps) => {
  const [show, SetShow] = useState(true);
  const [modalRootElement, setModalRootElement] = useState<any>(false);

  useEffect(() => {
    setModalRootElement(document.getElementById("modal-root"));
  }, []);

  const handleClose = () => {
    if (onClose && typeof onClose === "function") {
      onClose();
      SetShow(false);
    } else {
      SetShow(false);
    }
  };

  const handleGoBack = () => {
    if (onGoBack) onGoBack();
  };

  const modalContent = show ? (
    <Container>
      <LayoutModal>
        {(onGoBack || onClose) && (
          <Header $goBackIsEnabled={!!onGoBack}>
            {onGoBack && (
              <FiArrowLeft
                onClick={handleGoBack}
                className="back_button"
                id="back"
              />
            )}

            {onClose && (
              <FiX
                onClick={handleClose}
                className="close_button"
                id="close"
                data-testid="close"
              />
            )}
          </Header>
        )}
        <Body>{children}</Body>
      </LayoutModal>
    </Container>
  ) : null;

  if (modalRootElement) {
    return ReactDOM.createPortal(modalContent, modalRootElement);
  }
  return null;
};

export default Modal;
