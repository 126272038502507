import React from 'react'
import { Container, Title, Description, Icon } from './styles'

const SuccessMessage = (props) => (
  <Container>
    <Title>
      <Icon />
      {props.title}
    </Title>
    <Description>
      {props.msg1}:
    </Description>
    <Description>
      <b>{props.email}</b>
    </Description>
    <Description>
      {props.msg2}
    </Description>
  </Container>
);

export default SuccessMessage;