import styled from "styled-components";

import { ConsolePageButtonStyles } from "pages/[tenant]/console/components/molecules/ConsolePageForm/atoms/ConsolePageButton/styles";
import { mobileFeatureNotInPlanTemplateBreakpoint } from "pages/[tenant]/console/utils/layoutUtils";

export const ActionButtonContainer = styled(ConsolePageButtonStyles)<{
  backgroundColor: string;
}>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: #f2f2f2;

  position: relative;
  z-index: -1;

  opacity: 1;

  @media (max-width: ${mobileFeatureNotInPlanTemplateBreakpoint}px) {
    margin: 0 auto;
  }
`;
