import React from "react";

import Link from "next/link";

import { HelpIcon } from "pages/[tenant]/console/components/templates/ConsoleLayout/assets/icons/HelpIcon";
import { RocketIcon } from "pages/[tenant]/console/components/templates/ConsoleLayout/assets/icons/RocketIcon";

import { ActionButtonContainer } from "./styles";

interface IActionButtonProps {
  children: string;
  href: string;
  backgroundColor: string;
}

export const ActionButton = ({
  children,
  href,
  backgroundColor,
}: IActionButtonProps) => {
  return (
    <a href={href} target="_blank" rel="noreferrer">
      <ActionButtonContainer backgroundColor={backgroundColor}>
        <HelpIcon /> {children}
      </ActionButtonContainer>
    </a>
  );
};
