import styled from "styled-components";

export const MediaContainer = styled.div`
  border: 1px solid #373940;
  border-radius: 4px;

  grid-column: 1/-1;
  overflow: hidden;

  img {
    max-width: 100%;
  }

  :has(iframe) {
    display: flex;
    align-items: center;
    justify-content: center;

    iframe {
      width: 100%;
      aspect-ratio: 16/9;
      border: 0;
      border-radius: 4px;
    }
  }
`;
