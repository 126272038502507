import styled from "styled-components";

import { consolePageMobileBreakpoint } from "pages/[tenant]/console/hooks/useConsolePageConfig";

interface IActiveSectionIndicatorProps {
  position: number;
}

export const ActiveSectionIndicatorContainer = styled.li<IActiveSectionIndicatorProps>`
  background-color: #605cff;

  transition: 0.3s all;
  border-radius: 4px;

  z-index: 3;

  list-style: none;

  position: absolute;
  pointer-events: none;

  @media (min-width: ${consolePageMobileBreakpoint}px) {
    width: 40px;
    height: 40px;
    left: 50%;
    transform: translateX(-50%);
    top: ${({ position }) => position}px;
  }

  @media (max-width: ${consolePageMobileBreakpoint - 1}px) {
    width: 32px;
    height: 32px;
    left: 5px;

    top: calc(${({ position }) => position}px + 0.7rem);
  }
`;
