import styled from "styled-components";

interface FooterMenuMyGroupListContainerProps {
  show: boolean;
}

export const FooterMenuMyGroupListContainer = styled.div<FooterMenuMyGroupListContainerProps>`
  top: 0;
  left: 0;
  z-index: 99;
  position: fixed;

  width: 100vw;
  height: 100vh;
  padding: 1.75rem 3.75rem;
  padding-bottom: 4rem;

  background-color: ${({ theme }) => theme.colors.globalTopbar.solidBackground};
  overflow: scroll;

  transition: 0.3s all;

  ${({ show }) =>
    show ? "transform: translateX(0)" : "transform: translateX(100%)"};

  svg {
    color: ${({ theme }) => theme.colors.globalTopbar.textColor};
    width: 2rem;
    height: 2rem;
  }

  a {
    margin: .5rem 0;
    color: ${({ theme }) => theme.colors.globalTopbar.textColor};

    font-size: 1.25rem;
    font-weight: 500;
  }
`
