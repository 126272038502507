import { createContext, ReactNode, useState } from "react";

import api from "shared/infra/services/api";

async function onSearchGloballyRequest({ search, model_class }: any) {
  try {
    const response = await api.get("/globalsearch", {
      params: {
        term: search,
        model_class,
      },
    });

    return response;
  } catch (error) {
    console.log(error);
    return [];
  }
}

interface GlobalSearchContextData {
  loader: boolean;
  results: any;
  modelClass: any;
  searchText: string;
  setModelClass: (model: any) => void;
  onSearchGlobally: (search: any, model_class: any) => void;
}

interface GlobalSearchProviderProps {
  children: ReactNode;
}

const GlobalSearchContext = createContext({} as GlobalSearchContextData);

export function GlobalSearchProvider({
  children,
  ...rest
}: GlobalSearchProviderProps) {
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [results, setResults] = useState<any>([]);
  const [modelClass, setStateModelClass] = useState(null);

  function setModelClass(model: any) {
    setStateModelClass(model);
  }

  async function onSearchGlobally(search: any, model_class: any) {
    setSearchText(search);
    setLoader(true);
    const results = await onSearchGloballyRequest({ search, model_class });
    setResults(results);
    setLoader(false);
  }

  return (
    <GlobalSearchContext.Provider
      value={{
        loader,
        results,
        modelClass,
        searchText,
        setModelClass,
        onSearchGlobally,
      }}
    >
      {children}
    </GlobalSearchContext.Provider>
  );
}

export default GlobalSearchContext;
