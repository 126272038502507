import { useRef } from "react";

export default function useDebounce() {
  const timeoutRef = useRef(null);

  function debouncedFunc(func, delay, ...args) {
    window.clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(() => {
      func(...args);
    }, delay);
  }

  return debouncedFunc;
}
