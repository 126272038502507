import styled from "styled-components";

export const GroupListContainer = styled.ul`
  width: 100%;

  margin-bottom: 0;

  .my-groups,
  .no-data {
    display: flex;

    padding: 0.5rem;

    font-size: 1.125rem;
    font-weight: 600;
    line-height: 150%;

    color: ${({ theme }) => theme.groups.primaryText};
  }

  .my-groups {
    border-bottom: 1px solid ${({ theme }) => theme.groups?.borderGroups};
  }

  @media (max-width: 1200px) {
    padding: 0.25rem;

    .my-groups {
      border-bottom: none;
    }
  }

  .loader {
    width: 24px;
    height: 24px;
    margin: 0 auto;
  }
`;

export const Empty = styled.div`
  color: ${({ theme }) => theme.groups.primaryText};

  padding: 0 1rem;
  width: 100%;
  text-align: center;
`;
