import React from "react";

import { DescriptionContainer } from "./styles";

interface IDescriptionProps {
  children: string;
}

export const Description = ({ children }: IDescriptionProps) => {
  return <DescriptionContainer>{children}</DescriptionContainer>;
};
