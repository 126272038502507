import React from "react";

export const MenusIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66797 4H13.3346V5.33333H2.66797V4ZM2.66797 7.33333H13.3346V8.66667H2.66797V7.33333ZM2.66797 10.6667H13.3346V12H2.66797V10.6667Z"
        fill="currentColor"
      />
    </svg>
  );
};
