import { FiAlertTriangle } from "react-icons/fi";
import styled from "styled-components";

export const PasswordInvalidContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0.5rem 0 0;
`;

export const Text = styled.p`
  display: flex;
  align-items: center;

  font-style: normal;
  font-weight: normal;
  font-family: Roboto;

  margin: 0 0 0 8px;
  padding-top: 2px;
  color: ${({ theme }) => theme.colors.textColor};
`;

export const Image = styled(FiAlertTriangle)`
  color: #1a1a1a;
  fill: #fef65b;

  font-size: 20px;
`;
