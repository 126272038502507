import Link from "next/link";
import { useRouter } from "next/router";

import { DisabledFeatureIcon } from "pages/[tenant]/console/assets/DisabledFeatureIcon";
import {
  BackButton,
  HomeButton,
} from "pages/[tenant]/console/styles/BasePageInfoSection";

import { DisabledPageContainer } from "./styles";

export const DisabledPage = () => {
  const { back } = useRouter();

  return (
    <DisabledPageContainer>
      <DisabledFeatureIcon />

      <h1>Essa funcionalidade está desabilitada</h1>
      <p>
        Esta funcionalidade específica não está disponível no momento devido a
        manutenção, atualizações ou ela ainda não foi lançada.
      </p>

      <div className="buttons-container">
        <BackButton onClick={back}>Voltar</BackButton>
        <Link href={"/console"}>
          <HomeButton>Página inicial</HomeButton>
        </Link>
      </div>
    </DisabledPageContainer>
  );
};
