import React from "react";

export const QuizzAnswersIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.332 10.6668V2.66683H5.33203V10.6668H13.332ZM14.6654 10.6668C14.6654 11.4002 14.0654 12.0002 13.332 12.0002H5.33203C4.5987 12.0002 3.9987 11.4002 3.9987 10.6668V2.66683C3.9987 1.9335 4.5987 1.3335 5.33203 1.3335H13.332C14.0654 1.3335 14.6654 1.9335 14.6654 2.66683V10.6668ZM10.6654 13.3335V14.6668H2.66536C1.93203 14.6668 1.33203 14.0668 1.33203 13.3335V4.66683H2.66536V13.3335H10.6654ZM9.46536 3.3335C8.86536 3.3335 8.3987 3.46683 8.06536 3.7335C7.73203 4.00016 7.53203 4.40016 7.53203 4.9335H8.7987C8.7987 4.7335 8.86537 4.60016 8.9987 4.46683C9.13203 4.40016 9.26536 4.3335 9.46536 4.3335C9.66536 4.3335 9.86537 4.40016 9.9987 4.5335C10.132 4.66683 10.1987 4.80016 10.1987 5.06683C10.1987 5.26683 10.132 5.46683 10.0654 5.60016C9.9987 5.7335 9.7987 5.86683 9.66537 6.00016C9.33203 6.20016 9.06536 6.40016 8.9987 6.60016C8.73203 6.7335 8.66536 7.00016 8.66536 7.3335H9.9987C9.9987 7.1335 9.9987 6.9335 10.0654 6.86683C10.132 6.7335 10.2654 6.60016 10.3987 6.5335C10.6654 6.40016 10.932 6.20016 11.132 5.9335C11.332 5.60016 11.4654 5.3335 11.4654 5.00016C11.4654 4.46683 11.2654 4.06683 10.932 3.80016C10.5987 3.46683 10.0654 3.3335 9.46536 3.3335ZM8.66536 8.00016V9.3335H9.9987V8.00016H8.66536Z"
        fill="currentColor"
      />
    </svg>
  );
};
