import styled from "styled-components";

export const SubSectionContainer = styled.div`
  margin-top: 0.75rem;

  & + & {
    margin-top: 1.5rem;
  }

  .subsection-label {
    color: ${({ theme }) => theme.console.sidebar.subSectionTitleColor};
    text-transform: uppercase;

    margin-bottom: 0.12rem;

    font: 600 0.625rem/0.9375rem "Inter";
  }
`;
