import styled from "styled-components";

import { CardContainer } from "pages/[tenant]/search/components/atoms/Card/styles";
import { CardsListContainer } from "pages/[tenant]/search/components/atoms/molecules/CardsList/styles";

interface ISearchContainerProps {
  show: boolean;
}

export const SearchContainer = styled.div<ISearchContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  transition: 0.3s all;

  padding-bottom: 4rem;

  overflow: scroll;

  background-color: ${({ theme }) => theme.colors.globalTopbar.solidBackground};

  ${({ show }) =>
    show ? "transform: translateY(0)" : "transform: translateY(100%)"};

  color: ${({ theme }) => theme.colors.globalTopbar.textColor};

  ${CardsListContainer} {
    padding: 0 0.5rem;
    margin: 0;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex-wrap: wrap;

    ${CardContainer} {
      max-width: initial !important;
    }

    a {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 0.5rem;

      height: initial;

      color: ${({ theme }) =>
        theme.mode === "dark" ? "#f2f2f2" : "#050505bf"};

      font: 600 1rem/1.5rem "Inter";

      :hover {
        background: ${({ theme }) => theme.groups.backgroundOptionsMenuTopBar};
      }
    }

    a,
    img,
    ${CardContainer}, [data-testid="fallback-background"] {
      aspect-ratio: initial;
    }

    .group-info {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
    }
  }
`;

export const Empty = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.searchPage.inputFocusColor};

  margin: 3rem 0;
  font: 500 1rem/1.5rem "Inter";
`;

export const SearchInputContainer = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 99;
  padding: 0.5rem;

  background-color: ${({ theme }) => theme.colors.globalTopbar.solidBackground};

  :focus-within,
  :has(input.is-filled) {
    input {
      color: ${({ theme }) =>
        theme.colors.searchPage.mobileBottomNavigationInputColor};
    }

    svg &.clearInput {
      stroke: ${({ theme }) =>
        theme.colors.searchPage.mobileBottomNavigationInputColor};
    }

    path {
      stroke: ${({ theme }) =>
        theme.colors.searchPage.mobileBottomNavigationInputColor};
    }
  }

  input,
  svg path {
    transition: 0.3s all;
  }

  button {
    background: transparent;
    border: none;
    padding: 0.5rem 1rem;
    position: absolute;
    top: calc((100% / 2) - 1rem);
    left: 0.5rem;
    font-size: 0;

    :disabled {
      pointer-events: none;
    }

    :hover:not(:disabled) {
      cursor: pointer;
      pointer-events: all;
    }

    svg {
      width: 1rem;
      height: 1rem;
    }
  }

  input {
    color: ${({ theme }) => theme.controlPanel.groups.inputColor};
    font: 500 0.875rem/1.3125rem "Inter";

    padding: 0.5rem 0.75rem;
    padding-left: 3rem;

    border-radius: 4px;

    background: ${({ theme }) =>
      theme.colors.searchPage.mobileBottomNavigationInputBackground};
    border: none;

    width: 100%;
  }
`;
