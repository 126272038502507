import React from "react";

export const PermissionIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3346 5.3335V4.66683C11.3346 2.82883 9.8393 1.3335 8.0013 1.3335C6.1633 1.3335 4.66797 2.82883 4.66797 4.66683V6.66683H4.0013C3.26597 6.66683 2.66797 7.26483 2.66797 8.00016V13.3335C2.66797 14.0688 3.26597 14.6668 4.0013 14.6668H12.0013C12.7366 14.6668 13.3346 14.0688 13.3346 13.3335V8.00016C13.3346 7.26483 12.7366 6.66683 12.0013 6.66683H6.0013V4.66683C6.0013 3.56416 6.89863 2.66683 8.0013 2.66683C9.10397 2.66683 10.0013 3.56416 10.0013 4.66683V5.3335H11.3346ZM12.0013 8.00016L12.0026 13.3335H4.0013V8.00016H12.0013Z"
        fill="currentColor"
      />
    </svg>
  );
};
