import { usePathname } from "next/navigation";
import { useRouter } from "next/router";

import { ModulesIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/individualGroup/ModulesIcon";
import { IOptionsHook, ISubSection } from "pages/[tenant]/console/types";

export const useIndividualGroupOptions = (): IOptionsHook => {
  const { query } = useRouter();
  const pathname = usePathname();

  const sectionLabel = "Configuração do grupo";

  const individualGroupSubSection: ISubSection[] = [
    {
      label: "",
      options: [
        {
          id: 1,
          label: "Módulos",
          path: `/console/groups/${query?.group_slug}/modules`,
          icon: <ModulesIcon />,
          shouldHide:
            pathname !== `/console/groups/${query?.group_slug}/modules`,
        },
        {
          id: 2,
          label: "Módulos ",
          path: `/console/groups/${query?.group_slug}/modules/${query?.module_slug}/lesson-types`,
          icon: <ModulesIcon />,
          shouldHide:
            pathname !==
            `/console/groups/${query?.group_slug}/modules/${query?.module_slug}/lesson-types`,
        },
        {
          id: 3,
          label: "Módulos  ",
          path: `/console/groups/${query?.group_slug}/modules/${query?.module_slug}/lesson-types/${query?.lesson_slug}`,
          icon: <ModulesIcon />,
          shouldHide:
            pathname !==
            `/console/groups/${query?.group_slug}/modules/${query?.module_slug}/lesson-types/${query?.lesson_slug}`,
        },
      ],
    },
  ];

  return {
    label: sectionLabel,
    subSections: individualGroupSubSection,
  };
};
