import styled from "styled-components";

import { JoinButtonContainer } from "pages/[tenant]/g/components/JoinButton/styles";

interface IAboutSideBarContainerProps {
  imageFailedToLoad?: boolean;
}

export const AboutSideBarContainer = styled.div<IAboutSideBarContainerProps>`
  display: flex;
  flex-direction: column;

  gap: 1rem;
  width: 100%;
  margin-bottom: 3rem;
  margin-top: ${(props) => (props.imageFailedToLoad ? "0px" : "-100px")};

  position: relative;

  @media (max-width: 1200px) {
    margin: 0;
  }

  @media (max-width: 650px) {
    align-items: center;
  }

  ${JoinButtonContainer} {
    width: 100%;

    button {
      position: initial;
      width: 100%;
    }
  }
`;

export const Image = styled.img`
  width: 100%;
  border-radius: 4px;

  object-fit: cover;
`;
