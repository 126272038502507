import React, { useContext } from "react";

import GlobalContext from "shared/providers/context/GlobalContext";

import Content from "./content";

const OnlyEnrolledUsersPermission = () => {
  const { state } = useContext(GlobalContext);

  const show = state.enrolledUsersPermission?.show;

  return <>{show && <Content />}</>;
};

export default OnlyEnrolledUsersPermission;
