import React from "react";

export const NotificationsIcon = () => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="notifications-icon"
    >
      <path
        d="M11.5 20.1673C12.0677 20.168 12.6215 19.9919 13.0844 19.6633C13.5474 19.3347 13.8965 18.8701 14.0832 18.334H8.91683C9.10352 18.8701 9.45261 19.3347 9.91555 19.6633C10.3785 19.9919 10.9323 20.168 11.5 20.1673ZM17.9167 13.3712V9.16732C17.9167 6.2184 15.9138 3.73423 13.2004 2.98715C12.9318 2.31065 12.2755 1.83398 11.5 1.83398C10.7245 1.83398 10.0682 2.31065 9.79958 2.98715C7.08625 3.73515 5.08333 6.2184 5.08333 9.16732V13.3712L3.51858 14.9359C3.4333 15.0209 3.36566 15.1219 3.31956 15.2331C3.27347 15.3443 3.24983 15.4636 3.25 15.584V16.5007C3.25 16.7438 3.34658 16.9769 3.51849 17.1488C3.69039 17.3207 3.92355 17.4173 4.16667 17.4173H18.8333C19.0765 17.4173 19.3096 17.3207 19.4815 17.1488C19.6534 16.9769 19.75 16.7438 19.75 16.5007V15.584C19.7502 15.4636 19.7265 15.3443 19.6804 15.2331C19.6343 15.1219 19.5667 15.0209 19.4814 14.9359L17.9167 13.3712Z"
        fill="#848484"
      />
    </svg>
  );
};
