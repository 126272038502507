import React from "react";

export const CustomScriptIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.91568 11.1842L5.74901 10.1429L3.06634 7.9969L5.74901 5.8509L4.91568 4.80956L1.58234 7.47623C1.50424 7.53868 1.44119 7.61791 1.39786 7.70804C1.35453 7.79817 1.33203 7.89689 1.33203 7.9969C1.33203 8.0969 1.35453 8.19562 1.39786 8.28575C1.44119 8.37588 1.50424 8.45511 1.58234 8.51756L4.91568 11.1842ZM11.0823 4.80956L10.249 5.8509L12.9317 7.9969L10.249 10.1429L11.0823 11.1842L14.4157 8.51756C14.4938 8.45511 14.5568 8.37588 14.6002 8.28575C14.6435 8.19562 14.666 8.0969 14.666 7.9969C14.666 7.89689 14.6435 7.79817 14.6002 7.70804C14.5568 7.61791 14.4938 7.53868 14.4157 7.47623L11.0823 4.80956ZM9.98301 2.1409L7.31634 14.1409L6.01434 13.8516L8.68101 1.85156L9.98301 2.1409Z"
        fill="currentColor"
      />
    </svg>
  );
};
