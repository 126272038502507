const dark = (tenant) => {
  const setStyleProperty = (property) =>
    tenant ? tenant[property] : "#FFFFFF";

  return {
    settings: {
      themeMainMenu: tenant?.themeMainMenu,
    },
    colors: {
      gray100: "#1A1A1A",
      gray200: "#404040",
      gray300: "#666666",
      gray400: "#8C8C8C",
      gray500: "#B2B2B2",
      gray600: "#D9D9D9",
      gray700: "#F2F2F2",

      primary: setStyleProperty("themeDarkModeMasterColor"),
      secondary: setStyleProperty("themeMasterColor"),
      textMasterColor: setStyleProperty("themeDarkModeTextMasterColor"),
      topbar: "#0e1217",
      topbarText: "#FFFFFF",
      sidebar: setStyleProperty("themeTopBarBackgroundColor"),
      sidebarBackgroudColor: "#121212",
      sidebarItemColor: "#ffffff",
      sidebarColapseIconColor: "#ffffff",
      backgroundColor: "#141414;",
      secondaryBackgroundColor: "#181818",
      textColor: "#f1f1f1",
      btnTextColor: "#1a1a1a",
      blogTextColorPrimary: "#F2F2F2",
      blogTextColorSecondary: "#D9D9D9",
      blogTextColorTertiary: "#8C8C8C",
      cardBlog: "#1a1a1a",
      inputBackgroundColor: "#242424",
      modalBackgroundColor: "#1D1D1D",
      headerBackgroundColor: "#404040",
      headerTextColor: "#f7f7f7",
      borderColor: "#262626",
      collectionsInputBorderColor: "#606060",
      followButtonColor: "#000000",
      dividerColor: "#262626",
      globalSearchInputBackgroundColor: "rgb(50, 50, 50)",
      enrollmentCardTitleBackground: "#4d4d4d",
      customSwitchBackground: "#808080",
      textColorInstallments: "#f1f1f1",

      globalTopbar: {
        linearGradient:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 73.96%)",
        solidBackground: "#1e1f23",
        primaryBackground: "#050505",
        secondaryBackground: "#131313",
        textColor: "#F2F2F2bf",
        textHoverColor: "#F2F2F2",
        borderColor: "#373940",
        userMenuBackground: "#ffffff1a",
        menuItensColor: "#C8C8C9",
        menuItensHoverColor: "rgba(255, 255, 255, 0.1)",
        bottomIconsActiveColor: "#f2f2f2",
        bottomIconsColor: "#848484",
        iconsColor: "#f2f2f2",
      },

      searchPage: {
        inputColor: "#757575",
        inputFocusColor: "#F2F2F2",
        cardHoverBorderColor: "white",
        mobileBottomNavigationInputColor: "white",
        mobileBottomNavigationInputBackground: "#373940",
      },
    },
    checkoutOnePage: {
      textColor: "#ffffff",
      placeholderColor: "#B3B3B3",
      inputBorderColor: "#404040",
      inputBackground: "#262626",
      buttonBackgroundDisabled: "#404040",

      leftSideBoxShadow: "0.5rem 0rem 3.5rem rgba(41, 41, 41, 0.08)",

      leftSideBackgroud: "#1A1A1A",
      rightSideBackground: "#141414",

      green: "#0D8020",
      blue: "#0073E5",
      red: "#E50000",
    },
    groups: {
      backgroundColor: "#17181C",

      iconsTopBar: "#F2F2F2",
      primaryText: "#F2F2F2",
      secondaryText: "#B3B3B3",
      nexusLinkTextColor: "#c8c8c9",
      borderGroups: "#1e1e1e",
      groupFeedTextColor: "#F2F2F2",
      groupFeedBackgroundColor: "#1C1C1C",
      groupFeedCommentBackgroundColor: "#1C1C1C",
      groupFeedCommentCorderColor: "#373940",
      groupFeedCommentCorderColorHover: "#373940cc",
      backgroundGroups: "#050505",
      backgroundHoverGroups: "#212121",
      borderGroupSelected: "#242424",
      topBarGroupsBackground: "#000000",
      backgroundComponentsMural: "#1E1F23",
      borderColorComponentsMural: "#282A30",
      backgroundOptionsMenuTopBar: "rgba(255,255,255,0.1)",
      backgroundOptionsMenuTopBarHover: "#282A30",
      primaryTextTopbarGroups: "#F2F2F2",
      descriptionTextTopbarGroups: "#F2F2F2cc",
      secondaryTextTopbarGroups: "#B3B3B3",
      thirdTextTopbarGroups: "#F2F2F2",
      strokeOptionsMenu: "#FFFFFF",
      offerItemBorder: "#1e1e1e",
      layoutOptionBackground: "#26282D",
      layoutOptionBackgroundHover: "#212121cc",
      layoutImageColor1: "#373940",
      layoutImageColor2: "#454851",

      activitiesInputBackgroundColor: "#303030",
      activitiesModalBackgroundColor: "#212121",
      activitiesButtonBackgroundColor: "#303030",

      backgroundColorDetail: "#60636D",
      backgroundColorInteraction: "#393939",
      backgroundButtonPublishFeed: "#313131",

      backgroundModeration: "#050505",
      backgroundEditButtonModeration: "#3C3C3C",

      dropdownModeration: "#303030",
      backgroundDropdownModeration: "#262626",
      inputBackgroundModeration: "#262626;",

      backgroundDeleteGroup: "#212121",
      backgroundSideMenuModeration: "#262626",
      backgroundMobileMenuModeration: "#141414",

      moderationTopicLessonBackground: "#2c2c2c",
      moderationSectionTopbarBackground: "#232323",
      moderationContainerBorder: "#373940",

      groupModerationCards: "#262626",
      textFeedDialog: "#8C8C8C",
      borderFooterDialog: "#363636",
      activeSectionModeration: "rgba(255, 255, 255, 0.05)",
      boxShadowModeration: "rgba(255, 255, 255, 0.08)",
      iconsMenuFooterMobile: "#F2F2F2",

      createLessonButton: "#464646",
      borderTitle: "#262626",
      lessonStatusBorder: "#323232",
      lessonHover: "#262626",
      customSwitchBall: "rgba(255,255,255,0.4)",

      singleOfferBackgroundHover: "#282828",
      singleOfferBackgroundChecked: "#1d1d1d",
      listOffersBorder: "#262626",

      listOffersScrollbarTrack: "#ffffff0a",
      listOffersScrollbarThumb: "#1c1c1c",
      listOffersScrollbarThumbBorder: "#ffffff2f",

      createOfferModalPlaceholder: "#435363",
      createOfferModalBlur: "#0505057f",
      createOfferModalBackground: "#1c1c1c",
      createOfferModalSectionHeaderIcon: "#212121",
      createOfferModalSectionBorderBottom: "#262626",
      createOfferModalSelectOptionHover: "#3c3c3c",
      createOfferModalCheckboxBorder: "#8c8c8c",
      createOfferModalBorder: "#212121",

      tooltipBackground: "#181818",
      tooltipText: "#B3B3B3",

      questionsStatement: "#292929",
      questionsContentHover: "#262626",
      questionsContent: "#262626CC",
      questionsborderBottom: "#303030",
      questionsInput: "#303030",
      questionDificultyHover: "rgb(41, 41, 41)",
      questionDificulty: "rgba(41, 41, 41, 0.8)",
      questionBorder: "#2D2D2D",

      groupTopbarTabColor: "#C8C8C9",
      groupTopbarTabActiveColor: "#F2F2F2",

      contextualMenuBackgroundColor: "#17181c",
      contextualMenuIconColor: "#F2F2F2",

      lives: {
        inputBackgroundColor: "#373940",
        modalBackgroundColor: "#1E1F23",
      },
      commissioning: {
        backgroundColorComponents: "#1E1F23",
        primaryText: "#F2F2F2",
        secondaryText: "rgba(242,242,242,0.8)",
        backgroundSwitch: "#6c6c6c",
        switch: "#808080",
        border: "#262626",
        borderActive: "#FFFFFF",
        input: "#373940",
        textActive: "#FFFFFF",
        textInactive: "#C8C8C9",
        textHeaderTable: "#f2f2f2cc",
        TopBarDescription: "#282A30",
      },

      nexus: {
        boxBackgroundColor: "#1E1F23",
        titleColor: "#F2F2F2",
        borderColor: "#373940",
        selectBackgroundColor: "#373940",
        selectTextColor: "#C8C8C9",
      },

      gamification: {
        cardBorder: "#373940",
        cardBackground: "#1E1F23",
        inputBackground: "#373940",
        acceptMissionButtonBackground: "#ffffff",
        acceptMissionButtonColor: "#1E1F23",
        pageBackgroundColor: "#050505",
      },

      ranking: {
        cardBackgroundColor: "#1e1f23",
        cardHoverBackgroundColor: "#282A30",
        cardBorderColor: "#282a30",
        cardNameColor: "#fff",
        cardExpColor: "#eeb116",
        cardPositionColor: "#fff",
        cardPositionBackgroundColor: "#282A30",
        lastTimeUpdatedCardBackgroundColor: "#1e1f23",
        lastTimeUpdatedCardBorderColor: "#282a30",
        lastTimeUpdatedCardTextColor: "rgba(242, 242, 242, 0.8)",
      },
    },
    classwork: {
      backgroundColor: "#17181C",
      primaryColor: "#282A30",
      lesson: {
        backgroundColor: "#17181C",
        buttonBackgroundColor: "#282A30",
        inputBackgroundColor: "#373940",
        videoDetailsBackground: "#1e1f23",
        navigationHover: "#282A30",
        borderColor: "#373940",
      },
    },
    orders: {
      cardBackground: "#373940",
      modalBackground: "#1E1F23",
      cardBorder: "#373940",
    },
    controlPanel: {
      backgroundControlPanel: "#17181C",
      backgroundModalControlPanel: "#1E1F23",
      backgroundSectionButtonModal: "#373940",
      textControlPanel: "#F2F2F2",
      backgroundColorTolltip: "#101010",
      secondaryBackgroundControlPanel: "#1E1F23",
      backgroundColorTagsInfo: "#373940",
      iconsControlPanel: "#F2F2F2",
      backgroundButtonActive: "#1E1F23",
      secondaryTextControlPanel: "#8C8C8C",
      borderTableControlPanel: "#373940",
      backgroundShadowModal: "rgb(0, 0, 0, 0.8)",
      backgroundColorRowTable: "#1c1c1c",
      backgroundDetailsControlPanel: "#262626",
      backgroundInputControlPanel: "#303030",
      bordersControlPanel: "#2C2C2C",
      backgroundButtonInactive: "#393939",
      tertiaryTextControlPanel: "#8C8C8C",
      backgroundTableModal: "#262626",
      backgroundInputValue: "#252525",
      secondaryButton: "#F2F2F2",
      quaternaryTextControlPanel: "#FFFFFF",
      firstTableBackground: "#17181C",
      secondTableBackground: "#373940",
      primaryTextTable: "#8C8C8C",
      secondaryTextTable: "#FFFFFF",
      searchInputText: "#B3B3B3",
      textButtonWithdraw: "#6C6C6C",
      borderButtonSectionStatement: "#262626",
      hoverButtonSectionStatement: "rgba(33, 33, 33, 0.2)",
      billboardBackgroundListItems: "#262626",
      billboardBackgroundButtonConfig: "#303030",
      disabledButtonText: "rgba(179, 179, 179, 0.3)",
      backgroundIcon: "#373940",
      backgroundFilledInput: "#272727",
      textFilledInput: "#F2F2F2",
      borderCheckbox: "#404040",
      backgroundSoonCard: "rgba(30, 31, 35, 0.3)",
      cardBorder: "#373940",
      buttonSeeOnEnsinio1: "#23A840",
      buttonSeeOnEnsinio1Hover: "rgba(35, 168, 64, 0.8)",
      borderCharts: "#212121",
      primaryColorTableDetails: "#1E1F23",
      secondaryColorTableDetails: "#373940",
      backgroundCard: "#1E1F23",
      backgroundCardHover: "rgba(30, 31, 35, 0.9)",
      backgroundFilter: "#1E1F23",
      secondaryTopBarButton: "#373940",
      secondaryTopBarButtonHover: "#373940cc",
      backgroundTooltip: "#282A30",
      backgroundImageFallback: "#1E1F23",

      groups: {
        containerBackground: "#262626",
        headerBackground: "#292929",
        inputColor: "#8C8C8C",
        titleRowColor: "#F2F2F2",
        textRowColor: "#B3B3B3",
        inputDetailsBackground: "#303030",
        inputTableBackground: "#303030",
        inputFocusColor: "#FFFFFF",
        moderatorTagBackground: "#353535",
        tableRowHoverColor: "#353535",
      },
      videos: {
        dateInputColor: "#f2f2f2cc",
        dateInputBackgroundColor: "#303030",
        videoInfoInSettingsPageColor: "#c0c0c0",
        videoCardNumbersColor: "#ffffffcc",
        videoCardTitleColor: "#FFFFFF",
        videoCardHoverBackgroundColor: "#303030",
        deleteVideoModalBackgroundColor: "#1e1f23",
      },
      menu: {
        inputColor: "#c5c5c5",
        inputFocusColor: "#F2F2F2",
        inputBackgroundColor: "#373940",
        boxBorderColor: "#373940",
        boxBackgroundColor: "#1E1F23",
        textColor: "#f1f1f1",
        descriptionColor: "#B3B3B3",
        boxDescriptionColor: "#f2f2f2cc",
        boxLinkColor: "#C8C8C9",
        switchBackground: "#373940",
        pageBackgroundColor: "#17181c",
      },
      tools: {
        containerBorderColor: "#373940",
        modalBackgroundColor: "#1E1F23",
        modalBorderColor: "#373940",
        modalBackgroundColorHover: "#00000033",
        pixelInputColor: "#373940",
        placeHolderColor: "#8C8C8C",
      },
    },
    console: {
      backgroundColor: "#000",
      cardHoverColor: "#0E1216",
      cardDescriptionColor: "#C8C8C9",
      pageTitleColor: "#F2F2F2",
      pageSubtitleColor: "#C8C8C9",
      disabledFeatureButtonTextColor: "#FAFAFA",
      inputBorderColor: "#464646",
      inputColor: "#FFFFFF",
      secondaryButtonBackground: "#202020",
      secondaryButtonTextColor: "#FAFAFA",
      tableBorderColor: "#464646",
      tableDotsOptionHover: "#1E1E1E",
      tableOptionsBackground: "#000000",
      tableOptionsBorder: "#373940",
      sidebar: {
        backgroundColor: "#211e1f",
        titleColor: "#f2f2f2",
        subSectionTitleColor: "#959595",
        borderColor: "#373940",
        tooltipBackgroundColor: "#333131",
        tooltipTextColor: "#FFF",
        iconButtonActiveBackgroundColor: "#333131",
        optionTextColor: "#C8C8C9",
        optionHoverBackgroundColor: "#333131",
        mobileSectionTextColor: "#FFF",
      },
      design: {
        imageBackgroundColor: "#333131",
      },
    },
    affiliates: {
      background: "#050505",
    },
  };
};

export default dark;
