import React from "react";

import { AnalyticsDashboards } from "pages/[tenant]/console/components/templates/ConsoleLayout/assets/featureIllustrations/AnalyticsDashboards";
import { AppStoreTrail } from "pages/[tenant]/console/components/templates/ConsoleLayout/assets/featureIllustrations/AppStoreTrail";
import { TemplateFeatureNotInPlan } from "pages/[tenant]/console/components/templates/ConsoleLayout/components/template/TemplateFeatureNotInPlan";

export const FeatureNotAvailable = () => {
  return (
    <TemplateFeatureNotInPlan
      topFlag={{
        text: "Esta funcionalidade estará disponível em breve!",
      }}
      backgroundColor="#EC3434"
      topContent={{
        title: "App Store",
        description:
          "Estenda a funcionalidade da sua plataforma com inúmeros apps que facilitam sua vida e permitem uma integração rápida com serviços terceiros.",
        illustration: <AppStoreTrail />,
        actionButton: {
          text: "Saiba mais sobre a App Store",
          href: "https://ajuda.ensinio.com/hc/pt-br/articles/23292292526989-Perguntas-e-respostas-sobre-a-App-Store",
        },
      }}
      checkList={[
        {
          id: 1,
          text: "Serão mais de 20 apps e integrações nativas",
        },
        {
          id: 2,
          text: "Utilize soluções populares para problemas comuns",
        },
        {
          id: 3,
          text: "Configurações simples e ativação com um click",
        },
        {
          id: 4,
          text: "Maior liberdade para criar fluxos de integração",
        },
      ]}
    />
  );
};
