/* eslint-disable react/button-has-type */
import { useContext, useState } from "react";

import Image from "next/image";

import { Spin } from "antd";
import useTranslation from "next-translate/useTranslation";

import Alert from "shared/components/molecules/Alert";
import AuthContext from "shared/providers/context/AuthContext";
import GlobalContext from "shared/providers/context/GlobalContext";

import Login from "./components/Login";
import { RegisterModalContainer } from "./styles";

function RegisterModal({ navigate }) {
  const { t } = useTranslation();
  const { state, setState } = useContext(GlobalContext);
  const { register } = useContext(AuthContext);

  const [loadingSpin, setLoadingSpin] = useState(false);
  const [emailAlreadyInUse, setEmailAlreadyInUse] = useState(false);
  const [message, setMessage] = useState(null);

  const { settings } = state.tenancy;
  const isDarkMode = state.theme.value === "dark";

  const { users, site } = settings;
  const theme = settings["general-theme"];

  const signUpImageStatus = users?.userSignUpImage;
  const registerImage = users?.userSignUpCustomImage;
  const defaultImage = theme?.themeLogoHorizontal;
  const fallbackImage = theme?.themeLogoTopBar;

  const title = users?.signUpTitle;
  const description = users?.userSignUpDescriptionTop;

  const platformHasNoImage = !defaultImage && !fallbackImage;

  const handleRegister = async (data) => {
    const { email, password, name } = data;

    if (email && password && name) {
      setLoadingSpin(true);
      const response = await register(data);

      if (response?.type?.includes("success")) {
        setState((prev) => ({
          ...prev,
          modal: {
            ...prev.modal,
            newlyCreatedUserEmail: email,
            createdUserEmailStatus: { success: true, message: null },
          },
        }));

        navigate("confirmation");
        return;
      }

      setMessage(response);
      setLoadingSpin(false);
    }
  };

  return (
    <RegisterModalContainer>
      <div className="col">
        <div
          style={{
            position: "relative",
            width: "224px",
            paddingBottom: "18%",
          }}
        >
          {signUpImageStatus === "none" || platformHasNoImage ? (
            <h1 className="siteName">{site?.siteName}</h1>
          ) : isDarkMode ? (
            <Image
              src={theme?.themeDarkModeLogoHorizontal || defaultImage}
              fill
              alt="logo"
              className="logo"
            />
          ) : signUpImageStatus === "default" ? (
            <Image
              src={defaultImage || fallbackImage}
              fill
              alt="logo"
              className="logo"
            />
          ) : (
            <Image
              src={registerImage || fallbackImage || defaultImage}
              fill
              alt="logo"
              className="logo"
            />
          )}
        </div>

        <div className="titulos">
          <h1>
            {title && title?.length > 3 ? title : t("common:createYourAccount")}
          </h1>
          <h2>
            {description && description?.length > 3
              ? description
              : t("common:createYourAccountToContinue")}
          </h2>
        </div>

        {loadingSpin && <Spin />}

        {message === "success" ? (
          <Alert
            message={message}
            margin="0 0 8px 0"
            success
            onClose={() => setMessage(null)}
            error={false}
          />
        ) : emailAlreadyInUse ? (
          <Alert error onClose={() => setMessage(null)} margin="-16px 0 8px 0">
            {t("common:alreadyExistAnAccount")}
            <p
              onClick={() =>
                setState((prev) => ({
                  ...prev,
                  modal: {
                    open: true,
                    page: "forgot",
                  },
                }))
              }
            >
              {t("common:clickToRedefine")}
            </p>
          </Alert>
        ) : message ? (
          <Alert
            message={message}
            margin="0 0 8px 0"
            error
            onClose={() => setMessage(null)}
          />
        ) : null}

        <Login
          title={title}
          description={description}
          handleRegister={handleRegister}
          navigate={navigate}
        />
      </div>
    </RegisterModalContainer>
  );
}

export default RegisterModal;
