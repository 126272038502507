
function staticIntlTranslatorToString (param, intl) {
  if (!param) return;

  const data = parseData(param);

  const locale = intl?.replace("-", "_");

  const formattedString =
    data[locale] || data[intl.locale] || data[locale.slice(0, 2)];

  return (
    formattedString ||
    (typeof data === "object"
      ? Object.keys(data).map((key) => data[key])[0]
      : data)
  );
}

function parseData (param) {
  if (typeof param === "string") {
    return param?.startsWith('{') ? JSON.parse(param) : param
  };

  return param;
}

export default staticIntlTranslatorToString;
