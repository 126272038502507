import React from "react";

export const AffiliatesIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.47396 7.99479C7.24129 7.99479 7.80729 7.42879 7.80729 6.66146C7.80729 5.89412 7.24129 5.32812 6.47396 5.32812C5.70663 5.32812 5.14062 5.89412 5.14062 6.66146C5.14062 7.42879 5.70596 7.99479 6.47396 7.99479Z"
        fill="currentColor"
      />
      <path
        d="M13.332 2.66406H2.66536C1.93003 2.66406 1.33203 3.22473 1.33203 3.91406V12.0807C1.33203 12.7701 1.93003 13.3307 2.66536 13.3307H13.332C14.0674 13.3307 14.6654 12.7701 14.6654 12.0807V3.91406C14.6654 3.22473 14.0674 2.66406 13.332 2.66406ZM13.332 11.9974L2.66536 11.9901V3.9974L13.332 4.00473V11.9974Z"
        fill="currentColor"
      />
      <path
        d="M9.33333 6H12V7.33333H9.33333V6ZM10 8.66667H12V10H10V8.66667ZM8.95333 10.3573C8.95333 9.44133 7.836 8.5 6.47667 8.5C5.11733 8.5 4 9.44133 4 10.3573V10.6667H8.95333V10.3573Z"
        fill="currentColor"
      />
    </svg>
  );
};
