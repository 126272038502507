import React from "react";

export const OrdersIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6667 5.66927H12C12 3.77794 10.1633 2.91527 8.66667 2.7166V1.33594H7.33333V2.7166C5.83667 2.91527 4 3.77794 4 5.66927C4 7.47327 5.77733 8.41127 7.33333 8.6226V11.9359C6.368 11.7686 5.33333 11.2533 5.33333 10.3359H4C4 12.0619 5.61667 13.0819 7.33333 13.2933V14.6693H8.66667V13.2893C10.1633 13.0906 12 12.2273 12 10.3359C12 8.4446 10.1633 7.58194 8.66667 7.38327V4.06927C9.55333 4.2286 10.6667 4.6966 10.6667 5.66927ZM5.33333 5.66927C5.33333 4.6966 6.44667 4.2286 7.33333 4.06927V7.2686C6.41933 7.09994 5.33333 6.6006 5.33333 5.66927ZM10.6667 10.3359C10.6667 11.3086 9.55333 11.7766 8.66667 11.9359V8.73594C9.55333 8.89527 10.6667 9.36327 10.6667 10.3359Z"
        fill="currentColor"
      />
    </svg>
  );
};
