import Image from "next/image";
import { useRouter } from "next/router";

import { Button } from "antd";
import useTranslation from "next-translate/useTranslation";
import PropTypes from "prop-types";

import lockIcon from "shared/assets/icons/Lock.svg";
import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";

import { ExclusivePageContainer, Modal } from "./styles";

interface IExclusivePage {
  accessWarn?: string;
}

function ExclusivePage({ accessWarn }: IExclusivePage) {
  const router = useRouter();
  const { setState } = useGlobalContext();

  const { t } = useTranslation();

  const handleOpenAuthModal = () => {
    setState((prev) => ({
      ...prev,
      modal: {
        open: true,
      },
    }));
  };

  return (
    <ExclusivePageContainer>
      <Modal>
        <Image src={lockIcon} alt="logo" width={160} height={90} />

        <strong>{accessWarn || t("common:exclusiveForMembers")}</strong>
        <div className="buttons">
          <Button onClick={() => router.push("/")}>
            {t("common:backToPlataform")}
          </Button>
          <Button onClick={handleOpenAuthModal}>{t("common:login")}</Button>
        </div>
      </Modal>
    </ExclusivePageContainer>
  );
}

ExclusivePage.defaultProps = {
  accessWarn: null,
};

ExclusivePage.propTypes = {
  accessWarn: PropTypes.string,
};

export default ExclusivePage;
