import React, { ReactNode } from "react";

import { CardsListContainer } from "./styles";

interface ICardsListProps {
  isInSearchPage?: boolean;
  children: ReactNode;
}

export const CardsList = ({
  children,
  isInSearchPage = false,
}: ICardsListProps) => {
  return (
    <CardsListContainer isInSearchPage={isInSearchPage}>
      {children}
    </CardsListContainer>
  );
};
