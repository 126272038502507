import { useState } from "react";

import { DropResult } from "react-beautiful-dnd";

import api from "shared/infra/services/api";

import * as Sentry from "@sentry/browser";

import { IModule } from "../types";
import useModulesPageContext from "./useModulesPageContext";

const useModulesDragAndDrop = () => {
  const { modules, group, setModules, setModule, updateModuleLocally, module } =
    useModulesPageContext();

  const [isLoading, setIsLoading] = useState(false);

  const onDragEnd = (result: DropResult) => {
    setIsLoading(true);
    const { source, destination } = result;
    if (!destination) return;
    const newModules = Array.from(modules);
    const [newOrder] = newModules.splice(source.index, 1);
    newModules.splice(destination.index, 0, newOrder);
    onDragSuccess({ newModules });
    setIsLoading(false);
  };

  const onDragSuccess = ({ newModules }) => {
    const modulesIds = newModules.map((module: IModule) => module.id);
    reorderModules(modulesIds);
    setModules(
      newModules.map((module, index) => ({ ...module, position: index })),
    );

    const [selectedModule] = modules.filter(
      (moduleItem) => moduleItem?.id === module?.id,
    );
    updateModuleLocally("cover_image_src", selectedModule?.cover_image);
    updateModuleLocally("position", selectedModule?.position);

    setModule(selectedModule);
  };

  const reorderModules = async (topics_ids: number[]) => {
    const abortController = new AbortController();

    try {
      await api.patch(
        `group-topics/reorder/${group?.id}`,
        {
          topics_ids,
        },
        {
          signal: abortController.signal,
        },
      );
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
      abortController.abort();
    }
  };

  return {
    onDragEnd,
    setIsLoading,
    isLoading,
  };
};

export default useModulesDragAndDrop;
