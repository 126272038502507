import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";

export const Bag = () => {
  const {
    state: { theme },
  } = useGlobalContext();
  const isDarkMode = theme.value === "dark";

  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9838 6.45547C15.8795 6.35045 15.7554 6.26717 15.6188 6.21044C15.4821 6.15372 15.3355 6.12467 15.1875 6.125H12.9375V5.5625C12.9375 4.51821 12.5227 3.51669 11.7842 2.77827C11.0458 2.03984 10.0443 1.625 9 1.625C7.95571 1.625 6.95419 2.03984 6.21577 2.77827C5.47734 3.51669 5.0625 4.51821 5.0625 5.5625V6.125H2.8125C2.51413 6.125 2.22798 6.24353 2.017 6.4545C1.80603 6.66548 1.6875 6.95163 1.6875 7.25V14.8438C1.6875 16.2148 2.84766 17.375 4.21875 17.375H13.7812C14.4445 17.3752 15.0814 17.1159 15.5559 16.6525C15.7947 16.4247 15.9849 16.1509 16.1149 15.8476C16.2449 15.5442 16.3122 15.2177 16.3125 14.8877V7.25C16.313 7.10241 16.2841 6.95619 16.2277 6.81981C16.1713 6.68343 16.0884 6.5596 15.9838 6.45547ZM6.1875 5.5625C6.1875 4.81658 6.48382 4.10121 7.01126 3.57376C7.53871 3.04632 8.25408 2.75 9 2.75C9.74592 2.75 10.4613 3.04632 10.9887 3.57376C11.5162 4.10121 11.8125 4.81658 11.8125 5.5625V6.125H6.1875V5.5625Z"
        fill={isDarkMode ? "#F2F2F2" : "#050505bf"}
        stroke={isDarkMode ? "#F2F2F2" : "#050505bf"}
        strokeWidth="1.00189"
      />
    </svg>
  )
};
