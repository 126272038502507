import GroupList from "./components/GroupList";
import { MenuGroupListContainer } from "./styles";

const MenuGroupList = ({ show }) => {
  return (
    <MenuGroupListContainer show={show}>
      <GroupList />
    </MenuGroupListContainer>
  );
};

export default MenuGroupList;
