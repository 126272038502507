import styled from "styled-components";

export const SearchPageContainer = styled.div`
  max-width: 112.5rem;
  margin: 0 auto;

  @media (max-width: 850px) {
    margin-top: 4rem;
  }
`;

export const Empty = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.searchPage.inputFocusColor};

  margin: 3rem 0;
  font: 500 1rem/1.5rem "Inter";

  @media (max-width: 600px) {
    font: 500 0.875rem/1.3125rem "Inter";
  }
`;

export const SearchInputContainer = styled.div`
  position: relative;
  width: 100%;

  :focus-within,
  :has(input.is-filled) {
    input {
      color: ${({ theme }) => theme.colors.searchPage.inputFocusColor};
    }

    svg {
      &.clearInput {
        stroke: ${({ theme }) => theme.colors.searchPage.inputFocusColor};
      }

      path {
        stroke: ${({ theme }) => theme.colors.searchPage.inputFocusColor};
      }
    }
  }

  input,
  svg path {
    transition: 0.3s all;
  }

  input {
    color: ${({ theme }) => theme.colors.searchPage.inputColor};
    font: 500 1.5rem/2.25rem "Inter";

    padding: 1rem;
    padding-left: 3.6rem;

    background: transparent;
    border: none;

    width: 100%;

    @media (max-width: 600px) {
      font: 500 1rem/1.3125rem "Inter";

      padding: 0.75rem;
      padding-left: 2.6rem;

      button {
        top: -1px;
      }
    }

    @media (max-width: 500px) {
      font: 500 0.875rem/1.3125rem "Inter";
    }
  }

  button {
    background: transparent;
    border: none;

    position: absolute;
    left: 0;
    top: 0;

    height: 100%;
    padding: 1rem;

    font-size: 0;

    :disabled {
      pointer-events: none;
    }

    :hover:not(:disabled) {
      cursor: pointer;
      pointer-events: all;
    }

    svg {
      width: 2rem;
      height: 2rem;

      @media (max-width: 600px) {
        width: 1rem;
        height: 1rem;
      }
    }

    @media (max-width: 600px) {
      top: -1px;
    }

    @media (max-width: 500px) {
      top: 0px;
    }
  }

  svg path {
    color: ${({ theme }) => theme.colors.searchPage.inputColor};
  }
`;
