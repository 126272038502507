import { createContext, useEffect, useState } from "react";

import { useRouter } from "next/router";

import { IGroup } from "pages/[tenant]/g/@types";
import { IKeysToSearch } from "pages/[tenant]/search/@types";
import { removeAccents } from "pages/[tenant]/search/utils/searchUtils";

import {
  INewGlobalSearchContext,
  INewGlobalSearchContextProviderProps,
} from "shared/providers/context/types/NewGlobalSearch";
import { getAllGroupsInPlataform } from "shared/utils/getAllGroupsInPlataform";
import { useFeature } from "shared/utils/hooks/useFeature";
import useWindowSize from "shared/utils/hooks/useWindowSize";
import { removeDuplicatedItems } from "shared/utils/removeDuplicatedItems";

export const NewGlobalSearch = createContext<INewGlobalSearchContext>(
  {} as INewGlobalSearchContext,
);

const keysToUseToSearchTerms: IKeysToSearch[] = ["title", "subtitle"];

export const NewGlobalSearchContextProvider = ({
  children,
}: INewGlobalSearchContextProviderProps) => {
  const [searchResult, setSearchResult] = useState<IGroup[] | null>(null);
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [itemsWasFounded, setItemsWasFounded] = useState(true);

  const listToIterate = searchResult || groups;
  const isSearchEnabled = useFeature("f_search");

  const { asPath } = useRouter();
  const isInSearchPage = asPath === "/search";

  const { width } = useWindowSize();
  const isMobile = width <= 850;
  const shouldFetchGroups = !isInSearchPage && isSearchEnabled && isMobile;

  useEffect(() => {
    const initialFetch = async () => {
      const response = await getAllGroupsInPlataform();
      updateGroupsList(response);
    };

    if (shouldFetchGroups) initialFetch();
  }, [isMobile]);

  const searchInList = (term: string) => {
    const result = groups.filter((item) =>
      keysToUseToSearchTerms.find((key) => {
        const itemNameWithoutAccents = removeAccents(
          item[key] || "",
        ).toLowerCase();
        const termWithoutAccents = removeAccents(term).toLowerCase();

        return itemNameWithoutAccents.includes(termWithoutAccents);
      }),
    );

    const haveResult = !!result.length;
    if (haveResult) {
      setSearchResult(result);
      setItemsWasFounded(true);
    } else {
      setSearchResult(null);
      setItemsWasFounded(false);
    }
  };

  const clearSearchResult = () => {
    setItemsWasFounded(true);
    setSearchResult(null);
  };

  const updateGroupsList = (newGroupsList: IGroup[]) =>
    setGroups((prev) =>
      [...prev, ...newGroupsList].reduce(removeDuplicatedItems, []),
    );

  return (
    <NewGlobalSearch.Provider
      value={{
        searchInList,
        clearSearchResult,
        updateGroupsList,
        listToIterate,
        itemsWasFounded,
      }}
    >
      {children}
    </NewGlobalSearch.Provider>
  );
};
