import React from "react";

export const PeopleIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_383_135)">
        <path
          d="M12.5 15.5977V14.0977C12.5 13.302 12.1839 12.5389 11.6213 11.9763C11.0587 11.4137 10.2956 11.0977 9.5 11.0977H3.5C2.70435 11.0977 1.94129 11.4137 1.37868 11.9763C0.816071 12.5389 0.5 13.302 0.5 14.0977V15.5977"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.5 8.09766C8.15685 8.09766 9.5 6.75451 9.5 5.09766C9.5 3.4408 8.15685 2.09766 6.5 2.09766C4.84315 2.09766 3.5 3.4408 3.5 5.09766C3.5 6.75451 4.84315 8.09766 6.5 8.09766Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 15.5998V14.0998C16.9995 13.4351 16.7783 12.7893 16.371 12.264C15.9638 11.7387 15.3936 11.3634 14.75 11.1973"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.75 2.19727C12.3953 2.36249 12.9673 2.73779 13.3757 3.264C13.7842 3.79021 14.0059 4.43739 14.0059 5.10352C14.0059 5.76964 13.7842 6.41683 13.3757 6.94303C12.9673 7.46924 12.3953 7.84454 11.75 8.00977"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_383_135">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
