import api from "shared/infra/services/api";

type EmailConfirmationData = {
  email: string;
};

const emailConfirmationRequest = async (email: EmailConfirmationData) => {
  let message = "";
  try {
    await api.post("user/resendconfirmation", { email });
    return { success: true, message };
  } catch (error: any) {
    if (error.response.status === 409) {
      message = error.response.data.message;
    }
    return { success: false, message, status: error.response.status };
  }
};

const emailConfirmation = async (email: EmailConfirmationData) => {
  const response = await emailConfirmationRequest(email);
  return response;
};

export default emailConfirmation;
