import styled from "styled-components";

export const MenusListContainer = styled.ul`
  margin: 0;
  list-style: none;

  overflow: hidden;

  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;

    transition: 0.3s all;

    :hover,
    &.active {
      color: ${({ theme }) =>
        theme.mode === "dark" ? "white" : "black"} !important;
    }
  }
`;
