import { IGroup } from "pages/[tenant]/g/@types";

import api from "shared/infra/services/api";

export const getAllGroupsInPlataform = async (): Promise<IGroup[]> => {
  let allGroups = [];

  try {
    const { data } = await api.get("/search/groups");

    allGroups = data.data;
  } catch (error) {
    console.log("Error (getAllGroupsInPlataform): ", error);
  }

  return allGroups;
};
