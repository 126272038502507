import styled from "styled-components";

export const ContentSectionContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .content-section-title {
    color: ${({ theme }) => theme.console.sidebar.titleColor} !important;
    font: 600 1.125rem/1.40625rem "Inter";
  }
`;

export const ContentBox = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.console.sidebar.borderColor};
  padding: 1.5rem;
`;
