import React from "react";

import { Props as InputProps } from "react-input-mask";

import { Container, InputStyled } from "./styles";

interface IInputMaskProps extends InputProps {
  icon: React.ReactNode;
}

const InputMask = ({ icon, ...rest }: IInputMaskProps) => {
  return (
    <Container>
      <InputStyled {...rest} />

      <span className="inputOptions">
        {icon && <span className="prefix">{icon}</span>}{" "}
      </span>
    </Container>
  );
};

export default InputMask;
