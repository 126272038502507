import React from "react";

export const GroupsIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 4.75C1.5 4.336 1.836 4 2.25 4H6.25C6.664 4 7 4.336 7 4.75V7.25C7 7.664 6.664 8 6.25 8H2.25C2.05109 8 1.86032 7.92098 1.71967 7.78033C1.57902 7.63968 1.5 7.44891 1.5 7.25V4.75ZM9.5 5.75C9.5 5.336 9.836 5 10.25 5H13.75C14.164 5 14.5 5.336 14.5 5.75V11.25C14.5 11.664 14.164 12 13.75 12H10.25C10.0511 12 9.86032 11.921 9.71967 11.7803C9.57902 11.6397 9.5 11.4489 9.5 11.25V5.75ZM2.5 10.75C2.5 10.336 2.836 10 3.25 10H6.75C7.164 10 7.5 10.336 7.5 10.75V12.25C7.5 12.664 7.164 13 6.75 13H3.25C3.05109 13 2.86032 12.921 2.71967 12.7803C2.57902 12.6397 2.5 12.4489 2.5 12.25V10.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
