import useGroupContext from "pages/[tenant]/g/hooks/useGroupContext";

import CircularLoader from "shared/components/atoms/CircularLoader";

import SingleGroup from "./components/SingleGroup";
import { Empty, GroupListContainer } from "./styles";

const GroupList = () => {
  const { myGroups, hasErrorInMyGroupsRequest, isFetchingMyGroups } =
    useGroupContext();

  const haveGroups = myGroups?.length && !hasErrorInMyGroupsRequest;

  return (
    <GroupListContainer>
      {isFetchingMyGroups ? (
        <CircularLoader className="loader" />
      ) : haveGroups ? (
        <>
          {myGroups?.map((group) => (
            <SingleGroup key={group?.id} groups={group} />
          ))}
        </>
      ) : (
        <Empty>Você não está em nenhum grupo</Empty>
      )}
    </GroupListContainer>
  );
};

export default GroupList;
