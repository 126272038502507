import styled from "styled-components";

import {
  ConsolePageSelectContainerOptions,
  Select,
} from "pages/[tenant]/console/components/molecules/ConsolePageForm/atoms/ConsolePageSelect/styles";

import { ModulesBaseButtonContainer } from "../../../styles/ModulesBaseButtonContainer";

export const ModulesInternalSidebarContentContainer = styled.div`
  [role="heading"] {
    color: ${({ theme }) => theme.console.sidebar.optionTextColor};
    font: 600 1rem/1.25rem "Inter";

    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;

    gap: 0.5rem;

    height: 3.125rem;
  }

  ${ConsolePageSelectContainerOptions} {
    max-height: 432px;
  }

  ${Select} {
    background-color: transparent;
    margin-bottom: 1rem;
  }

  ${ConsolePageSelectContainerOptions} {
    &::-webkit-scrollbar {
      display: none;
    }

    &::-webkit-scrollbar-track {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }
`;

export const ModuleManagerContainer = styled.div`
  margin-bottom: 0.75rem;
`;

export const NewModuleButton = styled(ModulesBaseButtonContainer)`
  height: 2.813rem;
  padding: 0.5rem;
  width: 100%;

  color: ${({ theme }) => theme.console.sidebar.titleColor};
  font: 600 0.875rem/1rem "Inter";

  background-color: transparent;

  border: 1px solid ${({ theme }) => theme.console.sidebar.borderColor};
  transition: all 0.2s ease-in-out;

  svg * {
    transition: all 0.2s ease-in-out;
    stroke: ${({ theme }) => theme.console.sidebar.titleColor};
  }

  &:hover {
    background-color: #605cff;
    color: #f2f2f2;

    svg * {
      stroke: #f2f2f2;
    }
  }
`;
