import { AxiosError } from "axios";

import api from "shared/infra/services/api";

import {
  IComplementaryMaterials,
  IGroup,
  ITopic,
  ITopicLesson,
} from "../@types";

const getGroupDataAvailablePublicOffer = async ({
  headers,
  group_slug,
}): Promise<IGroup> => {
  try {
    const response = await api.get(
      `/groups/${group_slug}?with_offers=1&available_offers=1&public_offers=1`,
      {
        headers,
      },
    );
    return response.data.data;
  } catch (error) {
    console.log(error);

    return null;
  }
};

const getGroupDataWithOffer = async ({
  headers,
  group_slug,
}): Promise<IGroup> => {
  try {
    const response = await api.get(`/groups/${group_slug}?with_offers=1`, {
      headers,
    });

    return response.data.data;
  } catch (error) {
    console.log(error);

    return null;
  }
};

const getGroup = async ({ group_slug, headers }): Promise<IGroup> => {
  try {
    const response = await api.get(`groups/${group_slug}`, { headers });
    return response.data.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

interface IGetMeyGroupsProps {
  headers?: any;
}
const getMyGroups = async ({
  headers,
}: IGetMeyGroupsProps): Promise<IGroup[] | number> => {
  try {
    const response = await api.get(`/my-groups`, { headers });

    return response.data?.data || null;
  } catch (erro) {
    const error = erro as AxiosError;
    const statusCode = error?.response?.status;

    console.log("Erro (fetchMyGroups): ", {
      message: error?.response?.data?.message,
      status: error?.response?.status,
      erro,
    });

    return statusCode;
  }
};

const getGroups = async (): Promise<IGroup[]> => {
  try {
    const response = await api.get("/groups?page=1&perPage=10");
    return response.data.data;
  } catch (error) {
    return [];
  }
};

const getGroupTopics = async ({
  group,
  headers,
  withInactive,
}): Promise<ITopic[]> => {
  try {
    const response = await api.get(`group-topics/${group?.id}/all`, {
      headers,
      params: {
        withLessons: true,
        withInactive,
        perPage: 10,
      },
    });

    return response.data.data;
  } catch (error) {
    return null;
  }
};

const getLesson = async ({ activitieSlug, headers }): Promise<ITopicLesson> => {
  try {
    const response = await api.get(`group-lessons/${activitieSlug}`, {
      headers,
    });
    return response.data.data;
  } catch (error) {
    return null;
  }
};

const getComplementaryMaterials = async (
  lesson: ITopicLesson,
  headers: {
    Authorization: string;
    "X-Requested-With": string;
  },
): Promise<IComplementaryMaterials[]> => {
  try {
    const response = await api.get(`group-materials/${lesson.id}/all`, {
      headers,
    });
    return response.data.data;
  } catch (error) {
    return null;
  }
};

const updateGroupState = async ({ slug, setGroup }) => {
  try {
    const response = await api.get(
      `groups/${slug}?with_offers=1&available_offers=1&public_offers=1`,
    );
    const groupFromResponse = response.data.data;

    setGroup(groupFromResponse);
  } catch (error) {
    console.log("Error (updateGroupState): ", error);
    return null;
  }
};

export {
  getComplementaryMaterials,
  getGroup,
  getGroupDataAvailablePublicOffer,
  getGroupDataWithOffer,
  getGroupTopics,
  getGroups,
  getLesson,
  getMyGroups,
  updateGroupState,
};
