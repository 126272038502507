import React from "react";

export const NexusIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1108 5.80312C13.4084 5.54271 13.4084 5.11979 13.1108 4.85937L10.8251 2.85938C10.5275 2.59896 10.0442 2.59896 9.74654 2.85938C9.44892 3.11979 9.44892 3.54271 9.74654 3.80312L10.7323 4.66562H3.42987C3.00844 4.66562 2.66797 4.96354 2.66797 5.33229C2.66797 5.70104 3.00844 5.99896 3.42987 5.99896H10.7323L9.74654 6.86146C9.44892 7.12187 9.44892 7.54479 9.74654 7.80521C10.0442 8.06562 10.5275 8.06562 10.8251 7.80521L13.1108 5.80521V5.80312ZM5.17511 13.1365C5.47273 13.3969 5.95606 13.3969 6.25368 13.1365C6.5513 12.876 6.5513 12.4531 6.25368 12.1927L5.27035 11.3323H12.5727C12.9942 11.3323 13.3346 11.0344 13.3346 10.6656C13.3346 10.2969 12.9942 9.99896 12.5727 9.99896H5.27035L6.25606 9.13646C6.55368 8.87604 6.55368 8.45312 6.25606 8.19271C5.95844 7.93229 5.47511 7.93229 5.17749 8.19271L2.89178 10.1927C2.59416 10.4531 2.59416 10.876 2.89178 11.1365L5.17749 13.1365H5.17511Z"
        fill="currentColor"
      />
    </svg>
  );
};
