import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  h1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
  }

  h1,
  h3 {
    color: ${({ theme }) => theme.colors.textColor};
  }

  svg {
    color: ${({ theme }) => theme.colors.textColor} !important;
  }

  button.ant-btn.ant-btn-primary {
    cursor: pointer;
    width: 280px;
    height: 44px;
    margin: 20px 0px 0px 0px;

    font-size: 14px;
    text-align: center;
  }

  img {
    max-width: 88px;
  }

  h1 {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 29px;
    line-height: 115%;
    letter-spacing: -0.5px;
    margin-bottom: 12px;
  }

  .titles {
    margin: 40px 0;
    padding: 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 1400px) {
      margin: 0;
    }
  }

  section {
    margin-top: 40px;
    margin-bottom: 15px;
  }

  section h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 200%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.5px;
  }

  @media screen and (max-width: 992px) {
    margin: auto 0;
    border-radius: 0px;

    img {
      max-width: 70% !important;
    }
  }
`;
