import React from "react";

export const LearningIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10196_66409)">
        <path
          d="M8.0013 2L0.667969 6L3.33464 7.45333V11.4533L8.0013 14L12.668 11.4533V7.45333L14.0013 6.72667V11.3333H15.3346V6L8.0013 2ZM12.548 6L8.0013 8.48L3.45464 6L8.0013 3.52L12.548 6ZM11.3346 10.66L8.0013 12.48L4.66797 10.66V8.18L8.0013 10L11.3346 8.18V10.66Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_10196_66409">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
