type IEnrolledUsersPermission = {
  show: boolean;
  contextType: string;
  reasonsCourses: string;
  reasonsBundles: string;
  reasonsAnyDescription: string;
};

const enrolledUsersPermission: IEnrolledUsersPermission = {
  show: false,
  contextType: "",
  reasonsCourses: "",
  reasonsBundles: "",
  reasonsAnyDescription: "",
};

export default enrolledUsersPermission;
