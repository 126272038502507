import sendLogToSlack from "shared/infra/services/slackAPI";

const logToTenancyMonitoring = (customLines) => {
  const slackChannelEndpoint = "https://hooks.slack.com/services/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";

  const lines = [
    `\n *Horário da requisição:* ${new Date()}`,
    ...customLines
  ];

  const logText = lines.reduce(
    (currentText, currentLine) => currentText + currentLine,
    ""
  );

  sendLogToSlack(logText, slackChannelEndpoint);
}

export default logToTenancyMonitoring;
