import React from "react";

import Image from "next/image";

import { Button } from "antd";
import useTranslation from "next-translate/useTranslation";

import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";

import { AlreadyHaveAccountButton, FirstModalContainer, OptionButtons } from "./styles";

function FirstModal({ navigate }) {
  const { t } = useTranslation();
  const { state } = useGlobalContext();

  const { settings } = state.tenancy;
  const isDarkMode = state.theme.value === "dark";

  const { site, users } = settings;
  const theme = settings["general-theme"];

  const userWarningImage = users?.userWarningImage;
  const warningImage = users?.userLoginRequiredWarningImage;
  const defaultImage = theme?.themeLogoHorizontal;
  const fallbackImage = theme?.themeLogoTopBar;

  const platformHasNoImage = !defaultImage && !fallbackImage;

  const { userWarningTitle, userWarningDescription } = users;

  return (
    <FirstModalContainer>
      <div className="col">
        <div
          style={{ position: "relative", width: "240px", paddingBottom: "25%" }}
        >
          {userWarningImage === "none" || platformHasNoImage ? (
            <h1 className="siteName">{site?.siteName}</h1>
          ) : isDarkMode ? (
            <Image
              src={theme?.themeDarkModeLogoHorizontal || defaultImage}
              fill
              alt="logo"
            />
          ) : userWarningImage === "default" ? (
            <Image src={defaultImage || fallbackImage} fill alt="logo" />
          ) : (
            <Image
              src={warningImage || fallbackImage || defaultImage}
              fill
              alt="logo"
            />
          )}
        </div>

        <div className="titulos">
          <h1>
            {userWarningTitle?.length > 3
              ? userWarningTitle
              : t("common:accessYourAccount")}
          </h1>
          <h2>
            {userWarningDescription?.length > 3
              ? userWarningDescription
              : `${t("common:accessYourPlatformAccount")} ${site.siteName}`}
          </h2>
        </div>

        <OptionButtons>
          <Button
            type="primary"
            className="primary"
            onClick={() => navigate("register")}
          >
            {t("common:createAnAccount")}
          </Button>

          <AlreadyHaveAccountButton onClick={() => navigate("login")}>
            {t("common:isAlreadyHave")}
          </AlreadyHaveAccountButton>
        </OptionButtons>
      </div>
    </FirstModalContainer>
  );
}

export default FirstModal;
