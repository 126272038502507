import { Empty } from "antd";
import useTranslation from "next-translate/useTranslation";
import PropTypes from "prop-types";
import React, { memo } from "react";

const EmptyData = ({ style }) => {
  const { t } = useTranslation();
  return (
    <Empty
      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
      imageStyle={{ height: 80 }}
      style={{ ...style }}
      description={<span>{t("common:noData")}</span>}
    />
  );
};

EmptyData.defaultProps = {
  style: {},
};

EmptyData.propTypes = {
  style: PropTypes.object,
};

export default memo(EmptyData);
