import { useConsolePageContext } from "pages/[tenant]/console/hooks/useConsolePageContext";

import { ActiveSectionIndicatorContainer } from "./styles";

interface IActiveSectionIndicatorProps {
  activeSectionPosition: number;
}

export const ActiveSectionIndicator = ({
  activeSectionPosition,
}: IActiveSectionIndicatorProps) => {
  const { activeSectionLabel } = useConsolePageContext();

  if (!activeSectionLabel) return null;
  return <ActiveSectionIndicatorContainer position={activeSectionPosition} />;
};
