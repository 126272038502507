import React, { useEffect, useState } from "react";

import useTranslation from "next-translate/useTranslation";
import { FaAward } from "react-icons/fa";

import StaticIntlTranslator from "shared/components/atoms/StaticIntlTranslator";
import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";
import staticIntlTranslatorToString from "shared/utils/staticIntlTranslatorToString";

import { BackgroundBlur, Content } from "./styles";

function PurchaseOptionsModal() {
  const { lang } = useTranslation();
  const { state, setState } = useGlobalContext();

  const { entity, context, handlePurchase, show } =
    state.enrollments.paymentOptions;

  const handleClosePurchaseOptionsModal = () => {
    setState((prev) => ({
      ...prev,
      enrollments: {
        paymentOptions: {
          entity: "",
          context: "",
          show: false,
        },
      },
    }));
  };

  const handlePurchaseOptions = (e) => {
    const classes = String(e.target.className);

    const isTheElement = classes.includes("p-opt");

    if (!isTheElement) {
      handleClosePurchaseOptionsModal();
    }
  };

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        document.addEventListener("click", handlePurchaseOptions, false);
      }, 1000);
    }

    return () =>
      document.removeEventListener("click", handlePurchaseOptions, false);

    // eslint-disable-next-line
  }, [show]);

  const goToCheckout = (plan = null) => {
    let paramType;
    let entityType;

    switch (context) {
      case "course":
        entityType = "course";
        paramType = "course_id";
        break;

      case "product_bundle":
        entityType = "product-bundle";
        paramType = "product_bundle_id";
        break;

      case "media_space_content":
        entityType = "media-space-content";
        paramType = "media_space_content_id";
        break;

      default:
        break;
    }

    if (plan) {
      entityType = "plan";
      paramType = "plan_id";

      if (handlePurchase)
        handlePurchase(entityType, paramType, {
          ...plan,
          description: entity.description,
          maintenance: entity.maintenance,
        });
      handleClosePurchaseOptionsModal();

      return;
    }

    if (handlePurchase) handlePurchase(entityType, paramType, entity);
    handleClosePurchaseOptionsModal();
  };

  return (
    <>
      {show && (
        <BackgroundBlur>
          <BackgroundBlur />
          <Content className="p-opt">
            {!!entity?.individual_sale && (
              <div className="p-opt option-card" onClick={() => goToCheckout()}>
                <span className="p-opt badge green">
                  <StaticIntlTranslator id="common:singlePayment" />
                </span>

                <div className="p-opt option-info">
                  <h5 className="p-opt">{entity.price}</h5>
                  <span className="p-opt">
                    <StaticIntlTranslator id="common:singlePayment" />
                  </span>
                </div>

                <div className="p-opt icon-container">
                  <FaAward className="p-opt" size={30} color="#ccc" />
                </div>
              </div>
            )}

            {entity.plans.map((plan) => {
              if (plan?.status !== "published") return null;

              return (
                <div
                  key={plan.id}
                  className="p-opt option-card"
                  onClick={() => goToCheckout(plan)}
                >
                  <span className="p-opt badge blue">
                    <StaticIntlTranslator id="common:subscription" />
                  </span>

                  <div className="p-opt option-info">
                    <h5 className="p-opt">{plan.price}</h5>
                    <span className="p-opt">
                      {staticIntlTranslatorToString(plan.title, lang)}
                    </span>
                    <span className="p-opt p-opt-font-size">
                      (
                      {staticIntlTranslatorToString(
                        plan?.interval_description.toLowerCase(),
                        lang,
                      )}
                      )
                    </span>
                  </div>

                  <div className="p-opt icon-container">
                    <FaAward className="p-opt" size={30} color="#ccc" />
                  </div>
                </div>
              );
            })}
          </Content>
        </BackgroundBlur>
      )}
    </>
  );
}

export default PurchaseOptionsModal;
