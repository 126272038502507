/* eslint-disable @typescript-eslint/no-empty-function */
type PaymentOptionsProps = {
  entity: any;
  context: string;
  handlePurchase?: (
    entityType: string,
    paramType: string,
    product?: any,
  ) => void;
  show: boolean;
};

export type EnrollmentType = {
  paymentOptions: PaymentOptionsProps;
};

const enrollments: EnrollmentType = {
  paymentOptions: {
    entity: "",
    context: "",
    show: false,
    handlePurchase: () => {},
  },
};

export default enrollments;
