import styled from "styled-components";

import { Form as DefaultForm } from "@ant-design/compatible";

export const ForgotPasswordContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  animation: appearFromLeft 0.4s;

  .col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 28.125rem;
    margin: 1.25rem 0;

    .gx-login-header {
      margin-top: -1.5rem;

      > div {
        width: 15.625rem !important;
        height: 5rem !important;
      }
    }

    h1 {
      font-family: Poppins;
      font-style: normal;
      font-weight: bold;
    }

    h1.siteName {
      font-size: 1.5rem;
      text-align: center;
    }

    h2,
    span,
    p,
    a {
      font-style: normal;
      font-weight: normal;
      font-family: Roboto;
    }

    .chat-room--chat--header {
      background: ${({ theme }) => theme.colors.primary} !important;
    }

    h1,
    h2,
    a.link,
    b,
    .bottomDescription,
    span i {
      color: ${({ theme }) => theme.colors.textColor} !important;
    }

    .top-header {
      border: 1px solid red;
    }

    .ant-alert {
      width: 100%;
      max-width: 18.75rem;
    }

    .ant-alert-success {
      max-width: 100%;
    }

    img {
      max-width: 15.625rem;
      max-height: 5.625rem;

      object-fit: contain;
    }

    div.titulos {
      text-align: center;
      margin: 3.5rem 0 1.25rem 0;
      width: 84%;

      h1 {
        color: #606060;
        font-style: normal;
        font-weight: 600;
        font-size: 1.813rem;
        line-height: 115%;
        letter-spacing: -0.5px;
        margin-bottom: 0.75rem;
      }

      h2 {
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 132.5%;
        align-items: center;
        text-align: center;
        letter-spacing: -0.5px;
        color: #606060;
      }

      img {
        padding-top: 0px !important;
      }
    }

    @media (max-width: 1400px) {
      margin: 0;
    }

    @media (max-width: 900px) {
      justify-content: center;

      div.titulos {
        margin: 3.75rem 0;
      }
    }
  }

  @keyframes appearFromLeft {
    from {
      opacity: 0;
      transform: translateX(-5px);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

export const Form = styled(DefaultForm)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ant-legacy-form-item {
    max-width: 18.75rem;

    input {
      width: 100%;
    }

    button {
      color: #f1f1f1 !important;
      align-self: center;
      width: 100%;
      height: 2.875rem;
      margin: 1.5rem 0;
    }
  }

  .bottomDescription {
    text-align: center;
    margin: 4.5rem 0 0 0;
    font-size: 0.75rem;

    a {
      color: inherit;
      text-decoration: inherit;
      font-weight: bolder;
    }
  }
`;
