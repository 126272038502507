import React from "react";

import { FaChromecast } from "react-icons/fa";
import { MdClose } from "react-icons/md";

import {
  CloseBtn,
  Description,
  ModalContainer,
  ModalContainerContent,
  Title,
} from "./styles";

const ModalContent = ({ onClose }) => {
  function handleClose() {
    if (onClose) onClose();
  }

  return (
    <ModalContainer>
      <ModalContainerContent>
        <CloseBtn onClick={handleClose}>
          <MdClose />
        </CloseBtn>

        <Title>
          Para iniciar a transmissão, clique no ícone <FaChromecast /> do player
          de vídeo
        </Title>

        <Description>
          O recurso de transmissão de vídeos em dispositivos Android só está
          disponível no navegador Chrome, por isso você foi redirecionado para
          fora do app.
        </Description>
      </ModalContainerContent>
    </ModalContainer>
  );
};

export default ModalContent;
