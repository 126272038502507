import React, { useEffect, useState } from "react";

import { Skeleton } from "antd";
import useTranslation from "next-translate/useTranslation";

import Auxiliary from "shared/components/atoms/Auxiliary";
import EmptyData from "shared/components/atoms/EmptyData";
import { NotificationsOptions } from "shared/components/organisms/AppNotification/NotificationsOptions";
import api from "shared/infra/services/api";
import { useAuthContext } from "shared/utils/hooks/useAuthContext";

import NotificationItem from "./NotificationItem";
import { AppNotificationContainer, ContainerEmptyData } from "./styles";

interface IAppNotificationProps {
  notifications?: Notification[];
}

const AppNotification = ({ notifications: mock }: IAppNotificationProps) => {
  const { t } = useTranslation();
  const { loggedUser } = useAuthContext();

  const [notifications, setNotifications] = useState<Notification[]>(
    process.env.NODE_ENV === "test" ? mock : [],
  );
  const hasNotifications = !!notifications?.length;
  const [isFetchingNotificationsData, setIsFetchingNotificationsData] =
    useState(false);

  const fetchAllNotifications = async () => {
    try {
      setIsFetchingNotificationsData(true);
      const { data } = await api.get("notifications");
      setNotifications(data.data);
      setIsFetchingNotificationsData(false);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  async function onMarkReadNotificationUnique(id: string | number) {
    try {
      await api.post(`notifications/markasread/${id}`);
    } catch (error) {
      throw new Error(error);
    }
  }

  useEffect(() => {
    if (loggedUser) fetchAllNotifications();
  }, []);

  return (
    <AppNotificationContainer>
      <Auxiliary>
        <div className="gx-popover-header">
          <h3 className="gx-mb-0">{t("common:notifications")}</h3>

          <NotificationsOptions
            notifications={notifications}
            setNotifications={setNotifications}
          />
        </div>

        {isFetchingNotificationsData ? (
          <div className="container-loader">
            <Skeleton avatar active loading />
            <Skeleton avatar active loading />
            <Skeleton avatar active loading />
            <Skeleton avatar active loading />
            <Skeleton avatar active loading />
            <Skeleton avatar active loading />
            <Skeleton avatar active loading />
          </div>
        ) : hasNotifications ? (
          <>
            <ul className="gx-sub-popover">
              {notifications.map((notification, index) => (
                <NotificationItem
                  key={index}
                  notification={notification}
                  onMarkReadNotificationUnique={onMarkReadNotificationUnique}
                />
              ))}
            </ul>
            <div className="no-more-notifications">
              Não há mais notificações
            </div>
          </>
        ) : (
          <ContainerEmptyData>
            <EmptyData />
          </ContainerEmptyData>
        )}
      </Auxiliary>
    </AppNotificationContainer>
  );
};

export default AppNotification;
