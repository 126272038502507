import styled from "styled-components";

interface IFallbackBackgroundProps {
  backgroundColor: string;
  width: number;
  height: number;
}

export const FallbackBackground = styled.div<IFallbackBackgroundProps>`
  border-radius: 4px;

  background-color: ${({ backgroundColor }) => backgroundColor};

  min-width: ${({ width }) => width}px;
  min-height: ${({ height }) => height}px;

  max-width: ${({ width }) => width}px;
  max-height: ${({ height }) => height}px;
`;
