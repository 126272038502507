import type { NextPage } from "next";
import { useContext, useEffect } from "react";
import { Spin } from "antd";
import GlobalContext from "shared/providers/context/GlobalContext";
import { useRouter } from "next/router";

const Home: NextPage = () => {
  const router = useRouter();
  const { state } = useContext(GlobalContext);

  const tenancySettings = state.tenancy.settings;

  useEffect(() => {
    const mainPage = tenancySettings.site.mainPage;

    if (mainPage) {
      router.push(`/${mainPage}`);
    }
  }, [tenancySettings]);

  return (
    <>
      <div className="container-loading">
        <Spin />
      </div>
    </>
  );
};

export default Home;
