import React from "react";

import Link from "next/link";
import { useRouter } from "next/router";

import { FeatureIsNotInUserPlanIcon } from "pages/[tenant]/console/assets/FeatureIsNotInUserPlanIcon";
import {
  BackButton,
  HomeButton,
} from "pages/[tenant]/console/styles/BasePageInfoSection";

import { GenericTemplateFeatureNotInUserPlanContainer } from "./styles";

export const GenericTemplateFeatureNotInUserPlan = () => {
  const { back } = useRouter();

  return (
    <GenericTemplateFeatureNotInUserPlanContainer>
      <FeatureIsNotInUserPlanIcon />

      <h1>Essa funcionalidade não faz parte do seu plano.</h1>
      <p>
        Essa funcionalidade não está inclusa no seu plano atual. Para desfrutar
        desse recurso, considere explorar opções de upgrade.
      </p>

      <div className="buttons-container">
        <BackButton onClick={back}>Voltar</BackButton>
        <Link href={"/console"}>
          <HomeButton>Página inicial</HomeButton>
        </Link>
      </div>
    </GenericTemplateFeatureNotInUserPlanContainer>
  );
};
