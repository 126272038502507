import React from "react";

export const CentralHelpIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6377 6.05469C9.84109 6.0557 9.07737 6.37261 8.51405 6.93593C7.95074 7.49924 7.63382 8.26297 7.63281 9.05961H9.16048C9.16048 8.2446 9.82349 7.58236 10.6377 7.58236C11.452 7.58236 12.115 8.2446 12.115 9.05961C12.115 9.51639 11.7476 9.84789 11.1862 10.3016C11.0028 10.4453 10.8266 10.598 10.6584 10.7591C9.89605 11.5207 9.8739 12.3296 9.8739 12.4197V12.9292H11.4016L11.4008 12.4457C11.4016 12.4335 11.426 12.1509 11.7377 11.84C11.8522 11.7254 11.9966 11.6108 12.1463 11.4901C12.7413 11.0082 13.6419 10.2802 13.6419 9.05961C13.6413 8.26298 13.3246 7.49913 12.7614 6.93575C12.1981 6.37237 11.4344 6.0555 10.6377 6.05469ZM9.8739 13.693H11.4016V15.2207H9.8739V13.693Z"
        fill="currentColor"
      />
      <path
        d="M10.6383 3C6.42656 3 3 6.42656 3 10.6383C3 14.8501 6.42656 18.2767 10.6383 18.2767C14.8501 18.2767 18.2767 14.8501 18.2767 10.6383C18.2767 6.42656 14.8501 3 10.6383 3ZM10.6383 16.749C7.26907 16.749 4.52767 14.0076 4.52767 10.6383C4.52767 7.26907 7.26907 4.52767 10.6383 4.52767C14.0076 4.52767 16.749 7.26907 16.749 10.6383C16.749 14.0076 14.0076 16.749 10.6383 16.749Z"
        fill="currentColor"
      />
    </svg>
  );
};
