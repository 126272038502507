import styled from "styled-components";

import { mobileFeatureNotInPlanTemplateBreakpoint } from "pages/[tenant]/console/utils/layoutUtils";

export const DescriptionContainer = styled.p`
  color: ${({ theme }) => theme.console.pageSubtitleColor} !important;
  font: 400 1.125rem/1.6875rem "Inter";

  @media (max-width: ${mobileFeatureNotInPlanTemplateBreakpoint}px) {
    font: 400 1rem/1.5 "Inter";
  }

  margin-bottom: 1.5rem;
`;
