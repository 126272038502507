import styled from "styled-components";

export const BackToTopFooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.colors.modalBackgroundColor};
  margin-bottom: 0;
  width: 100%;

  .footer {
    padding: 0.5rem;
    display: none;
  }

  #img-footer {
    margin-right: 1rem;
    height: 3.5rem;
    display: none;
  }

  .to-top-container {
    background-color: ${({ theme }) => theme.settings.themeMainMenu};
    display: grid;
    place-items: center;
    padding: 0 1rem;

    a {
      color: ${({ theme }) => theme.colors.textColor} !important;
    }

    h1,
    button,
    a {
      font-size: 0.8rem;
      font-weight: 700;
      line-height: 1.125rem;
      padding: 1.125rem 0 1.125rem 0;
    }

    h1,
    span,
    a {
      color: ${({ theme }) => theme.colors.textColor};
      margin-bottom: 0;
    }

    button {
      align-items: center;
      background-color: transparent;
      border: none;
      transition: transform 100ms ease-in-out;
      :hover {
        cursor: pointer;
        transform: scale(1.02);
      }
    }

    .button-back-to-top {
      display: flex;
    }

    div {
      background-color: ${({ theme }) => theme.colors.primary};
      border-radius: 1rem;
      display: grid;
      height: 1.5rem;
      place-items: center;
      margin-left: 4px;
      width: 1.5rem;

      svg * {
        height: 12px;
        stroke: ${({ theme }) => theme.colors.textMasterColor};
        fill: ${({ theme }) => theme.colors.textMasterColor};
      }
    }

    @media screen and (min-width: 768px) {
      display: flex;
      justify-content: space-between;
    }

    @media screen and (max-width: 768px) {
      h1 {
        padding: 1.5rem 0 0.6rem 0;
        margin: 0;
      }

      button {
        padding: 0.6rem 0 1.5rem 0;
        margin: 0;
      }
    }
  }
`;
