export function reducer(state, action) {
  switch (action.type) {
    case "SET_MODULES":
      return { ...state, modules: action.payload };

    case "SET_MATERIALS":
      return { ...state, materials: action.payload };

    case "SET_MATERIAL_FILES":
      return { ...state, materialFiles: action.payload };

    case "SET_MODULE":
      return { ...state, module: action.payload };

    case "SET_LESSON":
      return { ...state, lesson: action.payload };

    case "SET_GROUP":
      return { ...state, group: action.payload };

    case "SET_QUIZ_LOCAL_QUESTIONS":
      return { ...state, quizLocalQuestions: action.payload };

    case "SET_MODULES_INTERNAL_ROUTES":
      return { ...state, modulesInternalRoutes: action.payload };

    case "CLEAR_MODULE_STATE":
      return { ...state, module: null };

    case "CLEAR_MODULES_STATE":
      return { ...state, modules: [] };

    case "CLEAR_LESSON_STATE":
      return { ...state, lesson: null };

    case "CLEAR_GROUP_STATE":
      return { ...state, group: null };

    case "CLEAR_MATERIAL_FILES_STATE":
      return { ...state, materialFiles: [] };

    case "CLEAR_MATERIAL_STATE":
      return { ...state, materials: [] };

    case "UPDATE_MODULE_LOCALLY":
      return {
        ...state,
        module: { ...state.module, [action.key]: action.value },
      };

    case "UPDATE_LESSON_LOCALLY":
      return {
        ...state,
        lesson: { ...state.lesson, [action.key]: action.value },
      };

    default:
      return state;
  }
}
