import { Modal as ModalANTD } from "antd";
import styled from "styled-components";

export const Modal = styled(ModalANTD)`
  .ant-modal-header {
    background: transparent;
    border-bottom-color: ${({ theme }) => theme.colors.btnTextColor};
  }

  .ant-modal-title,
  .ant-select-selection-item,
  .ant-modal-close-x {
    color: ${({ theme }) => theme.colors.textColor};
  }

  .ant-legacy-form-explain {
    color: #ff3100;
  }

  .ant-modal-content {
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.modalBackgroundColor};
  }

  .ant-select {
    width: 100%;
  }

  .ant-modal-body .ant-select-selector {
    border-radius: 8px;
    border-color: ${({ theme }) => theme.colors.inputBackgroundColor};
    background: ${({ theme }) => theme.colors.inputBackgroundColor};
  }

  p {
    margin-top: 1rem;
    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.colors.textColor};
  }

  textarea {
    margin-top: 1rem;
    color: ${({ theme }) => theme.colors.textColor};
    border-color: ${({ theme }) => theme.colors.inputBackgroundColor};
    background: ${({ theme }) => theme.colors.inputBackgroundColor};
  }

  button.ant-btn-primary {
    border: none;
    background: ${({ theme }) => theme.colors.primary};
    &,
    span {
      color: ${({ theme }) => theme.colors.textMasterColor};
    }
  }
`;
