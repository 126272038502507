import React from "react";

export const AnalyticsIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5718 2.16406C5.93794 2.16406 2.16797 5.93404 2.16797 10.5679C2.16797 15.2018 5.93794 18.9718 10.5718 18.9718C15.2057 18.9718 18.9757 15.2018 18.9757 10.5679C18.9757 5.93404 15.2057 2.16406 10.5718 2.16406ZM17.2369 9.72754H11.4122V3.90282C12.8912 4.09136 14.2656 4.76568 15.3198 5.81991C16.3741 6.87414 17.0484 8.2486 17.2369 9.72754ZM3.84874 10.5679C3.84874 7.14587 6.42116 4.31881 9.73145 3.90282V10.5679C9.73393 10.6961 9.76585 10.8221 9.82473 10.936C9.83733 10.9612 9.84322 10.989 9.85918 11.0142L13.3905 16.6641C12.5079 17.0761 11.5459 17.29 10.5718 17.291C6.86489 17.291 3.84874 14.2749 3.84874 10.5679ZM14.8175 15.775L12.0887 11.4083H17.2369C17.0214 13.1207 16.155 14.6843 14.8175 15.775Z"
        fill="currentColor"
      />
    </svg>
  );
};
