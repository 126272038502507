import {
  ICheckoutStepsOffersTheme,
  ICheckoutStepsTheme,
} from "@CheckoutStepsOffers/themes/themes";

import dark from "./dark";
import light from "./light";

export const themes = {
  dark,
  light,
};

type ILight = ReturnType<typeof light>;
type IDark = ReturnType<typeof dark>;
type ITheme = ILight & IDark;

export interface ICustomTheme extends ITheme {
  mode: "light" | "dark";
  checkoutStepsOffers: ICheckoutStepsOffersTheme & ICheckoutStepsTheme;
  checkoutOffers: ICheckoutStepsOffersTheme & ICheckoutStepsTheme;
}
