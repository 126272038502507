import { useState } from "react";

import Image from "next/image";

import { FallbackBackground } from "./styles";

interface IGroupImageProps {
  logoHorizontal: string;
  primaryColor: string;
  width: number;
  height: number;
}

export const GroupImage = ({
  height,
  logoHorizontal,
  primaryColor,
  width,
}: IGroupImageProps) => {
  const [imageFailedToLoad, setImageFailedToLoad] = useState(false);

  const hasImageToShow = !imageFailedToLoad && logoHorizontal;

  return hasImageToShow ? (
    <Image
      alt=""
      src={logoHorizontal}
      width={width}
      height={height}
      style={{ backgroundColor: primaryColor, objectFit: "cover" }}
      onError={() => setImageFailedToLoad(true)}
    />
  ) : (
    <FallbackBackground
      backgroundColor={primaryColor}
      width={width}
      height={height}
      data-testid="fallback-background"
    />
  );
};
