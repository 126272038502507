import { useConsolePageContext } from "pages/[tenant]/console/hooks/useConsolePageContext";

import { ActiveOptionIndicatorContainer } from "./styles";

interface IActiveOptionIndicatorProps {
  activeOptionPosition: number;
}

export const ActiveOptionIndicator = ({
  activeOptionPosition,
}: IActiveOptionIndicatorProps) => {
  const { activeSectionLabel, activeOptionPathInRealTime } =
    useConsolePageContext();

  const shouldShowIndicator =
    activeSectionLabel && activeOptionPathInRealTime && activeOptionPosition;

  if (!shouldShowIndicator) return null;
  return <ActiveOptionIndicatorContainer position={activeOptionPosition} />;
};
