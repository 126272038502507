import React from "react";

export const AppsInstalledIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8546 8.14689C10.9012 8.19334 10.9381 8.24852 10.9633 8.30926C10.9886 8.37001 11.0015 8.43513 11.0015 8.50089C11.0015 8.56666 10.9886 8.63178 10.9633 8.69253C10.9381 8.75327 10.9012 8.80845 10.8546 8.85489L7.85463 11.8549C7.80819 11.9015 7.75301 11.9384 7.69227 11.9636C7.63152 11.9888 7.5664 12.0018 7.50063 12.0018C7.43486 12.0018 7.36974 11.9888 7.309 11.9636C7.24825 11.9384 7.19308 11.9015 7.14663 11.8549L5.64663 10.3549C5.60014 10.3084 5.56327 10.2532 5.53811 10.1925C5.51295 10.1317 5.5 10.0666 5.5 10.0009C5.5 9.93515 5.51295 9.87005 5.53811 9.80931C5.56327 9.74857 5.60014 9.69338 5.64663 9.64689C5.74052 9.55301 5.86786 9.50026 6.00063 9.50026C6.06638 9.50026 6.13148 9.51321 6.19222 9.53837C6.25295 9.56353 6.30814 9.60041 6.35463 9.64689L7.50063 10.7939L10.1466 8.14689C10.1931 8.10033 10.2483 8.06339 10.309 8.03818C10.3697 8.01297 10.4349 8 10.5006 8C10.5664 8 10.6315 8.01297 10.6923 8.03818C10.753 8.06339 10.8082 8.10033 10.8546 8.14689Z"
        fill="currentColor"
      />
      <path
        d="M8 1C8.66304 1 9.29893 1.26339 9.76777 1.73223C10.2366 2.20107 10.5 2.83696 10.5 3.5V4H5.5V3.5C5.5 2.83696 5.76339 2.20107 6.23223 1.73223C6.70107 1.26339 7.33696 1 8 1ZM11.5 4V3.5C11.5 2.57174 11.1313 1.6815 10.4749 1.02513C9.8185 0.368749 8.92826 0 8 0C7.07174 0 6.1815 0.368749 5.52513 1.02513C4.86875 1.6815 4.5 2.57174 4.5 3.5V4H1V14C1 14.5304 1.21071 15.0391 1.58579 15.4142C1.96086 15.7893 2.46957 16 3 16H13C13.5304 16 14.0391 15.7893 14.4142 15.4142C14.7893 15.0391 15 14.5304 15 14V4H11.5ZM2 5H14V14C14 14.2652 13.8946 14.5196 13.7071 14.7071C13.5196 14.8946 13.2652 15 13 15H3C2.73478 15 2.48043 14.8946 2.29289 14.7071C2.10536 14.5196 2 14.2652 2 14V5Z"
        fill="currentColor"
      />
    </svg>
  );
};
