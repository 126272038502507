import styled from "styled-components";
import { css } from "styled-components";

import { consolePageMobileBreakpoint } from "pages/[tenant]/console/hooks/useConsolePageConfig";
import { BaseSideBarSectionButton } from "pages/[tenant]/console/styles/BaseSideBarSectionButton";

interface ISideBarSectionContainerProps {
  tooltipLabel: string;
  disabled: boolean;
}

export const SideBarSectionContainer = styled.div<ISideBarSectionContainerProps>`
  @media (min-width: ${consolePageMobileBreakpoint}px) {
    width: 40px;
    height: 40px;
  }

  > a svg {
    width: 18px;
    height: 18px;

    z-index: 4;
  }

  a {
    color: ${({ theme }) => theme.console.sidebar.optionTextColor};
    ${BaseSideBarSectionButton}
  }

  :has(a.active) {
    a {
      color: white;
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
      a:hover {
        cursor: not-allowed !important;
      }
    `}
`;
