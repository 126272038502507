import styled from "styled-components";

interface INotificationsContainerProps {
  show: boolean;
}

export const NotificationsContainer = styled.div<INotificationsContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  transition: 0.3s all;

  padding-bottom: 2rem;

  overflow: scroll;

  background-color: ${({ theme }) => theme.colors.globalTopbar.solidBackground};

  ${({ show }) =>
    show ? "transform: translateY(0)" : "transform: translateY(100%)"};

  color: ${({ theme }) => theme.colors.globalTopbar.textColor};

  .gx-popover-scroll {
    margin: 0 auto;
  }
`;
