import { Form } from "@ant-design/compatible";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Input, notification, Select } from "antd";
import useTranslation from "next-translate/useTranslation";
import PropTypes from "prop-types";
import React, { memo, useState } from "react";
import api from "shared/infra/services/api";
import { Modal } from "./styles";

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    lg: { span: 24 },
    md: { span: 24 },
    xl: { span: 24 },
    xxl: { span: 24 },
  },
};

const ModalDenounce = ({
  form,
  visible,
  handleVisible,
  contentType,
  entityId,
  reportedUserId,
}) => {
  const { t } = useTranslation();

  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  const { getFieldDecorator, validateFields } = form;

  const onSubmitModal = async () => {
    await validateFields(async (err) => {
      if (!err) {
        try {
          await api.post("report", {
            reportgable_type: contentType,
            reportgable_id: entityId,
            reported_user_id: reportedUserId,
            type,
            description: message,
          });

          const args = {
            message: t("common:contentSuccessfullyReported"),
            description: t("common:thisContentWillBeAnalyzedThanks"),
            icon: <CheckCircleOutlined style={{ color: "#87d067" }} />,
            duration: 6,
          };

          notification.open(args);
          handleVisible();
        } catch (error) {
          console.log("error --> ", error);
        }
      }
    });
  };

  return (
    <>
      <Modal
        centered
        maskClosable={null}
        footer={null}
        visible={visible}
        title={t("common:reportContent")}
        onCancel={(e) => {
          e.stopPropagation();
          handleVisible();
        }}
      >
        <Form
          {...formItemLayout}
          onSubmit={(e) => {
            e.preventDefault();
            onSubmitModal();
          }}
        >
          <FormItem>
            {getFieldDecorator(t("common:optionsDenounce"), {
              rules: [
                {
                  required: true,
                  message: t("common:selectAnOption"),
                },
              ],
            })(
              <Select
                allowClear
                placeholder={t("common:selectTheOptionThatBestFits")}
                onSelect={(option) => setType(option)}
              >
                <Option value="spam">Spam</Option>
                <Option value="improper">
                  {t("common:inappropriateContent")}
                </Option>
                <Option value="abuse">{t("common:abusiveContent")}</Option>
              </Select>
            )}
          </FormItem>

          <FormItem>
            {getFieldDecorator("messageDenounce", {
              rules: [
                {
                  required: true,
                  message: t("common:enterTheReasonForTheComplaint"),
                },
              ],
            })(
              <TextArea
                placeholder={t("common:enterTheReasonForTheComplaint")}
                onChange={({ target: { value } }) => setMessage(value)}
                rows={6}
                margin="normal"
              />
            )}
          </FormItem>

          <p>{t("common:submitYourComplaintByClickingTheButtonBelow")}</p>
          <Button htmlType="submit" size="large" type="primary" block>
            {t("common:report")}
          </Button>
        </Form>
      </Modal>
    </>
  );
};

ModalDenounce.defaultProps = {
  visible: false,
  handleVisible: () => null,
  entityId: "",
  contentType: "",
  reportedUserId: "",
};

ModalDenounce.propTypes = {
  form: PropTypes.object.isRequired,
  visible: PropTypes.bool,
  contentType: PropTypes.string,
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  reportedUserId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleVisible: PropTypes.func,
};

const WrappedRegistrationForm = Form.create({ name: "form_modal_denounce" })(
  ModalDenounce
);

export default memo(WrappedRegistrationForm);
