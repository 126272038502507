import React, { ReactNode } from "react";

import { ContentSectionRowContainer } from "./styles";

interface IContentSectionRowProps {
  title?: string;
  subtitle?: string | ReactNode;
  alignContent: "rows" | "grid";
  children: ReactNode;
  show?: boolean;
}

export const ContentSectionRow = ({
  title,
  subtitle,
  alignContent,
  children,
  show = true,
}: IContentSectionRowProps) => {
  const hasSomeTextToShow = !!(title || subtitle);

  return (
    <ContentSectionRowContainer
      alignContent={alignContent}
      show={show}
      className={!show ? "should-hide" : ""}
    >
      {hasSomeTextToShow && (
        <div className="row-texts">
          {title && <span className="title">{title}</span>}
          {subtitle && <p className="subtitle">{subtitle}</p>}
        </div>
      )}

      <div className="content">{children}</div>
    </ContentSectionRowContainer>
  );
};
