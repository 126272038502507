import React, { useContext, useEffect, useState } from "react";

import { useRouter } from "next/router";

import ModalContent from "./Content";

const ChromeCastWarnModal = () => {
  const router = useRouter();
  const castButtonAutoPlay = router.query.cast_auto_play;

  const [showChromeCastWarnModal, setShowChromeCastWarnModal] = useState(false);

  function handleClose() {
    setShowChromeCastWarnModal(false);
  }

  useEffect(() => {
    function autoPlayCastButton() {
      setShowChromeCastWarnModal(true);
    }

    if (castButtonAutoPlay === "1") {
      autoPlayCastButton();
    }
  }, [castButtonAutoPlay]);

  return showChromeCastWarnModal && <ModalContent onClose={handleClose} />;
};

export default ChromeCastWarnModal;
