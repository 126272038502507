import React, { useState } from "react";

import useTranslation from "next-translate/useTranslation";

import api from "shared/infra/services/api";
import { AjustsIcon } from "shared/providers/Layout/components/NewTopBar/assets/AjustsIcon";

import { CheckOutlined, DeleteOutlined } from "@ant-design/icons";

import { NotificationsOptionsContainer, OptionsContainer } from "./styles";

interface INotificationsOptionsProps {
  notifications: Notification[];
  setNotifications: React.Dispatch<React.SetStateAction<Notification[]>>;
}

export const NotificationsOptions = ({
  notifications,
  setNotifications,
}: INotificationsOptionsProps) => {
  const [optionsIsVisible, setOptionsIsVisible] = useState(false);

  const { t } = useTranslation();

  const onMarkReadNotificationAll = async () => {
    setNotifications((prev) =>
      prev.map((notification) => ({ ...notification, is_read: true })),
    );

    try {
      await api.post("notifications/markasread/all");
    } catch (error) {
      console.log("Error (onMarkReadNotificationAll): ", error);
    }
  };

  const onRemoveAllNotifications = async () => {
    setNotifications([]);

    try {
      await api.delete("notifications/delete/all");
    } catch (error) {
      console.log("Error (onRemoveAllNotifications): ", error);
    }
  };

  return (
    <NotificationsOptionsContainer>
      <button onClick={() => setOptionsIsVisible(!optionsIsVisible)}>
        <AjustsIcon />
      </button>

      <OptionsContainer show={optionsIsVisible}>
        <button
          onClick={onMarkReadNotificationAll}
          disabled={!notifications?.length}
        >
          <CheckOutlined /> {t("common:markAllAsRead")}
        </button>

        <button
          onClick={onRemoveAllNotifications}
          disabled={!notifications?.length}
        >
          <DeleteOutlined /> {t("common:clearNotifications")}
        </button>
      </OptionsContainer>
    </NotificationsOptionsContainer>
  );
};
