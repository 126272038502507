import React from "react";

export const ThreeDots = () => {
  return (
    <svg
      width="35"
      height="36"
      viewBox="0 0 35 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 11.7308C18.0523 11.7308 18.5 11.2831 18.5 10.7308C18.5 10.1785 18.0523 9.73077 17.5 9.73077C16.9477 9.73077 16.5 10.1785 16.5 10.7308C16.5 11.2831 16.9477 11.7308 17.5 11.7308Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 18.7308C18.0523 18.7308 18.5 18.2831 18.5 17.7308C18.5 17.1785 18.0523 16.7308 17.5 16.7308C16.9477 16.7308 16.5 17.1785 16.5 17.7308C16.5 18.2831 16.9477 18.7308 17.5 18.7308Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 25.7308C18.0523 25.7308 18.5 25.2831 18.5 24.7308C18.5 24.1785 18.0523 23.7308 17.5 23.7308C16.9477 23.7308 16.5 24.1785 16.5 24.7308C16.5 25.2831 16.9477 25.7308 17.5 25.7308Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
