import React from "react";

export const ModulesIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10196_58396)">
        <path
          d="M12.0013 1.33594H4.0013C3.26797 1.33594 2.66797 1.93594 2.66797 2.66927V13.3359C2.66797 14.0693 3.26797 14.6693 4.0013 14.6693H12.0013C12.7346 14.6693 13.3346 14.0693 13.3346 13.3359V2.66927C13.3346 1.93594 12.7346 1.33594 12.0013 1.33594ZM6.0013 2.66927H7.33464V6.0026L6.66797 5.5026L6.0013 6.0026V2.66927ZM12.0013 13.3359H4.0013V2.66927H4.66797V8.66927L6.66797 7.16927L8.66797 8.66927V2.66927H12.0013V13.3359Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_10196_58396">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
