import {
  FirstAccess,
  RegisterWithoutPassword,
  ResetPasswordData,
  SignInData,
  SignUpRequestData,
  UpdateUserRequest,
} from "shared/providers/context/types/auth-context";

import api from "../api";
import getToken from "./getToken";

export async function signInRequest(data: SignInData) {
  try {
    const response = await api.post("login", {
      ...data,
    });

    return response.data;
  } catch (error: any) {
    return error?.response?.data || error;
  }
}

export async function registerRequest(data: SignUpRequestData) {
  try {
    const response = await api.post("register", {
      ...data,
    });
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
}

export async function updateUserRequest(data: UpdateUserRequest) {
  try {
    const response = await api.post("user/update", {
      ...data,
    });
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
}

export async function registerWithoutPasswordRequest(
  data: RegisterWithoutPassword,
) {
  const headers = {
    "X-Requested-With": "XMLHttpRequest",
  };

  try {
    const response = await api.post(
      "user/create-without-password",
      { ...data },
      {
        headers,
      },
    );

    return response.data;
  } catch (error) {
    return error?.response?.data || error;
  }
}

export async function recoverUserInformation(username: string, api) {
  if (!(username && api)) return null;

  try {
    const response = await api.get(`user-profile/${username}`);

    return response.data;
  } catch (error: any) {
    console.log("Error (recoverUserInformation): ", error);
    return error?.response?.data || error;
  }
}

export async function resetPasswordRequest({
  token,
  passwords,
}: ResetPasswordData) {
  const { password, confirmPassword } = passwords;

  if (password === confirmPassword) {
    try {
      const response = await api.post("user/password/newpassword", {
        password,
        token,
      });

      return response.data;
    } catch (error: any) {
      return error?.response?.data.message;
    }
  } else {
    return "Password and ConfirmPassword must be the same";
  }
}

export async function firstAccessRequest({
  confirmPassword,
  password,
  token: newToken,
}: FirstAccess) {
  const token = getToken();

  const headers = {
    Authorization: "Bearer " + token,
    "X-Requested-With": "XMLHttpRequest",
  };

  const data = {
    password,
    isFirstAccess: true,
  };

  if (newToken) {
    data["token"] = newToken;
  } else {
    data["headers"] = headers;
  }

  if (password === confirmPassword) {
    try {
      const response = await api.post("user/password/newpassword", data);

      return response.data;
    } catch (error: any) {
      return error?.response?.data.message;
    }
  } else {
    return "Password and ConfirmPassword must be the same";
  }
}
