import { notification } from "antd";

export default function openNotificationWithIcon(
  type,
  message,
  description,
  duration,
  btn,
) {
  notification.config({ duration, top: 85 });

  notification[type]({
    message,
    description,
    btn,
  });
}
