import styled from "styled-components";

import {
  ContentBox,
  ContentSectionContainerStyled,
} from "pages/[tenant]/console/components/molecules/ContentSection/molecules/atoms/Container/styles";

import { CircularLoaderContainer } from "shared/components/atoms/CircularLoader/styles";

export const ModulesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 70%;
  overflow-y: scroll;
  max-height: 85vh;
  overscroll-behavior-y: none;

  li {
    list-style: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  ${CircularLoaderContainer} {
    margin-top: 0.75rem;
  }

  ${ContentBox} {
    transition: all 0.3s;
    padding: 0;
  }

  ${ContentSectionContainerStyled} {
    gap: 0;
    margin-bottom: 0.75rem;
  }
`;
