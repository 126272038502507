import { useCallback } from "react";

import { DropdownArrow } from "pages/[tenant]/console/assets/DropdownArrow";
import { useConsolePageContext } from "pages/[tenant]/console/hooks/useConsolePageContext";

import ModulesInternalSidebarContent from "../ModuleInternalSidebarContent";
import {
  InternalSectionOptionPageSidebarContainer,
  InternalSectionSidebarContentContainer,
  SectionsDropdownButton,
} from "./styles";

export const InternalSectionOptionPageSidebar = () => {
  const {
    isMobileInternalSectionOptionsDropdownOpen,
    openMobileInternalSectionsDropdown,
    closeMobileInternalSectionsDropdown,
  } = useConsolePageContext();

  const handleClick = useCallback(() => {
    isMobileInternalSectionOptionsDropdownOpen
      ? closeMobileInternalSectionsDropdown()
      : openMobileInternalSectionsDropdown();
  }, [
    isMobileInternalSectionOptionsDropdownOpen,
    closeMobileInternalSectionsDropdown,
    openMobileInternalSectionsDropdown,
  ]);

  return (
    <InternalSectionOptionPageSidebarContainer
      showMobileDropdown={isMobileInternalSectionOptionsDropdownOpen}
    >
      <SectionsDropdownButton
        onClick={handleClick}
        shouldRotateIcon={isMobileInternalSectionOptionsDropdownOpen}
      >
        <DropdownArrow />
        Opções dos módulos
      </SectionsDropdownButton>

      <InternalSectionSidebarContentContainer>
        <ModulesInternalSidebarContent />
      </InternalSectionSidebarContentContainer>
    </InternalSectionOptionPageSidebarContainer>
  );
};
