import React from "react";

export const AccessIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.9987 4C4.68536 4 1.33203 5.03067 1.33203 7V9.66667C1.33203 11.636 4.68536 12.6667 7.9987 12.6667C11.312 12.6667 14.6654 11.636 14.6654 9.66667V7C14.6654 5.03067 11.312 4 7.9987 4ZM2.66536 9.66667V8.86867C3.08759 9.10778 3.53469 9.30004 3.9987 9.442V10.696C3.12403 10.3533 2.66536 9.94533 2.66536 9.66667ZM10.6654 9.76533V11.0793C10.0874 11.1987 9.4207 11.286 8.66536 11.318V9.98467C9.33631 9.95795 10.0046 9.88466 10.6654 9.76533ZM7.33203 11.3187C6.66004 11.2929 5.99121 11.2131 5.33203 11.08V9.766C5.97136 9.882 6.6467 9.95733 7.33203 9.986V11.3187ZM11.9987 10.696V9.442C12.4628 9.30033 12.9099 9.10806 13.332 8.86867V9.66667C13.332 9.94533 12.874 10.3533 11.9987 10.696ZM7.9987 8.66667C4.54736 8.66667 2.66536 7.566 2.66536 7C2.66536 6.434 4.54736 5.33333 7.9987 5.33333C11.45 5.33333 13.332 6.434 13.332 7C13.332 7.566 11.45 8.66667 7.9987 8.66667Z"
        fill="currentColor"
      />
    </svg>
  );
};
