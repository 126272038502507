import styled from "styled-components";

import { CloseOutlined, NotificationOutlined } from "@ant-design/icons";

interface ITheme {
  mode?: string;
}

export const NotificationItemContainer = styled.li<ITheme>`
  cursor: pointer;
  width: 100%;
  list-style: none;

  color: ${({ theme }) => theme.colors.textColor};

  .notification-initials {
    border-radius: 50%;

    width: 2rem;
    height: 2rem;
  }

  .gx-meta-date {
    color: #096dd9;
  }

  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
    display: flex;
    align-items: flex-start;
    margin-top: -1px;
  }

  i {
    padding-top: 2px;
  }

  @media (min-width: 1080px) {
    .notifications-actions {
      visibility: hidden;
    }
  }

  &:hover {
    background-color: ${({ mode }) =>
      mode === "dark" ? "#545454" : "#f5f5f5"};

    @media (min-width: 1080px) {
      .notifications-actions {
        visibility: visible;
      }
    }
  }
`;

export const NotificationItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.7rem;

  & > div {
    display: flex;
    gap: 0.7rem;
  }

  width: 100%;
`;

export const NotificationItemContent = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  font-size: 0.8rem;
  margin: 0;
`;

export const Actions = styled.div`
  .ant-checkbox-inner {
    border-radius: 50%;
    width: 12px;
    height: 12px;

    &::after {
      display: none !important;
    }
  }
`;

export const IconCloseOutlined = styled(CloseOutlined)<ITheme>`
  color: ${({ theme }) => theme.colors.textColor};
  cursor: pointer;
`;

export const IconNotificationOutlined = styled(NotificationOutlined)<ITheme>`
  margin-right: 1.8rem;
  padding: 1rem 0 0 0.8rem;

  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.textColor};
  cursor: pointer;
`;
