import styled from "styled-components";

import { consolePageMobileBreakpoint } from "pages/[tenant]/console/hooks/useConsolePageConfig";

export const EnsinioLogoContainer = styled.div`
  @media (max-width: ${consolePageMobileBreakpoint}px) {
    display: none;
  }

  a {
    display: block;
    padding: 0.5rem;

    height: 50px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg path {
    fill: ${({ theme }) => (theme.mode === "dark" ? "#C8C8C9" : "#050505")};
  }
`;
