import styled, { css } from "styled-components";

import { SwitchThemeContainer } from "shared/providers/Layout/components/NewTopBar/components/atoms/SwitchTheme/styles";

export const OptionsContainer = styled.div`
  list-style: none;
  margin: 0;

  width: 230px;
  display: flex;
  flex-direction: column;

  h1 {
    color: ${({ theme }) => theme.colors.globalTopbar.textColor};
    font: 600 1.1rem/1.4rem "Inter";

    padding: 0.5rem;
    margin: 0;
  }

  a,
  button,
  li {
    display: block;

    color: ${({ theme }) => theme.colors.globalTopbar.textColor};
    font: 400 1rem/1.4rem "Inter";
  }

  a,
  button {
    transition: 0.3s all;
    display: flex;
    align-items: center;
    gap: 1rem;

    padding: 1rem;

    :hover {
      color: ${({ theme }) => theme.colors.globalTopbar.textHoverColor};
    }
  }

  ul {
    margin-bottom: 1.5rem;

    li:last-child > :first-child {
      padding-bottom: 0;
    }
  }

  a svg {
    width: 20px;
    height: 20px;

    &.user-icon {
      stroke-width: 2px;
    }
  }

  button {
    border: none;
    background: none;
    width: 100%;
    text-align: start;

    :hover {
      cursor: pointer;
    }
  }

  ${SwitchThemeContainer} {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
`;

interface IUserMenuContainerProps {
  optionsIsOpen: boolean;
  scrollIsOnTop: boolean;
}

export const UserMenuContainer = styled.div<IUserMenuContainerProps>`
  border-radius: 4px;
  transition: 0.3s all;

  :hover {
    cursor: pointer;
  }

  .popover {
    padding: 0.31rem 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .avatar {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ theme }) =>
      theme.mode === "dark" &&
      css`
        background-color: #303030;
      `}

    color: white;

    font-weight: 500;
  }

  img {
    object-fit: cover;
  }

  .avatar,
  img {
    border-radius: 50%;
  }

  svg:not(.user-icon) {
    transition: 0.3s all;

    path {
      stroke: ${({ theme }) => theme.colors.globalTopbar.iconsColor};
    }
  }

  ${({ optionsIsOpen }) =>
    optionsIsOpen &&
    css`
      svg:not(.user-icon) {
        transform: rotate(180deg);
      }
    `}
`;
