import api from "shared/infra/services/api";
import getToken from "shared/infra/services/auth/getToken";

const token = getToken();
const headers = {
  Authentication: `Bearer ${token}`,
  "X-Requested-With": "XMLHttpRequest",
};

export const getAllCollectionsRequest = async () => {
  try {
    const response = await api.get("collection?per_page=15", {
      headers,
    });

    return response?.data;
  } catch (error) {
    console.log("error -->", error);
  }
};

export const getSingleCollectionRequest = async (id: any, slug: string) => {
  try {
    const response = await api.get(`collection/${id}/${slug}`);
    return response?.data;
  } catch (error) {
    console.log("error -->", error);
  }
};

export const getFilterRequest = async (options: any) => {
  const { apiURL, name, className } = options;
  const queryParams: any = {};

  if (name) {
    queryParams["where[0][index]"] = "name";
    queryParams["where[0][condition]"] = "like";
    queryParams["where[0][value]"] = name;
  }

  if (className) {
    queryParams[`whereHas[0][index]`] = "collectionable_type";
    queryParams[`whereHas[0][condition]`] = "=";
    queryParams[`whereHas[0][value]`] = className;
    queryParams[`whereHasWith[0]`] = "UserCollectionItems";
  }

  try {
    const response = await api.get(apiURL, { params: queryParams });

    return response.data;
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const updateCollectionRequest = async (
  id: any,
  name: string,
  description: string,
) => {
  try {
    await api.put(`collection/${id}`, { name, description });
  } catch (error) {
    console.log("error --> ", error);
  }
  return null;
};

export const removeCollectionRequest = async (id: any) => {
  try {
    await api.delete(`collection/${id}`);
  } catch (error) {
    console.log("error --> ", error);
  }
  return null;
};

export const setPrivateCollectionsRequest = async (id: number) => {
  try {
    await api.post(`collection/private/${id}`);
  } catch (error) {
    console.log("error --> ", error);
  }
  return null;
};

export const setCollectionItemPositionRequest = async (
  idArrays: any,
  idCollection: any,
) => {
  try {
    await api.post(`collection/order/${idCollection}`, {
      ids: idArrays,
    });
  } catch (error) {
    console.log("error --> ", error);
  }
  return null;
};

export const setCollectionRequest = async ({ name, description }: any) => {
  try {
    const response = await api.post("collection", { name, description });
    return response.data?.data;
  } catch (error) {
    console.log("error --> ", error);
  }

  return null;
};

export const setItemsCollectionsRequest = async (payload: any) => {
  const data = {
    className: payload.className,
    items: [payload.item],
    collections: [payload.collections],
  };

  await api.post("collection/relation-item", data);

  return data;
};

export const setCollectionPositionRequest = async (idsArray) => {
  try {
    await api.post("collection/order", {
      ids: idsArray,
    });
  } catch (error) {
    console.log("error --> ", error);
  }
  return null;
};

export const removeItemCollectionsRequest = async (payload: any) => {
  const { className, item, collections } = payload;
  try {
    await api.post("collection/remove-relation-item", {
      className,
      items: [item],
      collections: [collections],
    });

    return true;
  } catch (error) {
    console.log("error --> ", error);
    return false;
  }
};

export const getMoreCollectionsRequest = async (page = 1) => {
  try {
    const response = await api.get(`collection?page=${page}`);
    return response?.data;
  } catch (error) {
    console.log("error -->", error);
  }
  return null;
};
