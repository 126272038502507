import useGroupContext from "pages/[tenant]/g/hooks/useGroupContext";
import { useTopbarContext } from "shared/providers/Layout/hooks/useTopbarContext";

import { SideMenu } from "shared/providers/Layout/components/NewTopBar/components/molecules/SideMenu";

import { HamburguerMenuContainer } from "./styles";

export const Hamburguermenu = () => {
  const {
    openSideMenu,
    closeSideMenu,
    sideMenuIsOpen,
    closeSecondaryMenuContainer,
  } = useTopbarContext();

  const { fetchMyGroups } = useGroupContext();

  const handleIconClick = async () => {
    if (sideMenuIsOpen) {
      closeSideMenu();
      closeSecondaryMenuContainer();
    } else {
      openSideMenu();
    }
    await fetchMyGroups();
  };

  return (
    <>
      <HamburguerMenuContainer
        onClick={handleIconClick}
        sideMenuIsOpen={sideMenuIsOpen}
      >
        <div className="bar-1" id="bar-1"></div>
        <div className="bar-2" id="bar-2"></div>
        <div className="bar-3" id="bar-3"></div>
      </HamburguerMenuContainer>

      <SideMenu />
    </>
  );
};
