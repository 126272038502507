import React from "react";

export const StatmentIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9987 7.33333H11.9987V2.66667C11.9987 2.48986 11.9285 2.32029 11.8034 2.19526C11.6784 2.07024 11.5088 2 11.332 2H1.9987C1.82189 2 1.65232 2.07024 1.52729 2.19526C1.40227 2.32029 1.33203 2.48986 1.33203 2.66667V12C1.33203 13.1027 2.22936 14 3.33203 14H12.6654C13.768 14 14.6654 13.1027 14.6654 12V8C14.6654 7.82319 14.5951 7.65362 14.4701 7.5286C14.3451 7.40357 14.1755 7.33333 13.9987 7.33333ZM3.33203 12.6667C3.15522 12.6667 2.98565 12.5964 2.86063 12.4714C2.7356 12.3464 2.66536 12.1768 2.66536 12V3.33333H10.6654V12C10.6654 12.234 10.706 12.4587 10.7794 12.6667H3.33203ZM13.332 12C13.332 12.1768 13.2618 12.3464 13.1368 12.4714C13.0117 12.5964 12.8422 12.6667 12.6654 12.6667C12.4886 12.6667 12.319 12.5964 12.194 12.4714C12.0689 12.3464 11.9987 12.1768 11.9987 12V8.66667H13.332V12Z"
        fill="currentColor"
      />
      <path
        d="M4 4.66406H9.33333V5.9974H4V4.66406ZM4 7.33073H9.33333V8.66406H4V7.33073ZM7.33333 9.9974H9.33333V11.3307H7.33333V9.9974Z"
        fill="currentColor"
      />
    </svg>
  );
};
