import React, { InputHTMLAttributes } from "react";

import { Container, InputStyled } from "./styles";

interface IDocumentInputProps extends InputHTMLAttributes<HTMLInputElement> {
  icon: React.ReactNode;
  newValue: string;
}

const DocumentInput = ({ icon, newValue, ...rest }: IDocumentInputProps) => {
  return (
    <Container>
      <InputStyled newValue={newValue} {...rest} />

      <span className="inputOptions">
        {icon && <span className="prefix">{icon}</span>}{" "}
      </span>
    </Container>
  );
};

export default DocumentInput;
