import React from "react";

export const WebhookHistoryIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6667 1.33594L13.9998 4.66927L14 13.9976C14 14.3685 13.7034 14.6693 13.3377 14.6693H2.66227C2.29651 14.6693 2 14.3657 2 14.0081V1.99714C2 1.63197 2.29833 1.33594 2.66567 1.33594H10.6667ZM10 2.66927H3.33333V13.3359H12.6667V5.33594H10V2.66927ZM8.66667 6.0026V8.66927H10.6667V10.0026H7.33333V6.0026H8.66667Z"
        fill="currentColor"
      />
    </svg>
  );
};
