import React from "react";

export const EnsinioSteamIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5013 2.16406C5.9063 2.16406 2.16797 5.9024 2.16797 10.4974C2.16797 15.0924 5.9063 18.8307 10.5013 18.8307C15.0963 18.8307 18.8346 15.0924 18.8346 10.4974C18.8346 5.9024 15.0963 2.16406 10.5013 2.16406ZM10.5013 17.1641C6.82547 17.1641 3.83464 14.1732 3.83464 10.4974C3.83464 6.82156 6.82547 3.83073 10.5013 3.83073C14.1771 3.83073 17.168 6.82156 17.168 10.4974C17.168 14.1732 14.1771 17.1641 10.5013 17.1641Z"
        fill="currentColor"
      />
      <path
        d="M8 14.6615L14.6667 10.4948L8 6.32812V14.6615Z"
        fill="currentColor"
      />
    </svg>
  );
};
