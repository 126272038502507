import styled from "styled-components";

export const MobileMenuButton = styled.button`
  background-color: transparent;
  border: none;

  z-index: 99;

  padding: 1rem;

  svg {
    width: 20px;
    height: 18px;
    transition: 0.3s all;
  }

  :hover {
    cursor: pointer;
  }
`;

interface IGroupNavigationContainerProps {
  isMobile: boolean;
  show: boolean;
}

export const GroupNavigationContainer = styled.div<IGroupNavigationContainerProps>`
  max-width: 90rem;
  margin: 0 auto;
  padding: ${({ isMobile }) => (isMobile ? "0" : "0 2.6rem")};

  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .navigation {
    list-style: none;
    display: flex;
    position: relative;

    gap: 0rem;
    align-items: center;

    grid-column: 1/-1;
    grid-row: 1;

    margin-bottom: 0;

    @media (max-width: 850px) {
      overflow-y: scroll;
      scroll-behavior: smooth;
    }

    li a {
      text-transform: uppercase;
      padding: 0.8rem 1.88rem;

      white-space: nowrap;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  li {
    transition: 0.3s all;

    :hover {
      background-color: ${({ theme }) =>
        theme.groups.groupFeedCommentCorderColor};
    }

    a {
      display: flex;
      align-items: center;
      gap: 0.63rem;
      padding: 0.8rem 0.75rem;

      font: 600 0.8125rem/1.21875rem "Inter";

      color: ${({ theme }) => theme.groups.groupTopbarTabColor} !important;

      transition: 0.3s all;

      &.active {
        color: ${({ theme }) =>
          theme.groups.groupTopbarTabActiveColor} !important;
      }
    }
  }
`;

export const GroupNavigationBackground = styled.div`
  background-color: ${({ theme }) =>
    theme.groups.contextualMenuBackgroundColor};

  position: fixed;
  top: 4rem;
  z-index: 97;

  width: 100%;

  border-bottom: 1px solid
    ${({ theme }) => theme.colors.globalTopbar.borderColor};
`;
