import React from "react";

import { Card } from "pages/[tenant]/search/components/atoms/Card";
import { CardsList } from "pages/[tenant]/search/components/atoms/molecules/CardsList";
import { useNewGlobalSearch } from "pages/[tenant]/search/hooks/useNewGlobalSearch";

import { SearchInput } from "shared/components/atoms/SearchInput";
import { useTopbarContext } from "shared/providers/Layout/hooks/useTopbarContext";

import { Empty, SearchContainer, SearchInputContainer } from "./styles";

export const Search = () => {
  const { itemsWasFounded, clearSearchResult, searchInList, listToIterate } =
    useNewGlobalSearch();
  const { searchIsOpen, closeSearch } = useTopbarContext();

  return (
    <SearchContainer show={searchIsOpen}>
      <SearchInputContainer>
        <SearchInput
          clearSearchMethod={clearSearchResult}
          searchListMethod={searchInList}
          withSearchAndXIcon
        />
      </SearchInputContainer>

      <CardsList>
        {itemsWasFounded ? (
          listToIterate.map((group) => (
            <Card key={group.id} group={group} onClick={closeSearch} />
          ))
        ) : (
          <Empty>Nenhum resultado foi encontrado.</Empty>
        )}
      </CardsList>
    </SearchContainer>
  );
};
