import React from "react";

export const CuponsIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.138 2.19533C9.0762 2.13331 9.00273 2.08411 8.92184 2.05059C8.84095 2.01707 8.75423 1.99987 8.66667 2H2.66667C2.48986 2 2.32029 2.07024 2.19526 2.19526C2.07024 2.32029 2 2.48986 2 2.66667V8.66667C2 8.844 2.07 9.01333 2.19533 9.138L7.52867 14.4713C7.59044 14.5335 7.66388 14.5828 7.74477 14.6164C7.82565 14.65 7.9124 14.6674 8 14.6674C8.08761 14.6674 8.17435 14.65 8.25524 14.6164C8.33612 14.5828 8.40956 14.5335 8.47133 14.4713L14.4713 8.47133C14.5333 8.40948 14.5825 8.33601 14.616 8.25513C14.6495 8.17425 14.6668 8.08756 14.6668 8C14.6668 7.91245 14.6495 7.82575 14.616 7.74487C14.5825 7.664 14.5333 7.59053 14.4713 7.52867L9.138 2.19533ZM8 13.0573L3.33333 8.39067V3.33333H8.39067L13.0573 8L8 13.0573Z"
        fill="currentColor"
      />
      <path
        d="M5.66349 6.67073C6.21762 6.67073 6.66682 6.22152 6.66682 5.6674C6.66682 5.11327 6.21762 4.66406 5.66349 4.66406C5.10936 4.66406 4.66016 5.11327 4.66016 5.6674C4.66016 6.22152 5.10936 6.67073 5.66349 6.67073Z"
        fill="#C8C8C9"
      />
    </svg>
  );
};
