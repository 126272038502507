import styled from "styled-components";

interface IActiveItemIndicatorProps {
  position: number;
  width: number;
  indicatorColor: string;
}

export const ActiveItemIndicatorContainer = styled.li<IActiveItemIndicatorProps>`
  width: ${({ width }) => width}px;
  height: 3px;
  background-color: ${({ indicatorColor }) => indicatorColor};

  transition: 0.3s all;

  list-style: none;

  position: absolute;
  bottom: -1px;
  pointer-events: none;
  left: ${({ position }) => position}px;
`;
