import { useEffect, useRef } from "react";

interface IInfinityScrollProps {
  fetchMore: () => Promise<void>;
  root?: HTMLElement | Document;
  rootMargin?: string;
}

const InfinityScroll = ({
  fetchMore,
  root,
  rootMargin,
}: IInfinityScrollProps) => {
  const target = useRef();

  useEffect(() => {
    const options = {
      root: root,
      rootMargin: rootMargin,
      threshold: 1,
    };

    const observer = new IntersectionObserver(async ([entry]) => {
      if (entry.isIntersecting) {
        observer.disconnect();
        await fetchMore();
      }
    }, options);

    observer.observe(target.current);

    return () => observer.disconnect();
  }, []);

  return <div className="infinity-scroll" ref={target} />;
};

export default InfinityScroll;
