import { parseCookies } from "nookies";

const getToken = (): string | null => {
  const token = parseCookies(null)?.token;

  if (!!token && token !== "undefined") {
    const parseToken = JSON.parse(token);
    return parseToken;
  }

  return null;
};

export default getToken;
