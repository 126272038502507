import React from "react";

export const UserIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 1.3335C7.34073 1.3335 6.69626 1.52899 6.1481 1.89526C5.59994 2.26154 5.17269 2.78213 4.9204 3.39122C4.66811 4.0003 4.6021 4.67053 4.73072 5.31713C4.85933 5.96373 5.1768 6.55768 5.64298 7.02385C6.10915 7.49003 6.7031 7.8075 7.3497 7.93611C7.9963 8.06473 8.66652 7.99872 9.27561 7.74643C9.8847 7.49414 10.4053 7.06689 10.7716 6.51873C11.1378 5.97057 11.3333 5.3261 11.3333 4.66683C11.3333 3.78277 10.9821 2.93493 10.357 2.30981C9.7319 1.68469 8.88406 1.3335 8 1.3335ZM8 6.66683C7.60444 6.66683 7.21776 6.54953 6.88886 6.32977C6.55996 6.11001 6.30362 5.79765 6.15224 5.4322C6.00087 5.06674 5.96126 4.66461 6.03843 4.27665C6.1156 3.88869 6.30608 3.53232 6.58579 3.25262C6.86549 2.97291 7.22186 2.78243 7.60982 2.70526C7.99778 2.62809 8.39991 2.6677 8.76537 2.81907C9.13082 2.97045 9.44318 3.22679 9.66294 3.55569C9.8827 3.88459 10 4.27127 10 4.66683C10 5.19726 9.78929 5.70597 9.41421 6.08104C9.03914 6.45612 8.53043 6.66683 8 6.66683ZM14 14.0002V13.3335C14 12.0958 13.5083 10.9088 12.6332 10.0337C11.758 9.15849 10.571 8.66683 9.33333 8.66683H6.66667C5.42899 8.66683 4.242 9.15849 3.36683 10.0337C2.49167 10.9088 2 12.0958 2 13.3335V14.0002H3.33333V13.3335C3.33333 12.4494 3.68452 11.6016 4.30964 10.9765C4.93477 10.3514 5.78261 10.0002 6.66667 10.0002H9.33333C10.2174 10.0002 11.0652 10.3514 11.6904 10.9765C12.3155 11.6016 12.6667 12.4494 12.6667 13.3335V14.0002H14Z"
        fill="currentColor"
      />
    </svg>
  );
};
