import { APIDocIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/appstore/APIDocIcon";
import { AllAppsIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/appstore/AllAppsIcon";
import { AppsInstalledIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/appstore/AppsInstalledIcon";
import { CustomScriptIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/appstore/CustomScriptIcon";
import { SearchCircle } from "pages/[tenant]/console/assets/icons/sidebarSection/appstore/SearchCircle";
import { WebhookHistoryIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/appstore/WebhookHistoryIcon";
import { WebhooksIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/appstore/WebhooksIcon";
import {
  IOption,
  IOptionsHook,
  ISubSection,
} from "pages/[tenant]/console/types";

export const useAppStoreOptions = (): IOptionsHook => {
  const sectionLabel = "App Store";
  const appStore: IOption[] = [
    {
      id: 1,
      label: "Todos os apps",
      description:
        " Estenda a funcionalidade da sua plataforma através de apps e integrações com terceiros.",
      path: "/console/apps",
      icon: <AllAppsIcon />,
    },
    {
      id: 2,
      label: "Apps instalados",
      path: "/console/apps/installed",
      icon: <AppsInstalledIcon />,
      shouldHide: true,
    },
  ];
  const appStoreSubSections: ISubSection[] = [
    {
      label: "Webhooks",
      options: [
        {
          id: 30,
          label: "Webhooks",
          description:
            "Automatize fluxos e processos com o envio de informações em tempo real",
          path: "/console/webhooks",
          icon: <WebhooksIcon />,
        },
        {
          id: 31,
          label: "Históricos de disparos",
          description:
            "Confira o histórico de disparos de webhooks e reenvie payloads da suas informações.",
          path: "/console/webhooks/history",
          icon: <WebhookHistoryIcon />,
        },
      ],
    },
    {
      label: "Pixel",
      options: [
        {
          id: 20,
          label: "Pixel de Rastreamento",
          description:
            "Colete informações que auxiliam na mensuração o desempenho das suas campanhas.",
          path: "/console/pixel",
          icon: <SearchCircle />,
        },
      ],
    },
    {
      label: "Outros",
      options: [
        {
          id: 40,
          label: "Documentação API",
          path: "/console/docsapi",
          icon: <APIDocIcon />,
          shouldHide: true,
        },
        {
          id: 41,
          label: "Scripts customizados",
          path: "/console/customscripts",
          icon: <CustomScriptIcon />,
          shouldHide: true,
        },
      ],
    },
  ];

  return {
    options: appStore,
    subSections: appStoreSubSections,
    label: sectionLabel,
  };
};
