import styled from 'styled-components';
import { VscChromeClose } from 'react-icons/vsc';

export const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  z-index: 9999;
  width: 100vw;
  height: 100vh;

  padding: 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(15px);

  @media (max-width: 825px) and (max-height: 825px) {
    padding: 0;
    backdrop-filter: unset;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  padding: 0 10px;
  background: #f5f5f5;

  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 700px;
  padding: 40px;
  border-radius: 12px;

  overflow-y: auto;

  h1 {
    font-size: 36px;
    text-align: center;
    margin-bottom: 35px;
  }

  h3 {
    font-size: 26px;
    font-weight: 300;
    width: 100%;
    margin-bottom: 35px;

    max-width: 900px;
    text-align: center;

    & + h3 {
      margin-top: 40px;
    }
  }

  button {
    font-size: 18px;
    padding: 5px 30px;
    height: max-content;
    width: max-content;
    border-radius: 10px;
  }

  @media (max-width: 825px) and (max-height: 825px) {
    h1 {
      font-size: 30px;
      color: #fff;
      text-shadow: 0 0 6px rgb(0 0 0 / 30%);
      font-weight: 500;
    }

    h3 {
      color: #fff;
      text-shadow: 0 0 4px rgb(0 0 0 / 60%);
      font-weight: 400;
      font-size: 24px;
    }

    max-width: 100%;
    max-height: 100%;
    border-radius: 0;
    padding: 20px 20px 0 20px;
    background: rgb(0 0 0 / 10%);
    backdrop-filter: blur(100px);
  }
`;

export const CloseIcon = styled(VscChromeClose)`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 35px;
  cursor: pointer;

  transition: color 0.4s;

  &:hover,
  &:focus {
    color: #b5b5b5;
  }

  @media (max-width: 825px) and (max-height: 825px) {
    color: #fff;
  }
`;
