import styled from "styled-components";

import { Form } from "@ant-design/compatible";

export const LoginComponentContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  input {
    width: 300px;
    margin-bottom: 9px;
  }

  button.primary {
    margin-top: 20px;
    cursor: pointer;
    width: 214px;
    height: 44px;
  }

  .ant-form-item-children {
    display: flex;
    flex-direction: column;

    .validEmail {
      min-height: 19px;
      margin: 5px 0 -2px 5px;
      color: red;
      font-size: 14px;
      line-height: 1.3;
    }
  }

  button.login.ant-btn.ant-btn-primary {
    margin: 18px 0px 0px 0px;
    width: 105px;
    height: 44px;
    font-size: 14px;

    > span {
      pointer-events: none;
    }

    :hover {
      background-color: ${({ theme }) => theme?.colors?.primary};
      opacity: 0.8;
    }
  }

  @media (max-width: 1400px) {
    margin: 8px 0 0 0;
  }
`;
