interface IIconProps extends React.SVGProps<SVGSVGElement> {}

export default ({ ...props }: IIconProps) => (
  <svg
    {...props}
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="drag-icon"
  >
    <path
      d="M4.16797 8L1.66797 10.5L4.16797 13"
      stroke="#B2B2B2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 4.66797L10 2.16797L12.5 4.66797"
      stroke="#B2B2B2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 16.332L10 18.832L7.5 16.332"
      stroke="#B2B2B2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.832 8L18.332 10.5L15.832 13"
      stroke="#B2B2B2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.66797 10.5H18.3346"
      stroke="#B2B2B2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 2.16797V18.8346"
      stroke="#B2B2B2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
