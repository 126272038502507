import React from "react";

export const TagsIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3319 2.66406H5.67522C5.46301 2.66415 5.25389 2.71488 5.06524 2.81205C4.87658 2.90922 4.71385 3.05001 4.59055 3.22273L1.45655 7.61006C1.37558 7.72298 1.33203 7.85844 1.33203 7.9974C1.33203 8.13635 1.37558 8.27181 1.45655 8.38473L4.59055 12.7721C4.71385 12.9448 4.87658 13.0856 5.06524 13.1827C5.25389 13.2799 5.46301 13.3306 5.67522 13.3307H13.3319C14.0672 13.3307 14.6652 12.7327 14.6652 11.9974V3.9974C14.6652 3.26206 14.0672 2.66406 13.3319 2.66406ZM13.3319 11.9974H5.67522L2.81789 7.9974L5.67522 3.9974H13.3319V11.9974Z"
        fill="currentColor"
      />
    </svg>
  );
};
