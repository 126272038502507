import styled from "styled-components";

export const SideBarContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    display: none;
  }

  img {
    border-radius: 4px;
  }

  width: 100%;
  height: min-content;

  margin-bottom: 1.5rem;

  border-radius: 4px;

  position: relative;

  .subtitle {
    font: 600 1rem/1.5rem "Inter";
    color: ${({ theme }) => theme.groups.groupTopbarTabActiveColor};
    margin-top: 1rem;
    text-align: center;
    border-radius: 4px;

    background-color: ${({ theme }) => theme.groups.backgroundComponentsMural};
    border: 1px solid ${({ theme }) => theme.groups.borderColorComponentsMural};
    padding: 1rem;
  }
`;

export const Image = styled.img`
  width: 100%;

  object-fit: cover;
`;
