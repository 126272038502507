import React, { useState, useContext } from 'react';
import Image from 'next/image';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import GlobalContext from "shared/providers/context/GlobalContext";
import useTranslation from "next-translate/useTranslation";
import auth from "shared/infra/services/auth";
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Container, ErrorSection, Button } from './styles';

function EmailConfirmation({ navigate }) {
  const { t } = useTranslation();
  const { state } = useContext(GlobalContext);
  const userEmail = state.modal.newlyCreatedUserEmail;
  const { settings, site } = state.tenancy;
  const isDarkMode = state.theme.value === "dark";

  const [resendEmailLoader, setResendEmailLoader] = useState(false);
  const [resendEmailStatus, setResendEmailStatus] = useState(state.modal.createdUserEmailStatus);

  const handleResend = async () => {
    setResendEmailLoader(true);
    const response = await auth.emailConfirmation(userEmail);

    if (response) {
      setResendEmailLoader(false);
      setResendEmailStatus(response);
    }

  };

  const { users } = settings;
  const theme = settings['general-theme'];

  const signUpConfirmationImageStatus = users?.userSignUpConfirmationImage;
  const signUpConfirmationImage = users?.userSignUpConfirmationCustomImage;
  const defaultImage = theme?.themeLogoHorizontal;
  const fallbackImage = theme?.themeLogoTopBar;

  const platformHasNoImage = !defaultImage && !fallbackImage;

  const { userSignUpConfirmationTitle, userSignUpConfirmationDescription } =
    users;

  return (
    <>
      <Container >
        <div style={{ position: "relative", width: "180px", paddingBottom: "15%" }} >
          {signUpConfirmationImageStatus === 'none' || platformHasNoImage ? (
            <h1 className="siteName">{site?.siteName}</h1>
          ) : isDarkMode ? (
            <Image
              src={theme?.themeDarkModeLogoHorizontal || defaultImage}
              fill
              alt="logo"
            />
          ) : signUpConfirmationImageStatus === 'default' ? (
            <Image
              src={defaultImage || fallbackImage}
              fill
              alt="logo"
            />
          ) : (
            <Image
              src={signUpConfirmationImage || fallbackImage || defaultImage}
              fill
              alt="logo"
            />
          )}
        </div>

        <div className="titles">
          <section className="header-title">
            <h1>
              {userSignUpConfirmationTitle?.length > 3
                ? userSignUpConfirmationTitle
                : t('common:confirmAccount')}
            </h1>

            <h2>{userEmail}</h2>

            <h3>
              {userSignUpConfirmationDescription.length
                ? userSignUpConfirmationDescription
                : t('common:weSentYouAMessage')}
            </h3>
          </section>
          <section className="bottom-container">
            <span>
              {resendEmailLoader ? (
                <Spin />
              ) : !resendEmailStatus ? null :
                resendEmailStatus?.success ? (
                  <CheckCircleTwoTone
                    style={{ fontSize: '20px' }}
                    twoToneColor="#52c41a"
                  />
                ) : (
                  <ErrorSection>
                    <CloseCircleTwoTone
                      style={{ fontSize: '20px' }}
                      twoToneColor="#ff0000"
                    />

                    <p>{resendEmailStatus?.message}</p>
                  </ErrorSection>
                )}
            </span>

            <Button>
              {t('common:linkExpiresIn')}
            </Button>
            <Button
              $btnType="resend"
              onClick={handleResend}
              disabled={!resendEmailStatus?.success}
            >
              {t('common:newLink')}
            </Button>
          </section>
        </div>
      </Container>
    </>
  );
}

EmailConfirmation.propTypes = {
  navigate: PropTypes.func.isRequired,
};

export default EmailConfirmation;
