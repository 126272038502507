/* eslint-disable @next/next/no-img-element */
import React, { useMemo, useState } from "react";

import Link from "next/link";

import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";
import useWindowSize from "shared/utils/hooks/useWindowSize";

import { LogoContainer } from "./styles";

interface ILogoProps {
  shouldPositionLogoInCenterOfScreen?: boolean;
  shouldAlwaysShowMobileLogo?: boolean;
}

export const Logo = ({
  shouldPositionLogoInCenterOfScreen = true,
  shouldAlwaysShowMobileLogo = false,
}: ILogoProps) => {
  const [failedToLoadImage, setFailedToLoadImage] = useState({
    dark: false,
    light: false,
  });

  const {
    state: { tenancy, theme },
  } = useGlobalContext();
  const generalTheme = tenancy.settings["general-theme"];

  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 850;

  const plataformIcon = useMemo(
    () => ({
      dark: shouldAlwaysShowMobileLogo
        ? generalTheme?.themeDarkModeLogoIcon
        : generalTheme?.themeDarkModeLogoHorizontal,
      light: shouldAlwaysShowMobileLogo
        ? generalTheme?.themeLogoIcon
        : generalTheme?.themeLogoHorizontal,
    }),
    [theme.value, isMobile],
  );

  const logoImageExists = useMemo(
    () => !!plataformIcon[theme.value],
    [plataformIcon],
  );

  const handleLoadImageError = () =>
    setFailedToLoadImage((prev) => ({
      ...prev,
      [theme.value]: true,
    }));

  return (
    <LogoContainer
      shouldPositionLogoInCenterOfScreen={shouldPositionLogoInCenterOfScreen}
    >
      <Link href={`/${tenancy.settings.site.mainPage}`}>
        {logoImageExists && !failedToLoadImage[theme.value] ? (
          <img
            src={plataformIcon[theme.value]}
            alt={`Logotipo da plataforma ${tenancy.settings?.site?.siteName}`}
            onError={handleLoadImageError}
          />
        ) : (
          <h1>{tenancy.settings?.site?.siteName}</h1>
        )}
      </Link>
    </LogoContainer>
  );
};
