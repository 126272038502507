import React from "react";

import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";

import ModalContent from "./Content";

const MobilePurchaseDisableModal = () => {
  const { state, setState } = useGlobalContext();

  function handleCloseModal() {
    if (state.showDisablePurchaseModal)
      setState((prev) => ({ ...prev, showDisablePurchaseModal: false }));
  }

  return (
    state.showDisablePurchaseModal && (
      <ModalContent onCloseModal={handleCloseModal} />
    )
  );
};

export default MobilePurchaseDisableModal;
