import React, { memo } from "react";
/* import { useSelector, useDispatch } from "react-redux"; */

import antdNotification from "shared/utils/openNotificationWithIcon";
/* import { setLastToastNotifications } from "shared/providers/redux/actions"; */

const ToastNotification = () => {
  /* const { toastNotificationsList, lastToastNotificationsList } = useSelector(
    ({ notifications }) => notifications
  ); */

  function setLastToastNotifications() {}

  const toastNotificationsList = {};
  const lastToastNotificationsList = {};

  const renderToastNotification = () => {
    if (toastNotificationsList) {
      const { type, message } = toastNotificationsList;
      if (
        toastNotificationsList?.message === lastToastNotificationsList?.message
      ) {
        if (
          Math.floor(
            (new Date().getTime() -
              lastToastNotificationsList?.date?.getTime()) /
              1000
          ) > 30
        ) {
          setLastToastNotifications({
            ...toastNotificationsList,
            date: new Date(),
          });

          return antdNotification(type, message);
        }
      } else {
        setLastToastNotifications({
          ...toastNotificationsList,
          date: new Date(),
        });

        return antdNotification(type, message);
      }
    }
  };

  return <>{renderToastNotification()}</>;
};

export default memo(ToastNotification);
