import { ITenantTheme } from "shared/styles/Theme";

interface IGetColorsFromTopbarThemesReturn {
  background: string;
  color: string;
}

export function getColorsFromTopbarThemes(
  tenant: ITenantTheme,
): IGetColorsFromTopbarThemesReturn {
  const themeBackground = {
    advanced: tenant?.themeTopBarBackgroundColor,
    dark: "#121212",
    light: "#ffffff",
    trasparent: "rgba(0, 0, 0, 0.2)",
    gradient: "rgba(0, 0, 0, 0.2)",
    automatic: tenant?.themeMasterColor,
  };

  const themeColors = {
    advanced: tenant?.themeTopBarIconsColor,
    dark: tenant?.themeDarkModeMasterColor,
    light: "#545454",
    trasparent: "#1a1a1a",
    gradient: "#1a1a1a",
    automatic: "#fff",
  };

  return {
    background: themeBackground[tenant?.themeTopBar],
    color: themeColors[tenant?.themeTopBar],
  };
}
