import styled, { css } from "styled-components";

import { ModulesBaseButtonContainer } from "pages/[tenant]/console/groups/[group_slug]/modules/styles/ModulesBaseButtonContainer";

export const ModulesListItemContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 0.75rem;
  padding: 0.75rem;

  span {
    font: 600 1rem/1.125rem "Inter";
    color: ${({ theme }) => theme.console.sidebar.titleColor};

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    margin: 0;

    width: 100%;
  }

  svg {
    min-width: 1.5rem;
    * {
      stroke: ${({ theme }) => theme.console.sidebar.titleColor};
    }
  }
`;

export const ModulesListControls = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
  cursor: pointer;

  svg {
    height: 1.25rem;
    width: 1.25rem;

    * {
      stroke: ${({ theme }) => theme.console.sidebar.optionTextColor};
    }
  }

  #module-options {
    position: relative;
  }
`;

interface IControlButtonProps {
  shouldRotate?: boolean;
}

export const ControlButton = styled(
  ModulesBaseButtonContainer,
)<IControlButtonProps>`
  background-color: transparent;

  padding: 0;

  width: 2rem;
  height: 2rem;

  :hover {
    background-color: ${({ theme }) =>
      theme.console.sidebar.optionHoverBackgroundColor};
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    transition: all 0.3s;
    transform: rotate(180deg);

    * {
      stroke: ${({ theme }) => theme.console.sidebar.titleColor};
    }

    ${({ shouldRotate }) =>
      shouldRotate &&
      css`
        transform: rotate(0);
      `}
  }
`;

interface ICreateLessonProps {
  isLessonlistVisible: boolean;
  isModuleSelected: boolean;
}

export const CreateLesson = styled.div<ICreateLessonProps>`
  border-top: 1px solid ${({ theme }) => theme.console.sidebar.borderColor};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  background-color: ${({ isModuleSelected }) =>
    isModuleSelected ? "#605cff" : "transparent"};

  width: 100%;

  color: ${({ theme, isModuleSelected }) =>
    isModuleSelected ? "#fff" : theme.console.sidebar.titleColor};
  font: 600 0.813rem/1rem "Inter";

  cursor: pointer;

  transform: translateY(-10%);
  transition: all 0.2s ease-in-out;
  opacity: 0;
  pointer-events: none;
  height: 0;
  padding: 0;

  svg {
    max-height: 1rem;
    max-width: 1rem;
    transition: all 0.2s ease-in-out;

    * {
      transition: all 0.2s ease-in-out;
      stroke: ${({ theme, isModuleSelected }) =>
        isModuleSelected ? "#fff" : theme.console.sidebar.titleColor};
    }
  }

  &:hover {
    background-color: #605cff;
    color: #f2f2f2;

    svg * {
      stroke: #f2f2f2;
    }
  }

  ${({ isLessonlistVisible }) =>
    isLessonlistVisible &&
    css`
      padding: 1rem 1.5rem;

      transform: translateY(0);
      opacity: 1;
      pointer-events: all;
      height: 100%;
    `}
`;

interface INoLessonsAvailableProps {
  showNoLessonMessage: boolean;
}

export const NoLessonsAvailable = styled.div<INoLessonsAvailableProps>`
  border-top: 1px solid ${({ theme }) => theme.console.sidebar.borderColor};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  gap: 1.5rem;

  transform: translateY(-10%);
  transition: all 0.2s;
  opacity: 0;
  pointer-events: none;
  height: 0;
  padding: 0;

  color: ${({ theme }) => theme.console.sidebar.optionTextColor};
  font: 600 0.875rem/1rem "Inter";

  ${({ showNoLessonMessage }) =>
    showNoLessonMessage &&
    css`
      padding: 1.5rem;

      transform: translateY(0);
      opacity: 1;
      pointer-events: all;
      height: 100%;
    `}
`;

interface IModuleOptionsProps {
  showModuleOptions: boolean;
}

export const ModuleOptions = styled.div<IModuleOptionsProps>`
  transition: all 0.1s ease-in-out;

  border: 1px solid ${({ theme }) => theme.console.sidebar.borderColor};
  padding: 0.5rem;
  position: absolute;
  top: -8px;
  right: 30px;

  color: ${({ theme }) => theme.console.sidebar.optionTextColor};

  width: 100px;
  background-color: ${({ theme }) => theme.console.sidebar.backgroundColor};

  pointer-events: ${({ showModuleOptions }) =>
    showModuleOptions ? "all" : "none"};

  opacity: ${({ showModuleOptions }) => (showModuleOptions ? "1" : "0")};
  transform: ${({ showModuleOptions }) =>
    showModuleOptions ? "translateX(-10%)" : "translateX(0)"};

  button {
    opacity: 1;
    width: 100%;
  }

  svg {
    height: 1rem;
    width: 1rem;
  }
`;
