import styled, { css } from "styled-components";

interface IOptionsContainerProps {
  buttonsShouldHaveBackground: boolean;
}

export const OptionsContainer = styled.div<IOptionsContainerProps>`
  display: flex;
  gap: 0.75rem;
  align-items: center;

  > button {
    transition: 0.3s all;
    padding: 0.6875rem;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${({ buttonsShouldHaveBackground }) =>
      buttonsShouldHaveBackground
        ? "rgba(255, 255, 255, 0.10)"
        : "transparent"};
    border: none;

    :hover {
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.2);

      ${({ theme, buttonsShouldHaveBackground }) =>
        !buttonsShouldHaveBackground &&
        theme.mode !== "dark" &&
        css`
          box-shadow: 0 0 0 1px #282a30;
        `}
    }

    svg {
      width: 18px;
      height: 18px;

      path {
        stoke: #050505;

        ${({ buttonsShouldHaveBackground, theme }) =>
          !buttonsShouldHaveBackground &&
          css`
            stroke: ${theme.colors.globalTopbar.textHoverColor};
          `}
      }
    }
  }

  .search {
    font-size: 0;
    padding: 10px;
    display: block;

    svg {
      width: 18px;
      height: 18px;

      path {
        stroke: ${({ theme }) => theme.colors.globalTopbar.iconsColor};
      }
    }
  }
`;
