import React from "react";

import Link from "next/link";

import { IGroup } from "pages/[tenant]/g/@types";
import { GroupImage } from "pages/[tenant]/g/[group_slug]/moderation/components/Nexus/components/atoms/GroupImage";

import { OptionArrowIcon } from "shared/providers/Layout/components/NewTopBar/assets/OptionArrowIcon";

import { CardContainer } from "./styles";

interface ICardProps {
  onClick?: () => void;
  group: IGroup;
  isInSearchPage?: boolean;
}

export const Card = ({
  onClick,
  group,
  isInSearchPage = false,
}: ICardProps) => {
  return (
    <CardContainer isInSearchPage={isInSearchPage}>
      <Link
        href={`/g/${group.slug}`}
        onClick={onClick}
        title={group.title}
        prefetch={false}
      >
        <GroupImage
          width={isInSearchPage ? 335 : 120}
          height={isInSearchPage ? 186 : 66}
          logoHorizontal={group?.logo_horizontal}
          primaryColor={group.primary_color}
        />

        {!isInSearchPage && (
          <div className="group-info">
            {group.title}
            <OptionArrowIcon />
          </div>
        )}
      </Link>
    </CardContainer>
  );
};
