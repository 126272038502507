import axios from "axios";
import api from "shared/infra/services/api";

export const useAddress = () => {
  const getAddressBrazilAPIRequest = async (zipcode: string) => {
    try {
      const response = await axios.get(
        `https://brasilapi.com.br/api/cep/v1/${zipcode}`,
      );

      const { cep, street, state, neighborhood, city } = response.data;

      if (!cep || !street || !state || !neighborhood || !city)
        return {
          error: "Dados do endereço incompletos",
          ...response.data,
        };

      return response.data;
    } catch (error) {
      console.log("error -->", error);
    }
    return { error: "CEP não encontrado na base do ViaCEP" };
  };

  const updateAddressSessionRequest = async (payload: any) => {
    const { addressId, updateAddressObject } = payload;
    const { zipcode, state, city, neighborhood, street, street_number } =
      updateAddressObject;

    try {
      const response = await api.put(`address/${addressId}`, {
        street,
        city,
        state,
        neighborhood,
        zipcode: zipcode?.replace("-", ""),
        country_id: 31,
        street_number,
        complementary: "",
      });

      return response.data.data;
    } catch (error) {
      console.log("error -->", error);
    }
    return null;
  };

  const setAddressRequest = async (payload: any) => {
    const { city, state, street, street_number, zipcode, neighborhood } =
      payload;

    try {
      const { data } = await api.post("address", {
        zipcode,
        country_id: 31,
        street_number,
        complementary: "",
        state,
        city,
        neighborhood,
        street,
      });
      return data.data;
    } catch (error: any) {
      const { data } = error.response;

      if (data) {
        sessionStorage.setItem(
          "register_address_error",
          JSON.stringify(Object.values(data)),
        );
      }

      console.log("error -->", data);
    }
    return null;
  };

  return {
    updateAddressSessionRequest,
    getAddressBrazilAPIRequest,
    setAddressRequest,
  };
};
