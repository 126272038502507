import styled, { css } from "styled-components";

interface ICardContainerProps {
  isInSearchPage: boolean;
}

export const CardContainer = styled.li<ICardContainerProps>`
  aspect-ratio: 16/9;

  ${({ isInSearchPage }) =>
    isInSearchPage &&
    css`
      a {
        :hover,
        :active,
        :focus {
          transform: scale(1.02);
          box-shadow: 0px 0px 0px 3px
            ${({ theme }) => theme.colors.searchPage.cardHoverBorderColor};
        }
      }

      a {
        div[data-testid="fallback-background"] {
          min-width: 100%;
          min-height: 100%;
        }

        img {
          width: 100%;
        }
      }
    `}

  a {
    position: relative;
    display: block;
    transition: 0.3s all;

    height: 100%;

    img {
      object-fit: cover;
      height: auto;
    }
  }

  a,
  img,
  [data-testid="fallback-background"] {
    border-radius: 4px;
    aspect-ratio: 16/9;
  }
`;
