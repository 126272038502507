import styled from "styled-components";

import { mobileFeatureNotInPlanTemplateBreakpoint } from "pages/[tenant]/console/utils/layoutUtils";

export const CheckListContainer = styled.ul`
  list-style: none;
  margin-bottom: 7.12rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.75rem 1.25rem;

  @media (max-width: ${mobileFeatureNotInPlanTemplateBreakpoint}px) {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: 1fr;
    margin-bottom: 2rem;
  }

  li {
    color: ${({ theme }) => theme.console.pageSubtitleColor} !important;
    font: 400 1rem/1.25rem "Inter";

    display: flex;
    align-items: center;
    gap: 0.75rem;

    @media (max-width: ${mobileFeatureNotInPlanTemplateBreakpoint}px) {
      font: 600 0.875rem/1.09375rem "Inter";
    }
  }
`;
