import React from "react";

export const AppStoreIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.42522 2.28125H2.63951C2.44308 2.28125 2.28237 2.44196 2.28237 2.63839V9.42411C2.28237 9.62054 2.44308 9.78125 2.63951 9.78125H9.42522C9.62165 9.78125 9.78237 9.62054 9.78237 9.42411V2.63839C9.78237 2.44196 9.62165 2.28125 9.42522 2.28125ZM8.26451 8.26339H3.80022V3.79911H8.26451V8.26339ZM18.3538 2.28125H11.5681C11.3717 2.28125 11.2109 2.44196 11.2109 2.63839V9.42411C11.2109 9.62054 11.3717 9.78125 11.5681 9.78125H18.3538C18.5502 9.78125 18.7109 9.62054 18.7109 9.42411V2.63839C18.7109 2.44196 18.5502 2.28125 18.3538 2.28125ZM17.1931 8.26339H12.7288V3.79911H17.1931V8.26339ZM18.3538 11.2098H11.5681C11.3717 11.2098 11.2109 11.3705 11.2109 11.567V18.3527C11.2109 18.5491 11.3717 18.7098 11.5681 18.7098H18.3538C18.5502 18.7098 18.7109 18.5491 18.7109 18.3527V11.567C18.7109 11.3705 18.5502 11.2098 18.3538 11.2098ZM17.1931 17.192H12.7288V12.7277H17.1931V17.192ZM8.53237 14.9598H5.67522V12.1027C5.67522 12.0045 5.59487 11.9241 5.49665 11.9241H4.42522C4.32701 11.9241 4.24665 12.0045 4.24665 12.1027V14.9598H1.38951C1.29129 14.9598 1.21094 15.0402 1.21094 15.1384V16.2098C1.21094 16.308 1.29129 16.3884 1.38951 16.3884H4.24665V19.2455C4.24665 19.3438 4.32701 19.4241 4.42522 19.4241H5.49665C5.59487 19.4241 5.67522 19.3438 5.67522 19.2455V16.3884H8.53237C8.63058 16.3884 8.71094 16.308 8.71094 16.2098V15.1384C8.71094 15.0402 8.63058 14.9598 8.53237 14.9598Z"
        fill="currentColor"
      />
    </svg>
  );
};
