/* eslint-disable @next/next/no-img-element */
import React, { useState } from "react";

import { IMedia } from "pages/[tenant]/console/components/templates/ConsoleLayout/types";

import { MediaContainer } from "./styles";

interface IMediaProps {
  media: IMedia;
}

export const Media = ({ media }: IMediaProps) => {
  const [loadFailed, setLoadFailed] = useState(false);

  const haveSomeMediaToShow = !!(media?.imageUrl || media?.videoUrl);
  const isImage = media?.imageUrl;

  const handleLoadError = () => setLoadFailed(true);

  if (!haveSomeMediaToShow || loadFailed) return null;
  return (
    <MediaContainer>
      {isImage ? (
        <img src={media.imageUrl} onError={handleLoadError} />
      ) : (
        <iframe src={media.videoUrl} onError={handleLoadError} />
      )}
    </MediaContainer>
  );
};
