import React from "react";

export const UsersIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1219_4479)">
        <path
          d="M11.112 13.8647V12.5314C11.112 11.8242 10.831 11.1459 10.3309 10.6458C9.83083 10.1457 9.15256 9.86475 8.44531 9.86475H3.11198C2.40474 9.86475 1.72646 10.1457 1.22636 10.6458C0.726264 11.1459 0.445313 11.8242 0.445312 12.5314V13.8647"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.77995 7.19808C7.25271 7.19808 8.44661 6.00417 8.44661 4.53141C8.44661 3.05865 7.25271 1.86475 5.77995 1.86475C4.30719 1.86475 3.11328 3.05865 3.11328 4.53141C3.11328 6.00417 4.30719 7.19808 5.77995 7.19808Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.1133 13.8665V12.5331C15.1128 11.9423 14.9162 11.3683 14.5542 10.9013C14.1922 10.4344 13.6854 10.1008 13.1133 9.95312"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.4453 1.95312C11.0189 2.09999 11.5273 2.43359 11.8904 2.90133C12.2535 3.36907 12.4505 3.94434 12.4505 4.53646C12.4505 5.12857 12.2535 5.70384 11.8904 6.17158C11.5273 6.63932 11.0189 6.97292 10.4453 7.11979"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1219_4479">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
