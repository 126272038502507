import React from "react";

export const ProgressIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2502 4C14.0467 4.00005 13.8463 4.0498 13.6664 4.14492C13.4864 4.24003 13.3325 4.37764 13.2178 4.54579C13.1031 4.71394 13.0313 4.90755 13.0084 5.10978C12.9856 5.31202 13.0125 5.51677 13.0868 5.70625L10.4565 8.33656C10.1633 8.22114 9.8372 8.22114 9.54398 8.33656L7.66367 6.45625C7.73813 6.26673 7.76516 6.06189 7.74241 5.85954C7.71966 5.65719 7.64783 5.46346 7.53315 5.2952C7.41847 5.12694 7.26443 4.98925 7.08441 4.89409C6.90439 4.79892 6.70386 4.74918 6.50023 4.74918C6.29661 4.74918 6.09607 4.79892 5.91605 4.89409C5.73604 4.98925 5.58199 5.12694 5.46732 5.2952C5.35264 5.46346 5.2808 5.65719 5.25806 5.85954C5.23531 6.06189 5.26234 6.26673 5.3368 6.45625L2.20648 9.58656C1.93915 9.48169 1.64385 9.47207 1.37026 9.55931C1.09666 9.64655 0.861443 9.82534 0.70416 10.0656C0.546876 10.3059 0.477118 10.593 0.506613 10.8786C0.536108 11.1643 0.663059 11.4311 0.866118 11.6341C1.06918 11.8372 1.33596 11.9641 1.62161 11.9936C1.90727 12.0231 2.19436 11.9534 2.43463 11.7961C2.6749 11.6388 2.85368 11.4036 2.94092 11.13C3.02816 10.8564 3.01854 10.5611 2.91367 10.2937L6.04398 7.16344C6.3372 7.27886 6.66327 7.27886 6.95648 7.16344L8.83679 9.04375C8.76234 9.23327 8.73531 9.43811 8.75806 9.64046C8.7808 9.84281 8.85264 10.0365 8.96731 10.2048C9.08199 10.3731 9.23604 10.5108 9.41605 10.6059C9.59607 10.7011 9.79661 10.7508 10.0002 10.7508C10.2039 10.7508 10.4044 10.7011 10.5844 10.6059C10.7644 10.5108 10.9185 10.3731 11.0332 10.2048C11.1478 10.0365 11.2197 9.84281 11.2424 9.64046C11.2652 9.43811 11.2381 9.23327 11.1637 9.04375L13.794 6.41344C13.9651 6.48068 14.149 6.50933 14.3325 6.49734C14.516 6.48535 14.6946 6.43302 14.8556 6.34408C15.0166 6.25514 15.1559 6.13178 15.2637 5.9828C15.3715 5.83382 15.4451 5.66289 15.4793 5.48219C15.5135 5.3015 15.5073 5.11549 15.4613 4.93744C15.4154 4.75938 15.3307 4.59367 15.2133 4.45211C15.0959 4.31055 14.9487 4.19663 14.7823 4.11848C14.6158 4.04034 14.4341 3.99988 14.2502 4Z"
        fill="currentColor"
      />
    </svg>
  );
};
