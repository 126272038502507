import { useEffect, useState } from "react";

import ArrowDown from "pages/[tenant]/g/assets/icons/ArrowDown";
import useGroupContext from "pages/[tenant]/g/hooks/useGroupContext";

import ChevronDown from "../../assets/ChevronDown";
import MenuGroupList from "../MenuGroupList";
import { DropDownGroupsContainer } from "./styles";

const DropDownGroups = () => {
  const [showMenuGroupList, setShowMenuGroupList] = useState(false);
  const {
    group,
    groupTitleInRealTime,
    hasErrorInMyGroupsRequest,
    fetchMyGroups,
  } = useGroupContext();

  async function handleToggleMenuGroupList() {
    setShowMenuGroupList(!showMenuGroupList);
    await fetchMyGroups();
  }

  useEffect(() => {
    function handleClick(e) {
      document.removeEventListener("click", handleClick);
      setShowMenuGroupList(!showMenuGroupList);
    }
    if (showMenuGroupList) {
      setTimeout(() => {
        document.addEventListener("click", handleClick);
      }, 250);
    }
    return () => document.removeEventListener("click", handleClick);
  }, [showMenuGroupList]);

  if (hasErrorInMyGroupsRequest) return null;
  return (
    <>
      <DropDownGroupsContainer
        showMenuGroupList={showMenuGroupList}
        onClick={handleToggleMenuGroupList}
      >
        <div id="group-info">
          <div className="title">{groupTitleInRealTime || group?.title}</div>
        </div>

        <ChevronDown className="chevron arrow-down-desktop" />
        <ArrowDown className="arrow-down-mobile" />
      </DropDownGroupsContainer>
      <MenuGroupList show={showMenuGroupList} />
    </>
  );
};

export default DropDownGroups;
