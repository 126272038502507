import useGroupContext from "pages/[tenant]/g/hooks/useGroupContext";

import { ActiveItemIndicatorContainer } from "./styles";

interface IActiveItemIndicatorProps {
  activeItemPosition: number;
  activeItemWidth: number;
}

export const ActiveItemIndicator = ({
  activeItemPosition,
  activeItemWidth,
}: IActiveItemIndicatorProps) => {
  const { group } = useGroupContext();

  return (
    <ActiveItemIndicatorContainer
      position={activeItemPosition}
      width={activeItemWidth}
      indicatorColor={group?.primary_color}
    />
  );
};
