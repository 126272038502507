import React, { useState } from "react";

import Image from "next/image";

import { Spin, Tooltip } from "antd";
import useTranslation from "next-translate/useTranslation";

import Alert from "shared/components/molecules/Alert";
import { useAuthContext } from "shared/utils/hooks/useAuthContext";
import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";
import { validateEmail } from "shared/utils/regex";

import Login from "./components/LoginComponent";
import { LoginModalContainer } from "./styles";

function LoginModal({ navigate }) {
  const { t } = useTranslation();
  const { state } = useGlobalContext();
  const { signIn } = useAuthContext();

  const [message, setMessage] = useState(null);
  const [loadingSpin, setLoadingSpin] = useState(false);

  const { settings } = state.tenancy;
  const isDarkMode = state.theme.value === "dark";

  const { users, site } = settings;
  const theme = settings["general-theme"];

  const loginImageStatus = users?.userLoginImage;
  const loginImage = users?.userLoginRequiredImage;
  const defaultImage = theme?.themeLogoHorizontal;
  const fallbackImage = theme?.themeLogoTopBar;

  const title = users?.userLoginTitle;
  const description = users?.userLoginDescriptionTop;

  const platformHasNoImage = !defaultImage && !fallbackImage;

  const handleSubmit = async ({ email, password }) => {
    if (validateEmail(email)) {
      if (email && password) {
        setLoadingSpin(true);
        const response = await signIn({ email, password });
        if (response) {
          setMessage(response);
          setLoadingSpin(false);
        }
      }
    } else {
      setMessage(t("common:notAValidEmail"));
    }
  };
  console.log(theme?.themeDarkModeLogoHorizontal || defaultImage);

  return (
    <LoginModalContainer hasImage={loginImageStatus !== "none"}>
      <div className="col">
        <div
          style={{
            position: "relative",
            width: "224px",
            paddingBottom: "18%",
          }}
        >
          {loginImageStatus === "none" || platformHasNoImage ? (
            <h1 className="siteName">{site?.siteName}</h1>
          ) : isDarkMode ? (
            <Image
              src={theme?.themeDarkModeLogoHorizontal || defaultImage}
              fill
              alt="logo"
              className="logo"
            />
          ) : loginImageStatus === "default" ? (
            <Image src={defaultImage} fill alt="logo" className="logo" />
          ) : (
            <Image
              src={defaultImage || fallbackImage || loginImage}
              fill
              alt="logo"
              className="logo"
            />
          )}
        </div>

        <div className="titulos">
          <h1>{title}</h1>
          <h2>{description}</h2>
        </div>

        {loadingSpin && <Spin />}

        {message && message !== "password_reset_id" ? (
          <Tooltip title={t("common:createAnAccount")} placement="bottomRight">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                navigate("register");
              }}
            >
              <Alert
                message={message}
                error={message == "success" ? false : true}
                onClose={() => setMessage(null)}
                margin="0"
              />
            </a>
          </Tooltip>
        ) : null}

        <Login handleSubmit={handleSubmit} />

        <div className="links">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              navigate("forgot");
            }}
            style={{
              color: "#000",
            }}
            className="link"
          >
            {t("common:forgotYourPassword")}
          </a>

          {/* eslint-disable-next-line */}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              navigate("register");
            }}
            className="link"
          >
            {t("common:dontHaveAnAccount")}
            <b style={{ marginLeft: "4px" }}>{t("common:createAnAccount")}</b>
          </a>
        </div>
      </div>
    </LoginModalContainer>
  );
}

export default LoginModal;
