import styled, { css } from "styled-components";

interface IJoinButtonContainerProps {
  isMobile?: boolean;
}

interface ButtonProps {
  isRequested: boolean;
  isMobile?: boolean;
}

export const JoinButtonContainer = styled.div<IJoinButtonContainerProps>`
  ${(props) =>
    !props.isMobile &&
    css`
      max-width: 90.5rem;
      margin: 0 auto;
      position: relative;
    `}

  ${(props) =>
    props.isMobile &&
    css`
      @media (max-width: 650px) {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        max-width: 24rem;
      }

      @media (max-width: 450px) {
        max-width: 15rem;
      }
    `}
`;

export const Button = styled.button<ButtonProps>`
  cursor: pointer;
  align-self: flex-end;
  padding: 0.5rem 1rem;
  background-color: ${({ isRequested }) =>
    isRequested ? "#616161" : "#0073e5"};

  position: ${(props) => (props.isMobile ? "stick" : "absolute")};
  right: 1rem;
  bottom: 1rem;
  z-index: 1;

  border: none;
  border-radius: 4px;
  box-shadow: ${({ isRequested }) =>
    !isRequested && "0px 0px 16px rgba(0, 115, 229, 0.48)"};

  color: #f2f2f2;
  font-size: 0.875rem;
  font-weight: 600;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all 0.5s ease-out;

  :hover {
    filter: brightness(0.8);
  }

  pointer-events: ${({ isRequested }) => (isRequested ? "none" : null)};

  ${(props) =>
    props.isMobile &&
    css`
      width: 100%;
    `}
`;
