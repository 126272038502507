import styled from "styled-components";

export const ContainerImage = styled.div`
  width: 100%;

  img {
    width: 100%;
    border-radius: 5px;
  }

  .gx-product-image {
    width: 25% !important;
  }

  .gx-grid-thumb-equal {
    padding-bottom: 0 !important;
  }

  @media (max-width: 500px) {
    .gx-product-image {
      width: 100% !important;
    }

    margin-bottom: 5px;
  }
`;

export const ContainerModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  cursor: pointer;
`;

export const Header = styled.div`
  text-align: center;
  margin-top: 2rem;

  h1 {
    margin-bottom: 32px;
    font-size: 25px !important;
  }

  p {
    margin-bottom: 32px;
    font-size: 1rem;
  }
`;

export const OnlyEnrolledUsersPermissionContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 100%;

  .locked-container {
    display: grid;
    place-items: center;
    width: 100%;
  }

  h1,
  p {
    color: ${({ theme }) => theme.colors.textColor};
  }

  .gx-product-item.gx-product-horizontal {
    background-color: transparent;

    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const RequiredItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 320px;
  overflow: scroll;
`;
