import styled from "styled-components";

import { mobileFeatureNotInPlanTemplateBreakpoint } from "pages/[tenant]/console/utils/layoutUtils";

export const IllustrationContainer = styled.div`
  > * {
    width: 100%;
  }

  > svg {
    min-width: 210px;

    @media (max-width: ${mobileFeatureNotInPlanTemplateBreakpoint}px) {
      height: fit-content;
      min-width: initial;

      width: 160px;
    }
  }
`;
