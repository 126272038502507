import { HTMLAttributes } from "react";

import { handleSplitName } from "shared/utils/handleSplitName";

import { SimpleUserInitialsNameContainer } from "./styles";

interface iSimpleUserInitialsName {
  smallFormat?: boolean;
  squareFormat?: boolean;
  feed?: boolean;
  fullName: string;
  onClick?: () => void;
}

const SimpleUserInitialsName = ({
  fullName,
  squareFormat,
  smallFormat,
  onClick,
}: iSimpleUserInitialsName) => {
  if (!fullName)
    return (
      <SimpleUserInitialsNameContainer className="SimpleUserInitialsName">
        <h1>{handleSplitName("not a user")}</h1>
      </SimpleUserInitialsNameContainer>
    );

  return (
    <SimpleUserInitialsNameContainer
      className="SimpleUserInitialsName"
      onClick={onClick}
      squareFormat={squareFormat}
      smallFormat={smallFormat}
    >
      <h1>{handleSplitName(fullName)}</h1>
    </SimpleUserInitialsNameContainer>
  );
};

export default SimpleUserInitialsName;
