import api from "shared/infra/services/api";

const fetchMenus = async () => {
  let menus;

  const sessionStorageMenus = sessionStorage.getItem("menus");

  if (sessionStorageMenus) return JSON.parse(sessionStorageMenus);

  try {
    const sidebar = await api.get("menu/menu");
    const topbar = await api.get("menu/topbar");
    const profileMenu = await api.get("menu/profile");

    menus = {
      sidebar: sidebar.data.data,
      topbar: topbar.data.data,
      profileMenu: profileMenu.data.data,
    };

    sessionStorage.setItem("menus", JSON.stringify({ menus }));

    return { menus };
  } catch (error) {
    return error;
  }
};

export default fetchMenus;
