import React from "react";

export const EngagementIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33203 13.3359H14.6654V14.6693H1.33203V13.3359ZM2.66536 2.0026V11.3359C2.66536 11.5127 2.7356 11.6823 2.86063 11.8073C2.98565 11.9324 3.15522 12.0026 3.33203 12.0026H6.66536C6.84218 12.0026 7.01174 11.9324 7.13677 11.8073C7.26179 11.6823 7.33203 11.5127 7.33203 11.3359V2.0026C7.33203 1.82579 7.26179 1.65622 7.13677 1.5312C7.01174 1.40618 6.84218 1.33594 6.66536 1.33594H3.33203C3.15522 1.33594 2.98565 1.40618 2.86063 1.5312C2.7356 1.65622 2.66536 1.82579 2.66536 2.0026ZM3.9987 2.66927H5.9987V10.6693H3.9987V2.66927ZM8.66536 11.3359C8.66536 11.5127 8.7356 11.6823 8.86063 11.8073C8.98565 11.9324 9.15522 12.0026 9.33203 12.0026H12.6654C12.8422 12.0026 13.0117 11.9324 13.1368 11.8073C13.2618 11.6823 13.332 11.5127 13.332 11.3359V4.66927C13.332 4.49246 13.2618 4.32289 13.1368 4.19787C13.0117 4.07284 12.8422 4.0026 12.6654 4.0026H9.33203C9.15522 4.0026 8.98565 4.07284 8.86063 4.19787C8.7356 4.32289 8.66536 4.49246 8.66536 4.66927V11.3359ZM9.9987 5.33594H11.9987V10.6693H9.9987V5.33594Z"
        fill="currentColor"
      />
    </svg>
  );
};
