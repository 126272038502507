import styled from "styled-components";

export const PageLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  img {
    width: 80px;
    height: 80px;
  }

  .ant-spin-dot-item {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;
