import React from "react";

export const SEOIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3558 13.6464L11.2265 10.5176C12.1335 9.42871 12.5858 8.03201 12.4892 6.6181C12.3927 5.20419 11.7548 3.88193 10.7082 2.92637C9.66158 1.97082 8.28687 1.45555 6.87004 1.48775C5.4532 1.51995 4.10332 2.09714 3.10121 3.09926C2.0991 4.10137 1.5219 5.45125 1.4897 6.86808C1.4575 8.28492 1.97278 9.65963 2.92833 10.7062C3.88388 11.7528 5.20614 12.3908 6.62005 12.4873C8.03396 12.5838 9.43066 12.1315 10.5196 11.2245L13.6483 14.3539C13.6948 14.4003 13.75 14.4372 13.8106 14.4623C13.8713 14.4875 13.9364 14.5004 14.0021 14.5004C14.0678 14.5004 14.1328 14.4875 14.1935 14.4623C14.2542 14.4372 14.3094 14.4003 14.3558 14.3539C14.4023 14.3074 14.4392 14.2523 14.4643 14.1916C14.4894 14.1309 14.5024 14.0658 14.5024 14.0001C14.5024 13.9344 14.4894 13.8694 14.4643 13.8087C14.4392 13.748 14.4023 13.6928 14.3558 13.6464ZM2.5021 7.00014C2.5021 6.11013 2.76602 5.2401 3.26048 4.50008C3.75495 3.76006 4.45775 3.18328 5.28002 2.84268C6.10229 2.50209 7.00709 2.41298 7.88 2.58661C8.75292 2.76024 9.55474 3.18883 10.1841 3.81816C10.8134 4.4475 11.242 5.24932 11.4156 6.12224C11.5893 6.99515 11.5001 7.89995 11.1596 8.72222C10.819 9.54449 10.2422 10.2473 9.50216 10.7418C8.76214 11.2362 7.89211 11.5001 7.0021 11.5001C5.80903 11.4988 4.6652 11.0243 3.82158 10.1807C2.97795 9.33703 2.50342 8.19321 2.5021 7.00014Z"
        fill="currentColor"
      />
    </svg>
  );
};
