import { DropdownArrow } from "pages/[tenant]/console/assets/DropdownArrow";
import { ActiveOptionIndicator } from "pages/[tenant]/console/components/atoms/ActiveOptionIndicator";
import { SubSection } from "pages/[tenant]/console/components/atoms/SubSection";
import { useConsolePageContext } from "pages/[tenant]/console/hooks/useConsolePageContext";
import { useLayoutOptions } from "pages/[tenant]/console/hooks/useLayoutOptions";
import { ISection } from "pages/[tenant]/console/types";

import { Option } from "./Option";
import {
  OptionsContainer,
  OptionsContent,
  SectionOptionsDropdownButton,
} from "./styles";

interface IOptionsProps {
  currentSectionOptions: ISection;
}

export const Options = ({ currentSectionOptions }: IOptionsProps) => {
  const { activeOption } = useLayoutOptions();
  const {
    activeSectionLabel,
    activeOptionPosition,
    isMobileSectionsDropdownOpen,
    openMobileSectionsDropdown,
    closeMobileSectionsDropdown,
  } = useConsolePageContext();

  const handleClick = () =>
    isMobileSectionsDropdownOpen
      ? closeMobileSectionsDropdown()
      : openMobileSectionsDropdown();

  const hasSubsections = !!currentSectionOptions?.subSections;
  const hasNotifications = !!activeOption?.notificationNumber;

  return (
    <OptionsContainer showMobileDropdown={isMobileSectionsDropdownOpen}>
      <SectionOptionsDropdownButton
        onClick={handleClick}
        shouldRotateIcon={isMobileSectionsDropdownOpen}
      >
        <DropdownArrow />
        {activeOption?.label}
        {hasNotifications && (
          <span className="notification-number">
            {activeOption.notificationNumber}
          </span>
        )}
      </SectionOptionsDropdownButton>

      <OptionsContent>
        <div className="title">{activeSectionLabel}</div>

        <ul>
          {currentSectionOptions?.options?.map((option) => (
            <Option option={option} key={option.id} />
          ))}
        </ul>

        {hasSubsections &&
          currentSectionOptions.subSections.map((section) => (
            <SubSection key={section.label} section={section} />
          ))}

        <ActiveOptionIndicator activeOptionPosition={activeOptionPosition} />
      </OptionsContent>
    </OptionsContainer>
  );
};
