/* eslint-disable @typescript-eslint/no-empty-function */
import initialStates from "./initialStates";

const GlobalContextInitialState = {
  state: {
    ...initialStates,
  },
  setState: () => {},
};

export default GlobalContextInitialState;
