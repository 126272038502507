import styled, { css } from "styled-components";

interface ICardsListContainerProps {
  isInSearchPage: boolean;
}

export const CardsListContainer = styled.ul<ICardsListContainerProps>`
  margin: 0;
  margin-top: 2rem;
  list-style: none;

  ${({ isInSearchPage }) =>
    isInSearchPage &&
    css`
      gap: 1rem;

      display: grid;
      grid-template-columns: repeat(5, minmax(240px, 335px));

      @media (max-width: 1366px) {
        grid-template-columns: repeat(4, minmax(250px, 335px));
      }

      @media (max-width: 1200px) {
        grid-template-columns: repeat(3, minmax(250px, 365px));
      }

      @media (max-width: 850px) {
        grid-template-columns: repeat(2, minmax(250px, 385px));
      }

      @media (max-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 0.5rem;
      }
    `}
`;
