import { css } from "styled-components";

import { consolePageMobileBreakpoint } from "pages/[tenant]/console/hooks/useConsolePageConfig";

interface IBaseSideBarSectionButtonProps {
  tooltipLabel: string;
}

export const BaseSideBarSectionButton = css<IBaseSideBarSectionButtonProps>`
  border: none;
  background-color: transparent;

  transition: 0.3s all;

  text-align: start;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  width: 40px;
  height: 40px;

  @media (max-width: ${consolePageMobileBreakpoint}px) {
    height: initial;
    width: 100%;
  }

  @media (max-width: ${consolePageMobileBreakpoint}px) {
    width: 100%;
    max-height: initial;
    flex-direction: row-reverse;
    gap: 0.75rem;
    justify-content: start;

    padding: 1rem 0.75rem;

    &.active {
      background-color: ${({ theme }) =>
        theme.console.sidebar.iconButtonActiveBackgroundColor} !important;
    }
  }

  ::before {
    content: "${({ tooltipLabel }) => tooltipLabel}";
    left: 110%;
    top: 50%;
    transform: translateY(-50%);

    white-space: nowrap;

    pointer-events: none;

    transition: 0.3s all;
    opacity: 0;

    padding: 0.25rem 0.5rem;
    border-radius: 4px;

    color: ${({ theme }) => theme.console.sidebar.tooltipTextColor};
    font: 600 0.875rem/1.3125rem "Inter";

    box-shadow: 0px 0px 48px 0px rgba(0, 0, 0, 0.08);
  }

  ::after {
    content: "";
    width: 10px;
    height: 10px;
    left: 105%;
    transform: rotate(140deg);

    @media (max-width: ${consolePageMobileBreakpoint}px) {
      display: none;
    }
  }

  ::before,
  ::after {
    position: absolute;

    opacity: 0;
    transition: 0.3s all;
    pointer-events: none;

    background-color: ${({ theme }) =>
      theme.console.sidebar.tooltipBackgroundColor};
  }

  @media (max-width: ${consolePageMobileBreakpoint}px) {
    ::before {
      position: initial;
      background-color: transparent;
      left: initial;
      top: initial;
      transform: initial;
      opacity: 1;
      display: block;
      padding: 0;
      color: ${({ theme }) => theme.console.sidebar.optionTextColor};
    }

    &:has(.switch-theme) {
      color: ${({ theme }) => theme.console.sidebar.optionTextColor};
      padding: 0;

      .switch-toggle-label {
        position: absolute;
        left: calc(30px + 0.75rem);
        pointer-events: none;
      }

      ::before {
        display: none;
      }

      .switch-theme {
        padding: 1rem 0.75rem;
      }
    }
  }

  @media (min-width: ${consolePageMobileBreakpoint}px) {
    :hover:not(:disabled) {
      cursor: pointer;

      ::before {
        left: 120%;
      }

      ::after {
        left: 112%;
      }

      ::before,
      ::after {
        opacity: 1;
      }
    }
  }

  :disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  border: none;

  &.active {
    background-color: ${({ theme }) =>
      theme.console.sidebar.iconButtonActiveBackgroundColor};
  }
`;
