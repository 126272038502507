const ArrowUp = () => (
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 12C5.18941 12 5.37106 11.921 5.50499 11.7804C5.63892 11.6397 5.71416 11.449 5.71416 11.2501V2.56155L8.77932 5.7817C8.84572 5.85142 8.92455 5.90673 9.0113 5.94446C9.09805 5.9822 9.19104 6.00162 9.28494 6.00162C9.37884 6.00162 9.47183 5.9822 9.55858 5.94446C9.64534 5.90673 9.72417 5.85142 9.79056 5.7817C9.85696 5.71197 9.90964 5.6292 9.94557 5.5381C9.98151 5.447 10 5.34936 10 5.25075C10 5.15215 9.98151 5.05451 9.94557 4.96341C9.90964 4.87231 9.85696 4.78954 9.79056 4.71981L5.50562 0.220316C5.43928 0.150479 5.36048 0.0950707 5.27371 0.0572652C5.18695 0.0194598 5.09394 0 5 0C4.90606 0 4.81305 0.0194598 4.72629 0.0572652C4.63952 0.0950707 4.56072 0.150479 4.49438 0.220316L0.209436 4.71981C0.0753365 4.86063 0 5.05161 0 5.25075C0 5.4499 0.0753365 5.64088 0.209436 5.7817C0.343536 5.92251 0.525414 6.00162 0.715059 6.00162C0.904705 6.00162 1.08658 5.92251 1.22068 5.7817L4.28584 2.56155V11.2501C4.28584 11.449 4.36108 11.6397 4.49502 11.7804C4.62895 11.921 4.81059 12 5 12Z"
      fill="white"
    />
  </svg>
);

export default ArrowUp;
