export const removeDuplicatedObjects = (arrayWithDuplicatedObjects: any[]) => {
  const seenIds = new Set();
  const uniqueArr = arrayWithDuplicatedObjects.filter((obj) => {
    if (!seenIds.has(obj.id)) {
      seenIds.add(obj.id);
      return true;
    }
    return false;
  });

  return uniqueArr;
};
