import React from "react";

export const ExportsIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6667 1.3335L13.9998 4.66683L14 13.9952C14 14.3661 13.7034 14.6668 13.3377 14.6668H2.66227C2.29651 14.6668 2 14.3633 2 14.0056V1.9947C2 1.62953 2.29833 1.3335 2.66567 1.3335H10.6667ZM10 2.66683H3.33333V13.3335H12.6667V5.3335H10V2.66683ZM8.66667 6.00016V8.66683H10.6667V10.0002H7.33333V6.00016H8.66667Z"
        fill="currentColor"
      />
    </svg>
  );
};
