import { createGlobalStyle, css } from "styled-components";

type GlobalStyleProps = {
  $generalTheme: any;
  $advancedTheme: any;
  themeMode: "dark" | "light";
};

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  :root {
    --link-color: ${({ themeMode, $generalTheme }) =>
      themeMode === "dark"
        ? $generalTheme?.themeDarkModeMasterColor
        : "#1155cc"};

    --master-color: ${({ $generalTheme, themeMode }) =>
      themeMode === "dark"
        ? $generalTheme.themeDarkModeMasterColor
        : $generalTheme.themeMasterColor} !important;

    --themeTopbar-iconsColor: ${({ themeMode, $generalTheme }) =>
      themeMode === "dark" ||
      $generalTheme.themeTopBar === "dark" ||
      $generalTheme.themeTopBar === "automatic"
        ? "#f7f7f7"
        : $generalTheme.themeTopBar === "light"
        ? "#545454"
        : $generalTheme.themeTopBar === "advanced"
        ? $generalTheme?.themeTopBarIconsColor
        : "#000000"};

    --themeTopbar-backgroundColor: ${({ themeMode, $generalTheme }) =>
      themeMode === "dark"
        ? "#f7f7f7"
        : $generalTheme?.themeTopBarBackgroundColor};
      --themeMode: ${({ themeMode }) => themeMode};
  }

  * {
    margin: 0;
    padding: 0;
    font-family: Inter, sans-serif;

    :focus {
      outline: 0;
    }
  }

  #canny-changelog-iframe {
    @media(min-width: 500px) {
      position: fixed !important;
      left: 64px !important;
      bottom: 1rem !important;
      top: initial !important;
    }

  }

  .Canny_Badge{
    background-color: #0073e5!important;
    border: none !important;

    @media(max-width: 850px) {
      left: 32px;
      right: initial !important;
      top: 23% !important;
      transform: translateY(-50%);
    }
  }

  html,
  body {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }

  
    div .ant-notification.ant-notification-topRight {
      z-index: 9999;
    }

    
  .topbar-usermenu-popover-container {
    position: fixed;
  }
  

  body, #__next, .app-layout, .app-layout > .ant-layout {
    background-color: ${({ themeMode }) =>
      themeMode === "dark" ? "#17181c" : "#f5f5f5"};
  }

  #__next {
    width: 100%;
    height: 100%;
  }

  a {
    text-decoration: none;
    background: transparent !important;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    color: var(--link-color);
  }

  h1 {
    font-size: 22px;
  }

  h2 {
    font-size: 20px;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
    line-height: 1.2;
  }

  @media(max-width: 850px) {
    #chatbase-bubble-button {
      bottom: 4rem !important;
    }
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-disabled {
    opacity: 0.3;
    background: #1a1a1a;
    
    :hover {
      cursor: not-allowed;
    }
  }

  .ant-tooltip-content {
    i {
      margin-right: 10px !important;
    }
  }

  .ant-collapse-header,
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: ${({ themeMode }) =>
      themeMode === "dark" ? "#242424" : "#f1f1f1"} !important;
  }

  .ant-menu-submenu-popup {
    ul {
      background: ${({ themeMode }) =>
        themeMode === "dark" ? "#242424" : "#f7f7f7"} !important;
    }

    i,
    span {
      color: ${({ themeMode }) =>
        themeMode === "dark" ? "#f7f7f7" : "#1a1a1a"} !important;
    }

    .ant-menu-submenu-title {
      padding: 0;
    }

    .ant-menu-title-content {
      width: 100%;
      padding: 0 20px;

      i {
        margin-right: 10px;
      }
    }
  }

  .ant-menu-light .ant-menu-submenu-title,
  .ant-menu-submenu-arrow {
    color: ${({ themeMode, $generalTheme }) =>
      themeMode === "dark"
        ? $generalTheme.themeMasterColor
        : $generalTheme.themeDarkModeMasterColor} !important;
  }

  .ant-menu-submenu-title {
    display: flex;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  }

  .ant-btn,
  .ant-btn:is(:hover, :focus, :active) {
    border-color: unset !important;

    &,span {
      color: ${({ themeMode }) =>
        themeMode !== "dark" ? "#f7f7f7" : "#242424"};
    }
  }

  .ant-btn:is(:hover, :focus, :active) {
    filter: opacity(0.8);
  }

  .ant-popover-inner {
    padding: 0;
    margin: 10px 0 0;
    border-radius: 8px;
    background: ${({ themeMode }) =>
      themeMode === "dark" ? "#242424" : "#f5f5f5"};

    .ant-popover-inner-content {
      padding: 0.5rem;

      input[type="file"],
      input[type="file"]::-webkit-file-upload-button {
        cursor: pointer;
      }
    }
  }

  .menu-profile-popover
    .ant-popover-content
    .ant-popover-inner
    .ant-popover-inner-content,
  .offline-menu-custom
    .ant-popover-content
    .ant-popover-inner
    .ant-popover-inner-content {
    padding: 0;
  }

  .menu-profile-popover.ant-popover-content.ant-popover-inner.ant-popover-inner-content.ant-menu-title-content div {
    height: 34px;
  }

  .ant-select-arrow,
  .ant-select-selection-item,
  .ant-popover-message-title {
    color: ${({ themeMode }) => (themeMode === "dark" ? "#f7f7f7" : "#242424")};
  }

  .ant-popover-buttons .ant-btn-default{
    color: #1a1a1a ;
    border: none;

  }

  .ant-popover .ant-men242u {
    &,
    .ant-menu-item,
    .ant-menu-item-selected {
      background: ${({ themeMode }) =>
        themeMode === "dark" ? "#242424" : "#ffffff"} !important;

      span,
      a {
        color: ${({ themeMode }) =>
          themeMode === "dark" ? "#fff" : "#242424"} !important;
      }
    }
  }

  .ant-popover-arrow {
    span::before {
      background: ${({ themeMode }) =>
        themeMode === "dark" ? "#242424" : "#f5f5f5"};
    }
  }

  .ant-collapse,
  .ant-menu-submenu,
  .ant-tooltip-inner {
    border-radius: 4px;
  }

  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    border-radius: 0 6px 6px 0;
  }

  .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .ant-btn,
  .ant-input,
  .ant-dropdown,
  .ant-select-selection {
    border-radius: 8px;
  }

  .ant-modal-header {
    border-radius: 12px 12px 0px 0px;
  }

  .ant-modal-content,
  .ant-notification-notice.ant-notification-notice-success.ant-notification-notice-closable {
    border-radius: 12px;
  }

  .ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
    background: ${({ themeMode }) => themeMode === "dark" && "#242424"};
  }

  .ant-dropdown-menu-title-content {
    color: ${({ themeMode }) => themeMode === "dark" && "#f2f2f2"};
  }

  .ant-dropdown-arrow {
    border-color: ${({ themeMode }) =>
      themeMode === "dark" && "#242424"} !important;
  }

  .ant-collapse,
  .ant-dropdown-arrow {
    border-color: ${({ themeMode }) =>
      themeMode === "dark" && "#242424"} !important;
  }

  .ant-input-group-addon {
    background-color: transparent !important;
  }

  .ant-select-dropdown {
    background: ${({ themeMode }) => themeMode === "dark" && "#1A1A1A"};
  }

  .ant-select-item-option-content,
  .ant-select-item.ant-select-item-group {
    color: ${({ themeMode }) => themeMode === "dark" && "#F2F2F2"};
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: ${({ themeMode }) => themeMode === "dark" && "#242424"};
  }

  .ant-layout-header {
    line-height: 1.2;
  }

  .user-menu-notifications .ant-popover-arrow-content {
    background: ${({ themeMode }) =>
      themeMode === "dark" ? "#242424" : "#f5f5f5"};
  }

  .ant-dropdown-menu,
  .ant-select-dropdown,
  .ant-select-selector {
    border-radius: 6px !important;
  }

  .sidebar-button {
    top: 50px !important;
    left: 10px !important;
  }

  .ant-dropdown.ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight.dropdown-user-lesson {
    min-width: 230px !important;
    top: 35px !important;

    .ant-dropdown-arrow {
      right: 5px;
    }

    li {
      padding: 0.7rem 0.75rem;
    }

    svg:not(.icon-moon) {
      color: ${({ themeMode }) => themeMode === "dark" && "#fff"} !important;
      font-size: 20px;
    }

    li > span {
      margin-right: 0.5rem !important;
    }
  }

  .ant-dropdown-menu-item.ant-dropdown-menu-item-active {
    &:hover {
      background: ${({ themeMode }) =>
        themeMode === "dark" && "#121212"} !important;
    }
  }

  @media screen and (max-width: 575px) {
    .ql-action {
      display: none;
    }

    .gx-main-content-wrapper {
      padding-bottom: 100px;
    }
  }

  .container-loading {
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-popover-placement-bottomRight .ant-popover-arrow {
    right: 8px;
  }

  .address-form {
    .ant-input-affix-wrapper,
    .ant-input-status-error {
      background: ${({ themeMode }) =>
        themeMode === "dark" ? "#242424" : "#f7f7f7"} !important;
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ themeMode }) =>
      themeMode === "dark" ? "#242424" : "#fcfcfc"};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: ${({ themeMode }) =>
      themeMode === "dark" ? "#000" : "#f5f5f5"};

  }
  
  @media (max-width: 990px) {  
    ::-webkit-scrollbar,
    ::-webkit-scrollbar-track,
    ::-webkit-scrollbar-thumb {
      display: none ;
    }
  }
  
  .live--chat--aside {
    height: calc(100vh - 64px) !important;
    top: unset !important;
    bottom: 0;
    z-index: 98 !important;
    box-shadow: none !important;

    @media (max-width: 900px) {
      width: 100% !important;

      max-height: 46%;
    }
  }

  ${({ themeMode }) =>
    themeMode === "dark" &&
    css`
    .live--chat--aside--button,
    .chat-room--chat--header,
    .arena-chat-widget--right-menu--menu-item,
    .arena-chat-widget--right-menu--menu-item::after,
    .arena-chat-widget--right-menu--menu-item::before {
      background: var(--master-color) !important;
    }

    .arena-icon-send {
      filter: invert(70%) sepia(100%) saturate(587%) hue-rotate(106deg) brightness(100%) contrast(89%) !important;
    }

    .chat-room--tabs--tab {
      color: var(--master-color) !important;
      border-color: var(--master-color) !important;
    }

    .chat-room--members--container div:not(:first-child) span,
    .style__ChatRoomScreenHeaderTitle-bnhnAV span {
      margin-right: 8px;
      color: var(--master-color) !important;
    }

    .live-chat-room,
    .live-chat-room--input,
    .chat-room--chat--wrapper,
    .live-chat-room-input--input--wrapper {
      background: #242424 !important;
    }

    .chat-room--members--search--input {
      background: #303030 !important;
    }

    .arena-chat-widget--message-body {
      &::before {
        border-color: transparent !important;
      }
    }

    .react-input-emoji--container {
      border-color: #181818 !important;
    }

    .chat-room--chat--tab--content + div svg path,
    .chat-room--chat--tab--content + div div,
    .chat-room--chat--tab--content + div {
      background: #303030 !important;

      div, svg path {
        color: #ccc !important;
        stroke: #ccc !important;
        fill: #ccc !important;
      }
    }

    .react-input-emoji--container,
    .chat-room--private--group-channels--menu--menu,
    .chat-room--private--group-channels--menu--menu--item,
    .arena-chat-widget--message-dropdown-menu,
    .arena-chat-widget--message-tools-container,
    .arena-chat-widget--message-content-reactions-container,
    .arena-chat-widget--message-content-container-wrapper {
      background: #303030 !important;
      color: #ccc !important;
    }

    .arena-chat-widget--message-dropdown-menu-item-content,
    .style__QnaListItemMessageText-hkmSMY {
      color: #fff !important;
    }

    .arena-dropdown-menu li div {
      color: #fff !important;
    }

    .react-input-emoji--button  {
      background: none !important;
    }

    .chat-room--chat--header svg path,
    .chat-room--chat--header--users--number--container svg path,
    .arena-chat-widget--right-menu--menu-item svg path {
      fill: #181818 !important;
    }

    .arena-dropdown-menu,
    .style__QnaListItemVoteMenu-inOKdR button {
      background: #303030 !important;
    }

    .chat-room--chat--header,
    .arena-chat-widget--right-menu--menu-item::before,
    .chat-room--chat--header--users--number--container,
    .chat-room--chat--header--users--number--container div {
      color: #181818 !important;
  `}

  .ant-popover-buttons .ant-btn-default span {
    color: #323232
  }


  `;

export default GlobalStyle;
