import React from "react";

import Image from "next/image";

import { handleSplitName } from "shared/utils/handleSplitName";
import { useAuthContext } from "shared/utils/hooks/useAuthContext";

import { UserAvatarContainer } from "./styles";

export const UserAvatar = () => {
  const { loggedUser: user } = useAuthContext();
  const userHavePhoto = !!user?.photo;

  return (
    <UserAvatarContainer>
      {userHavePhoto ? (
        <Image alt="" src={user?.photo} width={24} height={24} />
      ) : (
        <span>{handleSplitName(user?.full_name)}</span>
      )}
    </UserAvatarContainer>
  );
};
