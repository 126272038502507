import React, { MouseEvent } from "react";

import { FiChevronRight } from "react-icons/fi";

import { useAuthContext } from "shared/utils/hooks/useAuthContext";
import { useTopbarContext } from "shared/providers/Layout/hooks/useTopbarContext";

import { MenusList } from "shared/providers/Layout/components/NewTopBar/components/atoms/MenusList";
import { MyGroupsList } from "shared/providers/Layout/components/NewTopBar/components/atoms/MyGroupsList";

import {
  Divider,
  SecondaryDivider,
  Overlay,
  ContentItem,
  PrimarySideMenuContainer,
  SecondarySideMenuContainer,
} from "./styles";

export const SideMenu = () => {
  const {
    closeSideMenu,
    sideMenuIsOpen,
    isSecondaryMenuContainerOpen,
    openSecondaryMenuContainer,
    closeSecondaryMenuContainer,
  } = useTopbarContext();

  const { loggedUser } = useAuthContext();

  const handleOutsideClick = ({ target }: MouseEvent<HTMLDivElement>) => {
    const clickedElement = target as HTMLDivElement;
    const userClickedInOverlay = [...clickedElement.classList].includes(
      Overlay.styledComponentId,
    );

    if (userClickedInOverlay) {
      closeSideMenu();
      closeSecondaryMenuContainer();
    }
  };

  return (
    <Overlay show={sideMenuIsOpen} onClick={handleOutsideClick}>
      <PrimarySideMenuContainer show={sideMenuIsOpen}>
        <Divider />

        <MenusList triggerOnLinkClick={closeSideMenu} />

        {loggedUser &&
          <ContentItem
            onClick={isSecondaryMenuContainerOpen ? closeSecondaryMenuContainer : openSecondaryMenuContainer
          }>
            Meus conteúdos
            <FiChevronRight />
          </ContentItem>
        }

      </PrimarySideMenuContainer>

      <SecondarySideMenuContainer show={isSecondaryMenuContainerOpen}>
        <SecondaryDivider />

        <MyGroupsList triggerOnLinkClick={closeSecondaryMenuContainer} />
      </SecondarySideMenuContainer>
    </Overlay>
  );
};
