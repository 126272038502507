import denounceModal from "./denounceModal";
import enrolledUsersPermission from "./enrolledUsersPermission";
import enrollments from "./enrollments";
import groups from "./groups";
import menus from "./menus";
import modal from "./modal";
import tenancy from "./tenancy";
import theme from "./theme";
import user from "./user";
import userSettings from "./userSettings";

const initialStates = {
  denounceModal,
  enrolledUsersPermission,
  enrollments,
  affiliatesModal: false,
  isDevMode: false,
  isMobileAppClient: false,
  isIOS: false,
  showDisablePurchaseModal: false,
  showChromeCastWarnModal: false,
  menus,
  modal,
  tenancy,
  theme,
  totalComments: 0,
  user,
  userSettings,
  groups,
};

export default initialStates;
