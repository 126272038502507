import styled, { keyframes } from "styled-components";

interface IProps {
  $goBackIsEnabled?: boolean;
}

const appearFromTop = keyframes`
  from {
    opacity:0;
    transform: translateX(100px);
  }
  to {
    opacity:1;
    transform: translateX(0px);
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;

  width: 100%;
  height: 100vh;
  padding: 1.5rem 0;

  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);

  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;

  overflow-y: scroll;

  @media (max-width: 1024px) {
    padding: 0;
  }
`;

export const LayoutModal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  min-width: 460px;
  max-width: 520px;
  min-width: 460px;
  min-height: 572px;
  padding: 1.5rem 0;

  border-radius: 15px;
  animation: ${appearFromTop} 0.5s;
  color: ${({ theme }) => theme.colors.textColor};
  background: ${({ theme }) => theme.colors.modalBackgroundColor};

  z-index: 1000;
  position: relative;

  @media (max-width: 1024px) {
    justify-content: center;

    min-width: 100%;
    min-height: 100%;

    border-radius: 0;
  }

  @media (max-width: 340px) {
    zoom: 75%;
  }
`;

export const Header = styled.div<IProps>`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: ${(props) =>
    props?.$goBackIsEnabled ? "space-between" : "flex-end"};
  width: 90%;
  height: 32px;
  margin: 16px;

  svg {
    cursor: pointer;
    font-size: 25px;
  }

  svg.back_button#back,
  svg.close_button#close {
    color: ${({ theme }) => theme.colors.textColor};
    z-index: 99;
  }
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 380px;
  min-height: 528px;
`;
