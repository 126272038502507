import React from "react";

export const XIcon = ({
  onClick,
}: {
  onClick?: (params: any) => any;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      onClick={onClick}
      viewBox="0 0 24 24"
      width="20"
      height="20"
      strokeWidth={2}
      stroke="#8C8C8C"
      className="clearInput"
      aria-label="Limpar pesquisa"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  );
};
