import React from "react";

import Link from "next/link";

import { EnsinioLogoIcon } from "pages/[tenant]/console/assets/EnsinioLogoIcon";
import { useConsolePageContext } from "pages/[tenant]/console/hooks/useConsolePageContext";

import { EnsinioLogoContainer } from "./styles";

export const EnsinioLogo = () => {
  const { clearActiveSection } = useConsolePageContext();

  return (
    <EnsinioLogoContainer>
      <Link href="/console" onClick={clearActiveSection}>
        <EnsinioLogoIcon />
      </Link>
    </EnsinioLogoContainer>
  );
};
