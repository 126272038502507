import { createContext, useReducer } from "react";

import { IModulesPageContext } from "../types";
import { constructActions } from "../utils/actions";
import { reducer } from "../utils/reducer";

export const ModulesPageContext = createContext({} as IModulesPageContext);

const consoleModuleInitialState = {
  modules: [],
  module: null,
  materials: [],
  materialFiles: [],
  quizLocalQuestions: [],
  lesson: null,
  group: null,
  modulesInternalRoutes: "new-module",
};

export const ModulesPageContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, consoleModuleInitialState);
  const actions = constructActions({ dispatch });
  return (
    <ModulesPageContext.Provider
      value={{
        ...state,
        ...actions,
      }}
    >
      {children}
    </ModulesPageContext.Provider>
  );
};
