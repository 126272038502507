import { FiChevronLeft } from "react-icons/fi";

import { useTopbarContext } from "shared/providers/Layout/hooks/useTopbarContext";

import { MyGroupsList } from "shared/providers/Layout/components/NewTopBar/components/atoms/MyGroupsList";

import { Divider } from "../SideMenu/styles";
import { FooterMenuMyGroupListContainer } from "./styles";

const FooterMenuMyGroupList = () => {

  const {
    isContentMenuOpen,
    closeContentMenu,
  } = useTopbarContext();

  return (
    <FooterMenuMyGroupListContainer show={isContentMenuOpen}>
      <FiChevronLeft onClick={closeContentMenu} />

      <Divider />

      <MyGroupsList triggerOnLinkClick={closeContentMenu} />

    </FooterMenuMyGroupListContainer>
  );
};

export default FooterMenuMyGroupList;
