import { ReactNode } from "react";

import { ContentBox, ContentSectionContainerStyled } from "./styles";

interface IContentSectionContainerProps {
  title?: string;
  children: ReactNode;
}

export const ContentSectionContainer = ({
  title,
  children,
}: IContentSectionContainerProps) => {
  return (
    <ContentSectionContainerStyled>
      {title && <span className="content-section-title">{title}</span>}

      <ContentBox>{children}</ContentBox>
    </ContentSectionContainerStyled>
  );
};
