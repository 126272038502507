import styled, { css } from "styled-components";

import { GroupListContainer } from "pages/[tenant]/g/components/ContextTopbar/components/MenuGroupList/components/GroupList/styles";

export const MenuGroupListContainer = styled.div<{ show: boolean }>`
  padding: 0.5rem 0;

  position: absolute;
  right: 0;
  top: 60px;

  transition: 0.3s all;
  z-index: 1;

  max-height: 310px;
  overflow-y: scroll;

  opacity: 0;
  pointer-events: none;

  @media (min-width: 851px) {
    max-width: 310px;
    width: 100%;
  }

  @media (max-width: 850px) {
    max-height: calc(100vh - 120px);
    overflow: scroll;
    transform: translateY(-120%);

    opacity: 1;
    pointer-events: all;
  }

  ${({ show }) =>
    show &&
    css`
      @media (max-width: 850px) {
        transform: translateY(0);
      }

      opacity: 1;
      pointer-events: all;
    `}

  background: ${({ theme }) => theme.groups.backgroundComponentsMural};
  border: 1px solid ${({ theme }) => theme.groups.borderColorComponentsMural};

  align-self: flex-start;

  overflow-y: auto;
  overflow-x: hidden;

  border-radius: 4px;

  ${GroupListContainer} {
    flex-direction: column;
  }
`;
