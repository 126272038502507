import styled from "styled-components";

import { mobileFeatureNotInPlanTemplateBreakpoint } from "pages/[tenant]/console/utils/layoutUtils";

export const TitleContainer = styled.h3`
  color: ${({ theme }) => theme.console.sidebar.titleColor} !important;
  font: 600 1.375rem/1.71875rem "Inter";
  margin-bottom: 0;

  @media (max-width: ${mobileFeatureNotInPlanTemplateBreakpoint}px) {
    font: 600 0.875rem/1.09375rem "Inter";
  }
`;
