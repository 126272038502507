import React from "react";

import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";

export const LogoutIcon = () => {
  const {
    state: { theme },
  } = useGlobalContext();
  const isDarkMode = theme.value === "dark";

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16602 4.16579V15.8325C4.16602 16.0535 4.25381 16.2654 4.41009 16.4217C4.56637 16.578 4.77834 16.6658 4.99935 16.6658H7.49935V14.9991H5.83268V4.99912H7.49935V3.33245H4.99935C4.77834 3.33245 4.56637 3.42025 4.41009 3.57653C4.25381 3.73281 4.16602 3.94477 4.16602 4.16579ZM16.0343 3.35745L9.36768 1.69079C9.24486 1.66015 9.11668 1.6579 8.99286 1.6842C8.86904 1.71051 8.75284 1.76467 8.65308 1.84258C8.55332 1.9205 8.47262 2.02011 8.4171 2.13387C8.36159 2.24763 8.33272 2.37254 8.33268 2.49912V17.4991C8.33239 17.6258 8.36105 17.7509 8.41646 17.8648C8.47188 17.9787 8.55259 18.0785 8.65244 18.1565C8.75229 18.2345 8.86863 18.2886 8.99258 18.3148C9.11654 18.3409 9.24484 18.3384 9.36768 18.3075L16.0343 16.6408C16.2147 16.5958 16.3749 16.4918 16.4893 16.3453C16.6038 16.1989 16.666 16.0183 16.666 15.8325V4.16579C16.666 3.9799 16.6038 3.79937 16.4893 3.6529C16.3749 3.50643 16.2147 3.40244 16.0343 3.35745ZM12.4993 10.1558C12.4898 10.3704 12.3978 10.5729 12.2425 10.7214C12.0873 10.8698 11.8808 10.9527 11.666 10.9527C11.4512 10.9527 11.2447 10.8698 11.0895 10.7214C10.9343 10.5729 10.8423 10.3704 10.8327 10.1558V9.84162C10.8328 9.6206 10.9207 9.40869 11.0771 9.25248C11.2334 9.09628 11.4454 9.00859 11.6664 9.0087C11.8874 9.00881 12.0994 9.09672 12.2556 9.25307C12.4118 9.40943 12.4995 9.62144 12.4993 9.84245V10.1558Z"
        fill={isDarkMode ? "#F2F2F2" : "#050505bf"}
      />
    </svg>
  );
};
