import { UserIcon } from "pages/[tenant]/console/assets/icons/UserIcon";
import { IOption, IOptionsHook } from "pages/[tenant]/console/types";

export const usePeopleOptions = (): IOptionsHook => {
  const sectionLabel = "Pessoas";
  const options: IOption[] = [
    {
      id: 1,
      label: "Todos os usuários",
      description: "Gerencie e visualize informações sobre cada usuário habilitado ou desabilitado que já passaram por sua plataforma.",
      path: "/console/users",
      icon: <UserIcon />,
    },
    {
      id: 2,
      label: "Usuários habilitados",
      description: "Esses são todos os usuários com acesso pleno às suas contas. Um usuário habilitado pode ou não estar ativamente utilizando a plataforma.",
      path: "/console/users/active",
      icon: <UserIcon />,
    },
    {
      id: 3,
      label: "Usuários desabilitados",
      description: "Esses usuários já não possuem mais acesso às suas contas e seus registros permanecem para que você possa reativá-los mais tarde.",
      path: "/console/users/disabled",
      icon: <UserIcon />,
    },
  ];

  return {
    label: sectionLabel,
    options,
  };
};
