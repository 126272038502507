import React, { useEffect, useState } from "react";

import { Button } from "antd";
import useTranslation from "next-translate/useTranslation";
import PropTypes from "prop-types";

import EmailInvalid from "shared/components/organisms/AuthModal/components/EmailInvalid";
import Input from "shared/components/organisms/AuthModal/components/Input";
import PasswordInvalid from "shared/components/organisms/AuthModal/components/PasswordInvalid";
import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";
import { validateEmail } from "shared/utils/regex";

import { LockOutlined, UserOutlined } from "@ant-design/icons";

import { LoginComponentContainer } from "./styles";

const LoginComponent = ({ handleSubmit }) => {
  const { t } = useTranslation();
  const { state } = useGlobalContext();

  const [email, setEmail] = useState("");
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [password, setPassword] = useState("");

  const { settings } = state.tenancy;

  useEffect(() => {
    if (!email) {
      setEmailInvalid(false);
    }
  }, [email]);

  useEffect(() => {
    if (password?.length < 6) {
      setPasswordInvalid(true);
    } else {
      setPasswordInvalid(false);
    }

    if (!password) {
      setPasswordInvalid(false);
    }
  }, [password]);

  const handleLogin = (e) => {
    const isMouseClick = e.target.type === "button";
    const isKeyEnter = e.keyCode === 13;

    if (handleSubmit && (isMouseClick || isKeyEnter))
      handleSubmit({ email, password });
  };

  const getEmailValue = (e) => {
    const value = e.target?.value || "";

    const formatedEmail = String(value)?.toLowerCase().replace(/ /g, "");

    setEmail(formatedEmail);
  };

  const getPasswordValue = (e) => {
    const value = e.target?.value || "";

    const formatedPassword = String(value)?.replace(/ /g, "");

    setPassword(formatedPassword);
  };

  return (
    <LoginComponentContainer onKeyUp={handleLogin}>
      <LoginComponentContainer.Item>
        <Input
          autoComplete="off"
          prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          value={email}
          onChange={getEmailValue}
          onBlur={(e) => {
            if (email.length) {
              if (validateEmail(email)) {
                setEmailInvalid(false);
              } else {
                setEmailInvalid(true);
              }
            }
          }}
          onFocus={(e) => {
            setEmailInvalid(false);
          }}
          placeholder="Email"
          type={"email"}
        />
        {emailInvalid && <EmailInvalid />}
      </LoginComponentContainer.Item>

      <LoginComponentContainer.Item>
        <Input
          autoComplete="off"
          prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          type="password"
          value={password}
          onChange={getPasswordValue}
          placeholder={t("common:password")}
        />
        {passwordInvalid && <PasswordInvalid />}
      </LoginComponentContainer.Item>

      {settings?.users?.userLoginDescriptionBottom ? (
        <div
          className="bottomDescription"
          dangerouslySetInnerHTML={{
            __html: settings?.users?.userLoginDescriptionBottom,
          }}
        />
      ) : null}

      <LoginComponentContainer.Item className="gx-text-center">
        <Button
          type="primary"
          htmlType="button"
          size="large"
          className="login"
          disabled={!password || !email || emailInvalid || passwordInvalid}
          onClick={handleLogin}
        >
          {t("common:enter")}
        </Button>
      </LoginComponentContainer.Item>
    </LoginComponentContainer>
  );
};

LoginComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default LoginComponent;
