import { IModulesPageContext } from "../types";

export const constructActions = ({
  dispatch,
}): Partial<IModulesPageContext> => ({
  clearModuleState: () => dispatch({ type: "CLEAR_MODULE_STATE" }),
  clearModulesState: () => dispatch({ type: "CLEAR_MODULES_STATE" }),
  clearLessonState: () => dispatch({ type: "CLEAR_LESSON_STATE" }),
  clearGroupState: () => dispatch({ type: "CLEAR_GROUP_STATE" }),
  clearMaterialState: () => dispatch({ type: "CLEAR_MATERIAL_STATE" }),
  setGroup: (group) => dispatch({ type: "SET_GROUP", payload: group }),

  clearMaterialFilesState: () => {
    dispatch({ type: "CLEAR_MATERIAL_FILES_STATE" });
  },

  setModules: (modules) => {
    dispatch({ type: "SET_MODULES", payload: modules });
  },

  setModule: (module) => {
    dispatch({ type: "SET_MODULE", payload: module });
  },

  setMaterialFiles: (materialFiles) => {
    dispatch({ type: "SET_MATERIAL_FILES", payload: materialFiles });
  },

  setQuizLocalQuestions: (quizLocalQuestions) => {
    dispatch({ type: "SET_QUIZ_LOCAL_QUESTIONS", payload: quizLocalQuestions });
  },

  setMaterials: (materials) => {
    dispatch({ type: "SET_MATERIALS", payload: materials });
  },

  setLesson: (lesson) => {
    dispatch({ type: "SET_LESSON", payload: lesson });
  },

  setModulesInternalRoutes: (routes) => {
    dispatch({ type: "SET_MODULES_INTERNAL_ROUTES", payload: routes });
  },

  updateModuleLocally: (key, value) => {
    dispatch({ type: "UPDATE_MODULE_LOCALLY", key, value });
  },

  updateLessonLocally: (key, value) => {
    dispatch({ type: "UPDATE_LESSON_LOCALLY", key, value });
  },

  clearAllStates: () => {
    dispatch({ type: "CLEAR_MODULE_STATE" });
    dispatch({ type: "CLEAR_MODULES_STATE" });
    dispatch({ type: "CLEAR_LESSON_STATE" });
    dispatch({ type: "CLEAR_GROUP_STATE" });
  },
});
