import React from "react";

import { Input } from "antd";
import InputMask from "react-input-mask";

export const handleBrand = (brand: string, theme: string) => {
  switch (brand) {
    case "visa":
      return "https://ensinio-public.s3.us-west-1.amazonaws.com/svg/visa.svg";

    case "mastercard":
      return "https://ensinio-public.s3-us-west-1.amazonaws.com/svg/mastercard.svg";

    case "hipercard":
      return "https://ensinio-public.s3-us-west-1.amazonaws.com/svg/hipercard.svg";

    case "elo":
      return "https://ensinio-public.s3-us-west-1.amazonaws.com/svg/elo.svg";

    case "amex":
      return "https://ensinio-public.s3.us-west-1.amazonaws.com/svg/american.svg";

    case "discover":
      return "https://ensinio-public.s3.us-west-1.amazonaws.com/svg/discover.svg";

    case "jcb":
      return "https://ensinio-public.s3.us-west-1.amazonaws.com/svg/jcb.svg";

    case "aura":
      return "https://ensinio-public.s3.us-west-1.amazonaws.com/svg/aura.svg";

    case "diners":
      return theme === "dark"
        ? "https://ensinio-public.s3.us-west-1.amazonaws.com/svg/dark-diners.svg"
        : "https://ensinio-public.s3.us-west-1.amazonaws.com/svg/white-diners.svg";

    default:
      return "https://ensinio-public.s3.us-west-1.amazonaws.com/svg/coringa.png";
  }
};

export const handleCard = (brand: string, t: any) => {
  switch (brand) {
    case "visa":
      return "Visa";

    case "mastercard":
      return "Mastercard";

    case "hipercard":
      return "Hipercard";

    case "jcb":
      return "JCB";

    case "elo":
      return "Elo";

    case "amex":
      return "American Express";

    case "aura":
      return "Aura";

    case "diners":
      return "Diners Club";

    case "discover":
      return "Discover";

    case "unknown":
      return t("common: accounts.payments.endedIn");

    default:
      return t("common: accounts.payments.undefinedCard");
  }
};

export const handleTypeEntityInput = (
  typeEntity: string,
  t: any,
  FormItem: any,
) => {
  switch (typeEntity) {
    case "cpf":
      return (
        <FormItem
          name="cpf"
          label="CPF"
          className="form-item"
          rules={[
            {
              required: true,
              message: t.formatMessage({
                id: "eCommerce.checkoutLabelTypeCPF",
              }),
            },
          ]}
        >
          <InputMask
            mask="99999999999"
            placeholder={t.formatMessage({
              id: "eCommerce.checkoutLabelCPF",
            })}
            className="ant-input"
            maskPlaceholder={null}
          />
        </FormItem>
      );

    case "cnpj":
      return (
        <FormItem
          name="cnpj"
          label="CNPJ"
          className="form-item"
          rules={[
            {
              required: true,
              message: t.formatMessage({
                id: "eCommerce.document.billing.information",
              }),
            },
          ]}
        >
          <InputMask
            mask="99999999999999"
            placeholder="CNPJ"
            className="ant-input"
            maskPlaceholder={null}
          />
        </FormItem>
      );

    case "passport":
      return (
        <FormItem
          className="form-item"
          name="passport"
          rules={[
            {
              required: true,
              message: t.formatMessage({
                id: "eCommerce.document.billing.information.passport",
              }),
            },
          ]}
        >
          <Input placeholder={t.formatMessage({ id: "app.passport" })} />
        </FormItem>
      );

    case "other":
      return (
        <FormItem
          className="form-item"
          name="other"
          rules={[
            {
              required: true,
              message: t.formatMessage({
                id: "eCommerce.document.billing.information.other",
              }),
            },
          ]}
        >
          <Input placeholder={t.formatMessage({ id: "app.other" })} />
        </FormItem>
      );

    default:
      return null;
  }
};

export function capitalizeFullName(param: string) {
  return param
    ?.toLowerCase()
    ?.split(" ")
    ?.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    ?.join(" ");
}

export const setFormFieldsErrors = (
  field: string,
  message: string,
  form: any,
) => {
  form.setFields([
    {
      name: field,
      errors: [message],
    },
  ]);
};

export const getInstallment = (
  payment: any,
  paramType: any,
  entityId: any,
  final_price_int: any,
  has_installments: any,
) => {
  let installment = {};

  const couponEntityType = payment?.coupon_entity_type;
  const couponEntityId = Number(payment?.coupon_entity_id);
  const couponProductPriceDiscount = Number(
    payment?.coupon_product_price_discount,
  );

  const showDiscountWithCoupon =
    couponEntityType === paramType && couponEntityId === entityId;

  const installmentObject = {
    installment: 1,
    value: showDiscountWithCoupon
      ? couponProductPriceDiscount
      : final_price_int,
  };

  if (has_installments) {
    installment =
      JSON.parse(sessionStorage.getItem("installment") || "{}") ||
      sessionStorage.setItem("installment", JSON.stringify(installmentObject));
  }

  sessionStorage.setItem("installment", JSON.stringify(installmentObject));
  installment = JSON.parse(sessionStorage.getItem("installment") || "{}");

  return installment;
};

export function onSelectInstallment({ installment, payment }: any) {
  const productSession = JSON.parse(
    sessionStorage.getItem("product_session") || "{}",
  );
  const {
    entityId,
    paramType,
    final_price_int,
    has_installments,
    installments: installmentsStorage,
  } = productSession[1];

  installmentsStorage.unshift(
    getInstallment(
      payment,
      paramType,
      entityId,
      final_price_int,
      has_installments,
    ),
  );

  const installmentSelected = installmentsStorage?.filter(
    (item: any) => item.installment === installment,
  );

  sessionStorage.setItem(
    "installmentSelected",
    JSON.stringify(installmentSelected[0].installment),
  );

  /* if (installmentSelected?.length) {
    dispatch(
      setInfoInstallment({
        priceFormated: installmentSelected[0].value
          .toFixed(2)
          .toString()
          .replace('.', ','),
        amountInstallment: installmentSelected[0].installment,
        totalPriceFormated: final_price_int
          .toFixed(2)
          .toString()
          .replace('.', ','),
      })
    );
  } */
}
