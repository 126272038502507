import { useState } from "react";

export const useConsoleSideBarActiveItemsIndicator = () => {
  const [activeSectionPosition, setActiveSectionPosition] = useState<
    number | null
  >(null);
  const [activeOptionPosition, setActiveOptionPosition] = useState<
    number | null
  >(null);

  const setInfoToMoveActiveSectionIndicator = (activeItem: HTMLElement) => {
    const initialActiveSectionPosition = activeItem?.offsetTop;

    setActiveSectionPosition(initialActiveSectionPosition);
  };

  const setInfoToMoveActiveOptionIndicator = (activeItem: HTMLElement) => {
    const initialActiveOptionPosition = activeItem?.offsetTop;

    if (typeof initialActiveOptionPosition === "number")
      setActiveOptionPosition(initialActiveOptionPosition);
  };

  return {
    setInfoToMoveActiveSectionIndicator,
    setInfoToMoveActiveOptionIndicator,
    activeSectionPosition,
    activeOptionPosition,
    setActiveOptionPosition,
  };
};
