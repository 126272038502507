import React from "react";

import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";

export const PlayIcon = () => {
  const {
    state: { theme },
  } = useGlobalContext();
  const isDarkMode = theme.value === "dark";

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 3V17L15 10L4 3Z"
        fill={isDarkMode ? "#f2f2f2" : "#050505bf"}
      />
    </svg>
  );
};
