/* eslint-disable react-hooks/rules-of-hooks */
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const ErrorHandler = async (error) => {
  const errorResponseStatus = error?.response?.status;

  const requestWasCanceled = error?.message === "canceled";
  if (requestWasCanceled) return;

  const myGroupsRouteNotExists =
    error.request.responseURL.includes("my-groups");
  if (myGroupsRouteNotExists) return;

  const nexusRouteHasError =
    error.request.responseURL.includes("nexus/bindings");
  if (nexusRouteHasError) return;

  switch (errorResponseStatus) {
    case 401:
      if ("user") {
        Cookies.remove("username");
        Cookies.remove("token");
        window.location.href = "/401";
      }
      break;

    case 422:
      if (
        window.location.pathname === "/authentication" &&
        error?.response?.data.hasOwnProperty("email")
      ) {
        if (
          error?.response?.data?.email[0] ===
          "O atributo e-mail já foi escolhido."
        ) {
          toast(error?.response?.data?.email[0], { type: "error" });
        }
      }
      break;

    case 500: {
      if (
        window.location.pathname === "/payment" &&
        error?.response?.data?.message === "Transação autorizada gerada"
      )
        break;
    }
    default:
      break;
  }
};

export default ErrorHandler;
