import React, { ButtonHTMLAttributes, ReactNode } from "react";

import { SideBarButtonContainer } from "./styles";

interface ISideBarButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  children: ReactNode;
  shouldHide?: boolean;
  shouldDisable?: boolean;
}

export const SideBarButton = ({
  label,
  children,
  shouldHide,
  shouldDisable,
  ...props
}: ISideBarButtonProps) => {
  if (shouldHide) return null;
  return (
    <SideBarButtonContainer
      tooltipLabel={label}
      disabled={shouldDisable}
      {...props}
    >
      {children}
    </SideBarButtonContainer>
  );
};
