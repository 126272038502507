import styled from "styled-components";

export const FirstModalContainer = styled.div`
  width: 100%;
  height: 100%;

  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  animation: appearFromLeft 0.4s;

  .col {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    margin-top: 8px;
    height: 100%;
    min-height: 450px;

    .logo {
      height: 90px;
    }

    h1 {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: bold;
    }

    h1.siteName {
      font-size: 24px;
      text-align: center;
    }

    h2,
    span,
    p {
      font-style: normal;
      font-weight: normal;
      font-family: Roboto;
    }

    img {
      max-width: 250px;
      max-height: 90px;
      object-fit: contain;
    }

    div.titulos {
      text-align: center;
      h1 {
        color: ${({ theme }) => theme.colors.textColor};
        font-style: normal;
        font-weight: 600;
        font-size: 29px;
        line-height: 115%;
        letter-spacing: -0.5px;
        margin-bottom: 20px;
      }

      h2 {
        color: ${({ theme }) => theme.colors.textColor};
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 132.5%;
        align-items: center;
        text-align: center;
        letter-spacing: -0.5px;
      }
    }

    @media (max-width: 900px) {
      justify-content: space-around;
    }
  }

  @keyframes appearFromLeft {
    from {
      opacity: 0;
      transform: translateX(-5px);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

export const OptionButtons = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button.primary {
    cursor: pointer;
    width: 214px;
    height: 44px;
    padding: 0px 31px;
    margin: 0;
    font-size: 14px;
  }
`;

export const AlreadyHaveAccountButton = styled.button`
  margin-top: 30px;
  font-family: Roboto;
  font-weight: 500;

  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  color: ${({ theme }) => theme.colors.textColor};
`;
