import styled from "styled-components";

import contrastAutoAdjust from "shared/utils/contrastAutoAdjust";

export const ExclusivePageContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;

  background: ${({ theme }) => theme.colors?.inputBackgroundColor};
`;

export const Modal = styled.div`
  margin-top: 70px;
  padding: 40px;
  width: 600px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;

  background: ${({ theme }) => theme.colors?.modalBackgroundColor};

  strong {
    padding: 20px 0;
    font-size: 25px;
    font-weight: 600;
    line-height: 122%;
    text-align: center;
    letter-spacing: -0.340547px;

    color: ${({ theme }) => theme.colors?.textColor};
  }

  && {
    button {
      margin: 0;

      & + button {
        margin-left: 1rem;
      }

      span {
        color: #1a1a1a;
      }

      :last-child {
        span {
          color: ${({ theme }) =>
            contrastAutoAdjust({
              background: theme.colors?.primary,
              text: "#f5f5f5",
            })};
        }

        background-color: ${({ theme }) => theme.colors?.primary};
      }
    }
  }

  @media screen and (max-width: 380px) {
    .ant-btn:not(:last-child) {
      margin: 0;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      button {
        margin-top: 10px;
      }
    }
  }
`;
