import styled from "styled-components";

import { mobileFeatureNotInPlanTemplateBreakpoint } from "pages/[tenant]/console/utils/layoutUtils";

export const TopPageFlagContainer = styled.div<{ backgroundColor: string }>`
  height: 45px;

  padding: 1rem 1.25rem;

  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;

  background-color: ${({ backgroundColor }) => backgroundColor};

  color: #fff;
  font: 600 0.875rem / normal "Inter";

  @media (max-width: ${mobileFeatureNotInPlanTemplateBreakpoint}px) {
    font: 600 0.8125rem/1.01563rem "Inter";
  }
`;
