import styled, { css } from "styled-components";

import { consolePageMobileBreakpoint } from "pages/[tenant]/console/hooks/useConsolePageConfig";
import { BaseSideBarSectionButton } from "pages/[tenant]/console/styles/BaseSideBarSectionButton";

interface ISideBarButtonContainerProps {
  tooltipLabel: string;
  disabled: boolean;
}

export const SideBarButtonContainer = styled.button<ISideBarButtonContainerProps>`
  ${BaseSideBarSectionButton}
  font: 600 0.875rem/1.3125rem "Inter";

  svg {
    color: ${({ theme }) => theme.console.sidebar.optionTextColor};
  }

  .switch-theme {
    @media (min-width: ${consolePageMobileBreakpoint}px) {
      justify-content: center;
      width: 45px;
      height: 45px;
    }

    ${({ disabled }) =>
      disabled &&
      css`
        opacity: 0.7;
        cursor: not-allowed !important;
      `}
  }
`;
