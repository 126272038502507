import React from "react";

import { AnalyticsDashboards } from "pages/[tenant]/console/components/templates/ConsoleLayout/assets/featureIllustrations/AnalyticsDashboards";
import { TemplateFeatureNotInPlan } from "pages/[tenant]/console/components/templates/ConsoleLayout/components/template/TemplateFeatureNotInPlan";

export const SalesIsNotInPlan = () => {
  return (
    <TemplateFeatureNotInPlan
      topFlag={{
        text: "Faça upgrade para visualizar seus dados com Dashboards",
        href: "https://ensinio.com/planos",
      }}
      backgroundColor="#605cff"
      topContent={{
        title: "Analytics Dashboards",
        description:
          "Com os dashboards, você está no comando dos dados e tem acesso a visualização de informações importantes para tomar decisões informadas.",
        illustration: <AnalyticsDashboards />,
        actionButton: {
          text: "Obter funcionalidade",
          href: "https://ensinio.com/planos",
        },
      }}
      checkList={[
        {
          id: 1,
          text: "Monitore e identifique padrões de atividades",
        },
        {
          id: 2,
          text: "Visualize dados com gráficos interativos",
        },
        {
          id: 3,
          text: "Tome decisões com base em dados atualizados",
        },
        {
          id: 4,
          text: "Acompanhe o progresso da plataforma no tempo",
        },
        {
          id: 5,
          text: "Segmente as métricas por públicos específicos",
        },
        {
          id: 6,
          text: "Capture insights e saia na frente da concorrência",
        },
      ]}
    />
  );
};
