import React from "react";

import { CheckIcon } from "pages/[tenant]/console/components/templates/ConsoleLayout/assets/icons/CheckIcon";
import { IChecklist } from "pages/[tenant]/console/components/templates/ConsoleLayout/types";

import { CheckListContainer } from "./styles";

interface ICheckListProps {
  list: IChecklist[];
}

export const CheckList = ({ list }: ICheckListProps) => {
  return (
    <CheckListContainer>
      {list.map((item) => (
        <li key={item.id}>
          <CheckIcon /> {item.text}
        </li>
      ))}
    </CheckListContainer>
  );
};
