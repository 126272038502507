import React from "react";

export const WebhooksIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9987 11.32H8.00536C7.27203 11.32 6.70536 11.9467 6.35203 12.5867C6.07233 13.1115 5.62522 13.5278 5.08178 13.7693C4.53833 14.0109 3.92974 14.0638 3.35274 13.9197C2.77575 13.7757 2.26347 13.4429 1.89733 12.9743C1.53119 12.5056 1.33222 11.928 1.33203 11.3333C1.3387 10.8667 1.46536 10.4 1.71203 10"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 11.3364L6.08667 7.48305C6.44 6.83638 6.15333 6.02972 5.75333 5.41638C5.56454 5.11445 5.43814 4.77776 5.38157 4.42619C5.32501 4.07461 5.33943 3.71527 5.42399 3.36936C5.50855 3.02345 5.66154 2.69798 5.87392 2.41215C6.08631 2.12632 6.35379 1.88592 6.66058 1.70514C6.96737 1.52436 7.30727 1.40686 7.66021 1.35956C8.01315 1.31227 8.37199 1.33614 8.71556 1.42977C9.05913 1.52341 9.38046 1.6849 9.6606 1.90473C9.94074 2.12456 10.174 2.39827 10.3467 2.70972"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 4L10.0867 7.82C10.44 8.46667 11.2667 8.66667 12 8.66667C12.7072 8.66667 13.3855 8.94762 13.8856 9.44772C14.3857 9.94781 14.6667 10.6261 14.6667 11.3333C14.6667 12.0406 14.3857 12.7189 13.8856 13.219C13.3855 13.719 12.7072 14 12 14"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
