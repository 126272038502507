import React, { useState, useEffect, useLayoutEffect } from "react";
import { Button } from "antd";
import useTranslation from "next-translate/useTranslation";

import { checkIfBrowserIsOutdated } from "shared/utils/validateBrowser.js";

import { Container, Content, CloseIcon } from "./styles";

const BrowserNotSupported = () => {
  const { t } = useTranslation();
  const browserIsOutdated = checkIfBrowserIsOutdated();

  const [showBrowserModal, setShowBrowserModal] = useState(browserIsOutdated);

  const onCloseBrowserModal = () => {
    setShowBrowserModal(false);
    sessionStorage.setItem("browserOutdated", true);
  };

  useLayoutEffect(() => {
    const browserOutdated = sessionStorage.getItem("browserOutdated");

    if (browserIsOutdated) setShowBrowserModal(!browserOutdated);
  }, [browserIsOutdated]);

  useEffect(() => {
    const handleClick = (e) => {
      const elementClass = e?.target?.className;

      if (
        typeof elementClass === "string" &&
        elementClass?.includes("modal-background")
      )
        onCloseBrowserModal();
    };

    document.addEventListener("click", handleClick, false);

    return () => document.removeEventListener("click", handleClick, false);
  }, []);

  return (
    <>
      {showBrowserModal && (
        <Container className="modal-background">
          <Content>
            <CloseIcon onClick={onCloseBrowserModal} />

            <h1>{t("common:browserNotSupportedTitle")}</h1>

            <h3>{t("common:browserNotSupportedDescription")}</h3>

            <Button onClick={onCloseBrowserModal} type="primary">
              {t("common:close")}
            </Button>
          </Content>
        </Container>
      )}
    </>
  );
};

export default BrowserNotSupported;
