import { useContext } from "react";

import GlobalContext from "shared/providers/context/GlobalContext";

export const useFeature = (context: TypeFeatures): boolean => {
  const { state } = useContext(GlobalContext);

  if (typeof context !== "string") {
    throw new Error("the context must be of type string");
  }

  if (!context) throw new Error("the context must not be null");

  const features = state.tenancy.enabled_features;

  if (!features) return true;

  return features.includes(context);
};

type TypeFeatures =
  | "f_upload_ensinio_video"
  | "f_side_menu"
  | "f_my_access"
  | "f_categories"
  | "f_upload_youtube"
  | "f_upload_wistia"
  | "f_upload_vimeo"
  | "f_upload_loom"
  | "f_pages"
  | "f_custom_scripts"
  | "f_engagement_visualizer"
  | "f_engagement_metrics"
  | "f_learning_metrics"
  | "f_sales_metrics"
  | "f_shortcut_menu"
  | "f_email_management"
  | "f_spotlights"
  | "f_notifications"
  | "f_tags"
  | "f_imports"
  | "f_private_access"
  | "f_custom_domain"
  | "f_webhooks"
  | "f_webhooks_user_events"
  | "f_webhooks_orders_events"
  | "f_webhooks_enrollments_events"
  | "f_webhooks_subscriptions_events"
  | "f_webhooks_history"
  | "f_admin_permissions"
  | "f_global_search"
  | "f_search"
  | "f_checkout"
  | "f_statement"
  | "f_payment_pix"
  | "f_admin_withdraws"
  | "f_transactions_visualizer"
  | "f_orders_visualizer"
  | "f_product_bundles"
  | "f_plan_subscriptions"
  | "f_white_label"
  | "f_pixel"
  | "f_pixel_facebook"
  | "f_pixel_google_ads"
  | "f_pixel_google_analytics"
  | "f_pixel_google_analytics_g4"
  | "f_checkout_link"
  | "f_admin_refund"
  | "f_hotmart"
  | "f_payment_credit_card"
  | "f_payment_boleto"
  | "f_courses"
  | "f_quizzes"
  | "f_meets"
  | "f_manual_enrollments"
  | "f_media_space"
  | "f_complementary_contents"
  | "f_certificates"
  | "f_certifcates"
  | "f_comments"
  | "f_community"
  | "f_blog"
  | "f_collections"
  | "f_groups"
  | "f_groups_feed"
  | "f_groups_lessons"
  | "f_groups_quizzes"
  | "f_lives"
  | "f_groups_certificates"
  | "f_reports"
  | "f_promoters"
  | "f_gamification"
  | "f_ranking"
  | "f_simplified_upsell"
  | "f_nexus"
  | "f_checkout_images";
