import React from "react";

export const PublishedIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66534 10.3959L4.47001 8.20052L3.52734 9.14319L6.66534 12.2812L13.1367 5.80985L12.194 4.86719L6.66534 10.3959Z"
        fill="currentColor"
      />
    </svg>
  );
};
