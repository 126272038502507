import React from "react";

export const SalesIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5013 2.16406C8.85313 2.16406 7.24196 2.6528 5.87155 3.56848C4.50114 4.48416 3.43304 5.78565 2.80231 7.30837C2.17158 8.83109 2.00655 10.5066 2.3281 12.1231C2.64964 13.7397 3.44331 15.2245 4.60875 16.39C5.77419 17.5554 7.25905 18.3491 8.87555 18.6706C10.4921 18.9922 12.1676 18.8271 13.6903 18.1964C15.2131 17.5657 16.5145 16.4976 17.4302 15.1271C18.3459 13.7567 18.8346 12.1456 18.8346 10.4974C18.8346 9.40305 18.6191 8.31941 18.2003 7.30837C17.7815 6.29732 17.1677 5.37866 16.3939 4.60484C15.62 3.83102 14.7014 3.21719 13.6903 2.7984C12.6793 2.37961 11.5957 2.16406 10.5013 2.16406ZM10.5013 17.1641C9.18276 17.1641 7.89383 16.7731 6.7975 16.0405C5.70118 15.308 4.84669 14.2668 4.34211 13.0486C3.83752 11.8304 3.7055 10.49 3.96274 9.19679C4.21997 7.90359 4.85491 6.7157 5.78726 5.78335C6.71961 4.851 7.9075 4.21606 9.2007 3.95883C10.4939 3.70159 11.8344 3.83362 13.0525 4.3382C14.2707 4.84278 15.3119 5.69727 16.0444 6.79359C16.777 7.88992 17.168 9.17885 17.168 10.4974C17.168 12.2655 16.4656 13.9612 15.2154 15.2114C13.9651 16.4617 12.2694 17.1641 10.5013 17.1641Z"
        fill="currentColor"
      />
      <path
        d="M10.5013 9.66667C8.83464 9.66667 8.83464 9.14167 8.83464 8.83333C8.83464 8.525 9.41797 8 10.5013 8C11.5846 8 11.6596 8.53333 11.668 8.83333H13.3346C13.3234 8.26563 13.1192 7.71867 12.7557 7.28246C12.3922 6.84625 11.891 6.54678 11.3346 6.43333V5.5H9.66797V6.40833C8.0013 6.68333 7.16797 7.75833 7.16797 8.83333C7.16797 9.76667 7.6013 11.3333 10.5013 11.3333C12.168 11.3333 12.168 11.9 12.168 12.1667C12.168 12.4333 11.6513 13 10.5013 13C8.96797 13 8.83464 12.2833 8.83464 12.1667H7.16797C7.16797 12.9333 7.71797 14.2917 9.66797 14.6V15.5H11.3346V14.6C13.0013 14.3167 13.8346 13.2417 13.8346 12.1667C13.8346 11.2333 13.4013 9.66667 10.5013 9.66667Z"
        fill="currentColor"
      />
    </svg>
  );
};
