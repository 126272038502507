import React, { useEffect } from "react";

export interface ICpfCnpjProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  onChangeCustom?: (
    e: React.ChangeEvent<HTMLInputElement>,
    mask: string,
  ) => void;
  newValue?: string;
  inputRef?: React.MutableRefObject<HTMLInputElement>;
}

const CpfCnpj: React.FC<ICpfCnpjProps> = ({
  type,
  newValue,
  inputRef,
  onChange,
  onChangeCustom,
  ...rest
}: ICpfCnpjProps) => {
  const TYPES = {
    CPF: "999.999.999-999",
    CNPJ: "99.999.999/9999-99",
  };
  const MAX_LENGTH = clear(TYPES.CNPJ).length;

  function getMask(value: string): string {
    return value.length > 11 ? "CNPJ" : "CPF";
  }

  function applyMask(value: string, mask: string): string {
    let result = "";

    let inc = 0;
    Array.from(value).forEach((letter, index) => {
      if (!mask[index + inc].match(/[0-9]/)) {
        result += mask[index + inc];
        inc++;
      }
      result += letter;
    });
    return result;
  }

  function clear(value: string): string {
    return value && value.replace(/[^0-9]/g, "").slice(0, MAX_LENGTH);
  }

  function onLocalChange(e: React.ChangeEvent<HTMLInputElement>) {
    const inputValue = clear(e.target.value);

    const mask = getMask(inputValue);
    const valueWithMask = applyMask(inputValue, TYPES[mask]);

    e.target.value = valueWithMask;

    if (!!onChangeCustom) onChangeCustom(e, mask);
    if (!!onChange) onChange(e);
  }

  let value = clear(newValue);

  if (value) {
    value = applyMask(value, TYPES[getMask(value)]);
  }

  useEffect(() => {
    if (!!inputRef?.current?.value) {
      const cleanedValue = clear(inputRef?.current?.value);

      inputRef.current.value = applyMask(
        cleanedValue,
        TYPES[getMask(cleanedValue)],
      );
    }
  }, []);

  return (
    <input
      type={type}
      value={value}
      ref={inputRef}
      autoComplete="do-not-autofill"
      onChange={onLocalChange}
      {...rest}
    />
  );
};

export default CpfCnpj;
