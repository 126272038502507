import styled from "styled-components";

export const BannerContainer = styled.section`
  width: 100%;
  position: relative;

  min-height: 25rem;
  overflow: hidden;

  @media (max-width: 1900px) {
    min-height: 20rem;
  }

  @media (max-width: 850px) {
    min-height: 15rem;
  }

  @media (max-width: 500px) {
    min-height: 11.25rem;

    img {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @media (min-width: 1920px) {
    img {
      position: relative !important;
    }
  }
`;
