import styled, { css } from "styled-components";

import hexToRgb from "shared/utils/hexToRgb";

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;

  position: absolute;
  z-index: 9999;
  top: 0;

  background: ${(props) => `rgba(${hexToRgb(props.theme.colors.topbar)}, 0.5)`};
  backdrop-filter: blur(10px);
`;

interface IModalContent {
  willClose?: boolean;
}

export const ModalContent = styled.div<IModalContent>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
  padding: 30px;
  position: absolute;

  z-index: 999;
  bottom: 0;
  background: ${({ theme }) => theme.colors.topbar};

  animation: showUp 0.4s normal;

  ${(props) =>
    props.willClose &&
    css`
      animation: disapper 0.6s normal;
    `}

  @keyframes disapper {
    from {
      transform: translateY(0px);
    }

    to {
      transform: translateY(+150px);
    }
  }

  @keyframes showUp {
    from {
      transform: translateY(+150px);
    }

    to {
      transform: translateY(0px);
    }
  }
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.topbarText};
  font-size: 18px;
  font-weight: 600;
  pointer-events: none;
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.topbarText};
  padding: 0;
  margin: 0;
`;
