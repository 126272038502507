import React from "react";

import { Container, Image, Text } from "./styles";

const InvalidField = ({ fieldName }) => {
  return (
    <Container>
      <Image />

      <Text>Digite um {fieldName} válido</Text>
    </Container>
  );
};

export default InvalidField;
