import { detect } from "detect-browser";
import React from "react";

import getUsernameFromCookies from "shared/infra/services/auth/getUsernameFromCookies";
import sendLogToSlack from "shared/infra/services/slackAPI";

import { Container } from "./styles";

const PageHasBeenForceRefreshed = "page-has-been-force-refreshed";

const retryPageLoading = () => {
  const pageHasAlreadyBeenForceRefreshed = JSON.parse(
    window.localStorage.getItem(PageHasBeenForceRefreshed) || "false"
  );
  if (!pageHasAlreadyBeenForceRefreshed) {
    window.localStorage.setItem(PageHasBeenForceRefreshed, "true");
    return window.location.reload();
  }
  window.localStorage.setItem(PageHasBeenForceRefreshed, "false");
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    retryPageLoading();

    const username = getUsernameFromCookies();
    const cache = document
      ?.querySelector("meta[http-equiv=cache-control]")
      ?.getAttribute("content");

    const lines = [
      `\n *🔴 Erro:* ${error.message}`,
      `\n *Origem:* ${window.location.href}`,
      `\n *Navegador:* ${this.getBrowserInfo()}`,
      username ? `\n *Usuário:* ${username}` : "",
      cache ? `\n *Cache:* ${cache}` : "",
      `\n *Linha:* ${this.formatStackTrace(error.stack)}`,
    ];

    const logText = lines.reduce(
      (currentText, currentLine) => currentText + currentLine,
      ""
    );

    if (!window.location.hostname.includes("localhost")) {
      sendLogToSlack(logText);
    }
  }

  getBrowserInfo = () => {
    const browser = detect();
    return `${browser.name} v${browser.version}`;
  };

  formatStackTrace = (stackTrace) => {
    const firstLine = stackTrace.split("\n").slice(1, 2).join("\n");
    return `\`\`\`${firstLine}\`\`\``;
  };

  render() {
    const { hasError } = this.state;
    const { children } = this.props;


    return (
      hasError ? (
        <Container>
          <h1>Algo deu errado :(</h1>

          <p>Lamentamos o inconveniente.</p>
          <p>
            Geramos automaticamente um relatório sobre o ocorrido, e
            trabalharemos para corrigir o quanto antes.
          </p>

          <button onClick={() => window.location.reload()} type="button">
            Tentar novamente
          </button>
        </Container>
      ) : <>{children}</>
    )
  }
}

export default ErrorBoundary;
