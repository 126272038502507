import React from "react";

export const FeatureIsNotInUserPlanIcon = () => {
  return (
    <svg
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M57.1654 40.0026L40.4987 23.3359L23.832 40.0026M40.4987 60.0026V26.6693V60.0026ZM40.4987 76.6693C60.7491 76.6693 77.1654 60.253 77.1654 40.0026C77.1654 19.7522 60.7491 3.33594 40.4987 3.33594C20.2483 3.33594 3.83203 19.7522 3.83203 40.0026C3.83203 60.253 20.2483 76.6693 40.4987 76.6693Z"
        stroke="currentColor"
        strokeWidth="5"
      />
    </svg>
  );
};
