import React, { useEffect, useState } from "react";

import { Button } from "antd";
import useTranslation from "next-translate/useTranslation";
import PropTypes from "prop-types";

import PasswordInput from "shared/components/atoms/PasswordInput";
import DocumentInput from "shared/components/organisms/AuthModal/components/DocumentInput";
import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";
import openNotificationWithIcon from "shared/utils/openNotificationWithIcon";
import { validateEmail } from "shared/utils/regex";

import { UserOutlined } from "@ant-design/icons";
import { useAddress } from "@payment/hooks/useAddress";

import EmailInvalid from "../../../../components/EmailInvalid";
import Input from "../../../../components/Input";
import InputMask from "../../../../components/InputMask";
import InvalidField from "../../../../components/InvalidField";
import NameInvalid from "../../../../components/NameInvalid";
import { LoginStyle } from "./styles";

function Login({ handleRegister, navigate }) {
  const { t } = useTranslation();
  const { state } = useGlobalContext();
  const { getAddressBrazilAPIRequest } = useAddress();

  const { settings, create_account_options } = state.tenancy;

  const [password, setPassword] = useState("");
  const [passwordInvalid, setPasswordInvalid] = useState(false);

  const [email, setEmail] = useState("");
  const [emailInvalid, setEmailInvalid] = useState(false);

  const [name, setName] = useState("");
  const [nameInvalid, setNameInvalid] = useState(false);

  const [document, setDocument] = useState("");
  const [documentInvalid, setDocumentInvalid] = useState(false);

  const [phone, setPhone] = useState("");
  const [phoneInvalid, setPhoneInvalid] = useState(false);

  const [zipcode, setZipcode] = useState("");
  const [zipcodeInvalid, setZipcodeInvalid] = useState(false);

  const [streetNumber, setStreetNumber] = useState("");
  const [streetNumberInvalid, setStreetNumberInvalid] = useState(false);

  const [address, setAddress] = useState<{
    [key: string]: string;
  }>({});
  const [addressError, setAddressError] = useState(false);
  const [addressInvalid, setAddressInvalid] = useState<{
    [key: string]: boolean;
  }>({});

  const [customFields, setCustomFields] = useState(
    create_account_options?.custom_fields_active?.reduce(
      (a, v) => ({ ...a, [v.name]: "" }),
      {},
    ),
  );
  const [customFieldsInvalid, setCustomFieldsInvalid] = useState(
    create_account_options?.custom_fields_active?.reduce(
      (a, v) => ({ ...a, [v.name]: false }),
      {},
    ),
  );

  const getAddressBrazilAPI = async (
    e: React.FocusEvent<HTMLInputElement, Element>,
  ) => {
    const zipcode = e.currentTarget.value.replaceAll(/[^0-9]/g, "");
    const MIN_ZIPCODE_LENGTH = 8;

    if (zipcode.length < MIN_ZIPCODE_LENGTH) return;

    const address = await getAddressBrazilAPIRequest(zipcode);

    if (!address.error) {
      setAddressError(false);
      openNotificationWithIcon("success", t("common:zipCodeFoundWithSuccess"));

      setTimeout(() => {
        setAddress(address);
      }, 200);
    } else if (address.error === "CEP não encontrado na base do ViaCEP") {
      setAddressError(true);
      openNotificationWithIcon("error", t("common:zipCodeNotFound"));
    } else {
      setAddressError(true);
      openNotificationWithIcon(
        "warning",
        t("common:addressHasIncompleteInformation"),
      );

      const requiredField = ["city", "address", "street", "neighborhood"];

      setTimeout(() => {
        setAddressInvalid((prevState) => ({
          ...prevState,
          street_number: true,
        }));

        Object.keys(address).forEach((key) => {
          if (requiredField.includes(key)) {
            if (!address[key])
              return setAddressInvalid((prevState) => ({
                ...prevState,
                [key]: true,
              }));

            setAddress((prevState) => ({
              ...prevState,
              [key]: address[key],
            }));
          }
        });
      }, 10);
    }
  };

  useEffect(() => {
    if (!email) {
      setEmailInvalid(false);
    }
  }, [email]);

  useEffect(() => {
    if (password?.length < 6) {
      setPasswordInvalid(true);
    } else {
      setPasswordInvalid(false);
    }

    if (!password) {
      setPasswordInvalid(false);
    }
  }, [password]);

  useEffect(() => {
    if (name && name.split(" ").length <= 1) {
      setNameInvalid(true);
    } else {
      setNameInvalid(false);
    }

    if (!name) {
      setNameInvalid(false);
    }
  }, [name]);

  const handleOnSubmit = () => {
    const data = {
      password,
      email,
      name,
      document: document.replace(/[^0-9]/g, ""),
      phone_number: `+55${phone.replace(/[^0-9]/g, "")}`,
      zipcode: zipcode.replace(/[^0-9]/g, ""),
      country_id: 31,
      street_number: streetNumber,
      ...address,
      ...customFields,
    };

    handleRegister(data);
  };

  return (
    <LoginStyle>
      <LoginStyle.Item>
        <Input
          id="username"
          prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          onChange={(e) => setName(e.target.value)}
          placeholder={t("common:nameAndSurname")}
          type="text"
        />

        {nameInvalid && <NameInvalid />}
      </LoginStyle.Item>

      <LoginStyle.Item>
        <Input
          id="email"
          type="email"
          prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          value={email.toLowerCase().replace(/[^a-zA-Zs0-9@._]/g, "")}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={(e) => {
            if (email.length) {
              if (validateEmail(email)) {
                setEmailInvalid(false);
              } else {
                setEmailInvalid(true);
              }
            }
          }}
          onFocus={(e) => {
            setEmailInvalid(false);
          }}
          placeholder="Email"
        />

        {emailInvalid && <EmailInvalid />}
      </LoginStyle.Item>

      {create_account_options?.request_document_to_create_account && (
        <LoginStyle.Item>
          <DocumentInput
            id="document"
            type="document"
            newValue={document}
            placeholder="CPF ou CNPJ"
            onFocus={() => {
              setDocumentInvalid(false);
            }}
            onChange={(e) => setDocument(e.target.value)}
            onBlur={() => {
              if (document.length === 14 || document.length === 18) {
                setDocumentInvalid(false);
              } else {
                setDocumentInvalid(true);
              }
            }}
            icon={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          />

          {documentInvalid && <InvalidField fieldName="documento" />}
        </LoginStyle.Item>
      )}

      {create_account_options?.request_phone_to_create_account && (
        <LoginStyle.Item>
          <InputMask
            id="phone"
            type="phone"
            value={phone}
            mask="(99) 99999-9999"
            placeholder="Telefone"
            onFocus={() => {
              setPhoneInvalid(false);
            }}
            onChange={(e) => setPhone(e.target.value)}
            maskChar={null}
            onBlur={() => {
              if (phone.length === 15) {
                setPhoneInvalid(false);
              } else {
                setPhoneInvalid(true);
              }
            }}
            icon={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          />

          {phoneInvalid && <InvalidField fieldName="telefone" />}
        </LoginStyle.Item>
      )}

      {create_account_options?.request_address_to_create_account && (
        <>
          <LoginStyle.Item>
            <InputMask
              id="zipcode"
              type="zipcode"
              value={zipcode}
              mask="99999-999"
              placeholder="CEP"
              onFocus={() => {
                setZipcodeInvalid(false);
              }}
              onChange={(e) => setZipcode(e.target.value)}
              maskChar={null}
              onBlur={(e) => {
                if (zipcode.length === 9) {
                  setZipcodeInvalid(false);
                  getAddressBrazilAPI(e);
                } else {
                  setZipcodeInvalid(true);
                }
              }}
              icon={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            />

            {zipcodeInvalid && <InvalidField fieldName="CEP" />}
          </LoginStyle.Item>

          <LoginStyle.Item>
            <Input
              id="street_number"
              type="street_number"
              onFocus={() => {
                setStreetNumberInvalid(false);
              }}
              placeholder="Número da residência"
              onChange={(e) => setStreetNumber(e.target.value)}
              onBlur={(e) => {
                if (streetNumber.length) {
                  setStreetNumberInvalid(false);
                } else {
                  setStreetNumberInvalid(true);
                }
              }}
              value={streetNumber.toLowerCase().replace(/[^0-9]/g, "")}
              prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            />

            {streetNumberInvalid && <InvalidField fieldName="número" />}
          </LoginStyle.Item>

          {addressError && (
            <>
              <LoginStyle.Item>
                <Input
                  id="street"
                  type="street"
                  onFocus={() => {
                    setAddressInvalid((prevState) => ({
                      ...prevState,
                      street: false,
                    }));
                  }}
                  placeholder="Rua"
                  onChange={(e) =>
                    setAddress((prevState) => ({
                      ...prevState,
                      street: e.target.value,
                    }))
                  }
                  value={address?.street}
                  prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                />

                {addressInvalid?.street && <InvalidField fieldName="valor" />}
              </LoginStyle.Item>

              <LoginStyle.Item>
                <Input
                  id="neighborhood"
                  type="neighborhood"
                  onFocus={() => {
                    setAddressInvalid((prevState) => ({
                      ...prevState,
                      neighborhood: false,
                    }));
                  }}
                  placeholder="Bairro"
                  onChange={(e) =>
                    setAddress((prevState) => ({
                      ...prevState,
                      neighborhood: e.target.value,
                    }))
                  }
                  value={address?.neighborhood}
                  prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                />

                {addressInvalid?.neighborhood && (
                  <InvalidField fieldName="valor" />
                )}
              </LoginStyle.Item>

              <LoginStyle.Item>
                <Input
                  id="city"
                  type="city"
                  onFocus={() => {
                    setAddressInvalid((prevState) => ({
                      ...prevState,
                      city: false,
                    }));
                  }}
                  placeholder="Cidade"
                  onChange={(e) =>
                    setAddress((prevState) => ({
                      ...prevState,
                      city: e.target.value,
                    }))
                  }
                  value={address?.city}
                  prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                />

                {addressInvalid?.city && <InvalidField fieldName="valor" />}
              </LoginStyle.Item>

              <LoginStyle.Item>
                <Input
                  id="state"
                  type="state"
                  onFocus={() => {
                    setAddressInvalid((prevState) => ({
                      ...prevState,
                      state: false,
                    }));
                  }}
                  placeholder="Estado"
                  onChange={(e) =>
                    setAddress((prevState) => ({
                      ...prevState,
                      state: e.target.value,
                    }))
                  }
                  value={address?.state}
                  prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                />

                {addressInvalid?.state && <InvalidField fieldName="valor" />}
              </LoginStyle.Item>
            </>
          )}
        </>
      )}

      {create_account_options?.request_custom_fields_to_create_account &&
        create_account_options?.custom_fields_active?.map((field) => (
          <LoginStyle.Item key={field.label}>
            <Input
              id={field.name}
              type={field.name}
              onFocus={() => {
                setCustomFieldsInvalid((prevState) => ({
                  ...prevState,
                  [field.name]: false,
                }));
              }}
              placeholder={field.placeholder}
              onChange={(e) =>
                setCustomFields((prevState) => ({
                  ...prevState,
                  [field.name]: e.target.value,
                }))
              }
              value={customFields[field.name]}
              prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              onBlur={(e) => {
                if (field.required) {
                  const value = e.target.value;

                  if (!value.length)
                    return setCustomFieldsInvalid((prevState) => ({
                      ...prevState,
                      [field.name]: true,
                    }));

                  setCustomFieldsInvalid((prevState) => ({
                    ...prevState,
                    [field.name]: false,
                  }));
                }
              }}
            />

            {customFieldsInvalid[field.name] && (
              <InvalidField fieldName={field.label} />
            )}
          </LoginStyle.Item>
        ))}

      <PasswordInput
        FormItemComponent={LoginStyle.Item as any}
        InputComponent={Input}
        getValue={setPassword}
        passwordInvalid={passwordInvalid}
      />

      {settings?.users?.userSignUpDescriptionBottom ? (
        <div
          className="bottomDescription"
          dangerouslySetInnerHTML={{
            __html: settings?.users?.userSignUpDescriptionBottom,
          }}
        />
      ) : null}

      <LoginStyle.Item className="gx-text-center">
        <Button
          type="primary"
          className="primary"
          htmlType="submit"
          data-testid="btn-register"
          block
          size="large"
          disabled={
            !email ||
            !password ||
            !name ||
            emailInvalid ||
            passwordInvalid ||
            nameInvalid
          }
          onClick={handleOnSubmit}
        >
          {t("common:registerAndContinue")}
        </Button>
      </LoginStyle.Item>

      <button
        onClick={(e) => {
          e.preventDefault();
          navigate("login");
        }}
        type="button"
        className="link"
      >
        {t("common:alreadyHaveAccount")}
        <b style={{ marginLeft: "5px" }}>{t("common:login")}</b>
      </button>
    </LoginStyle>
  );
}

Login.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.any.isRequired,
  handleRegister: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default Login;
