import styled from "styled-components";

import { BasePageInfoSection } from "pages/[tenant]/console/styles/BasePageInfoSection";

export const DisabledPageContainer = styled(BasePageInfoSection)`
  max-width: 23.5rem; // 376px;

  svg {
    margin-bottom: 1.5rem;

    path {
      fill: ${({ theme }) => theme.console.sidebar.titleColor};
    }
  }
`;
