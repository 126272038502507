import React from 'react';
import useTranslation from 'next-translate/useTranslation';

import { Container, Text, Image } from './styles';

const NameInvalid = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Image />

      <Text>
        {t('common:notAValidFullName')}
      </Text>
    </Container>
  );
};

export default NameInvalid;
