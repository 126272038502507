import { Menu } from "antd";
import styled, { css } from "styled-components";

import { SettingOutlined } from "@ant-design/icons";

export const AppNotificationContainer = styled.div`
  cursor: default;
  padding-bottom: 2rem;

  .container-loader {
    padding: 10px 15px;
  }

  .gx-sub-popover {
    margin: 0;

    li {
      margin: 0;
      padding: 1.25rem 1rem 0.875rem 1rem;
    }
  }

  .gx-popover-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
    background-color: ${({ theme }) =>
      theme.colors.globalTopbar.solidBackground};

    position: sticky;
    z-index: 1;
    top: 0;

    h3 {
      margin: 0;
      padding-left: 1rem;

      color: ${({ theme }) => theme.colors.textColor};
    }
  }

  .no-more-notifications {
    color: ${({ theme }) => theme.colors.textColor};
    text-align: center;
    padding: 1rem 0;
    opacity: 0.7;
  }
`;

export const ContainerEmptyData = styled.div`
  @media (min-width: 700px) {
    height: 73vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const MenuAnt = styled(Menu)`
  ${({ theme }) =>
    theme.mode === "dark"
      ? css`
          background: #303030;

          li {
            background: #303030;
          }

          button,
          i {
            background: #303030 !important;
            color: #ffffff;
          }
        `
      : css`
          background: #ffffff;
        `}
`;
