import { ITenantTheme } from "shared/styles/Theme";

import { getColorsFromTopbarThemes } from "./getColorsFromTopbarThemes";

interface IGetColorsFromSidebarThemesReturn {
  background: string;
  itemColor: string;
  colapseIconColor: string;
}

export function getColorsFromSidebarThemes(
  tenant: ITenantTheme,
): IGetColorsFromSidebarThemesReturn {
  const topbarColors = getColorsFromTopbarThemes(tenant);

  const themeBackground = {
    advanced: tenant?.themeSideBarBackgroundColor,
    dark: "#121212",
    light: "#ffffff",
    automatic: topbarColors.background,
  };

  const themeItemColors = {
    advanced: tenant?.themeSideBarItemColor,
    dark: "#ffffff",
    light: "#545454",
    automatic: topbarColors.color,
  };

  const themeColapseIconColor = {
    advanced: tenant?.themeSideBarColapseIconColorColor,
    dark: "#ffffff",
    light: "#545454",
    automatic: topbarColors.color,
  };

  return {
    background: themeBackground[tenant?.themeSideBar],
    itemColor: themeItemColors[tenant?.themeSideBar],
    colapseIconColor: themeColapseIconColor[tenant?.themeSideBar],
  };
}
