import React from "react";

export const DesignIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3993 2.09312C11.7505 1.86743 10.0714 2.05351 8.51196 2.63474C6.95252 3.21598 5.56125 4.17428 4.46235 5.42412C3.52185 6.5073 2.82403 7.7792 2.41577 9.15439C2.00751 10.5296 1.89825 11.9762 2.09535 13.3971C2.62535 17.2911 5.55335 20.6041 9.38035 21.6431C10.2335 21.8766 11.1139 21.9956 11.9983 21.9971L12.1403 21.9961C12.6459 21.9896 13.1416 21.8555 13.5815 21.6062C14.0213 21.3569 14.391 21.0005 14.6563 20.5701C14.9225 20.1421 15.0762 19.6536 15.1029 19.1503C15.1297 18.6469 15.0287 18.145 14.8093 17.6911L14.6103 17.2751C14.4573 16.9761 14.3854 16.6421 14.4019 16.3066C14.4184 15.971 14.5227 15.6457 14.7043 15.3631C14.9643 14.9409 15.3715 14.63 15.8473 14.4906C16.323 14.3511 16.8336 14.393 17.2803 14.6081L17.6923 14.8051C18.1043 15.0031 18.5423 15.1041 18.9933 15.1041C19.7826 15.1001 20.539 14.7874 21.1007 14.2329C21.6625 13.6785 21.985 12.9263 21.9993 12.1371C22.0117 11.2051 21.8929 10.276 21.6463 9.37712C20.6063 5.55112 17.2933 2.62312 13.3993 2.09312ZM18.5573 13.0021L18.1453 12.8051C16.3173 11.9271 14.0753 12.6071 13.0103 14.2991C12.2723 15.4751 12.1973 16.8751 12.8063 18.1411L13.0053 18.5571C13.0799 18.7082 13.1144 18.876 13.1055 19.0443C13.0966 19.2126 13.0445 19.3757 12.9543 19.5181C12.8665 19.6636 12.7427 19.784 12.5949 19.8679C12.4471 19.9517 12.2803 19.9963 12.1103 19.9971H11.9983C11.2906 19.9952 10.5862 19.9 9.90335 19.7141C6.84035 18.8831 4.50035 16.2351 4.07735 13.1281C3.75635 10.7731 4.42935 8.50512 5.97035 6.73912C6.84709 5.73335 7.9614 4.96277 9.21191 4.4975C10.4624 4.03222 11.8094 3.88703 13.1303 4.07512C16.2373 4.49812 18.8853 6.83912 19.7163 9.90112C19.9143 10.6311 20.0093 11.3751 19.9983 12.1081C19.9863 12.9151 19.1533 13.2911 18.5573 13.0021Z"
        fill="currentColor"
      />
      <path
        d="M7.5 16C8.32843 16 9 15.3284 9 14.5C9 13.6716 8.32843 13 7.5 13C6.67157 13 6 13.6716 6 14.5C6 15.3284 6.67157 16 7.5 16Z"
        fill="currentColor"
      />
      <path
        d="M7.5 12C8.32843 12 9 11.3284 9 10.5C9 9.67157 8.32843 9 7.5 9C6.67157 9 6 9.67157 6 10.5C6 11.3284 6.67157 12 7.5 12Z"
        fill="currentColor"
      />
      <path
        d="M10.5 9C11.3284 9 12 8.32843 12 7.5C12 6.67157 11.3284 6 10.5 6C9.67157 6 9 6.67157 9 7.5C9 8.32843 9.67157 9 10.5 9Z"
        fill="currentColor"
      />
      <path
        d="M14.5 9C15.3284 9 16 8.32843 16 7.5C16 6.67157 15.3284 6 14.5 6C13.6716 6 13 6.67157 13 7.5C13 8.32843 13.6716 9 14.5 9Z"
        fill="currentColor"
      />
    </svg>
  );
};
