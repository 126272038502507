import emailConfirmation from "./emailConfirmation";
import forgotPassword from "./forgotPassword";
import getToken from "./getToken";

const auth = {
  emailConfirmation,
  forgotPassword,
  getToken,
};

export default auth;
