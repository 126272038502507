import React from "react";

import Link from "next/link";

import useGroupContext from "pages/[tenant]/g/hooks/useGroupContext";

import { PlayIcon } from "shared/providers/Layout/components/NewTopBar/assets/PlayIcon";

import { MyGroupsListContainer } from "./styles";

interface IMyGroupsListProps {
  triggerOnLinkClick: () => void;
  rightPlayIcon?: boolean;
}

export const MyGroupsList = ({
  triggerOnLinkClick,
  rightPlayIcon,
}: IMyGroupsListProps) => {
  const { myGroups, hasErrorInMyGroupsRequest } = useGroupContext();

  const userHasGroups = myGroups?.length;

  if (!userHasGroups || hasErrorInMyGroupsRequest) return null;
  return (
    <MyGroupsListContainer>
      {myGroups.map((group) => (
        <li key={group.id}>
          <Link href={`/g/${group.slug}`} onClick={triggerOnLinkClick}>
            {group.title}
            {rightPlayIcon && <PlayIcon />}
          </Link>
        </li>
      ))}
    </MyGroupsListContainer>
  );
};
