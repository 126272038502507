import styled, { css } from "styled-components";

export const CertificateWidgetsContainer = styled.div<{
  certificateAvailable: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: ${(props) => props.theme.groups.backgroundComponentsMural};
  border: 1px solid ${({ theme }) => theme.groups.borderColorComponentsMural};
  border-radius: 4px;
  padding: 1rem;
  height: fit-content;

  .infos {
    display: flex;
    align-items: center;

    svg {
      position: absolute;
      width: 3rem !important;
      height: 3rem !important;
    }

    .text {
      margin-left: 4rem;
    }
  }

  .title {
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    color: ${(props) => props.theme.groups.primaryText} !important;
  }

  .subtitle {
    margin-bottom: 0;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    color: ${(props) => props.theme.groups.secondaryText} !important;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: none;
    border-radius: 4px;
    font-size: 0.875rem;
    ${({ certificateAvailable }) =>
      certificateAvailable
        ? css`
            cursor: pointer;
            background-color: #e50000;
            :hover {
              background-color: #e50000cc;
            }
          `
        : css`
            cursor: not-allowed;
            background-color: #2b2c32;
          `}
    padding: 0.5rem;
    font-style: normal;
    font-weight: 600;
    color: #f2f2f2;
    transition: all ease-in-out 0.2s;
  }

  .loader {
    animation: loading 2s linear infinite;
    font-size: 1.3rem;
    margin: 1px 13px;
  }

  @keyframes loading {
    to {
      transform: rotate(0);
    }
    from {
      transform: rotate(360deg);
    }
  }
`;
