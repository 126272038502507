import React from "react";

export const OffersIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.764 1.98327C12.6452 1.78629 12.4777 1.62324 12.2775 1.50985C12.0774 1.39647 11.8514 1.33657 11.6214 1.33594H4.37603C3.9107 1.33594 3.4727 1.58394 3.23337 1.98327L1.42736 4.9926C1.36487 5.09621 1.3319 5.21494 1.33203 5.33594C1.33469 5.97941 1.57169 6.59988 1.9987 7.08127V12.6693C1.9987 13.4046 2.5967 14.0026 3.33203 14.0026H12.6654C13.4007 14.0026 13.9987 13.4046 13.9987 12.6693V7.08127C14.4257 6.59988 14.6627 5.97941 14.6654 5.33594C14.6655 5.21494 14.6325 5.09621 14.57 4.9926L12.764 1.98327ZM13.3214 5.50327C13.2802 5.82515 13.1233 6.12103 12.8798 6.33562C12.6364 6.55021 12.3232 6.66881 11.9987 6.66927C11.2634 6.66927 10.6654 6.07127 10.6654 5.33594C10.6654 5.2906 10.6487 5.2506 10.6394 5.20794L10.6527 5.20527L10.1454 2.66927H11.6214L13.3214 5.50327ZM7.21137 2.66927H8.78537L9.32737 5.37927C9.30403 6.09394 8.7187 6.66927 7.9987 6.66927C7.2787 6.66927 6.69337 6.09394 6.67003 5.37927L7.21137 2.66927ZM4.37603 2.66927H5.85203L5.34537 5.20527L5.3587 5.20794C5.3487 5.2506 5.33203 5.2906 5.33203 5.33594C5.33203 6.07127 4.73403 6.66927 3.9987 6.66927C3.6742 6.66881 3.36097 6.55021 3.11755 6.33562C2.87413 6.12103 2.71718 5.82515 2.67603 5.50327L4.37603 2.66927ZM6.66537 12.6693V10.6693H9.33203V12.6693H6.66537ZM10.6654 12.6693V10.6693C10.6654 9.93394 10.0674 9.33594 9.33203 9.33594H6.66537C5.93003 9.33594 5.33203 9.93394 5.33203 10.6693V12.6693H3.33203V7.90794C3.54603 7.96327 3.7667 8.0026 3.9987 8.0026C4.37724 8.0028 4.75147 7.9222 5.09637 7.76619C5.44127 7.61019 5.7489 7.38236 5.9987 7.09794C6.48737 7.6526 7.20337 8.0026 7.9987 8.0026C8.79403 8.0026 9.51003 7.6526 9.9987 7.09794C10.2485 7.38236 10.5561 7.61019 10.901 7.76619C11.2459 7.9222 11.6202 8.0028 11.9987 8.0026C12.2307 8.0026 12.4514 7.96327 12.6654 7.90794V12.6693H10.6654Z"
        fill="currentColor"
      />
    </svg>
  );
};
