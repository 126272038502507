import React from "react";

export const DisabledFeatureIcon = () => {
  return (
    <svg
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1629 3.875H5.40402C4.97679 3.875 4.62723 4.22455 4.62723 4.65179V19.4107C4.62723 19.8379 4.97679 20.1875 5.40402 20.1875H20.1629C20.5902 20.1875 20.9397 19.8379 20.9397 19.4107V4.65179C20.9397 4.22455 20.5902 3.875 20.1629 3.875ZM17.6384 16.8862H7.92857V7.17634H17.6384V16.8862ZM39.5826 3.875H24.8237C24.3964 3.875 24.0469 4.22455 24.0469 4.65179V19.4107C24.0469 19.8379 24.3964 20.1875 24.8237 20.1875H39.5826C40.0098 20.1875 40.3594 19.8379 40.3594 19.4107V4.65179C40.3594 4.22455 40.0098 3.875 39.5826 3.875ZM37.058 16.8862H27.3482V7.17634H37.058V16.8862ZM39.5826 23.2946H24.8237C24.3964 23.2946 24.0469 23.6442 24.0469 24.0714V38.8304C24.0469 39.2576 24.3964 39.6071 24.8237 39.6071H39.5826C40.0098 39.6071 40.3594 39.2576 40.3594 38.8304V24.0714C40.3594 23.6442 40.0098 23.2946 39.5826 23.2946ZM37.058 36.3058H27.3482V26.596H37.058V36.3058ZM18.221 31.4509H12.0067V25.2366C12.0067 25.023 11.8319 24.8482 11.6183 24.8482H9.28795C9.07433 24.8482 8.89955 25.023 8.89955 25.2366V31.4509H2.68527C2.47165 31.4509 2.29688 31.6257 2.29688 31.8393V34.1696C2.29688 34.3833 2.47165 34.558 2.68527 34.558H8.89955V40.7723C8.89955 40.9859 9.07433 41.1607 9.28795 41.1607H11.6183C11.8319 41.1607 12.0067 40.9859 12.0067 40.7723V34.558H18.221C18.4346 34.558 18.6094 34.3833 18.6094 34.1696V31.8393C18.6094 31.6257 18.4346 31.4509 18.221 31.4509Z"
        fill="currentColor"
      />
    </svg>
  );
};
