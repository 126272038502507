import React, { useEffect } from "react";

import { MoonIcon } from "shared/providers/Layout/components/NewTopBar/assets/MoonIcon";
import { SunIcon } from "shared/providers/Layout/components/NewTopBar/assets/SunIcon";
import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";

import { SwitchThemeContainer } from "./styles";

const saveThemeInLocalStorage = (theme: string) =>
  localStorage.setItem("theme_mode", theme);

const switchThemeMap = {
  light: "dark",
  dark: "light",
};

interface ISwitchThemeProps {
  withLabel?: boolean;
  disabled?: boolean;
}

export const SwitchTheme = ({
  withLabel = true,
  disabled,
}: ISwitchThemeProps) => {
  const {
    state: { theme },
    setState,
  } = useGlobalContext();

  const { bothModesEnabled } = theme;
  const isDarkMode = theme.value === "dark";

  const handleClick = () => {
    if (disabled) return null;

    const currentTheme = theme.value;

    saveThemeInLocalStorage(currentTheme);
    setState((prev) => ({
      ...prev,
      theme: {
        ...prev.theme,
        value: switchThemeMap[currentTheme] as keyof typeof switchThemeMap,
      },
    }));
  };

  useEffect(() => {
    const currentTheme = theme.value;

    saveThemeInLocalStorage(currentTheme);
  }, [theme]);

  if (!bothModesEnabled) return;
  return (
    <SwitchThemeContainer
      onClick={handleClick}
      className="switch-theme"
      disabled={disabled}
    >
      {withLabel && (isDarkMode ? "Modo claro" : "Modo escuro")}

      {isDarkMode ? <SunIcon /> : <MoonIcon />}
    </SwitchThemeContainer>
  );
};
