import styled, { css } from "styled-components";

export const RegisterModalContainer = styled.div`
  width: 100%;
  height: 100%;

  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  animation: appearFromLeft 0.6s;

  .ant-legacy-form-item {
    margin-bottom: 15px;
  }

  img {
    object-fit: contain;
  }

  .col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100%;
    min-height: 450px;

    h1 {
      font-family: Poppins;
      font-style: normal;
      font-weight: bold;
    }

    h1.siteName {
      font-size: 24px;
      text-align: center;
      margin: -20px 0 20px 0;
    }

    h2,
    span,
    p,
    a {
      font-style: normal;
      font-family: Roboto;
    }

    .ant-alert.ant-alert-error {
      .ant-alert-close-icon {
        height: 30px;
        margin-top: -5.5px;
      }

      .ant-alert-description {
        margin: 0px 5px 0px -2px;
      }
    }

    button.link {
      border: none;
      cursor: pointer;
      background: transparent !important;
      background-color: transparent !important;

      font-family: Roboto;
      font-size: 16px;
      margin: 6px 0 0 0;
    }

    img {
      max-width: 250px;
      max-height: 90px;
    }

    div.titulos {
      text-align: center;
      margin: 24px 0 24px 0;

      h1 {
        color: ${({ theme }) => theme.colors.textColor} !important;
        font-style: normal;
        font-weight: 600;
        font-size: 29px;
        line-height: 115%;
        letter-spacing: -0.5px;
        margin-bottom: 10px;
      }

      h2 {
        color: ${({ theme }) => theme.colors.textColor} !important;
        font-size: 16px;
        line-height: 132.5%;
        letter-spacing: -0.5px;
        margin: 0;
      }

      img {
        object-fit: contain;
      }
    }

    .bottomDescription {
      text-align: center;
      margin: 10px 0 0 0;
      font-size: 12px;

      a {
        color: inherit;
        text-decoration: inherit;
        font-weight: bolder;
      }
    }

    p.terms-text {
      padding: 0 120px;

      text-align: center;
    }

    @media (max-width: 1400px) {
      margin: 0;

      div.titulos {
        margin: 40px 0 20px 0;
      }

      button.link {
        margin: 5px 0 0 0;
      }
    }
  }

  @keyframes appearFromLeft {
    from {
      opacity: 0;
      transform: translateX(-5px);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;
