import styled from "styled-components";

export const ListAllGroupsContainer = styled.div`
  display: flex;
  flex-direction: column;

  grid-column: 3;

  background: ${({ theme }) => theme.groups.backgroundComponentsMural};
  border: 1px solid ${({ theme }) => theme.groups.borderColorComponentsMural};
  border-radius: 4px;
  padding: 1rem 0 1rem 1rem;
  height: fit-content;

  @media (max-width: 1400px) {
    display: none;
  }

  p {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.6875rem;
    color: ${({ theme }) => theme.groups.primaryTextTopbarGroups};
    margin-bottom: 0.75rem;
  }
`;
