import { captureException } from '@sentry/browser';
import { kv } from '@vercel/kv';
import logToTenancyMonitoring from './logToTenancyMonitoring';
const buffer = require("buffer").Buffer;

function checkIsDev(hostname) {
  return /ngrok|loca.lt|localhost|dev.ensinio.com|ensinio-next.vercel.app/.test(hostname);
}

function checkIsQA(hostname) {
  const uuidFromQuery = hostname.replace(".vercel.app", "").replace(".ensinio.com", "");

  return ['qa1', 'qa2', 'qa3'].includes(uuidFromQuery);
}

function getUUID(hostname) {
  const uuidFromQuery = hostname.replace(".vercel.app", "").replace(".ensinio.com", "");
  const isDevEnvironment = checkIsDev(hostname);
  if (isDevEnvironment) return 'dev';

  return uuidFromQuery;
}

function getTenantEndpoint(hostname) {
  const uuid = getUUID(hostname);
  const isDevEnvironment = checkIsDev(hostname);
  const isQAEnvironment = checkIsQA(hostname);

  if (isDevEnvironment) return 'ensiniodev.com';
  if (isQAEnvironment) return `ensinio${uuid}.click`;
  return 'ensinio.cloud';
}

function convertToBase64(hostname) {
  return buffer.from(hostname).toString("base64");
}

async function fetchTenant({ hostname, systemEndpoint, clientIp, tenantSlug }) {
  if (hostname?.includes("ensinio.com")) {
    const uuid = getUUID(hostname);
    const tenant = await kv.get(`${uuid}_tenancy`);
    if (tenant) return tenant;
  }

  if (tenantSlug && !tenantSlug.includes('browse')) {
    const tenant = await kv.get(`${tenantSlug}_tenancy`);
    if (tenant) return tenant;
  }

  let hostnameInBase64 = "";

  try {
    hostnameInBase64 = convertToBase64(hostname);

    const response = await fetch(
      `https://${systemEndpoint}/api/v1/ten?host=${hostnameInBase64}`,
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/x-www-form-urlencoded",
          "X-Requested-With": "XMLHttpRequest",
          ...(clientIp && { "X-Forwarded-For": clientIp }),
        }),
      },
    );

    const tenantData = await response.json();

    await kv.set(`${tenantData?.data?.uuid}_tenancy`, tenantData, { ex: 120 });

    return tenantData;
  } catch (error) {
    const dataToTenancyMonitoring = [
      `\n *Hostname:* ${hostname}`,
      `\n *IP do usuário:* ${clientIp}`,
      `\n *Valor de 'hostnameInBase64':* ${hostnameInBase64}`,
      `\n *🔴 Erro:* \`\`\`${error}\`\`\``,
    ];

    logToTenancyMonitoring(dataToTenancyMonitoring);
    captureException(error);
  }
}

export async function getPlatformTenant(hostname, clientIp?, tenantSlug?) {
  const systemEndpoint = getTenantEndpoint(hostname);

  const tenancy = await fetchTenant({ hostname, systemEndpoint, clientIp, tenantSlug });

  const uuid = tenancy?.data?.uuid;

  return { ...tenancy, uuid };
}
