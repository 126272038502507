import React from "react";

import Link from "next/link";

import { SearchIcon } from "shared/providers/Layout/components/NewTopBar/assets/SearchIcon";
import { UserMenu } from "shared/providers/Layout/components/NewTopBar/components/atoms/UserMenu";
import { useTopbarContext } from "shared/providers/Layout/hooks/useTopbarContext";
import { useFeature } from "shared/utils/hooks/useFeature";

import { OptionsContainer } from "./styles";

export const Options = () => {
  const { scrollIsOnTop, pageIsLoading } = useTopbarContext();

  const isSearchEnabled = useFeature("f_search");

  return (
    <OptionsContainer
      buttonsShouldHaveBackground={scrollIsOnTop || pageIsLoading}
    >
      {isSearchEnabled && (
        <Link href="/search" className="search">
          <SearchIcon />
        </Link>
      )}

      <UserMenu />
    </OptionsContainer>
  );
};
