import React from "react";

import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";

export const OptionArrowIcon = () => {
  const {
    state: { theme },
  } = useGlobalContext();
  const isDarkMode = theme.value === "dark";

  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4930_20798)">
        <path
          d="M1 7L17 7"
          stroke={isDarkMode ? "#F2F2F2" : "#050505bf"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 1.16602L16.8333 6.99935L11 12.8327"
          stroke={isDarkMode ? "#F2F2F2" : "#050505bf"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4930_20798">
          <rect width="18" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
