import React, { useEffect } from "react";

import { useRouter } from "next/router";

import { useConsolePageConfig } from "pages/[tenant]/console/hooks/useConsolePageConfig";
import DropDownGroups from "pages/[tenant]/g/components/ContextTopbar/components/DropDownGroups";
import { GroupNavigation } from "pages/[tenant]/g/components/GroupNavigation";

import { GlobalTopbarHeader } from "shared/providers/Layout/components/NewTopBar/components/atoms/GlobalTopbarHeader";
import { Logo } from "shared/providers/Layout/components/NewTopBar/components/atoms/Logo";
import { MenusList } from "shared/providers/Layout/components/NewTopBar/components/atoms/MenusList";
import { Hamburguermenu } from "shared/providers/Layout/components/NewTopBar/components/molecules/HamburguerMenu";
import { Options } from "shared/providers/Layout/components/NewTopBar/components/molecules/Options";
import { useTopbarContext } from "shared/providers/Layout/hooks/useTopbarContext";
import useDebounce from "shared/utils/hooks/useDebounce";
import useWindowSize from "shared/utils/hooks/useWindowSize";

import {
  NewTopBarContainer,
  SideOptionsContainer,
  TopBarBackground,
} from "./styles";

export const NewTopBar = () => {
  const { setScrollIsOnTop, scrollIsOnTop, pageIsLoading } = useTopbarContext();
  const { isInConsolePage } = useConsolePageConfig();

  const { asPath, pathname } = useRouter();
  const currentPath = asPath.split("/")[1];

  const { width } = useWindowSize();
  const isMobile = width <= 850;

  const isInGroupRoute = pathname.includes("/[tenant]/g/[group_slug]");
  const isInAboutGroup = pathname.includes("/[tenant]/g/[group_slug]/about");

  const shouldShowGroupNavigationComponents = isInGroupRoute && !isInAboutGroup;

  const debouncedFunction = useDebounce();
  const checkIfScrollIsInTop = () => {
    const scrollLimitToTrigger = 130;
    const scrollIsBelowTop = window.scrollY <= scrollLimitToTrigger;

    if (scrollIsBelowTop) setScrollIsOnTop(true);
    else setScrollIsOnTop(false);
  };

  const handleScroll = () => debouncedFunction(checkIfScrollIsInTop, 10);

  useEffect(() => {
    if (!isMobile && !isInConsolePage)
      window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const pageHaveMedia = currentPath === "browse";

  if (isInConsolePage) return null;
  return (
    <>
      <TopBarBackground
        shouldHaveBackground={!scrollIsOnTop || !pageHaveMedia}
        isMobile={isMobile}
      >
        <NewTopBarContainer
          shouldHaveBackground={scrollIsOnTop}
          pageHaveMedia={pageHaveMedia}
          isMobile={isMobile}
          pageIsLoading={pageIsLoading}
        >
          {isMobile ? (
            <>
              {shouldShowGroupNavigationComponents ? (
                <DropDownGroups />
              ) : (
                <GlobalTopbarHeader />
              )}
            </>
          ) : (
            <>
              <SideOptionsContainer scrollIsOnTop={scrollIsOnTop}>
                <Hamburguermenu />
                <MenusList shouldLimitMenuItensLength={true} />
              </SideOptionsContainer>

              <Logo />

              <Options />
            </>
          )}
        </NewTopBarContainer>
      </TopBarBackground>
      {shouldShowGroupNavigationComponents && <GroupNavigation />}
    </>
  );
};
