import styled from "styled-components";

export const ModalContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 9999;

  background: #000000aa;
  backdrop-filter: blur(10px);

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  border-radius: 4px;
  padding: 30px;
  gap: 20px;

  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 450px;
`;

export const Title = styled.h2`
  font-size: 26px;
  font-weight: 600;
  color: #fff;

  svg {
    bottom: -5px;
    position: relative;
  }
`;

export const Description = styled.p`
  font-size: 18px;
  color: #fff;
`;

export const CloseBtn = styled.button`
  border: none;
  background: none;
  position: absolute;
  top: 25px;
  right: 25px;

  svg {
    color: #fff;
    font-size: 40px;
  }
`;
