import React, { useState } from 'react';

import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';

import { Container } from './styles';

const Input = ({ prefix, type, ...rest }) => {
  const [passwordIsHidden, setPasswordIsHidden] = useState(true);
  const [inputType, setInputType] = useState(type || 'text');

  const handleChangePasswordViewMode = () => {
    setPasswordIsHidden(!passwordIsHidden);
    setInputType(passwordIsHidden ? 'text' : 'password');
  };

  return (
    <Container>
      <input type={inputType} {...rest} autoComplete="off" />
      <span className="inputOptions">
        {prefix && <span className="prefix">{prefix}</span>}{' '}
        {type === 'password' &&
          (passwordIsHidden ? (
            <AiOutlineEyeInvisible
              className="eyePassword"
              onClick={handleChangePasswordViewMode}
            />
          ) : (
            <AiOutlineEye
              className="eyePassword"
              onClick={handleChangePasswordViewMode}
            />
          ))}
      </span>
    </Container>
  );
};

export default Input;
