import styled from "styled-components";

import { mobileFeatureNotInPlanTemplateBreakpoint } from "pages/[tenant]/console/utils/layoutUtils";

export const TemplateFeatureNotInPlanContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 1rem;

  @media (max-width: ${mobileFeatureNotInPlanTemplateBreakpoint}px) {
    padding-top: 1.5rem;
  }

  a {
    display: block;
    width: fit-content;

    @media (max-width: ${mobileFeatureNotInPlanTemplateBreakpoint}px) {
      width: 100%;
    }
  }

  .top-content {
    display: flex;
    gap: 4rem;
    align-items: center;
    margin-bottom: 4rem;

    @media (max-width: ${mobileFeatureNotInPlanTemplateBreakpoint}px) {
      gap: 1rem;
      margin-bottom: 2rem;
      flex-direction: column-reverse;
    }
  }

  .texts {
    max-width: 27.1875rem; // 435px

    @media (max-width: ${mobileFeatureNotInPlanTemplateBreakpoint}px) {
      max-width: initial;
      text-align: center;
    }
  }
`;
