import styled, { css } from "styled-components";

interface IDropDownGroupsContainerProps {
  showMenuGroupList?: boolean;
}

export const DropDownGroupsContainer = styled.div<IDropDownGroupsContainerProps>`
  padding: 0.4rem 1rem;
  border-radius: 4px;

  background-color: ${({ theme }) =>
    theme.groups.contextualMenuBackgroundColor};

  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  justify-content: space-between;
  transition: 0.3s all;

  z-index: 10;

  .arrow-down-mobile {
    display: none;
  }

  @media (max-width: 850px) {
    background-color: ${(props) =>
      props.theme.colors.globalTopbar.solidBackground} !important;

    flex-direction: row-reverse;
    justify-content: flex-end;

    border-radius: 0px;

    .arrow-down-desktop {
      display: none;
    }

    .arrow-down-mobile {
      display: initial;
    }
  }

  :hover {
    cursor: pointer;
    background: ${(props) =>
      props.theme.groups.backgroundOptionsMenuTopBarHover};
  }

  ${({ showMenuGroupList }) =>
    showMenuGroupList &&
    css`
      background: ${(props) =>
        props.theme.groups.backgroundOptionsMenuTopBarHover};
    `}

  svg {
    transition: 0.3s all;

    ${({ showMenuGroupList }) =>
      showMenuGroupList &&
      css`
        transform: rotate(180deg);
        background: ${(props) =>
          props.theme.groups.backgroundOptionsMenuTopBarHover};
      `}

    &.arrow-down-mobile path {
      stroke: ${({ theme }) => theme.groups.contextualMenuIconColor};
    }

    &.arrow-down-desktop path {
      stroke: ${({ theme }) => theme.groups.contextualMenuIconColor};
      fill: ${({ theme }) => theme.groups.contextualMenuIconColor};
    }
  }

  #group-info {
    .title {
      max-width: 16rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .title {
      font: 600 1rem/1.5rem "Inter";
      color: ${({ theme }) => theme.groups.groupTopbarTabActiveColor};
    }
  }
`;
