import React, { memo } from "react";

import Image from "next/image";

import useTranslation from "next-translate/useTranslation";

import ensinioBrandIcon from "shared/assets/images/ensinio_logo.svg";
import ensinioBrandIconDark from "shared/assets/images/ensinio_logo_dark_new.svg";
import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";
import useWindowSize from "shared/utils/hooks/useWindowSize";

import { EnsinioBrandContainer } from "./styles";

const EnsinioBrand = () => {
  const { t } = useTranslation();
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 800;

  const { state } = useGlobalContext();

  const generalTheme = state.tenancy?.settings["general-theme"];
  const isDarkMode = state.theme.value;

  const showEnsinioBrand = generalTheme?.themeOthersShowEnsinioBrand === "1";

  if (!showEnsinioBrand) return null;
  return (
    <EnsinioBrandContainer
      href="https://ensinio.com"
      target="_blank"
      isMobile={isMobile}
    >
      <p>{t("common:ensinioBrandPlataformMadeWith")}</p>

      <Image
        src={isDarkMode ? ensinioBrandIconDark : ensinioBrandIcon}
        alt="ensinio_logo"
        height={18}
        width={62}
      />
    </EnsinioBrandContainer>
  );
};

export default memo(EnsinioBrand);
