import { useCallback, useEffect, useState } from "react";

import { useRouter } from "next/router";

import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";

import useModulesDragAndDrop from "pages/[tenant]/console/groups/[group_slug]/modules/hooks/useModulesDragAndDrop";
import useModulesPageContext from "pages/[tenant]/console/groups/[group_slug]/modules/hooks/useModulesPageContext";
import useModulesPageService from "pages/[tenant]/console/groups/[group_slug]/modules/hooks/useModulesPageService";

import CircularLoader from "shared/components/atoms/CircularLoader";
import InfinityScroll from "shared/components/atoms/InfinityScroll";

import ModulesListItem from "../ModulesListItem";
import { ModulesListContainer } from "./styles";

const ModulesList = () => {
  const { query } = useRouter();
  const { modules, group, clearAllStates } = useModulesPageContext();
  const { onDragEnd } = useModulesDragAndDrop();
  const { getModulesObserver, isModulesLastPage, isModulesLoading, getGroup } =
    useModulesPageService();

  const [isDragging, setIsDragging] = useState(false);

  const shouldActivateObserver = !isModulesLastPage && !isModulesLoading;
  const onDragStart = useCallback(() => setIsDragging(true), []);

  const handleDragDrop = useCallback(
    (r: DropResult) => {
      onDragEnd(r);
      setIsDragging(false);
    },
    [onDragEnd],
  );

  useEffect(() => {
    const controller = new AbortController();
    if (query?.group_slug) getGroup(controller);

    return () => {
      controller.abort();
      if (clearAllStates) clearAllStates();
    };
  }, [query?.group_slug]);

  if (!group) return null;
  return (
    <ModulesListContainer>
      <DragDropContext onDragEnd={handleDragDrop} onDragStart={onDragStart}>
        <Droppable droppableId="module-list">
          {(listProvided) => (
            <ul {...listProvided.droppableProps} ref={listProvided.innerRef}>
              {modules?.map((module, index) => (
                <ModulesListItem
                  key={module?.id}
                  index={index}
                  module={module}
                  isDragging={isDragging}
                />
              ))}

              {shouldActivateObserver && (
                <InfinityScroll fetchMore={getModulesObserver} />
              )}

              {isModulesLoading && <CircularLoader />}
              {listProvided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </ModulesListContainer>
  );
};

export default ModulesList;
