import React from "react";

import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";

import Modal from "./modal";

function Denounce() {
  const { state, setState } = useGlobalContext();

  const show = state.denounceModal?.show;
  const contentType = state.denounceModal?.contentType;
  const entityId = state.denounceModal?.entityId;
  const reportedUserId = state.denounceModal?.reportedUserId;

  const handleVisible = () =>
    setState((prev) => ({ ...prev, denounceModal: { show: !show } }));

  return (
    <>
      {state.denounceModal?.show && (
        <Modal
          visible
          handleVisible={handleVisible}
          contentType={contentType}
          entityId={entityId}
          reportedUserId={reportedUserId}
        />
      )}
    </>
  );
}

export default Denounce;
