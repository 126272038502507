import React from "react";

import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";

export const ConfigIcon = () => {
  const {
    state: { theme },
  } = useGlobalContext();
  const isDarkMode = theme.value === "dark";

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.95333 12.7252L3.62 15.6085C3.73065 15.7998 3.91272 15.9392 4.12619 15.9963C4.33965 16.0533 4.56703 16.0233 4.75833 15.9127L5.92166 15.241C6.405 15.6218 6.93916 15.9343 7.50083 16.1677V17.4993C7.50083 17.7204 7.58863 17.9323 7.74491 18.0886C7.90119 18.2449 8.11315 18.3327 8.33416 18.3327H11.6675C11.8885 18.3327 12.1005 18.2449 12.2568 18.0886C12.413 17.9323 12.5008 17.7204 12.5008 17.4993V16.1677C13.0669 15.932 13.5982 15.6202 14.08 15.241L15.2433 15.9127C15.6408 16.1418 16.1525 16.0043 16.3817 15.6085L18.0483 12.7252C18.158 12.5337 18.1876 12.3066 18.1306 12.0934C18.0736 11.8802 17.9347 11.6981 17.7442 11.5868L16.6008 10.926C16.6902 10.3114 16.6896 9.68711 16.5992 9.07268L17.7425 8.41185C18.1392 8.18268 18.2767 7.67018 18.0467 7.27352L16.38 4.39018C16.2693 4.19893 16.0873 4.05945 15.8738 4.00241C15.6603 3.94537 15.433 3.97544 15.2417 4.08602L14.0783 4.75768C13.5971 4.378 13.066 4.0662 12.5 3.83102V2.49935C12.5 2.27834 12.4122 2.06637 12.2559 1.91009C12.0996 1.75381 11.8877 1.66602 11.6667 1.66602H8.33333C8.11231 1.66602 7.90035 1.75381 7.74407 1.91009C7.58779 2.06637 7.5 2.27834 7.5 2.49935V3.83102C6.93393 4.06669 6.40265 4.37845 5.92083 4.75768L4.75833 4.08602C4.66364 4.03116 4.55905 3.9955 4.45057 3.9811C4.34208 3.9667 4.23182 3.97383 4.1261 4.00208C4.02037 4.03033 3.92125 4.07915 3.83441 4.14574C3.74756 4.21234 3.6747 4.2954 3.62 4.39018L1.95333 7.27352C1.84365 7.46502 1.81408 7.6921 1.87105 7.9053C1.92802 8.11851 2.06692 8.30058 2.2575 8.41185L3.40083 9.07268C3.31094 9.68719 3.31094 10.3115 3.40083 10.926L2.2575 11.5868C1.86083 11.816 1.72333 12.3285 1.95333 12.7252ZM10 6.66602C11.8383 6.66602 13.3333 8.16102 13.3333 9.99935C13.3333 11.8377 11.8383 13.3327 10 13.3327C8.16166 13.3327 6.66666 11.8377 6.66666 9.99935C6.66666 8.16102 8.16166 6.66602 10 6.66602Z"
        fill={isDarkMode ? "#F2F2F2" : "#050505bf"}
      />
    </svg>
  );
};
