import React from "react";

import { Option } from "pages/[tenant]/console/components/atoms/Options/Option";
import { IOption } from "pages/[tenant]/console/types";

import { SubSectionContainer } from "./styles";

interface ISubSectionProps {
  section: {
    label: string;
    options: IOption[];
  };
}

export const SubSection = ({ section }: ISubSectionProps) => {
  const subSectionHasAtLeastOneOptionToShow = section?.options.some(
    (option) => !option.shouldHide,
  );

  if (subSectionHasAtLeastOneOptionToShow)
    return (
      <SubSectionContainer>
        <div className="subsection-label">{section.label}</div>

        <ul>
          {section?.options.map((option) => (
            <Option key={option.label} option={option} />
          ))}
        </ul>
      </SubSectionContainer>
    );
  return null;
};
