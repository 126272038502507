import React from "react";

import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";

export const MoonIcon = () => {
  const {
    state: { theme },
  } = useGlobalContext();
  const isDarkMode = theme.value === "dark";

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99914 9.83852C8.95062 8.78958 8.23655 7.45338 7.94717 5.99876C7.65778 4.54414 7.80608 3.03638 8.37331 1.66602C6.75603 1.98439 5.27047 2.77794 4.10664 3.94518C0.852474 7.19935 0.852474 12.476 4.10664 15.7302C7.36164 18.9852 12.6375 18.9843 15.8925 15.7302C17.0594 14.5665 17.8529 13.0813 18.1716 11.4643C16.8013 12.0315 15.2935 12.1797 13.8389 11.8903C12.3843 11.6009 11.0481 10.8869 9.99914 9.83852Z"
        fill={isDarkMode ? "#F2F2F2" : "#050505bf"}
      />
    </svg>
  );
};
