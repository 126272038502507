import { DefinitionIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/gamification/DefinitionIcon";
import { LevelsIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/gamification/LevelsIcon";
import { MissionsIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/gamification/MissionsIcon";
import { IOption, IOptionsHook } from "pages/[tenant]/console/types";

export const useGamificationOptions = (): IOptionsHook => {
  const sectionLabel = "Gamificação";
  const options: IOption[] = [
    {
      id: 1,
      label: "Definições",
      path: "/console/definitions",
      icon: <DefinitionIcon />,
      shouldHide: true,
    },
    {
      id: 2,
      label: "Missões",
      path: "/console/missions",
      icon: <MissionsIcon />,
      shouldHide: true,
    },
    {
      id: 3,
      label: "Níveis",
      path: "/console/levels",
      icon: <LevelsIcon />,
      shouldHide: true,
    },
  ];

  return {
    label: sectionLabel,
    options,
  };
};
