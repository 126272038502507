import styled from "styled-components";

import { MailOutlined } from "@ant-design/icons";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  border: 0.25rem solid #c9f1aa;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 2.25rem;
  text-align: center;
`;

export const Title = styled.h1`
  font-weight: 600 !important;
  font-size: 1.4rem;
  margin-bottom: 1.25rem;
  color: ${({ theme }) => theme.colors.textColor} !important;
`;

export const Description = styled.h2`
  color: ${({ theme }) => theme.colors.textColor} !important;
  font-size: 1rem;
  margin-bottom: 0.25rem;
`;

export const Icon = styled(MailOutlined)`
  margin-right: 0.75rem;

  svg {
    margin-top: 0.25rem;
  }
`;
