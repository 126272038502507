import React from "react";

export const ArchivedIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4694 3.52994L12.47 1.53127C12.4082 1.46924 12.3348 1.42005 12.2539 1.38653C12.173 1.353 12.0863 1.33581 11.9987 1.33594H3.9987C3.91114 1.33581 3.82441 1.353 3.74352 1.38653C3.66263 1.42005 3.58917 1.46924 3.52737 1.53127L1.52803 3.52994C1.46574 3.59172 1.41634 3.66526 1.3827 3.74628C1.34905 3.82731 1.33183 3.91421 1.33203 4.00194V12.6693C1.33203 13.4046 1.93003 14.0026 2.66537 14.0026H13.332C14.0674 14.0026 14.6654 13.4046 14.6654 12.6693V4.00194C14.6656 3.91421 14.6483 3.82731 14.6147 3.74628C14.5811 3.66526 14.5317 3.59172 14.4694 3.52994ZM4.2747 2.66927H11.7227L12.3887 3.33527H3.6087L4.2747 2.66927ZM2.66537 12.6693V4.6686H13.332L13.3334 12.6693H2.66537Z"
        fill="currentColor"
      />
      <path
        d="M9.9974 8.00521H5.9974V6.67188H4.66406V9.33854H11.3307V6.67188H9.9974V8.00521Z"
        fill="currentColor"
      />
    </svg>
  );
};
