import { Button } from "antd";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import { useContext } from "react";
import GlobalContext from "shared/providers/context/GlobalContext";

import Lock from "shared/assets/icons/Lock.svg";

import { Container } from "./styles";

function RegisterClose({ navigate }) {
  const { t } = useTranslation();
  const { state } = useContext(GlobalContext);

  const userSignUpClosedWarning =
    state.tenancy?.settings?.users?.userSignUpClosedWarning;

  return (
    <Container>
      <div className="titles">
        <Image
          src={Lock}
          alt="logo"
          width={160}
          height={90}
        />
        <section className="header-title">
          <h1>{t("common:registrationClosed")}</h1>

          <h3>{userSignUpClosedWarning}</h3>
        </section>

        <Button
          type="primary"
          onClick={(e) => {
            e.preventDefault();
            navigate("login");
          }}
        >
          {t("common:alreadyHaveAccount")}
        </Button>
      </div>
    </Container>
  );
}

export default RegisterClose;
