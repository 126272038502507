import { parseCookies } from "nookies";

const getUsernameFromCookies = (): string | null => {
  const username = parseCookies(null).username;

  if (username) {
    const parseToken = JSON.parse(username);
    return parseToken;
  }

  return null;
};

export default getUsernameFromCookies;
