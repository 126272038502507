import React from "react";

import Link from "next/link";

import { BiSolidRocket } from "react-icons/bi";

import { TopPageFlagContainer } from "./styles";

interface ITopPageFlagProps {
  backgroundColor: string;
  children: string;
  href: string;
}

export const TopPageFlag = ({
  backgroundColor,
  children,
  href,
}: ITopPageFlagProps) => {
  return (
    <>
      {!!href ? (
        <Link href={href}>
          <TopPageFlagContainer backgroundColor={backgroundColor}>
            <BiSolidRocket /> {children}
          </TopPageFlagContainer>
        </Link>
      ) : (
        <TopPageFlagContainer backgroundColor={backgroundColor}>
          <BiSolidRocket /> {children}
        </TopPageFlagContainer>
      )}
    </>
  );
};
