import styled, { css } from "styled-components";

import { consolePageMobileBreakpoint } from "pages/[tenant]/console/hooks/useConsolePageConfig";

import { LogoContainer } from "shared/providers/Layout/components/NewTopBar/components/atoms/Logo/styles";

interface ISectionsDropdownButtonProps {
  shouldRotateIcon: boolean;
}

export const SectionsDropdownButton = styled.button<ISectionsDropdownButtonProps>`
  @media (min-width: ${consolePageMobileBreakpoint}px) {
    display: none;
  }

  background-color: ${({ theme }) => theme.console.sidebar.backgroundColor};

  display: flex;
  gap: 0.75rem;
  justify-content: start;

  padding: 1rem 0.75rem;

  width: 100%;

  position: sticky;
  z-index: 9999999;

  color: ${({ theme }) => theme.console.sidebar.mobileSectionTextColor};
  font: 600 0.875rem/1.3125rem "Inter";

  border: none;

  :hover {
    cursor: pointer;
  }

  &,
  svg {
    transition: 0.3s all;
  }

  ${({ shouldRotateIcon }) =>
    shouldRotateIcon &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;

export const SideBarContentContainer = styled.div`
  position: fixed;
  top: 0;

  @media (max-width: ${consolePageMobileBreakpoint}px) {
    position: absolute;
    transition: 0.3s all;
    z-index: 10;

    background-color: ${({ theme }) => theme.console.sidebar.backgroundColor};

    transform: translateY(-130%);

    width: 100%;

    border-right: none;
    border-bottom: 1px solid ${({ theme }) => theme.console.sidebar.borderColor};
  }
`;

interface IConsolePageSideBarContainerProps {
  showMobileDropdown: boolean;
}

export const ConsolePageSideBarContainer = styled.div<IConsolePageSideBarContainerProps>`
  background: ${({ theme }) => theme.console.sidebar.backgroundColor};
  z-index: 9999999;
  height: 100%;

  border-right: 1px solid ${({ theme }) => theme.console.sidebar.borderColor};
  padding: 0.5rem;

  @media (max-width: ${consolePageMobileBreakpoint}px) {
    position: fixed;
    top: 0;

    padding: 0;

    width: 100%;
    height: fit-content;

    border-bottom: 1px solid ${({ theme }) => theme.console.sidebar.borderColor};

    ${({ showMobileDropdown }) =>
      showMobileDropdown &&
      css`
        ${SideBarContentContainer} {
          transform: translateY(57px);
        }
      `}
  }

  ${LogoContainer} {
    margin: 0.7rem auto;
    width: fit-content;
  }
`;

export const TopButtons = styled.div`
  display: flex;
  flex-direction: column;

  position: sticky;
  top: 0;

  @media (min-width: ${consolePageMobileBreakpoint}px) {
    align-items: center;
  }
`;

export const BottomButtons = styled.div`
  position: fixed;
  bottom: 0;

  @media (max-width: ${consolePageMobileBreakpoint}px) {
    position: initial;
  }
`;
