import styled, { css } from "styled-components";

import { consolePageMobileBreakpoint } from "pages/[tenant]/console/hooks/useConsolePageConfig";
import { BaseSideBarSectionButton } from "pages/[tenant]/console/styles/BaseSideBarSectionButton";

interface ISideBarLinkContainerProps {
  tooltipLabel: string;
  disabled: boolean;
}

export const SideBarLinkContainer = styled.div<ISideBarLinkContainerProps>`
  a {
    ${BaseSideBarSectionButton}
    color: ${({ theme }) => theme.console.sidebar.optionTextColor};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
      a:hover {
        cursor: not-allowed !important;
      }
    `}
`;
