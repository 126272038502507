import React from "react";

import CircularLoader from "shared/components/atoms/CircularLoader";

import { PageLoaderContainer } from "./styles";

const PageLoader = () => {
  return (
    <PageLoaderContainer>
      <CircularLoader className="loader" />
    </PageLoaderContainer>
  );
};

export default PageLoader;
