import styled from "styled-components";

import { consolePageMobileBreakpoint } from "pages/[tenant]/console/hooks/useConsolePageConfig";

interface IActiveOptionIndicatorProps {
  position: number;
}

export const ActiveOptionIndicatorContainer = styled.li<IActiveOptionIndicatorProps>`
  background-color: #605cff !important;

  transition: 0.3s all;
  border-radius: 4px;

  z-index: 3;

  list-style: none;

  position: absolute;
  pointer-events: none;
  height: 36px;

  @media (min-width: ${consolePageMobileBreakpoint}px) {
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    top: ${({ position }) => position}px;
  }

  @media (max-width: ${consolePageMobileBreakpoint - 1}px) {
    left: 5px;
    width: calc(100% - 0.7rem);

    top: ${({ position }) => position}px;
  }
`;
