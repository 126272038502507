import styled, { css } from "styled-components";

interface IEnsinioBrandContainerProps {
  isMobile: boolean;
}

export const EnsinioBrandContainer = styled.a<IEnsinioBrandContainerProps>`
  position: absolute;

  bottom: ${({ isMobile }) => (isMobile ? "75px" : "15px")};
  margin-bottom: ${({ isMobile }) => isMobile && "4.6rem"};
  right: 35px;
  z-index: 98;
  cursor: pointer;
  text-decoration: none;

  width: max-content;
  padding: 5px 10px;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: transform 0.4s;
  box-shadow: rgb(255 255 255 / 69%) 0px 0px 200px -20px,
    rgb(0 0 0 / 73%) 0px 9px 20px -10px;

  p {
    margin: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 11.0162px;
    text-align: center;

    height: 18px;
    line-height: 18px;
    display: flex;
    align-items: center;

    margin: 1px 5px 1px 0;
  }

  &:hover {
    transition: transform 0.4s;
    transform: translateY(-5px);
  }

  ${({ theme }) =>
    theme.mode === "dark"
      ? css`
          background: #323232;

          p,
          p:hover {
            color: #fff;
          }
        `
      : css`
          background: #ffffff;

          p,
          p:hover {
            color: #1a1a1a;
          }
        `}

  @media(max-width: 800px) {
    zoom: 80%;
  }
`;
