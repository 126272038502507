import React from "react";

export const GroupsAndContentIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.375 6.4375C2.375 5.92 2.795 5.5 3.3125 5.5H8.3125C8.83 5.5 9.25 5.92 9.25 6.4375V9.5625C9.25 10.08 8.83 10.5 8.3125 10.5H3.3125C3.06386 10.5 2.8254 10.4012 2.64959 10.2254C2.47377 10.0496 2.375 9.81114 2.375 9.5625V6.4375ZM12.375 7.6875C12.375 7.17 12.795 6.75 13.3125 6.75H17.6875C18.205 6.75 18.625 7.17 18.625 7.6875V14.5625C18.625 15.08 18.205 15.5 17.6875 15.5H13.3125C13.0639 15.5 12.8254 15.4012 12.6496 15.2254C12.4738 15.0496 12.375 14.8111 12.375 14.5625V7.6875ZM3.625 13.9375C3.625 13.42 4.045 13 4.5625 13H8.9375C9.455 13 9.875 13.42 9.875 13.9375V15.8125C9.875 16.33 9.455 16.75 8.9375 16.75H4.5625C4.31386 16.75 4.0754 16.6512 3.89959 16.4754C3.72377 16.2996 3.625 16.0611 3.625 15.8125V13.9375Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
