import styled, { css } from "styled-components";

import {
  slideIn,
  slideOut,
} from "shared/providers/Layout/components/NewTopBar/components/molecules/SideMenu/styles";

interface IHamburguerMenuContainerProps {
  sideMenuIsOpen: boolean;
}

export const HamburguerMenuContainer = styled.button<IHamburguerMenuContainerProps>`
  padding: 0.6875rem;
  background: transparent;
  border: none;

  position: relative;
  z-index: 99;

  display: flex;
  flex-direction: column;
  gap: 4px;

  max-width: 46px;

  :hover {
    cursor: pointer;
  }

  .bar-1,
  .bar-2,
  .bar-3 {
    content: "";
    position: relative;

    width: 24px;
    height: 2px;

    background: ${({ theme }) => theme.colors.globalTopbar.iconsColor};
    transition: 0.3s all;

    border-radius: 8px;
  }

  ::before {
    content: "";
    width: 290px;
    height: 100px;
    background-color: ${({ theme }) =>
      theme.colors.globalTopbar.primaryBackground};

    position: absolute;
    bottom: 0;
    left: 0;

    opacity: 0;
    pointer-events: none;
    transition: 0.3s all;

    animation: ${({ sideMenuIsOpen }) => (sideMenuIsOpen ? slideIn : slideOut)}
      0.3s ease-in-out;

    @media (min-width: 2000px) {
      opacity: 0 !important;
    }
  }

  ${({ sideMenuIsOpen, theme }) =>
    sideMenuIsOpen &&
    css`
      .bar-1,
      .bar-2,
      .bar-3 {
        width: 28px;
      }

      #bar-1,
      #bar-3 {
        background-color: ${theme.colors.globalTopbar.iconsColor} !important;
      }

      .bar-1 {
        transform: rotate(130deg);
        top: 1px;
      }

      #bar-2 {
        background: transparent !important;
      }

      .bar-3 {
        transform: rotate(-130deg);
        bottom: 11px;
      }

      ::before {
        opacity: 1;
        pointer-events: all;
      }
    `}
`;
