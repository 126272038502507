module.exports = {
  locales: ["pt-BR"],
  defaultLocale: "pt-BR",
  extensionsRgx: /\.page\.(tsx|ts|js|jsx)$/,
  loadLocaleFrom: (locale, namespace) =>
    import(`./locales/${locale}/${namespace}.json`).then((m) => m.default),
  pages: {
    "*": ["common", "courses", "payment", "authentication", "404", "media","g"],
    "/[tenant]/about": ["about"],
    "/[tenant]/401": ["401"],
    "/[tenant]/404": ["404"],
    "/[tenant]/collections": ["collections"],
    "/[tenant]/media": ["media"],
    "/[tenant]/orders": ["orders"],
  },
};
