import styled from "styled-components";

import { ConsolePageButtonStyles } from "pages/[tenant]/console/components/molecules/ConsolePageForm/atoms/ConsolePageButton/styles";

export const BasePageInfoSection = styled.div`
  margin: 3rem auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 1rem;

  text-align: center;

  h1 {
    font: 500 1.25rem/1.875rem "Inter";
    color: ${({ theme }) => theme.console.sidebar.titleColor};
    margin-bottom: 0.5rem;
  }

  p {
    font: 400 0.875rem/1.3125rem "Inter";
    color: ${({ theme }) => theme.console.cardDescriptionColor} !important;
    margin-bottom: 1.5rem;
  }

  .buttons-container {
    display: flex;
    gap: 0.75rem;
  }
`;

export const BackButton = styled(ConsolePageButtonStyles)`
  font: 500 0.8125rem/1.21875rem "Inter";
  color: ${({ theme }) => theme.console.sidebar.titleColor};
`;
export const HomeButton = styled(ConsolePageButtonStyles)`
  font: 500 0.8125rem/1.21875rem "Inter";
  color: ${({ theme }) => theme.console.disabledFeatureButtonTextColor};

  background-color: #0073e5;
`;
