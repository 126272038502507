import api from "shared/infra/services/api";

interface IGetCustomPageTenancy {
  query: { course_slug?: string; blog_slug?: string };
  pathname: string;
}

const standardizeSeoTags = (content) => {
  if (!content) return content;
  const { data } = content;

  const title = data?.title;
  const seo_title = data?.seo_title;
  const seo_description = data?.seo_description;
  const seo_keyword = data?.seo_keyword;

  return {
    ...data,
    title: title?.pt_BR || (title?.length ? title : null),
    seo_title: seo_title?.pt_BR || (seo_title?.length ? seo_title : null),
    seo_description:
      seo_description?.pt_BR ||
      (seo_description?.length ? seo_description : null),
    seo_keyword:
      seo_keyword?.pt_BR || (seo_keyword?.length ? seo_keyword : null),
  };
};

const getCustomPageTenancy = async ({
  query,
  pathname,
}: IGetCustomPageTenancy) => {
  const course_slug = query?.course_slug;
  const blog_slug = query?.blog_slug;

  try {
    const courses = course_slug ? await api.get(`course/${course_slug}`) : null;
    const blog = blog_slug ? await api.get(`blog/${blog_slug}`) : null;

    const pages = { courses, blog };
    return standardizeSeoTags(pages[pathname]?.data) || null;
  } catch {
    return null;
  }
};

export default getCustomPageTenancy;
