import React from "react";

import Link from "next/link";

import { isPageActive } from "pages/[tenant]/cp/utils/isPageActive";

import { SideBarLinkContainer } from "./styles";

interface ISideBarLinkProps {
  label: string;
  path: string;
  isActive?: boolean;
  shouldHide?: boolean;
  shouldDisable?: boolean;
  icon: JSX.Element;
  target?: string
}

export const SideBarLink = ({
  label,
  path,
  isActive,
  shouldHide,
  shouldDisable,
  icon,
  target
}: ISideBarLinkProps) => {
  const linkIsActive = isPageActive(path) || isActive;
  const className = linkIsActive ? "active" : "";

  const handleClick = (e) => {
    if (shouldDisable) return e.preventDefault();
  };

  if (shouldHide) return null;
  return (
    <SideBarLinkContainer tooltipLabel={label} disabled={shouldDisable}>
      <Link href={path} className={className} onClick={handleClick} target={target}>
        {icon}
      </Link>
    </SideBarLinkContainer>
  );
};
