export const SearchCircle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 17 17"
    fill="none"
  >
    <path
      d="M8.00955 2.50908C6.92151 2.50908 5.85791 2.83172 4.95325 3.4362C4.04858 4.04068 3.34348 4.89985 2.9271 5.90506C2.51073 6.91027 2.40179 8.01638 2.61405 9.08351C2.82632 10.1506 3.35026 11.1309 4.11961 11.9002C4.88897 12.6696 5.86919 13.1935 6.93632 13.4058C8.00345 13.618 9.10955 13.5091 10.1148 13.0927C11.12 12.6764 11.9791 11.9712 12.5836 11.0666C13.1881 10.1619 13.5107 9.09831 13.5107 8.01028C13.5107 6.55127 12.9312 5.15202 11.8995 4.12034C10.8678 3.08867 9.46856 2.50908 8.00955 2.50908Z"
      stroke="currentColor"
      strokeWidth="1.00189"
      strokeMiterlimit="10"
    />
    <path
      d="M7.26058 5.00951C6.81541 5.00951 6.38024 5.14152 6.01009 5.38884C5.63995 5.63617 5.35146 5.98769 5.1811 6.39898C5.01074 6.81026 4.96617 7.26282 5.05302 7.69944C5.13986 8.13605 5.35423 8.53711 5.66901 8.85189C5.9838 9.16667 6.38485 9.38104 6.82147 9.46789C7.25808 9.55474 7.71064 9.51016 8.12192 9.3398C8.53321 9.16944 8.88474 8.88095 9.13206 8.51081C9.37938 8.14066 9.51139 7.70549 9.51139 7.26032C9.51139 6.96474 9.45317 6.67206 9.34005 6.39898C9.22694 6.1259 9.06115 5.87777 8.85214 5.66876C8.64313 5.45975 8.395 5.29396 8.12192 5.18085C7.84884 5.06773 7.55616 5.00951 7.26058 5.00951V5.00951Z"
      stroke="currentColor"
      strokeWidth="1.00189"
      strokeMiterlimit="10"
    />
    <path
      d="M8.87314 8.87422L10.5144 10.5155"
      stroke="currentColor"
      strokeWidth="1.00189"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
)

