import { useCallback, useContext, useLayoutEffect, useState } from "react";

import Image from "next/image";
import { useRouter } from "next/router";

import { Checkbox, Tooltip } from "antd";
import useTranslation from "next-translate/useTranslation";

import SimpleUserInitialsName from "shared/components/atoms/SimpleUserInitials";
import GlobalContext from "shared/providers/context/GlobalContext";

import {
  Actions,
  IconNotificationOutlined,
  NotificationItemContainer,
  NotificationItemContent,
  NotificationItemWrapper,
} from "./styles";

interface NotificationItemProps {
  notification: Notification;
  onMarkReadNotificationUnique: (data: string | number) => void;
}

const NotificationItem = ({
  notification,
  onMarkReadNotificationUnique,
}: NotificationItemProps) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { state } = useContext(GlobalContext);

  const mode = state.theme.value;
  const { id, is_read } = notification;
  const [checked, setChecked] = useState(is_read);

  const atomizeNotificationURL = (url: string): string => {
    const urlAtomized = url.replace("/comments_", "?commentId=");
    return urlAtomized;
  };

  const handleIconNotificationOrAvatarUser = useCallback(() => {
    let userInfo: Notification["user"] = {};

    if (!notification?.data?.user && !notification?.user)
      return <IconNotificationOutlined />;

    if (notification?.data) {
      const { user, user_commented_display_name } = notification.data;

      if (user) {
        const { display_name, photo, avatar_url, username } = user;

        userInfo = {
          display_name,
          avatar_url: avatar_url || photo,
          username,
        };
      } else {
        userInfo = {
          display_name: user_commented_display_name,
          avatar_url: user.avatar_url || user.photo,
          username: null,
        };
      }
    } else {
      const { user, user_commented_display_name } = notification;

      if (user) {
        const { display_name, photo, avatar_url, username } = user;

        userInfo = {
          display_name,
          avatar_url: avatar_url || photo,
          username,
        };
      } else {
        userInfo = {
          display_name: user_commented_display_name,
          avatar_url: user.avatar_url || user.photo,
          username: null,
        };
      }
    }

    if (!userInfo?.avatar_url) {
      return (
        <div className="notification-initials">
          <SimpleUserInitialsName fullName={userInfo?.display_name} />
        </div>
      );
    }

    return (
      <div className="notification-initials">
        <Image src={userInfo?.avatar_url} alt="" width={32} height={32} />
      </div>
    );
  }, [notification]);

  function formatContent(message: string) {
    if (process.env.NODE_ENV === "test") return message;

    return message
      ?.replaceAll(/(&quot\;)/g, '"')
      ?.replaceAll("&#039;", "'")
      ?.replaceAll("&lt;", "<")
      ?.replaceAll("&gt;", ">")
      ?.replaceAll("&gt;", ">")
      ?.replaceAll("&amp;", "&");
  }

  useLayoutEffect(() => {
    setChecked(is_read);
  }, [is_read]);

  return (
    <NotificationItemContainer mode={mode} data-testid="item-notification">
      <NotificationItemWrapper>
        <div
          onClick={() => {
            router.push(
              `${atomizeNotificationURL(
                notification?.data?.notification_url_action ||
                  notification?.notification_url_action,
              )}`,
            );

            if (!checked) {
              setChecked(!checked);
              onMarkReadNotificationUnique(id);
            }
          }}
        >
          <div>{handleIconNotificationOrAvatarUser()}</div>
          <NotificationItemContent>
            {formatContent(
              notification?.data?.template_notification_content_string ||
                notification?.template_notification_content_string,
            )}
          </NotificationItemContent>
        </div>

        <Actions>
          <Tooltip
            title={t("common:removeNotification")}
            className="notifications-actions"
          ></Tooltip>

          {!checked && (
            <Tooltip title={t("common:markAsRead")}>
              <Checkbox
                checked={!checked}
                onChange={() => {
                  setChecked(!checked);
                  onMarkReadNotificationUnique(id);
                }}
              />
            </Tooltip>
          )}
        </Actions>
      </NotificationItemWrapper>
    </NotificationItemContainer>
  );
};

export default NotificationItem;
