import styled, { css } from "styled-components";

import { LogoContainer } from "shared/providers/Layout/components/NewTopBar/components/atoms/Logo/styles";

interface IGlobalTopbarHeaderContainerProps {
  shouldAddAlignAndPadding: boolean;
}

export const GlobalTopbarHeaderContainer = styled.div<IGlobalTopbarHeaderContainerProps>`
  font: 600 1rem/1.5rem "Inter";
  color: ${({ theme }) => theme.colors.globalTopbar.textColor};

  ${({ shouldAddAlignAndPadding }) =>
    shouldAddAlignAndPadding &&
    css`
      padding: 0.4rem 0;

      display: flex;
      align-items: center;
      width: 100%;
    `}

  ${LogoContainer} a {
    padding: 0.5rem 0;
  }

  button {
    background-color: transparent;
    border: none;

    padding: 0.75rem 1rem;
    transform: rotate(180deg);

    :hover {
      cursor: pointer;
    }

    svg path {
      stroke: ${({ theme }) => theme.colors.globalTopbar.textColor};
    }
  }
`;
