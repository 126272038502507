import React from "react";

export const HelpIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 1.5C4.8645 1.5 1.5 4.8645 1.5 9C1.5 13.1355 4.8645 16.5 9 16.5C13.1355 16.5 16.5 13.1355 16.5 9C16.5 4.8645 13.1355 1.5 9 1.5ZM9.75 13.5H8.25V12H9.75V13.5ZM10.482 9.83625C10.335 9.95475 10.1932 10.068 10.0807 10.1805C9.77475 10.4858 9.75075 10.7632 9.75 10.7752V10.875H8.25V10.7498C8.25 10.6613 8.27175 9.867 9.0195 9.11925C9.16575 8.973 9.34725 8.8245 9.53775 8.67C10.0883 8.22375 10.4497 7.89825 10.4497 7.44975C10.441 7.07086 10.2844 6.71044 10.0133 6.44559C9.74222 6.18074 9.37824 6.03251 8.99926 6.0326C8.62027 6.0327 8.25638 6.18112 7.98543 6.44611C7.71449 6.7111 7.55802 7.07161 7.5495 7.4505H6.0495C6.0495 5.82375 7.37325 4.5 9 4.5C10.6267 4.5 11.9505 5.82375 11.9505 7.4505C11.9505 8.64825 11.0662 9.363 10.482 9.83625Z"
        fill="white"
      />
    </svg>
  );
};
