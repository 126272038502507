import React from "react";

import useTranslation from "next-translate/useTranslation";

import { Container, Image, Text } from "./styles";

const EmailInvalid = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Image />

      <Text>{t("common:notAValidEmail")}</Text>
    </Container>
  );
};

export default EmailInvalid;
