import { usePathname } from "next/navigation";
import { useRouter } from "next/router";

import { ArchivedIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/groupsAndContent/ArchivedIcon";
import { GroupsIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/groupsAndContent/GroupsIcon";
import { NexusIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/groupsAndContent/NexusIcon";
import { PublishedIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/groupsAndContent/PublishedIcon";
import { QuestionBankIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/groupsAndContent/QuestionBankIcon";
import { IOptionsHook, ISubSection } from "pages/[tenant]/console/types";

export const useGroupAndContentOptions = (): IOptionsHook => {
  const pathname = usePathname();
  const { query } = useRouter();
  const shouldHideGroupOption = !pathname.includes(`${query?.group_slug}`);

  const sectionLabel = "Grupos & recursos";
  const groupsAndContentSubSections: ISubSection[] = [
    {
      label: "Grupos",
      options: [
        {
          id: 1,
          label: "Todos os grupos",
          description: "Os grupos permitem que você reúna seus usuários, conteúdos, eventos e comunidade em um só lugar.",
          path: "/console/groups",
          icon: <GroupsIcon />,
        },
        {
          id: 2,
          label: "Publicados",
          path: "/console/groups/published",
          icon: <PublishedIcon />,
          shouldHide: true,
        },
        {
          id: 3,
          label: "Arquivados",
          path: "/console/groups/archived",
          icon: <ArchivedIcon />,
          shouldHide: true,
        },
      ],
    },
    {
      label: "Recursos",
      options: [
        {
          id: 20,
          label: "Vínculos",
          path: "/console/groups/bindings",
          icon: <NexusIcon />,
          shouldHide: true,
        },
        {
          id: 21,
          label: "Banco de Questões",
          description: "Reutilize questões entre quizzes de vários grupos diferentes e mantenha uma relação centralizada.",
          path: "/console/questionbank",
          icon: <QuestionBankIcon />,
        },
      ],
    },
  ];

  return {
    subSections: groupsAndContentSubSections,
    label: sectionLabel,
  };
};
