import React from "react";

export const MissionsIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.34272 9.54883L3.34938 13.8502C3.31862 13.9804 3.32786 14.1169 3.37588 14.2418C3.4239 14.3667 3.50848 14.4743 3.61856 14.5504C3.72865 14.6265 3.85912 14.6676 3.99295 14.6684C4.12678 14.6692 4.25773 14.6296 4.36872 14.5548L7.99872 12.1348L11.6287 14.5548C11.7423 14.6302 11.8762 14.669 12.0125 14.6661C12.1487 14.6631 12.2808 14.6184 12.391 14.5381C12.5011 14.4578 12.584 14.3457 12.6286 14.2169C12.6731 14.088 12.6771 13.9487 12.64 13.8175L11.4207 9.55083L14.4447 6.8295C14.5416 6.74229 14.6107 6.62861 14.6437 6.50251C14.6766 6.3764 14.6718 6.24342 14.63 6.11999C14.5881 5.99657 14.511 5.88811 14.4082 5.80805C14.3054 5.72798 14.1813 5.67981 14.0514 5.6695L10.2507 5.36683L8.60605 1.72616C8.55361 1.60893 8.46836 1.50939 8.36059 1.43954C8.25282 1.36969 8.12714 1.33252 7.99872 1.33252C7.87029 1.33252 7.74461 1.36969 7.63684 1.43954C7.52907 1.50939 7.44382 1.60893 7.39138 1.72616L5.74672 5.36683L1.94605 5.66883C1.81835 5.67895 1.69627 5.72564 1.59442 5.80332C1.49256 5.881 1.41524 5.98638 1.37171 6.10685C1.32818 6.22732 1.32027 6.35779 1.34895 6.48263C1.37762 6.60748 1.44165 6.72142 1.53338 6.81083L4.34272 9.54883ZM6.24472 6.66483C6.36371 6.65546 6.478 6.61424 6.57558 6.54551C6.67317 6.47677 6.75047 6.38305 6.79938 6.27416L7.99872 3.62016L9.19805 6.27416C9.24696 6.38305 9.32426 6.47677 9.42185 6.54551C9.51944 6.61424 9.63372 6.65546 9.75272 6.66483L12.4007 6.87483L10.22 8.8375C10.0307 9.00816 9.95538 9.27083 10.0247 9.51616L10.86 12.4395L8.36938 10.7788C8.26006 10.7055 8.13138 10.6663 7.99972 10.6663C7.86805 10.6663 7.73937 10.7055 7.63005 10.7788L5.02738 12.5142L5.72738 9.4835C5.75305 9.37203 5.7496 9.25584 5.71738 9.14609C5.68516 9.03634 5.62524 8.93673 5.54338 8.85683L3.51805 6.88216L6.24472 6.66483Z"
        fill="currentColor"
      />
    </svg>
  );
};
