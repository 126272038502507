import React from "react";

export const HomeIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6828 10.8353L12.0289 1.18846C11.9594 1.11881 11.8768 1.06356 11.7859 1.02586C11.6951 0.988155 11.5976 0.96875 11.4992 0.96875C11.4008 0.96875 11.3034 0.988155 11.2125 1.02586C11.1216 1.06356 11.039 1.11881 10.9695 1.18846L1.31562 10.8353C1.03437 11.1166 0.875 11.4986 0.875 11.8971C0.875 12.7244 1.54766 13.3971 2.375 13.3971H3.39219V20.2806C3.39219 20.6955 3.72734 21.0306 4.14219 21.0306H9.99922V15.7806H12.6242V21.0306H18.8563C19.2711 21.0306 19.6063 20.6955 19.6063 20.2806V13.3971H20.6234C21.0219 13.3971 21.4039 13.24 21.6852 12.9564C22.2687 12.3705 22.2687 11.4213 21.6828 10.8353Z"
        fill="#848484"
      />
    </svg>
  );
};
