import React from "react";

export const AnalyticsSalesIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.6641 8H11.9974V10.6667H10.6641V8Z" fill="currentColor" />
      <path
        d="M13.332 4.66667V3.33333C13.332 2.598 12.734 2 11.9987 2H3.33203C2.22936 2 1.33203 2.89733 1.33203 4V12C1.33203 13.4673 2.52803 14 3.33203 14H13.332C14.0674 14 14.6654 13.402 14.6654 12.6667V6C14.6654 5.26467 14.0674 4.66667 13.332 4.66667ZM3.33203 3.33333H11.9987V4.66667H3.33203C3.16038 4.65899 2.9983 4.5854 2.87955 4.46121C2.7608 4.33703 2.69452 4.17182 2.69452 4C2.69452 3.82818 2.7608 3.66297 2.87955 3.53879C2.9983 3.4146 3.16038 3.34101 3.33203 3.33333ZM13.332 12.6667H3.34003C3.03203 12.6587 2.66536 12.5367 2.66536 12V5.87667C2.8747 5.952 3.0967 6 3.33203 6H13.332V12.6667Z"
        fill="currentColor"
      />
    </svg>
  );
};
