import React, { useEffect, useState } from "react";
import useTranslation from "next-translate/useTranslation";

import { Container, Description, ModalContent, Title } from "./styles";

const MobilePurchaseDisableModal = ({ onCloseModal }) => {
  const { t } = useTranslation();
  const [willClose, setWillClose] = useState(false);

  function handleCloseModal(e) {
    const className = e.target.className;
    const isPurchaseModal = className.includes("purchase-disable");

    if (!isPurchaseModal && onCloseModal) {
      setWillClose(true);
      setTimeout(() => {
        onCloseModal();
      }, 380);
    }
  }

  useEffect(() => {
    window.addEventListener("click", handleCloseModal, true);

    return () => window.removeEventListener("click", handleCloseModal, true);
  }, []);

  return (
    <Container>
      <ModalContent className="purchase-disable" willClose={willClose}>
        <Title>{t("common:purchaseNotAvailableOnThisDevice")}</Title>
        <Description className="purchase-disable">
          {t("common:youCanWatchYourPreObtainedContents")}
        </Description>
      </ModalContent>
    </Container>
  );
};

export default MobilePurchaseDisableModal;
