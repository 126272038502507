interface IIconProps extends React.SVGProps<SVGSVGElement> {}

export const ClosedBookOutline = ({ ...props }: IIconProps) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.8125 15.1846C4.8125 14.7917 4.98322 14.4149 5.2871 14.137C5.59097 13.8592 6.00312 13.7031 6.43287 13.7031H15.1829"
      stroke="#B2B2B2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.43287 3.33203H15.1829V16.6654H6.43287C6.00312 16.6654 5.59097 16.4898 5.2871 16.1772C4.98322 15.8646 4.8125 15.4407 4.8125 14.9987V4.9987C4.8125 4.55667 4.98322 4.13275 5.2871 3.82019C5.59097 3.50763 6.00312 3.33203 6.43287 3.33203Z"
      stroke="#B2B2B2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
