import styled, { css } from "styled-components";

interface IContentSectionRowContainerProps {
  alignContent: "rows" | "grid";
  show?: boolean;
}

export const ContentSectionRowContainer = styled.div<IContentSectionRowContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  max-height: 0px;

  transition: 0.3s all;

  ${({ show }) =>
    show
      ? css`
          max-height: 100vh;
        `
      : css`
          overflow: hidden;
        `}

  &:not(.should-hide) + &:not(.should-hide) {
    margin-top: 2rem;
  }

  .content-section-title {
    color: ${({ theme }) => theme.console.sidebar.titleColor} !important;
    font: 600 1.125rem/1.40625rem "Inter";
  }

  .row-texts {
    .title {
      color: ${({ theme }) => theme.console.sidebar.titleColor} !important;
      letter-spacing: 0.025rem;
      font: 600 1rem/1.5rem "Inter";
    }

    .subtitle {
      color: #a4a4a4 !important;
      font: 400 0.875rem/1.3125rem "Inter";

      margin-bottom: 0;
    }
  }

  .content {
    padding: 2px;
  }

  display: grid;
  grid-template-columns: ${({ alignContent }) =>
    alignContent === "rows" ? "1fr" : "1fr 1fr"};
  gap: 0.25rem 1rem;

  ${({ alignContent }) =>
    alignContent === "grid"
      ? css`
          .content {
            grid-column: 2;
            grid-row: 1/4;
          }
        `
      : css`
          :has(.row-texts) {
            .content {
              margin-top: 1rem;
            }
          }
        `}

  @media(max-width: 600px) {
    display: flex;
    gap: 2rem;
  }
`;
