import React, { useCallback, useEffect, useState } from "react";

import { useRouter } from "next/router";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Plus } from "react-feather";

import useLessonsDragAndDrop from "pages/[tenant]/console/groups/[group_slug]/modules/hooks/useLessonsDragAndDrop";
import useModulesPageContext from "pages/[tenant]/console/groups/[group_slug]/modules/hooks/useModulesPageContext";
import useModulesPageService from "pages/[tenant]/console/groups/[group_slug]/modules/hooks/useModulesPageService";
import { IModule } from "pages/[tenant]/console/groups/[group_slug]/modules/types";

import { ContentSection } from "pages/[tenant]/console/components/molecules/ContentSection";
import { ThreeDots } from "pages/[tenant]/console/groups/assets/icons/ThreeDots";
import { useConsolePageContext } from "pages/[tenant]/console/hooks/useConsolePageContext";
import ArrowUp from "pages/[tenant]/g/assets/icons/ArrowUp";
import DragAndDropIcon from "pages/[tenant]/g/assets/icons/DragAndDropIcon";

import CircularLoader from "shared/components/atoms/CircularLoader";

import LessonListItem from "../LessonListItem";
import {
  ControlButton,
  CreateLesson,
  ModuleOptions,
  ModulesListControls,
  ModulesListItemContainer,
  NoLessonsAvailable,
} from "./styles";

interface IModulesListItemProps extends React.HTMLAttributes<HTMLDivElement> {
  module: IModule;
  isDragging: boolean;
  index: number;
}

const ModulesListItem = ({
  module,
  isDragging,
  index,
  ...props
}: IModulesListItemProps) => {
  const [isLessonlistVisible, setIsLessonlistVisible] = useState(false);
  const [showModuleOptions, setshowModuleOptions] = useState(false);
  const [isAllowedToDrag, setIsAllowedToDrag] = useState(true);

  const { closeMobileInternalSectionsDropdown } = useConsolePageContext();
  const { removeModule, isModulesLoading } = useModulesPageService();

  const {
    group,
    setModule,
    setModulesInternalRoutes,
    updateModuleLocally,
    clearModuleState,
    modules,
  } = useModulesPageContext();

  const hasLessonToShow = module?.lessons?.length > 0;
  const onMouseEnter = useCallback(() => setIsAllowedToDrag(false), []);
  const onMouseLeave = useCallback(() => setIsAllowedToDrag(true), []);
  const closeModuleOptions = useCallback(() => setshowModuleOptions(false), []);
  const { push, query } = useRouter();

  const moduleSlugMatchSlugOnPathname = module?.slug === query?.module_slug;

  const { isLoading, onLessonDragEnd } = useLessonsDragAndDrop({
    module,
  });

  const handleShowModuleoptions = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setshowModuleOptions((prev) => !prev);
  }, []);

  const handleShowLessonList = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setIsLessonlistVisible((prev) => !prev);
  }, []);

  const goToCreatingModule = useCallback(() => {
    setModulesInternalRoutes("creating-a-module");
    push(`/console/groups/${group?.slug}/modules`);
    updateModuleLocally("position", modules?.length);
    updateModuleLocally("group_id", group?.id);
    closeMobileInternalSectionsDropdown();
  }, [
    group,
    modules,
    push,
    setModulesInternalRoutes,
    updateModuleLocally,
    closeMobileInternalSectionsDropdown,
  ]);

  const goToCreatingLesson = useCallback(() => {
    closeMobileInternalSectionsDropdown();
    push(`/console/groups/${group?.slug}/modules/${module?.slug}/lesson-types`);
  }, [group, module, push, closeMobileInternalSectionsDropdown]);

  const handleClick = useCallback(() => {
    clearModuleState();
    setModule(module);
    updateModuleLocally("cover_image_src", module.cover_image);
    goToCreatingModule();
  }, [
    module,
    clearModuleState,
    setModule,
    updateModuleLocally,
    goToCreatingModule,
  ]);

  const handleCreateLesson = useCallback(() => {
    goToCreatingLesson();
    setIsLessonlistVisible(true);
  }, [goToCreatingLesson]);

  const deleteModule = useCallback(
    async (e) => {
      e.stopPropagation();
      await removeModule(module);
    },
    [removeModule],
  );

  useEffect(() => {
    if (showModuleOptions)
      window.addEventListener("click", closeModuleOptions, true);
    else window.removeEventListener("click", closeModuleOptions, true);

    return () => window.removeEventListener("click", closeModuleOptions, true);
  }, [showModuleOptions]);

  useEffect(() => {
    if (moduleSlugMatchSlugOnPathname) setIsLessonlistVisible(true);
  }, []);

  if (!group) return null;
  return (
    <Draggable
      key={module?.id}
      draggableId={module?.id?.toString()}
      index={index}
      isDragDisabled={!isAllowedToDrag}
    >
      {(itemProvided) => (
        <li
          ref={itemProvided.innerRef}
          {...itemProvided.draggableProps}
          {...itemProvided.dragHandleProps}
        >
          <ContentSection.Container title="" {...props}>
            <ModulesListItemContainer onClick={handleClick}>
              <DragAndDropIcon />
              <span>{module?.title}</span>

              <ModulesListControls>
                <ControlButton
                  onClick={handleShowLessonList}
                  shouldRotate={isLessonlistVisible}
                >
                  <ArrowUp />
                </ControlButton>

                <ControlButton
                  id="module-options"
                  onClick={handleShowModuleoptions}
                >
                  <ThreeDots />

                  <ModuleOptions showModuleOptions={showModuleOptions}>
                    <ControlButton onClick={deleteModule}>
                      {isModulesLoading ? <CircularLoader /> : "Excluir"}
                    </ControlButton>
                  </ModuleOptions>
                </ControlButton>
              </ModulesListControls>
            </ModulesListItemContainer>

            <NoLessonsAvailable
              showNoLessonMessage={!hasLessonToShow && isLessonlistVisible}
            >
              Nenhuma aula. Deseja adicionar uma nova?
            </NoLessonsAvailable>

            <DragDropContext onDragEnd={onLessonDragEnd}>
              <Droppable droppableId={`lesson-${module?.id?.toString()}`}>
                {(listProvided) => (
                  <ul
                    {...listProvided.droppableProps}
                    ref={listProvided.innerRef}
                    placeholder={`lesson-${module?.id?.toString()}`}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                  >
                    {module?.lessons?.map((lessonItem, lessonIndex) => (
                      <LessonListItem
                        key={lessonItem?.id}
                        index={lessonIndex}
                        lesson={lessonItem}
                        isLessonlistVisible={isLessonlistVisible && !isDragging}
                        isLoading={isLoading}
                      />
                    ))}
                    {listProvided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
            <CreateLesson
              onClick={handleCreateLesson}
              isModuleSelected={moduleSlugMatchSlugOnPathname}
              isLessonlistVisible={isLessonlistVisible && !isDragging}
            >
              <Plus />
              Nova aula
            </CreateLesson>
          </ContentSection.Container>
        </li>
      )}
    </Draggable>
  );
};

export default ModulesListItem;
