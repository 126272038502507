import React, { ChangeEvent, InputHTMLAttributes, useState } from "react";

import { SearchIcon } from "shared/assets/icons/SearchIcon";
import { XIcon } from "shared/assets/icons/XIcon";

interface ISearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  searchListMethod: (term: string) => void;
  clearSearchMethod: () => void;
  withSearchAndXIcon?: boolean;
}

export const SearchInput = ({
  clearSearchMethod,
  searchListMethod,
  withSearchAndXIcon,
  ...rest
}: ISearchInputProps) => {
  const [searchValue, setSearchValue] = useState("");

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const value = target.value;

    if (!value) return handleClearInput();

    setSearchValue(value);
    searchListMethod(value);
  };

  const handleClearInput = () => {
    setSearchValue("");
    clearSearchMethod();
  };

  return (
    <>
      {withSearchAndXIcon && (
        <button onClick={handleClearInput} disabled={!searchValue}>
          {searchValue ? <XIcon /> : <SearchIcon />}
        </button>
      )}

      <input
        type="text"
        placeholder="Escreva sua busca aqui"
        value={searchValue}
        onChange={handleChange}
        className={searchValue && "is-filled"}
        data-testid="search-input"
        {...rest}
      />
    </>
  );
};
