import { useState } from "react";

import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";

import useGroupContext from "pages/[tenant]/g/hooks/useGroupContext";
import { getUrlWithoutParams } from "pages/[tenant]/g/utils/getUrlWithoutParams";

import {
  ColorFullBackground,
  ContentNotificationColor,
  SingleGroupContainer,
} from "./styles";

const SingleGroup = (group) => {
  const router = useRouter();
  const slug = group?.groups.slug;
  const [failedToLoadImg, setFailedToLoadImg] = useState(false);

  const { setGroupTitleInRealTime } = useGroupContext();

  const colorPrimaryGroup = group?.groups.primary_color;
  const group_slug = router.query.group_slug;
  const backgroundType = group?.groups?.banner_type;
  const backgroundContent =
    backgroundType == "color"
      ? group?.groups.primary_color
      : group?.groups.logo_horizontal;

  const noImage = failedToLoadImg || !group?.groups.logo_horizontal;

  const groupRoute = getUrlWithoutParams(router.asPath);
  const shouldKeepGroupRoute = groupRoute !== group_slug;
  const route = shouldKeepGroupRoute ? `/${groupRoute}` : "";

  return (
    <SingleGroupContainer isSlug={group_slug === slug}>
      <Link
        href={`/g/${slug}${route}`}
        onClick={() => setGroupTitleInRealTime(group?.groups.title)}
      >
        <div className="data-group">
          <div className="image-group">
            {!noImage ? (
              <Image
                src={backgroundContent}
                onError={() => setFailedToLoadImg(true)}
                width={60}
                height={34}
                alt=""
              />
            ) : (
              <ColorFullBackground color={colorPrimaryGroup} />
            )}
          </div>

          <div className="data-notification-group">
            <div className="info-group">
              <h4>{group?.groups.title}</h4>
            </div>
            {group?.groups.new_posts > 0 && (
              <ContentNotificationColor>
                <span>{group?.groups.new_posts}</span>
              </ContentNotificationColor>
            )}
          </div>
        </div>
      </Link>
    </SingleGroupContainer>
  );
};

export default SingleGroup;
