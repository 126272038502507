import styled from "styled-components";

import { BasePageInfoSection } from "pages/[tenant]/console/styles/BasePageInfoSection";

export const GenericTemplateFeatureNotInUserPlanContainer = styled(
  BasePageInfoSection,
)`
  max-width: 32rem;

  svg {
    margin-bottom: 1.5rem;

    path {
      stroke: ${({ theme }) => theme.console.sidebar.titleColor};
    }
  }
`;
