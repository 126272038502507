import styled, { css } from "styled-components";

export const NotificationsOptionsContainer = styled.div`
  position: relative;

  button {
    background-color: transparent;
    border: none;
    white-space: nowrap;

    width: 100%;

    transition: 0.3s all;

    :disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  > button {
    padding: 1rem;
  }
`;

interface IOptionsContainerProps {
  show: boolean;
}

export const OptionsContainer = styled.div<IOptionsContainerProps>`
  background-color: ${({ theme }) => theme.colors.globalTopbar.solidBackground};
  border: 1px solid ${({ theme }) => theme.colors.globalTopbar.borderColor};

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;

  position: absolute;
  right: 20px;
  top: 50px;

  opacity: 0;
  pointer-events: none;
  transition: 0.3s all;

  ${({ show }) =>
    show &&
    css`
      opacity: 1;
      pointer-events: all;
    `}

  button {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;
