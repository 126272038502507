import {
  IAdvancedTheme,
  IGeneralTheme,
} from "shared/providers/context/initialStates/tenancy";
import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";

import { themes } from "./themes";

export type ITenantTheme = IAdvancedTheme & IGeneralTheme;

// here is the only place we should handle with dark mode
export const Theme = (theme: "dark" | "light", tenancyForTests: any) => {
  const {
    state: { tenancy },
  } = useGlobalContext();

  let tenantTheme: IAdvancedTheme & IGeneralTheme;

  if (tenancy?.id) {
    const generalTheme = tenancy.settings["general-theme"];
    const advancedTheme = tenancy.settings["advanced-theme"];

    tenantTheme = { ...generalTheme, ...advancedTheme };
  }

  const tenancyProps: IAdvancedTheme & IGeneralTheme =
    tenancyForTests || tenantTheme;

  return {
    ...(theme === "light"
      ? themes.light(tenancyProps)
      : themes.dark(tenancyProps)),
    mode: theme,
  };
};

export default Theme;
