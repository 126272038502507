export type GroupProps = {
  [x: string]: any;
  id: number;
  title: string;
  slug: string;
  subtitle: string;
  description: string;
  primary_color: string;
  logo_vertical: string;
  logo_horizontal: string;
  banner_type: string;
  banner_address: string;
  cover_type: string;
  cover_address: string;
  new_posts: number;
  created_at: string;
  updated_at: string;
  className: string;
};

const groups: GroupProps = {
  id: 0,
  title: "",
  slug: "",
  subtitle: "",
  description: "",
  primary_color: "",
  logo_vertical: "",
  logo_horizontal: "",
  banner_type: "",
  banner_address: "",
  cover_type: "",
  cover_address: "",
  new_posts: 0,
  created_at: "",
  updated_at: "",
  className: "",
};

export default groups;
