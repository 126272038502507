import styled, { css } from "styled-components";

import { CircularLoaderContainer } from "shared/components/atoms/CircularLoader/styles";

interface IConsolePageSelectContainerProps {
  optionsIsVisible: boolean;
}

export const ConsolePageSelectContainer = styled.div<IConsolePageSelectContainerProps>`
  position: relative;

  border-radius: 4px;
  transition: 0.2s all;

  width: 100%;

  .values-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .label {
      color: #b2b8b8;
      font: 600 0.625rem/0.9375rem "Inter";
    }

    .value {
      color: ${({ theme }) => theme.console.inputColor};
      font: 600 0.9375rem/1.40625rem "Inter";
    }
  }

  button,
  ul {
    background-color: ${({ theme }) => theme.console.backgroundColor};
    border: 1px solid ${({ theme }) => theme.console.inputBorderColor};
  }

  .select-arrow {
    transition: 0.3s all;
  }

  ${({ optionsIsVisible }) =>
    optionsIsVisible &&
    css`
      z-index: 10;
      border-radius: 4px 4px 0px 0px;

      z-index: 10;

      .select-arrow {
        transform: rotate(180deg);
      }

      button {
        border-radius: 4px 4px 0px 0px;
      }
    `}
`;

interface ISelectProps {
  isInvalid: boolean;
}

export const Select = styled.button<ISelectProps>`
  all: unset;

  width: 100%;
  height: 48px;
  padding: 1rem;
  border-radius: 4px;

  z-index: 2;
  position: relative;

  width: 100%;

  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  :disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  ${({ isInvalid }) =>
    isInvalid &&
    css`
      box-shadow: inset 0 0 0px 2px red;

      .label {
        color: red !important;
      }

      svg path {
        stroke: red;
      }
    `}

  :hover:not(:disabled) {
    cursor: pointer;
  }
`;

interface IConsolePageSelectContainerOptionsProps {
  optionsIsVisible: boolean;
}

export const ConsolePageSelectContainerOptions = styled.ul<IConsolePageSelectContainerOptionsProps>`
  position: absolute;
  left: 0;
  margin: 0;
  top: 50%;
  opacity: 0;
  pointer-events: none;

  border-top: 1px solid #505050;
  border-radius: 0px 0px 4px 4px;

  z-index: 1;

  transition: 0.2s all;

  list-style: none;

  width: 100%;

  background-color: inherit;
  color: inherit;

  overflow-y: scroll;

  ${({ optionsIsVisible }) =>
    optionsIsVisible &&
    css`
      top: 100%;
      opacity: 1;
      pointer-events: all;
    `}

  ${CircularLoaderContainer} {
    margin: 1rem 0;
  }
`;
