const ChevronDown = ({ className }) => (
  <svg
    className={className}
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.36328 7.5L10.3633 12.5L15.3633 7.5" fill="#F2F2F2" />
    <path
      d="M5.36328 7.5L10.3633 12.5L15.3633 7.5L5.36328 7.5Z"
      stroke="#F2F2F2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ChevronDown;
