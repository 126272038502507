import ReactInputMask from "react-input-mask";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  background: transparent;
  max-width: 300px;
  height: 50px;

  span.inputOptions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    position: absolute;
    width: 300px;
    height: 53px;
    pointer-events: none;

    span.prefix {
      font-size: 16px;
    }
  }

  span.prefix svg {
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const InputStyled = styled(ReactInputMask)`
  font-size: 16px;
  margin: 1px 5px 0px;
  min-height: 100%;
  padding-top: 3px;
  padding: 0 40px;
  border: none;
  border-radius: 10px;
  outline: none;
  color: ${({ theme }) => theme.colors.textColor};
  background: ${({ theme }) => theme.colors.inputBackgroundColor};

  &:-webkit-autofill {
    box-shadow: 0 0 0 50px ${({ theme }) => theme.colors.inputBackgroundColor}
      inset !important;
    -webkit-text-fill-color: ${({ theme }) => theme.colors.textColor};
    background: content-box;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.textColor};
  }
`;
