/* eslint-disable @next/next/no-img-element */
import useTranslation from "next-translate/useTranslation";

import { capitalizeFullName } from "modules/Checkout/utils";

import ArrowUp from "shared/assets/icons/ArrowUp";
import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";

import { BackToTopFooterContainer } from "./style";

const BackToTopFooter = ({}) => {
  const { state } = useGlobalContext();
  const { t } = useTranslation();

  const year = new Date().getFullYear();
  const tenancySiteName = capitalizeFullName(
    state.tenancy.settings.site?.siteName,
  );

  const handleNavigateToTop = () => {
    const pageWrapper = document.querySelector(".main-content-wrapper");

    if (pageWrapper)
      pageWrapper.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
  };

  return (
    <BackToTopFooterContainer>
      <div className="footer">
        <div className="to-top-container">
          <a className="text-footer" href="">
            <h1 className="text-footer" id="text-footer">{`${year} © ${t(
              "common:termsAndConditions",
            )}. ${tenancySiteName}`}</h1>
          </a>

          <button className="button-back-to-top" onClick={handleNavigateToTop}>
            <span>{t("common:backToTop")}</span>

            <div>
              <ArrowUp />
            </div>
          </button>
          <img id="img-footer" src="" alt="logo" />
        </div>
      </div>
    </BackToTopFooterContainer>
  );
};

export default BackToTopFooter;
