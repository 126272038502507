const sendLogToSlack = async (logText, customEndpoint = null) => {
  const payload = { text: logText };

  await fetch(customEndpoint || "https://hooks.slack.com/services/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", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
};

export default sendLogToSlack;
