import React from "react";

export const TransactionsIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.9987 10C6.77203 10 6.66536 9.42667 6.66536 9.33333H5.33203C5.33203 9.94667 5.77203 11.0333 7.33203 11.28V12H8.66536V11.28C9.9987 11.0533 10.6654 10.1933 10.6654 9.33333C10.6654 8.58667 10.3187 7.33333 7.9987 7.33333C6.66536 7.33333 6.66536 6.91333 6.66536 6.66667C6.66536 6.42 7.13203 6 7.9987 6C8.86536 6 8.92536 6.42667 8.93203 6.66667H10.2654C10.2564 6.2125 10.093 5.77494 9.8022 5.42597C9.5114 5.077 9.11046 4.83743 8.66536 4.74667V4H7.33203V4.72667C5.9987 4.94667 5.33203 5.80667 5.33203 6.66667C5.33203 7.41333 5.6787 8.66667 7.9987 8.66667C9.33203 8.66667 9.33203 9.12 9.33203 9.33333C9.33203 9.54667 8.9187 10 7.9987 10Z"
        fill="currentColor"
      />
      <path
        d="M3.33203 1.33594H1.33203V2.66927H2.66536V14.0026C2.66536 14.1794 2.7356 14.349 2.86063 14.474C2.98565 14.599 3.15522 14.6693 3.33203 14.6693H12.6654C12.8422 14.6693 13.0117 14.599 13.1368 14.474C13.2618 14.349 13.332 14.1794 13.332 14.0026V2.66927H14.6654V1.33594H3.33203ZM11.9987 13.3359H3.9987V2.66927H11.9987V13.3359Z"
        fill="currentColor"
      />
    </svg>
  );
};
