import { ReactNode, createContext, useEffect, useState } from "react";

import {
  IActiveItem,
  IDroppable,
  IMenu,
  IMenuType,
  IMenus,
  IMenusContext,
} from "pages/[tenant]/cp/@types";
import { orderMenusByPosition } from "pages/[tenant]/cp/utils/orderMenusByPosition";

import api from "shared/infra/services/api";
import openNotificationWithIcon from "shared/utils/openNotificationWithIcon";

export const MenusContext = createContext<IMenusContext>({} as IMenusContext);

export const MenusContextProvider = ({ children }: { children: ReactNode }) => {
  const [menus, setMenus] = useState<IMenus | null>({
    main: [],
  });
  const [initialMenus, setInitialMenus] = useState<IMenus | null>(null);
  const [menusChanged, setMenusChanged] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isFetchingMenus, setIsFetchingMenus] = useState(false);
  const [isCreatingNewItem, setIsCreatingNewItem] = useState(false);

  const [idListOfItensUnderUpdating, setIdListOfItensUnderUpdating] = useState<
    number[]
  >([]);
  const [activeItem, setActiveItem] = useState<IActiveItem>(null);

  const fetchMenus = async (): Promise<IMenu[] | undefined> => {
    const menusFromStorage = sessionStorage.getItem("menu-items");
    if (menusFromStorage) return JSON.parse(menusFromStorage);

    try {
      const response = await api.get("/menu-items");
      const menus = response.data.data || undefined;

      sessionStorage.setItem("menu-items", JSON.stringify(menus));
      return menus;
    } catch (error) {
      console.log("Erro (fetchMenus): ", error);
      return undefined;
    }
  };

  useEffect(() => {
    const initialFetch = async () => {
      setIsFetchingMenus(true);
      const menusFromApi = await fetchMenus();
      const ordenedMenus = orderMenusByPosition(menusFromApi);

      setMenus(ordenedMenus);
      setInitialMenus(ordenedMenus);
      setIsFetchingMenus(false);
    };

    initialFetch();
  }, []);

  useEffect(() => {
    if (menus?.main) {
      sessionStorage.setItem("menu-items", JSON.stringify(menus?.main));
    }
  }, [menus]);

  const addItemIdInListOfItensUnderUpdating = (itemId: number) =>
    setIdListOfItensUnderUpdating((prev) => [...(prev ?? []), itemId]);

  const removeItemIdFromListOfItensUnderUpdating = (itemId: number) =>
    setIdListOfItensUnderUpdating((prev) => [
      ...(prev ?? []).filter((id) => id !== itemId),
    ]);

  const itemIsUnderUpdate = (itemId: number) =>
    idListOfItensUnderUpdating?.includes(itemId);

  const updateInitialMenus = () => setInitialMenus(menus);

  const reorderMenuInView = (target: IDroppable): Promise<void> => {
    const indexExists = target?.destination !== null;
    if (!indexExists) return;

    const {
      source: { index: prevIndex, droppableId: menuName },
      destination: { index: newIndex },
    } = target;

    const menusList = { ...menus };
    const currentMenu = menusList[menuName];
    const [reordenedItem] = currentMenu.splice(prevIndex, 1);
    currentMenu.splice(newIndex, 0, reordenedItem);

    const reordenedMenu = currentMenu.map((menu, index) => ({
      ...menu,
      position: index,
    }));

    setMenus((prev) => ({
      ...prev,
      [menuName]: reordenedMenu,
    }));
  };

  const updateItemLocally = ({ key, value, currentMenu }) => {
    const menuName = activeItem.menu_type || currentMenu;
    const isChangingGroupId = key === "group_id";

    setMenusChanged(true);

    if (isChangingGroupId) {
      updateGroupIdInLocalItem({ value, currentMenu });
      setActiveItem((prev) => ({
        ...prev,
        [key]: value?.id,
        group: value,
      }));
    } else {
      setMenus((prev) => ({
        ...prev,
        [menuName]: prev[menuName].map((item) =>
          item.id === activeItem.id ? { ...item, [key]: value } : item,
        ),
      }));
      setActiveItem((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  const updateGroupIdInLocalItem = ({ value, currentMenu }) => {
    const menuName = activeItem.menu_type || currentMenu;

    setMenus((prev) => ({
      ...prev,
      [menuName]: prev[menuName].map((item) =>
        item.id === activeItem.id
          ? {
              ...item,
              group: {
                ...item.group,
                ...value,
              },
              group_id: value?.id,
            }
          : item,
      ),
    }));
  };

  const updateItemWithValuesFromApi = (itemFromApi: IMenu) => {
    const menuName = itemFromApi.menu_type;

    setMenus((prev) => ({
      ...prev,
      [menuName]: prev[menuName].map((item) =>
        item.id === itemFromApi.id ? itemFromApi : item,
      ),
    }));
    updateInitialMenus();
  };

  const updateItem = async () => {
    try {
      setIsLoading(true);
      const { data } = await api.post(`/menu-items/${activeItem.id}`, {
        ...activeItem,
        group_id: activeItem?.group?.id || null,
      });

      updateItemWithValuesFromApi(data.attrs[0]);
      openNotificationWithIcon("success", "As informações foram atualizadas!");
    } catch (error) {
      console.log("Erro (updateItem): ", error);
    } finally {
      setIsLoading(false);
      setMenusChanged(false);
    }
  };

  const getItemHasBeenDragged = (
    itemPosition: number,
    menuName: IMenuType,
  ): IMenu => {
    const itemDragged = menus[menuName].find(
      (_, index) => index === itemPosition,
    );

    return itemDragged;
  };

  const updateOrder = async (currentMenu: IMenuType) => {
    const ordenedIdsArray: number[] = menus[currentMenu].reduce(
      (acc, item) => [...acc, item.id],
      [],
    );

    try {
      await api.patch("/menu-items/reorder", {
        ids: [ordenedIdsArray],
      });

      openNotificationWithIcon("success", "A ordem foi atualizada!");
    } catch (error) {
      console.log("Erro (updateOrder): ", error);
    }
  };

  const removeItemFromMenu = () => {
    const menuName = activeItem.menu_type;

    setMenus((prev) => ({
      ...prev,
      [menuName]: prev[menuName].filter((item) => item.id !== activeItem.id),
    }));

    updateInitialMenus();
  };

  const removeLocalItemFromMenu = () => {
    const menuName = activeItem.menu_type;
    setMenus((prev) => ({
      ...prev,
      [menuName]: prev[menuName].filter((item) => item.id !== ("local" as any)),
    }));
    setActiveItem(null);
    setIsCreatingNewItem(false);
  };

  const deleteItem = async () => {
    try {
      setIsDeleting(true);
      await api.delete(`/menu-items/${activeItem.id}`);
      removeItemFromMenu();
      openNotificationWithIcon("success", "A opção foi deletada!");
    } catch (error) {
      console.log("Erro (deleteItem): ", error);
    } finally {
      setIsDeleting(false);
    }
  };

  const resetStates = () => {
    setActiveItem(null);
    setMenusChanged(false);
  };

  const replaceLocalItemWithItemFromApi = (itemFromApi: IMenu) => {
    const menuName = itemFromApi.menu_type;

    setMenus((prev) => {
      const havePrevItensInMenu = !!prev[menuName]?.length;

      return {
        ...prev,
        [menuName]: havePrevItensInMenu
          ? prev[menuName].map((item) =>
              item.id === ("local" as any) ? itemFromApi : item,
            )
          : [itemFromApi],
      };
    });
    updateInitialMenus();
  };

  const restoreValuesOfActiveItem = () =>
    setActiveItem((prev) => ({
      ...initialMenus[prev.menu_type].find((item) => item.id === prev.id),
    }));

  const createNewItem = async () => {
    try {
      setIsLoading(true);
      const { data } = await api.post("/menu-items", activeItem);
      const [newItemFromApi] = data.attrs as IMenu[];

      replaceLocalItemWithItemFromApi(newItemFromApi);
      setActiveItem(newItemFromApi);
      openNotificationWithIcon("success", "O item foi criado!");
    } catch (error) {
      console.log("Error (createNewItem): ", error);
    } finally {
      setIsLoading(false);
      setIsCreatingNewItem(false);
    }
  };

  const insertLocalItemInMenu = (newItem: IMenu, menuName: IMenuType) => {
    setMenus((prev) => {
      const havePrevItensInMenu = !!prev[menuName]?.length;

      return {
        ...prev,
        [menuName]: havePrevItensInMenu
          ? [...prev[menuName], newItem]
          : [newItem],
      };
    });
  };

  return (
    <MenusContext.Provider
      value={{
        menus,
        setMenus,
        initialMenus,
        menusChanged,
        setMenusChanged,
        reorderMenuInView,
        activeItem,
        setActiveItem,
        updateOrder,
        idListOfItensUnderUpdating,
        addItemIdInListOfItensUnderUpdating,
        removeItemIdFromListOfItensUnderUpdating,
        itemIsUnderUpdate,
        updateItemLocally,
        deleteItem,
        updateItem,
        createNewItem,
        resetStates,
        insertLocalItemInMenu,
        isLoading,
        getItemHasBeenDragged,
        isCreatingNewItem,
        setIsCreatingNewItem,
        removeLocalItemFromMenu,
        restoreValuesOfActiveItem,
        isFetchingMenus,
        isDeleting,
        fetchMenus,
      }}
    >
      {children}
    </MenusContext.Provider>
  );
};
