import axios from "axios";

import auth from "shared/infra/services/auth";

export const getMode = (returnBool = true) => {
  const hostname = location?.hostname || "";

  const isDevelopmentEnvironment =
    /ngrok|loca.lt|localhost|dev.ensinio.com|ensinio-next.vercel.app/.test(
      hostname,
    );

  if (isDevelopmentEnvironment) {
    if (returnBool) return true;
    return "development";
  }

  if (hostname === "staging.ensinio.com") {
    return "homologation";
  }

  if (hostname === "loadtest.ensinio.com") {
    return "loadtest";
  }

  if (returnBool) return false;
  return "production";
};

const api = axios.create({
  baseURL: "https://back.ensiniodev.com/api/v1",
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    "Client-Type": "web",
    "App-Name": "student-area",
  },
});

api.defaults.withCredentials = true;

api.interceptors.request.use(async (config) => {
  const token = auth.getToken();
  const apiConfig = config;

  if (token) apiConfig.headers["Authorization"] = `Bearer ${token}`;

  apiConfig.withCredentials = false;

  return apiConfig;
});

export default api;
